import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { DataShareService } from '../../services/data-share.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ConfigService } from '../../services/config.service';
import swal from 'sweetalert2';
import { IfStmt } from '../../../../node_modules/@angular/compiler';
b2cModalz: ElementRef;
@Component({
  selector: 'app-product-boundary-condition',
  templateUrl: './product-boundary-condition.component.html',
  styleUrls: ['./product-boundary-condition.component.scss']
})
export class ProductBoundaryConditionComponent implements OnInit {
  b2cModalz;
  benefits: any = [];
  child;
  savingData: any = {};
  childData: any = {};
  productName: any = [];
  name: any;
  showData: any = [];
  benefitData: any = [];
  savedData: any = [];
  policyList: any;
  partner_id: any;
  product_id: any;
  loan_id: any;
  partner_name: any;
  partner_logo: any;
  loanType: any;
  policyName: any;
  finalData: any;
  minAge;
  maxAge;
  minCoverCeasing;
  maxCoverCeasing;
  coverageType;
  bands;
  groupLoanSecure = environment.groupLoanSecure;
  superProductCredit = environment.superProductCredit;
  shubhRakshaCredit = environment.shubhRakshaCredit;
  goNext = false;
  level1 = {}
  level2 = {}
  level3 = {}
  selectedMortality = ""
  tempBenefits: any;
  copyFromBenefits: any = {}
  currBenefit = ""
  benefitNames: any = []
  copyFromBenefitName = ""
  dbMinTerm: any;
  dbMaxTerm: any;
  coverageTerm;
  aciciPresent = false;
  emicanPresent = false;
  benefitMaxAge;
  @ViewChild("b2cModalz") set content(content: ElementRef) {
    this.b2cModalz = content;
  }
  constructor(public routes: Router, public change: DataShareService, public config: ConfigService, public el: ElementRef, public loader: Ng4LoadingSpinnerService) {
    if (environment.loginSuccessfully == true) {
      this.coverageTerm = environment.maxCoverageTerm;
      var data = [];
      data = environment.savedProductBenefit[0].benefits;
      this.benefitMaxAge = environment.fixedData[0].max_age_at_entry
      if (this.groupLoanSecure) {
        //push death benefit first
        for (var i = 0; i < data["AgeBandWise"].length; i++) {
          var bands = Object.keys(data["AgeBandWise"][i]["Band"])
          for (var j = 0; j < bands.length; j++) {
            var age = bands[j].split("-");
            var allAge = []
            for (var k = 0; k < age.length; k++) {
              allAge.push(parseInt(age[k]))
            }
            if (data["AgeBandWise"][i].name.startsWith('Death Benefit')) {
              this.benefits.push({ "title": data["AgeBandWise"][i].name, "type": "AgeBandWise", "bands": bands[j], "minAge": Math.min(...allAge), "maxAge": Math.max(...allAge) })
            }
          }
        }
        //push everything else
        for (var i = 0; i < data["AgeBandWise"].length; i++) {
          var bands = Object.keys(data["AgeBandWise"][i]["Band"])
          for (var j = 0; j < bands.length; j++) {
            var age = bands[j].split("-");
            var allAge = []
            for (var k = 0; k < age.length; k++) {
              allAge.push(parseInt(age[k]))
            }
            if (!(data["AgeBandWise"][i].name.startsWith('Death Benefit'))) {
              this.benefits.push({ "title": data["AgeBandWise"][i].name, "type": "AgeBandWise", "bands": bands[j], "minAge": Math.min(...allAge), "maxAge": Math.max(...allAge) })
            }
          }
        }
      } else {
        var dbCovg
        //push death benefit first
        for (var i = 0; i < data["Flat"].length; i++) {
          if (data["Flat"][i].name.startsWith('Death Benefit')) {
            this.benefits.push({ "title": data["Flat"][i].name, "type": "Flat" })
            dbCovg = "Flat"
          }
        }
        for (var i = 0; i < data["TermWise"].length; i++) {
          var bands = Object.keys(data["TermWise"][i]["Band"])
          for (var j = 0; j < bands.length; j++) {
            var term = bands[j].split("-");
            var allTerm = []
            for (var k = 0; k < term.length; k++) {
              allTerm.push(parseInt(term[k]))
            }
            if (data["TermWise"][i].name.startsWith("Death Benefit")) {
              dbCovg = "TermWise"
              this.benefits.push({ "title": data["TermWise"][i].name, "type": "TermWise", "bands": bands[j], "minTerm": Math.min(...allTerm), "maxTerm": Math.max(...allTerm) })
            }
          }
        }
        for (var i = 0; i < data["AgeBandWise"].length; i++) {
          var bands = Object.keys(data["AgeBandWise"][i]["Band"])
          for (var j = 0; j < bands.length; j++) {
            var age = bands[j].split("-");
            var allAge = []
            for (var k = 0; k < age.length; k++) {
              allAge.push(parseInt(age[k]))
            }
            if (data["AgeBandWise"][i].name.startsWith('Death Benefit')) {
              dbCovg = "AgeBandWise"
              this.benefits.push({ "title": data["AgeBandWise"][i].name, "type": "AgeBandWise", "bands": bands[j], "minAge": Math.min(...allAge), "maxAge": Math.max(...allAge) })
            }
          }
        }
        //push everything else now
        if (dbCovg == "Flat") {
          for (var i = 0; i < data["Flat"].length; i++) {
            if (!(data["Flat"][i].name.startsWith('Death Benefit'))) {
              this.benefits.push({ "title": data["Flat"][i].name, "type": "Flat" })
            }
          }
        } else if (dbCovg == "TermWise") {
          for (var i = 0; i < data["TermWise"].length; i++) {
            var bands = Object.keys(data["TermWise"][i]["Band"])
            for (var j = 0; j < bands.length; j++) {
              var term = bands[j].split("-");
              var allTerm = []
              for (var k = 0; k < term.length; k++) {
                allTerm.push(parseInt(term[k]))
              }
              if (!(data["TermWise"][i].name.startsWith("Death Benefit"))) {
                this.benefits.push({ "title": data["TermWise"][i].name, "type": "TermWise", "bands": bands[j], "minTerm": Math.min(...allTerm), "maxTerm": Math.max(...allTerm) })
              }
            }
          }
        } else if (dbCovg == "AgeBandWise") {
          for (var i = 0; i < data["AgeBandWise"].length; i++) {
            var bands = Object.keys(data["AgeBandWise"][i]["Band"])
            for (var j = 0; j < bands.length; j++) {
              var age = bands[j].split("-");
              var allAge = []
              for (var k = 0; k < age.length; k++) {
                allAge.push(parseInt(age[k]))
              }
              if (!(data["AgeBandWise"][i].name.startsWith('Death Benefit'))) {
                this.benefits.push({ "title": data["AgeBandWise"][i].name, "type": "AgeBandWise", "bands": bands[j], "minAge": Math.min(...allAge), "maxAge": Math.max(...allAge) })
              }
            }
          }
        }
        if (dbCovg != "Flat") {
          for (var i = 0; i < data["Flat"].length; i++) {
            if (!(data["Flat"][i].name.startsWith('Death Benefit'))) {
              this.benefits.push({ "title": data["Flat"][i].name, "type": "Flat" })
            }
          }
        }
        if (dbCovg != "TermWise") {
          for (var i = 0; i < data["TermWise"].length; i++) {
            var bands = Object.keys(data["TermWise"][i]["Band"])
            for (var j = 0; j < bands.length; j++) {
              var term = bands[j].split("-");
              var allTerm = []
              for (var k = 0; k < term.length; k++) {
                allTerm.push(parseInt(term[k]))
              }
              if (!(data["TermWise"][i].name.startsWith("Death Benefit"))) {
                this.benefits.push({ "title": data["TermWise"][i].name, "type": "TermWise", "bands": bands[j], "minTerm": Math.min(...allTerm), "maxTerm": Math.max(...allTerm) })
              }
            }
          }
        }
        if (dbCovg != "AgeBandWise") {
          for (var i = 0; i < data["AgeBandWise"].length; i++) {
            var bands = Object.keys(data["AgeBandWise"][i]["Band"])
            for (var j = 0; j < bands.length; j++) {
              var age = bands[j].split("-");
              var allAge = []
              for (var k = 0; k < age.length; k++) {
                allAge.push(parseInt(age[k]))
              }
              if (!(data["AgeBandWise"][i].name.startsWith('Death Benefit'))) {
                this.benefits.push({ "title": data["AgeBandWise"][i].name, "type": "AgeBandWise", "bands": bands[j], "minAge": Math.min(...allAge), "maxAge": Math.max(...allAge) })
              }
            }
          }
        }
      }
      if (this.groupLoanSecure) {
        this.level3["AgeBandWise"] = {}
      } else {
        for (var i = 0; i < this.benefits.length; i++) {
          if (this.benefits[i].title.startsWith('EMI Waiver(Hosp)') || this.benefits[i].title == ('Cancer Protect')) {
            this.emicanPresent = true
            break;
          }
        }
        if (this.emicanPresent == false) {
          for (var i = 0; i < this.benefits.length; i++) {
            if (this.benefits[i].title.startsWith('Additional CI') || this.benefits[i].title.startsWith('Accelerated CI')) {
              this.aciciPresent = true
              break;
            }
          }
        }
        this.level3["FlatWise"] = {}
        this.level3["AgeBandWise"] = {}
        this.level3["TermWise"] = {}
      }
      console.log(this.benefits);

      var productMapping = environment.savedProductMapping[0];
    }
    else {
      this.routes.navigate(["login"]);
    }

  }
  ngOnInit() {
  }

  ngAfterViewInit() {
    var __self = this;
    setInterval(function () {
      __self.el.nativeElement.querySelectorAll('input').forEach(function (input) {
        input.onpaste = function (e) {
          e.preventDefault();
        }
      })
    }, 500)
    if (Object.keys(environment.savedProductBoundryCondition).length > 0) {
      this.showData = environment.savedProductBoundryCondition;
      var row = document.querySelectorAll("div.product-row");
      console.log(this.showData);
      if (this.groupLoanSecure) {
        for (var i = 0; i < row.length; i++) {
          this.productName = [];
          var band;
          var benName;
          var covType;
          var column = row[i].querySelectorAll('.product-column');
          for (var j = 0; j < column.length; j++) {
            if (i > 0) {
              this.child = row[i].querySelectorAll('.product-column')[j];
              if (this.child.classList.value.includes("product-column product-name")) {
                this.productName.push({ "product_name": this.child.innerText });
                this.name = (this.productName[0].product_name).trim();
                for (var k = 0; k < this.benefits.length; k++) {
                  if (this.name.startsWith(this.benefits[k].title)) {
                    if (this.name.includes(this.benefits[k].bands)) {
                      band = this.benefits[k].bands
                      benName = this.benefits[k].title
                      covType = this.benefits[k].type
                    } else if (this.benefits[k].bands == '999') {
                      band = this.benefits[k].bands
                      benName = this.benefits[k].title
                      covType = this.benefits[k].type
                    }
                  }
                }
                if (this.showData[covType][benName] != undefined) {
                  if (this.showData[covType][benName][band] != undefined) {
                    this.benefitData = this.showData[covType][benName][band];
                  } else {
                    this.benefitData = []
                  }
                }
                else {
                  this.benefitData = []
                }
                console.log(this.benefitData);
              }
              else if (this.child.classList.value.includes("product-column product-min-age") && Object.keys(this.benefitData).length > 0) {
                var value = this.config.getValues(this.benefitData, "product-min-age");
                this.child.querySelector('input').value = value;
              }
              else if (this.child.classList.value.includes("product-column product-max-age") && Object.keys(this.benefitData).length > 0) {
                var value = this.config.getValues(this.benefitData, "product-max-age");
                this.child.querySelector('input').value = value;
              }
              else if (this.child.classList.value.includes("product-column product-min-cover-ceasing-age") && Object.keys(this.benefitData).length > 0) {
                var value = this.config.getValues(this.benefitData, "product-min-cover-ceasing-age");
                this.child.querySelector('input').value = value;
              }
              else if (this.child.classList.value.includes("product-column product-cover-ceasing-age") && Object.keys(this.benefitData).length > 0) {
                var value = this.config.getValues(this.benefitData, "product-cover-ceasing-age");
                this.child.querySelector('input').value = value;
              }
              else if (this.child.classList.value.includes("product-column product-min-sum-assured") && Object.keys(this.benefitData).length > 0) {
                var value = this.config.getValues(this.benefitData, "product-min-sum-assured");
                this.child.querySelector('input').value = value;
              }
              else if (this.child.classList.value.includes("product-column product-max-sum-assured") && Object.keys(this.benefitData).length > 0) {
                var value = this.config.getValues(this.benefitData, "product-max-sum-assured");
                this.child.querySelector('input').value = value;
              }
              else if (this.child.classList.value.includes("product-column product-min-policy-term") && Object.keys(this.benefitData).length > 0) {
                var value = this.config.getValues(this.benefitData, "product-min-policy-term");
                this.child.querySelector('input').value = value;
              }
              else if (this.child.classList.value.includes("product-column product-max-policy-term") && Object.keys(this.benefitData).length > 0) {
                var value = this.config.getValues(this.benefitData, "product-max-policy-term");
                this.child.querySelector('input').value = value;
              }
            }
          }
        }
      } else if (this.superProductCredit) {
        for (var i = 0; i < row.length; i++) {
          this.productName = [];
          var band;
          var benName;
          var covType;
          var column = row[i].querySelectorAll('.product-column');
          for (var j = 0; j < column.length; j++) {
            if (i > 0) {
              this.child = row[i].querySelectorAll('.product-column')[j];
              if (this.child.classList.value.includes("product-column product-name")) {
                this.productName.push({ "product_name": this.child.innerText });
                this.name = (this.productName[0].product_name).trim();
                for (var k = 0; k < this.benefits.length; k++) {
                  if (this.name.startsWith(this.benefits[k].title)) {
                    if (this.benefits[k].type != "Flat" && this.name.includes(this.benefits[k].bands)) {
                      band = this.benefits[k].bands
                      benName = this.benefits[k].title
                      covType = this.benefits[k].type
                    } else if (this.benefits[k].type == "Flat") {
                      benName = this.benefits[k].title
                      covType = "FlatWise"
                    }
                  }
                }
                if (covType != "FlatWise") {
                  if (this.showData[covType][benName] != undefined) {
                    if (this.showData[covType][benName][band] != undefined) {
                      this.benefitData = this.showData[covType][benName][band];
                    } else {
                      this.benefitData = []
                    }
                  }
                  else {
                    this.benefitData = []
                  }
                } else {
                  if (this.showData[covType][benName] != undefined) {
                    this.benefitData = this.showData[covType][benName];
                  } else {
                    this.benefitData = []
                  }
                }
                console.log(this.benefitData);
              }
              else if (this.child.classList.value.includes("product-column product-min-age") && Object.keys(this.benefitData).length > 0) {
                var value = this.config.getValues(this.benefitData, "product-min-age");
                this.child.querySelector('input').value = value;
              }
              else if (this.child.classList.value.includes("product-column product-max-age") && Object.keys(this.benefitData).length > 0) {
                var value = this.config.getValues(this.benefitData, "product-max-age");
                this.child.querySelector('input').value = value;
              }
              else if (this.child.classList.value.includes("product-column product-min-cover-ceasing-age") && Object.keys(this.benefitData).length > 0) {
                var value = this.config.getValues(this.benefitData, "product-min-cover-ceasing-age");
                this.child.querySelector('input').value = value;
              }
              else if (this.child.classList.value.includes("product-column product-cover-ceasing-age") && Object.keys(this.benefitData).length > 0) {
                var value = this.config.getValues(this.benefitData, "product-cover-ceasing-age");
                this.child.querySelector('input').value = value;
              }
              else if (this.child.classList.value.includes("product-column product-min-sum-assured") && Object.keys(this.benefitData).length > 0) {
                var value = this.config.getValues(this.benefitData, "product-min-sum-assured");
                this.child.querySelector('input').value = value;
              }
              else if (this.child.classList.value.includes("product-column product-max-sum-assured") && Object.keys(this.benefitData).length > 0) {
                var value = this.config.getValues(this.benefitData, "product-max-sum-assured");
                this.child.querySelector('input').value = value;
              }
              else if (this.child.classList.value.includes("product-column product-min-policy-term") && Object.keys(this.benefitData).length > 0) {
                var value = this.config.getValues(this.benefitData, "product-min-policy-term");
                this.child.querySelector('input').value = value;
              }
              else if (this.child.classList.value.includes("product-column product-max-policy-term") && Object.keys(this.benefitData).length > 0) {
                var value = this.config.getValues(this.benefitData, "product-max-policy-term");
                this.child.querySelector('input').value = value;
              }
            }
          }
        }
      }
    }
  }

  saveDataOnNext() {
    this.saveData();
    if (this.goNext == true) {
      this.routes.navigate(['product-scheme']);
    }

  }
  checknumber(e, data) {
    var re = /^[0-9\ _.-]+$/;
    var str = e.target.value;
    if (e.keyCode != 45 && (e.keyCode < 48 || e.keyCode > 57)) {
      return false;
    }
  }

  checkbox(event) {
    var benefitName = event.target.innerText
    this.copyFromBenefits = {}
    this.benefitNames = []
    event.target.parentElement.classList.add('checked')
    this.saveDataForCopy()
    var pushBen = true
    for (var key in this.savingData) {
      pushBen = true
      if (this.savingData.hasOwnProperty(key)) {
        for (var innerKey in this.savingData[key]) {
          if (this.savingData[key][innerKey] == "") {
            pushBen = false
          }
        }
      }
      if (pushBen == true) {
        this.copyFromBenefits[key] = this.savingData[key]
      }
    }
    this.currBenefit = event.target.innerText
    var benName = Object.keys(this.copyFromBenefits)
    for (var q = 0; q < benName.length; q++) {
      if (benName[q] != this.currBenefit) {
        if (benefitName.startsWith("Terminal Illness")) {
          if (benName[q].startsWith("Death Benefit")) {
            var band = benefitName.replace("Terminal Illness", "")
            if (band != "") {
              if (benName[q].includes(band)) {
                this.benefitNames.push({ "item_name": benName[q] })
              }
            } else {
              this.benefitNames.push({ "item_name": benName[q] })
            }
          }
        }
        // commented by ramji
        // else if (benefitName.startsWith("Enhanced ADB")) {
        //   if (benName[q].startsWith("Accidental Death Benefit")) {
        //     var band = benefitName.replace("Enhanced ADB", "")
        //     if (band != "") {
        //       if (benName[q].includes(band)) {
        //         this.benefitNames.push({ "item_name": benName[q] })
        //       }
        //     } else {
        //       this.benefitNames.push({ "item_name": benName[q] })
        //     }
        //   }
        // }
        else {
          this.benefitNames.push({ "item_name": benName[q] })
        }
      }
    }
    if (this.benefitNames.length > 0) {
      this.b2cModalz.open();
    } else {
      swal({
        title: 'Please fill at least one row before attempting to copy',
        type: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#eb1826',
        cancelButtonColor: '#F4F1F1',
        confirmButtonText: 'OK'
      }).then((result) => {
      })
    }
    this.el.nativeElement.querySelector('.checked').classList.remove('checked')
  }

  selectRadioValue(value) {
    this.copyFromBenefitName = value
  }
  // submitDataOnSave() {
  //   this.saveData();
  // }
  validationCheck(e, data) {
    //ancient code. Do not blame the previous developer for not using switch case.
    var value = parseInt(e.target.value);
    var benefitName = e.target.parentElement.parentElement.firstElementChild.innerText;
    var min_age = e.target.parentElement.parentElement.querySelector('.product-min-age').firstElementChild.value
    var max_age = e.target.parentElement.parentElement.querySelector('.product-max-age').firstElementChild.value
    var min_cover = e.target.parentElement.parentElement.querySelector('.product-min-cover-ceasing-age').firstElementChild.value
    var max_cover = e.target.parentElement.parentElement.querySelector('.product-cover-ceasing-age').firstElementChild.value
    var min_sum = e.target.parentElement.parentElement.querySelector('.product-min-sum-assured').firstElementChild.value
    var max_sum = e.target.parentElement.parentElement.querySelector('.product-max-sum-assured').firstElementChild.value
    var min_term = e.target.parentElement.parentElement.querySelector('.product-min-policy-term').firstElementChild.value
    var max_term = e.target.parentElement.parentElement.querySelector('.product-max-policy-term').firstElementChild.value
    var min_cover_limit
    var max_cover_limit
    min_age = parseInt(min_age)
    max_age = parseInt(max_age)
    min_cover = parseInt(min_cover)
    max_cover = parseInt(max_cover)
    min_sum = parseInt(min_sum)
    max_sum = parseInt(max_sum)
    min_term = parseInt(min_term)
    max_term = parseInt(max_term)
    var minTerm = []
    var maxTerm = []
    var minSum = []
    var maxSum = []
    var minAge = []
    var maxAge = []
    var minCoverCeasing = []
    var maxCoverCeasing = []
    environment.minPolicyTerm = 2
    environment.maxPolicyTerm = environment.maxCoverageTerm
    this.el.nativeElement.querySelectorAll('.product-row:not(:first-child)').forEach(function (rows) {
      var benefit = rows.querySelector('#benefitName').innerText
      if (benefit.startsWith("Death Benefit")) {
        if (rows.querySelector('#minAge').value != "") {
          minAge.push(parseInt(rows.querySelector('#minAge').value))
          environment.minAge = Math.min(...minAge)
        }
        if (rows.querySelector('#maxAge').value != "") {
          maxAge.push(parseInt(rows.querySelector('#maxAge').value))
          environment.maxAge = Math.max(...maxAge)
        }
        if (rows.querySelector('#mincoverCeasingAge').value != "") {
          minCoverCeasing.push(parseInt(rows.querySelector('#mincoverCeasingAge').value))
          environment.minCoverCeasing = Math.min(...minCoverCeasing)
        }
        if (rows.querySelector('#coverCeasingAge').value != "") {
          maxCoverCeasing.push(parseInt(rows.querySelector('#coverCeasingAge').value))
          environment.maxCoverCeasing = Math.max(...maxCoverCeasing)
        }
        if (rows.querySelector('#minSumAssured').value != "") {
          minSum.push(parseInt(rows.querySelector('#minSumAssured').value))
          environment.minSumAssured = Math.min(...minSum)
        }
        if (rows.querySelector('#maxSumAssured').value != "") {
          maxSum.push(parseInt(rows.querySelector('#maxSumAssured').value))
          environment.maxSumAssured = Math.max(...maxSum)
        }
        if (rows.querySelector('#minPolicyTerm').value != "") {
          minTerm.push(parseInt(rows.querySelector('#minPolicyTerm').value))
          environment.minPolicyTerm = Math.min(...minTerm)
        }
        if (rows.querySelector('#maxPolicyTerm').value != "") {
          maxTerm.push(parseInt(rows.querySelector('#maxPolicyTerm').value))
          environment.maxPolicyTerm = Math.max(...maxTerm)
        }
      }
    })
    //conflict check
    if (data == "minAge") {
      if (value > max_age && max_age != NaN) {
        e.target.value = ""
        swal({
          title: 'Min age cannot be greater than max age',
          type: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#eb1826',
          cancelButtonColor: '#F4F1F1',
          confirmButtonText: 'OK'
        }).then((result) => {
          e.target.classList.add("error");
        })
      }
      else {
        e.target.classList.remove("error");
      }
    }
    else if (data == "maxAge") {
      if (value < min_age && min_age != NaN) {
        e.target.value = ""
        swal({
          title: 'Max age cannot be less than min age',
          type: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#eb1826',
          cancelButtonColor: '#F4F1F1',
          confirmButtonText: 'OK'
        }).then((result) => {
          e.target.classList.add("error");
        })
      }
      else {
        e.target.classList.remove("error");
      }
    }
    else if (data == "mincoverCeasingAge") {
      if (value > max_cover && max_cover != NaN) {
        e.target.value = ""
        swal({
          title: 'Min cover ceasing age cannot be greater than max cover ceasing age',
          type: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#eb1826',
          cancelButtonColor: '#F4F1F1',
          confirmButtonText: 'OK'
        }).then((result) => {
          e.target.classList.add("error");
        })
      }
      else {
        e.target.classList.remove("error");
      }
    }
    else if (data == "coverCeasingAge") {
      if (value < min_cover && min_cover != NaN) {
        e.target.value = ""
        swal({
          title: 'Max cover ceasing age cannot be less than min cover ceasing age',
          type: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#eb1826',
          cancelButtonColor: '#F4F1F1',
          confirmButtonText: 'OK'
        }).then((result) => {
          e.target.classList.add("error");
        })
      }
      else {
        e.target.classList.remove("error");
      }
    }
    else if (data == "minSumAssured") {
      if (value > max_sum && max_sum != NaN) {
        e.target.value = ""
        swal({
          title: 'Min sum assured cannot be greater than max sum assured',
          type: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#eb1826',
          cancelButtonColor: '#F4F1F1',
          confirmButtonText: 'OK'
        }).then((result) => {
          e.target.classList.add("error");
        })
      }
      else {
        e.target.classList.remove("error");
      }
    }
    else if (data == "maxSumAssured") {
      if (value < min_sum && min_sum != NaN) {
        e.target.value = ""
        swal({
          title: 'Max sum assured cannot be less than min sum assured',
          type: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#eb1826',
          cancelButtonColor: '#F4F1F1',
          confirmButtonText: 'OK'
        }).then((result) => {
          e.target.classList.add("error");
        })
      }
      else {
        e.target.classList.remove("error");
      }
    }
    else if (data == "minPolicyTerm") {
      if (value > max_term && max_term != NaN) {
        e.target.value = ""
        swal({
          title: 'Min policy term cannot be greater than max policy term',
          type: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#eb1826',
          cancelButtonColor: '#F4F1F1',
          confirmButtonText: 'OK'
        }).then((result) => {
          e.target.classList.add("error");
        })
      }
      else {
        e.target.classList.remove("error");
      }
    }
    else if (data == "maxPolicyTerm") {
      if (value < min_term && min_term != NaN) {
        e.target.value = ""
        swal({
          title: 'Max policy term cannot be less than min policy term',
          type: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#eb1826',
          cancelButtonColor: '#F4F1F1',
          confirmButtonText: 'OK'
        }).then((result) => {
          e.target.classList.add("error");
        })
      }
      else {
        e.target.classList.remove("error");
      }
    }
    if (isNaN(min_age)) {
      min_cover_limit = environment.fixedData[0].min_cover_ceasing_age
    } else {
      if (environment.groupLoanSecure) {
        min_cover_limit = min_age + 2;
        if (min_cover_limit > environment.fixedData[0].max_cover_ceasing_age) {
          min_cover_limit = environment.fixedData[0].max_cover_ceasing_age
        }
      } else if (environment.superProductCredit) {
        // min_cover_limit = min_age + this.coverageTerm;
        min_cover_limit = min_age + 2;
        if (min_cover_limit > environment.fixedData[0].max_cover_ceasing_age) {
          min_cover_limit = environment.fixedData[0].max_cover_ceasing_age
        }
      }
    }
    if (isNaN(max_age)) {
      max_cover_limit = environment.fixedData[0].max_cover_ceasing_age
    } else {
      if (environment.groupLoanSecure) {
        max_cover_limit = max_age + 5;
        if (max_cover_limit > environment.fixedData[0].max_cover_ceasing_age) {
          max_cover_limit = environment.fixedData[0].max_cover_ceasing_age
        }
      } else if (environment.superProductCredit) {
        max_cover_limit = max_age + this.coverageTerm;
        if (max_cover_limit > environment.fixedData[0].max_cover_ceasing_age) {
          max_cover_limit = environment.fixedData[0].max_cover_ceasing_age
        }
      }
    }
    if (data == "minAge" || data == "maxAge") {
      if (benefitName.startsWith("Death Benefit")) {
        if (value < environment.fixedData[0].min_age_at_entry || value > environment.fixedData[0].max_age_at_entry) {
          e.target.value = ""
          swal({
            title: 'Please enter age between' + ' ' + environment.fixedData[0]["min_age_at_entry"] + ' to' + ' ' + environment.fixedData[0]["max_age_at_entry"],
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#eb1826',
            cancelButtonColor: '#F4F1F1',
            confirmButtonText: 'OK'
          }).then((result) => {
            e.target.classList.add("error");
          })
        }
        else {
          e.target.classList.remove("error");
          if (data == "minAge" && !(isNaN(value))) {
            var a = e.target.parentElement.parentElement.querySelector('input#mincoverCeasingAge')
            if (environment.groupLoanSecure) {
              a.value = value + 2
              if (a.value < 18) {
                a.value = 18
              }
            }
            else if (environment.superProductCredit) {
              a.value = value + 2;  //min ceasing age spc
              if (a.value > 80) {
                a.value = 80
              }
            }
          } else if (data == "maxAge" && !(isNaN(value))) {
            var a = e.target.parentElement.parentElement.querySelector('input#coverCeasingAge')
            if (environment.groupLoanSecure) {
              a.value = value + 5
              if (a.value < 18) {
                a.value = 18
              }
            }
            else if (environment.superProductCredit) {
              a.value = value + this.coverageTerm;
              if (a.value > 80) {
                a.value = 80
              }
            }
          }
        }
      } else {
        if (value < environment.minAge || value > environment.maxAge) {
          e.target.value = ""
          swal({
            title: 'Please enter age between' + ' ' + environment.minAge + ' to' + ' ' + environment.maxAge,
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#eb1826',
            cancelButtonColor: '#F4F1F1',
            confirmButtonText: 'OK'
          }).then((result) => {
            e.target.classList.add("error");
          })
        }
        else {
          e.target.classList.remove("error");
          if (data == "minAge" && !(isNaN(value))) {
            var a = e.target.parentElement.parentElement.querySelector('input#mincoverCeasingAge')
            if (environment.groupLoanSecure) {
              a.value = value + 2
              if (a.value < 18) {
                a.value = 18
              }
            }
            else if (environment.superProductCredit) {
              a.value = value + this.coverageTerm;
              if (a.value > 80) {
                a.value = 80
              }
            }
          } else if (data == "maxAge" && !(isNaN(value))) {
            var a = e.target.parentElement.parentElement.querySelector('input#coverCeasingAge')
            if (environment.groupLoanSecure) {
              a.value = value + 5
              if (a.value < 18) {
                a.value = 18
              }
            }
            else if (environment.superProductCredit) {
              a.value = value + this.coverageTerm;
              if (a.value > 80) {
                a.value = 80
              }
            }
          }
        }
      }
    }
    else if (data == "coverCeasingAge" || data == "mincoverCeasingAge") {
      if (benefitName.startsWith("Death Benefit")) {
        if (value < min_cover_limit || value > max_cover_limit) {
          e.target.value = ""
          swal({
            title: 'Please enter cover ceasing age between' + ' ' + min_cover_limit + ' to' + ' ' + max_cover_limit,
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#eb1826',
            cancelButtonColor: '#F4F1F1',
            confirmButtonText: 'OK'
          }).then((result) => {
            e.target.classList.add("error");
          })
        }
        else {
          e.target.classList.remove("error");
        }
      } else {
        if (environment.minCoverCeasing > min_cover_limit) {
          min_cover_limit = environment.minCoverCeasing
        }
        if (environment.maxCoverCeasing < max_cover_limit) {
          max_cover_limit = environment.maxCoverCeasing
        }
        if (value < min_cover_limit || value > max_cover_limit) {
          e.target.value = ""
          swal({
            title: 'Please enter cover ceasing age between' + ' ' + min_cover_limit + ' to' + ' ' + max_cover_limit,
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#eb1826',
            cancelButtonColor: '#F4F1F1',
            confirmButtonText: 'OK'
          }).then((result) => {
            e.target.classList.add("error");
          })
        }
        else {
          e.target.classList.remove("error");
        }
      }
    }
    else if (data == "minSumAssured" || data == "maxSumAssured") {
      if (benefitName.startsWith("Death Benefit")) {
        if (value < environment.fixedData[0].min_sum_assured) {
          e.target.value = ""
          swal({
            title: 'Minimum sum assured starts from ' + ' ' + environment.fixedData[0]["min_sum_assured"],
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#eb1826',
            cancelButtonColor: '#F4F1F1',
            confirmButtonText: 'OK'
          }).then((result) => {
            e.target.classList.add("error");
          })
        }
        else {
          e.target.classList.remove("error");
        }
      } else if (environment.maxSumAssured == 0) {
        if (benefitName.includes("EMI Waiver(Hosp)")) {
          if (value < environment.minSumAssured || value > environment.fixedData[0]["max_sum_assured_emi"]) {
            e.target.value = ""
            swal({
              title: 'Sum assured limit should be between ' + environment.minSumAssured + ' to ' + environment.fixedData[0]["max_sum_assured_emi"],
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#eb1826',
              cancelButtonColor: '#F4F1F1',
              confirmButtonText: 'OK'
            }).then((result) => {
              e.target.classList.add("error");
            })
          }
          else {
            e.target.classList.remove("error");
          }
        } else if (benefitName.includes("Cancer Protect")) {
          if (value < environment.minSumAssured || value > environment.fixedData[0]["max_sum_assured_cancer"]) {
            e.target.value = ""
            swal({
              title: 'Sum assured limit should be between ' + environment.minSumAssured + ' to ' + environment.fixedData[0]["max_sum_assured_cancer"],
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#eb1826',
              cancelButtonColor: '#F4F1F1',
              confirmButtonText: 'OK'
            }).then((result) => {
              e.target.classList.add("error");
            })
          }
          else {
            e.target.classList.remove("error");
          }
        } else {
          if (value < environment.minSumAssured) {
            e.target.value = ""
            swal({
              title: 'Minimum sum assured starts from ' + ' ' + environment.minSumAssured,
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#eb1826',
              cancelButtonColor: '#F4F1F1',
              confirmButtonText: 'OK'
            }).then((result) => {
              e.target.classList.add("error");
            })
          }
          else {
            e.target.classList.remove("error");
          }
        }
      } else {
        if (benefitName.includes("EMI Waiver(Hosp)")) {
          var max_sum_emi = environment.fixedData[0]["max_sum_assured_emi"]
          if (environment.maxSumAssured < max_sum_emi) {
            max_sum_emi = environment.maxSumAssured
          }
          if (value < environment.minSumAssured || value > max_sum_emi) {
            e.target.value = ""
            swal({
              title: 'Sum assured limit should be between ' + environment.minSumAssured + ' to ' + max_sum_emi,
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#eb1826',
              cancelButtonColor: '#F4F1F1',
              confirmButtonText: 'OK'
            }).then((result) => {
              e.target.classList.add("error");
            })
          }
          else {
            e.target.classList.remove("error");
          }
        } else if (benefitName.includes("Cancer Protect")) {
          var max_sum_can = environment.fixedData[0]["max_sum_assured_cancer"]
          if (environment.maxSumAssured < max_sum_can) {
            max_sum_can = environment.maxSumAssured
          }
          if (value < environment.minSumAssured || value > max_sum_can) {
            e.target.value = ""
            swal({
              title: 'Sum assured limit should be between ' + environment.minSumAssured + ' to ' + max_sum_can,
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#eb1826',
              cancelButtonColor: '#F4F1F1',
              confirmButtonText: 'OK'
            }).then((result) => {
              e.target.classList.add("error");
            })
          }
          else {
            e.target.classList.remove("error");
          }
        } else {
          if (value < environment.minSumAssured || value > environment.maxSumAssured) {
            e.target.value = ""
            swal({
              title: 'Sum assured ranges from ' + environment.minSumAssured + ' to ' + environment.maxSumAssured,
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#eb1826',
              cancelButtonColor: '#F4F1F1',
              confirmButtonText: 'OK'
            }).then((result) => {
              e.target.classList.add("error");
            })
          }
          else {
            e.target.classList.remove("error");
          }
        }
      }
    }
    else if (data == "minPolicyTerm" || data == "maxPolicyTerm") {
      if (benefitName.startsWith("Death Benefit")) {
        if (value < environment.fixedMinPolicyTerm || value > environment.maxCoverageTerm) {
          e.target.value = ""
          swal({
            title: 'Please enter Policy Term between' + ' ' + environment.fixedMinPolicyTerm + ' to' + ' ' + environment.maxCoverageTerm,
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#eb1826',
            cancelButtonColor: '#F4F1F1',
            confirmButtonText: 'OK'
          }).then((result) => {
            e.target.classList.add("error");
          })
        }
        else {
          e.target.classList.remove("error");
        }
      } else if (benefitName.includes("Accelerated CI") || benefitName.includes("Additional CI")) {
        var aci
        environment.maxPolicyTerm > environment.acimaxPolicyTerm ? aci = environment.acimaxPolicyTerm : aci = environment.maxPolicyTerm;
        if (value < environment.minPolicyTerm || value > aci) {
          e.target.value = ""
          swal({
            title: 'Please enter Policy Term between' + ' ' + environment.minPolicyTerm + ' to' + ' ' + aci,
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#eb1826',
            cancelButtonColor: '#F4F1F1',
            confirmButtonText: 'OK'
          }).then((result) => {
            e.target.classList.add("error");
          })
        }
        else {
          e.target.classList.remove("error");
        }
      } else if (benefitName.includes("EMI Waiver(Hosp)") || benefitName.includes("Cancer Protect")) {
        var canceremi
        environment.maxPolicyTerm > environment.canceremimaxPolicyTerm ? canceremi = environment.canceremimaxPolicyTerm : canceremi = environment.maxPolicyTerm;
        if (value < environment.minPolicyTerm || value > canceremi) {
          e.target.value = ""
          swal({
            title: 'Please enter Policy Term between' + ' ' + environment.minPolicyTerm + ' to' + ' ' + canceremi,
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#eb1826',
            cancelButtonColor: '#F4F1F1',
            confirmButtonText: 'OK'
          }).then((result) => {
            e.target.classList.add("error");
          })
        }
        else {
          e.target.classList.remove("error");
        }
      } else {
        if (value < environment.minPolicyTerm || value > environment.maxPolicyTerm) {
          swal({
            title: 'Please enter Policy Term between' + ' ' + environment.minPolicyTerm + ' to' + ' ' + environment.maxPolicyTerm,
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#eb1826',
            cancelButtonColor: '#F4F1F1',
            confirmButtonText: 'OK'
          }).then((result) => {
            e.target.classList.add("error");
          })
        }
        else {
          e.target.classList.remove("error");
        }
      }
    }

    if (e.target.value != "") {
      if (benefitName.startsWith("Death Benefit")) {
        var band = benefitName.replace("Death Benefit", "")
        this.saveDataForCopy();
        var copyData = {}
        copyData = this.savingData[benefitName]
        this.el.nativeElement.querySelectorAll('.product-column.product-name').forEach(function (benefit) {
          if (benefit.innerText.startsWith("Terminal Illness")) {
            if (band != "" && benefit.innerText.includes(band)) {
              var main = benefit.parentElement
              main.querySelector('.product-column.product-min-age').firstElementChild.value = copyData["product-min-age"]
              main.querySelector('.product-column.product-max-age').firstElementChild.value = copyData["product-max-age"]
              main.querySelector('.product-column.product-min-cover-ceasing-age').firstElementChild.value = copyData["product-min-cover-ceasing-age"]
              main.querySelector('.product-column.product-cover-ceasing-age').firstElementChild.value = copyData["product-cover-ceasing-age"]
              main.querySelector('.product-column.product-min-sum-assured').firstElementChild.value = copyData["product-min-sum-assured"]
              main.querySelector('.product-column.product-max-sum-assured').firstElementChild.value = copyData["product-max-sum-assured"]
              main.querySelector('.product-column.product-min-policy-term').firstElementChild.value = copyData["product-min-policy-term"]
              main.querySelector('.product-column.product-max-policy-term').firstElementChild.value = copyData["product-max-policy-term"]
            } else if (band == "") {
              var main = benefit.parentElement
              main.querySelector('.product-column.product-min-age').firstElementChild.value = copyData["product-min-age"]
              main.querySelector('.product-column.product-max-age').firstElementChild.value = copyData["product-max-age"]
              main.querySelector('.product-column.product-min-cover-ceasing-age').firstElementChild.value = copyData["product-min-cover-ceasing-age"]
              main.querySelector('.product-column.product-cover-ceasing-age').firstElementChild.value = copyData["product-cover-ceasing-age"]
              main.querySelector('.product-column.product-min-sum-assured').firstElementChild.value = copyData["product-min-sum-assured"]
              main.querySelector('.product-column.product-max-sum-assured').firstElementChild.value = copyData["product-max-sum-assured"]
              main.querySelector('.product-column.product-min-policy-term').firstElementChild.value = copyData["product-min-policy-term"]
              main.querySelector('.product-column.product-max-policy-term').firstElementChild.value = copyData["product-max-policy-term"]
            }
          }
        })
      } else if (benefitName.startsWith("Accidental Death Benefit")) {
        var band = benefitName.replace("Accidental Death Benefit", "")
        this.saveDataForCopy();
        var copyData = {}
        copyData = this.savingData[benefitName]
        this.el.nativeElement.querySelectorAll('.product-column.product-name').forEach(function (benefit) {
          if (benefit.innerText.startsWith("Enhanced ADB")) {
            if (band != "" && benefit.innerText.includes(band)) {
              var main = benefit.parentElement
              main.querySelector('.product-column.product-min-age').firstElementChild.value = copyData["product-min-age"]
              main.querySelector('.product-column.product-max-age').firstElementChild.value = copyData["product-max-age"]
              main.querySelector('.product-column.product-min-cover-ceasing-age').firstElementChild.value = copyData["product-min-cover-ceasing-age"]
              main.querySelector('.product-column.product-cover-ceasing-age').firstElementChild.value = copyData["product-cover-ceasing-age"]
              main.querySelector('.product-column.product-min-sum-assured').firstElementChild.value = copyData["product-min-sum-assured"]
              main.querySelector('.product-column.product-max-sum-assured').firstElementChild.value = copyData["product-max-sum-assured"]
              main.querySelector('.product-column.product-min-policy-term').firstElementChild.value = copyData["product-min-policy-term"]
              main.querySelector('.product-column.product-max-policy-term').firstElementChild.value = copyData["product-max-policy-term"]
            } else if (band == "") {
              var main = benefit.parentElement
              main.querySelector('.product-column.product-min-age').firstElementChild.value = copyData["product-min-age"]
              main.querySelector('.product-column.product-max-age').firstElementChild.value = copyData["product-max-age"]
              main.querySelector('.product-column.product-min-cover-ceasing-age').firstElementChild.value = copyData["product-min-cover-ceasing-age"]
              main.querySelector('.product-column.product-cover-ceasing-age').firstElementChild.value = copyData["product-cover-ceasing-age"]
              main.querySelector('.product-column.product-min-sum-assured').firstElementChild.value = copyData["product-min-sum-assured"]
              main.querySelector('.product-column.product-max-sum-assured').firstElementChild.value = copyData["product-max-sum-assured"]
              main.querySelector('.product-column.product-min-policy-term').firstElementChild.value = copyData["product-min-policy-term"]
              main.querySelector('.product-column.product-max-policy-term').firstElementChild.value = copyData["product-max-policy-term"]
            }
          }
        })
      }
    }
  }

  backbutton() {
    this.savingData = {}
    var row = document.querySelectorAll("div.product-row");
    this.productName = [];
    var check = 0
    var clear = false;
    if (this.groupLoanSecure) {
      for (var i = 0; i < row.length; i++) {
        this.productName = [];
        var column = row[i].querySelectorAll('.product-column');
        if (Object.keys(this.childData).length > 0) {
          console.log(this.name);
          this.savingData[this.name] = this.childData;
          this.productName = [];
          this.childData = {};
        }
        for (var j = 0; j < column.length; j++) {
          if (i > 0) {
            this.child = row[i].querySelectorAll('.product-column')[j];
            if (this.child.classList.value.includes("product-column product-name")) {
              this.productName.push({ "product_name": this.child.innerText });
              this.name = (this.productName[0].product_name).trim();
              for (var n = 0; n < this.benefits.length; n++) {
                if (this.name.startsWith(this.benefits[n].title)) {
                  if (this.benefits[n].bands != undefined && this.name.includes(this.benefits[n].bands)) {
                    this.name = this.benefits[n].title;
                    if (this.coverageType != undefined && this.coverageType != this.benefits[n].type) {
                      clear = true
                    }
                    this.coverageType = this.benefits[n].type;
                    this.bands = this.benefits[n].bands;
                    break;
                  } else if (this.benefits[n].bands == '999') {
                    this.name = this.benefits[n].title;
                    if (this.coverageType != undefined && this.coverageType != this.benefits[n].type) {
                      clear = true
                    }
                    this.coverageType = this.benefits[n].type;
                    this.bands = this.benefits[n].bands;
                  }
                }
              }

              console.log(this.name);
            }
            else if (this.child.classList.value.includes("product-column product-min-age")) {
              this.childData["product-min-age"] = this.child.querySelector('input').value;
              check++
            }
            else if (this.child.classList.value.includes("product-column product-max-age")) {
              this.childData["product-max-age"] = this.child.querySelector('input').value;
              check++
            }
            else if (this.child.classList.value.includes("product-column product-min-cover-ceasing-age")) {
              this.childData["product-min-cover-ceasing-age"] = this.child.querySelector('input').value;
              check++
            }
            else if (this.child.classList.value.includes("product-column product-cover-ceasing-age")) {
              this.childData["product-cover-ceasing-age"] = this.child.querySelector('input').value;
              check++
            }
            else if (this.child.classList.value.includes("product-column product-min-sum-assured")) {
              this.childData["product-min-sum-assured"] = this.child.querySelector('input').value;
              check++
            }
            else if (this.child.classList.value.includes("product-column product-max-sum-assured")) {
              this.childData["product-max-sum-assured"] = this.child.querySelector('input').value;
              check++
            }
            else if (this.child.classList.value.includes("product-column product-min-policy-term")) {
              this.childData["product-min-policy-term"] = this.child.querySelector('input').value;
              check++
            }
            else if (this.child.classList.value.includes("product-column product-max-policy-term")) {
              this.childData["product-max-policy-term"] = this.child.querySelector('input').value;
              check++
            }
          }
          if (check == 8 && this.coverageType != undefined) {
            if (!(this.level3[this.coverageType].hasOwnProperty(this.name))) {
              this.level1 = {}
            }
            if (clear) {
              this.level2 = {}
            }
            this.level1[this.bands] = this.childData
            this.level2[this.name] = this.level1
            this.level3[this.coverageType] = this.level2
            check = 0
            clear = false
          }
        }
      }
      this.savingData[this.name] = this.childData;
      console.log(this.name);
      console.log(this.savingData);
      if (this.coverageType == 'FlatWise') {
        this.level1['flat'] = this.childData
        this.level2[this.name] = this.level1
        this.level3[this.coverageType] = this.level2
      } else {
        this.level1[this.bands] = this.childData
        this.level2[this.name] = this.level1
        this.level3[this.coverageType] = this.level2
      }
      this.productName = [];
      this.childData = {};
      environment.savedProductBoundryCondition = [];
      environment.savedProductBoundryCondition = this.level3;
      console.log(environment.savedProductBenefit[0].benefit);
      this.savedData.push({
        "partner_id": 1, "partner_name": "Indiabull", "product_name": environment.savedProductMapping[0].product_name, "loan_type": environment.savedProductMapping[0].loan_type,
        "policy_name": environment.savedProductMapping[0].policy_name, "minimum_group": environment.savedProductMapping[0].minimum_group, "mortality_rate_type": environment.savedProductMapping[0].mortality_rate_type,
        "mortality_rate": environment.savedProductMapping[0].mortality_rate, "benefits": environment.savedProductBenefit[0].benefits, "floor-rate": environment.savedProductBenefit[0]["floor-rate"],
        "boundary_condition": environment.savedProductBoundryCondition
      });
      environment.savedTillProductBoundryCondition = this.savedData;
    } else {
      for (var i = 0; i < row.length; i++) {
        this.productName = [];
        var column = row[i].querySelectorAll('.product-column');
        if (Object.keys(this.childData).length > 0) {
          console.log(this.name);
          this.savingData[this.name] = this.childData;
          this.productName = [];
          this.childData = {};
        }
        for (var j = 0; j < column.length; j++) {
          if (i > 0) {
            this.child = row[i].querySelectorAll('.product-column')[j];
            if (this.child.classList.value.includes("product-column product-name")) {
              this.productName.push({ "product_name": this.child.innerText });
              this.name = (this.productName[0].product_name).trim();
              for (var n = 0; n < this.benefits.length; n++) {
                if (this.name.startsWith(this.benefits[n].title)) {
                  if (this.benefits[n].bands != undefined && this.name.includes(this.benefits[n].bands)) {
                    this.name = this.benefits[n].title;
                    if (this.coverageType != undefined && this.coverageType != this.benefits[n].type) {
                      clear = true
                    }
                    this.coverageType = this.benefits[n].type;
                    this.bands = this.benefits[n].bands;
                    break;
                  } else if (this.benefits[n].type == 'Flat') {
                    this.name = this.benefits[n].title;
                    if (this.coverageType != undefined && this.coverageType != 'FlatWise') {
                      clear = true
                    }
                    this.coverageType = "FlatWise";
                    break;
                  }
                }
              }
              console.log(this.name);
            }
            else if (this.child.classList.value.includes("product-column product-min-age")) {
              this.childData["product-min-age"] = this.child.querySelector('input').value;
              check++
            }
            else if (this.child.classList.value.includes("product-column product-max-age")) {
              this.childData["product-max-age"] = this.child.querySelector('input').value;
              check++
            }
            else if (this.child.classList.value.includes("product-column product-min-cover-ceasing-age")) {
              this.childData["product-min-cover-ceasing-age"] = this.child.querySelector('input').value;
              check++
            }
            else if (this.child.classList.value.includes("product-column product-cover-ceasing-age")) {
              this.childData["product-cover-ceasing-age"] = this.child.querySelector('input').value;
              check++
            }
            else if (this.child.classList.value.includes("product-column product-min-sum-assured")) {
              this.childData["product-min-sum-assured"] = this.child.querySelector('input').value;
              check++
            }
            else if (this.child.classList.value.includes("product-column product-max-sum-assured")) {
              this.childData["product-max-sum-assured"] = this.child.querySelector('input').value;
              check++
            }
            else if (this.child.classList.value.includes("product-column product-min-policy-term")) {
              this.childData["product-min-policy-term"] = this.child.querySelector('input').value;
              check++
            }
            else if (this.child.classList.value.includes("product-column product-max-policy-term")) {
              this.childData["product-max-policy-term"] = this.child.querySelector('input').value;
              check++
            }
          }
          if (check == 8 && this.coverageType != undefined) {
            if (!(this.level3[this.coverageType].hasOwnProperty(this.name))) {
              this.level1 = {}
            }
            if (clear) {
              this.level2 = {}
            }
            if (this.coverageType == 'FlatWise') {
              this.level1['flat'] = this.childData
              this.level2[this.name] = this.level1
              this.level3[this.coverageType] = this.level2
            } else {
              this.level1[this.bands] = this.childData
              this.level2[this.name] = this.level1
              this.level3[this.coverageType] = this.level2
            }
            check = 0
            clear = false
          }
        }
      }
      this.savingData[this.name] = this.childData;
      console.log(this.name);
      console.log(this.savingData);
      if (this.coverageType == 'FlatWise') {
        this.level1['flat'] = this.childData
        this.level2[this.name] = this.level1
        this.level3[this.coverageType] = this.level2
      } else {
        this.level1[this.bands] = this.childData
        this.level2[this.name] = this.level1
        this.level3[this.coverageType] = this.level2
      }
      this.productName = [];
      this.childData = {};
      environment.savedProductBoundryCondition = [];
      environment.savedProductBoundryCondition = this.level3;
      console.log(environment.savedProductBenefit[0].benefit);
      this.savedData.push({
        "partner_id": 1, "partner_name": "Indiabull", "product_name": environment.savedProductMapping[0].product_name, "loan_type": environment.savedProductMapping[0].loan_type,
        "policy_name": environment.savedProductMapping[0].policy_name, "minimum_group": environment.savedProductMapping[0].minimum_group, "mortality_rate_type": environment.savedProductMapping[0].mortality_rate_type,
        "mortality_rate": environment.savedProductMapping[0].mortality_rate, "benefits": environment.savedProductBenefit[0].benefits, "floor-rate": environment.savedProductBenefit[0]["floor-rate"],
        "boundary_condition": environment.savedProductBoundryCondition
      });
      environment.savedTillProductBoundryCondition = this.savedData;
      var index = this.findIndexValue(this.savedData[0]["benefits"]["Flat"], "name", "Death Benefit")
      if (index != null) {
        var value = this.savedData[0]["benefits"]["Flat"][index]["Band"]["table_reference"]
        this.selectedMortality = value
        this.change.setDbMortality(this.selectedMortality)
      }
    }

    this.routes.navigate(['product-benefits']);
  }

  saveDataForCopy() {
    this.savingData = {}
    var row = document.querySelectorAll("div.product-row");
    for (var i = 0; i < row.length; i++) {
      this.productName = [];
      var column = row[i].querySelectorAll('.product-column');
      if (Object.keys(this.childData).length > 0) {
        this.productName = [];
        this.savingData[this.name] = this.childData;
        console.log(this.name);
        console.log(this.savingData);
        this.productName = [];
        this.childData = {};
      }
      for (var j = 0; j < column.length; j++) {
        if (i > 0) {
          this.productName = [];
          this.child = row[i].querySelectorAll('.product-column')[j];
          if (this.child.classList.value.includes("product-column product-name")) {
            this.productName.push({ "product_name": this.child.innerText });
            this.name = (this.productName[0].product_name).trim();
            this.savingData[this.name] = new Array();
          }
          else if (this.child.classList.value.includes("product-column product-min-age")) {
            this.childData["product-min-age"] = this.child.querySelector('input').value;
          }
          else if (this.child.classList.value.includes("product-column product-max-age")) {
            this.childData["product-max-age"] = this.child.querySelector('input').value;
          }
          else if (this.child.classList.value.includes("product-column product-min-cover-ceasing-age")) {
            this.childData["product-min-cover-ceasing-age"] = this.child.querySelector('input').value;
          }
          else if (this.child.classList.value.includes("product-column product-cover-ceasing-age")) {
            this.childData["product-cover-ceasing-age"] = this.child.querySelector('input').value;
          }
          else if (this.child.classList.value.includes("product-column product-min-sum-assured")) {
            this.childData["product-min-sum-assured"] = this.child.querySelector('input').value;
          }
          else if (this.child.classList.value.includes("product-column product-max-sum-assured")) {
            this.childData["product-max-sum-assured"] = this.child.querySelector('input').value;
          }
          else if (this.child.classList.value.includes("product-column product-min-policy-term")) {
            this.childData["product-min-policy-term"] = this.child.querySelector('input').value;
          }
          else if (this.child.classList.value.includes("product-column product-max-policy-term")) {
            this.childData["product-max-policy-term"] = this.child.querySelector('input').value;
          }
        }
      }
    }
    this.savingData[this.name] = this.childData;
    console.log(this.name);
    console.log(this.savingData);
    this.productName = [];
    this.childData = {};
  }

  closeModel(event) {
    this.b2cModalz.close(event);
  }

  copyRow(event) {
    this.closeModel(event)
    this.el.nativeElement.querySelector('.checked').classList.remove('checked')
    var copyData = {}
    for (var key in this.copyFromBenefits) {
      if (key == (this.copyFromBenefitName)) {
        copyData = this.copyFromBenefits[key]
      }
    }
    var __self = this
    this.el.nativeElement.querySelectorAll('.product-column.product-name').forEach(function (benefit) {
      if (benefit.innerText == __self.currBenefit) {
        var main = benefit.parentElement
        if (!(main.querySelector('.product-column.product-min-age').firstElementChild.classList.contains('disabled'))) {
          main.querySelector('.product-column.product-min-age').firstElementChild.value = copyData["product-min-age"]
        }
        if (!(main.querySelector('.product-column.product-max-age').firstElementChild.classList.contains('disabled'))) {
          main.querySelector('.product-column.product-max-age').firstElementChild.value = copyData["product-max-age"]
        }
        main.querySelector('.product-column.product-min-cover-ceasing-age').firstElementChild.value = copyData["product-min-cover-ceasing-age"]
        main.querySelector('.product-column.product-cover-ceasing-age').firstElementChild.value = copyData["product-cover-ceasing-age"]
        main.querySelector('.product-column.product-min-sum-assured').firstElementChild.value = copyData["product-min-sum-assured"]
        main.querySelector('.product-column.product-max-sum-assured').firstElementChild.value = copyData["product-max-sum-assured"]
        if (!(main.querySelector('.product-column.product-min-policy-term').firstElementChild.classList.contains('disabled'))) {
          main.querySelector('.product-column.product-min-policy-term').firstElementChild.value = copyData["product-min-policy-term"]
        }
        if (!(main.querySelector('.product-column.product-max-policy-term').firstElementChild.classList.contains('disabled'))) {
          main.querySelector('.product-column.product-max-policy-term').firstElementChild.value = copyData["product-max-policy-term"]
        }
      }
      //for eadb and ti to auto copy if db or adb is selected
      if (__self.currBenefit.startsWith("Death Benefit")) {
        if (benefit.innerText.startsWith("Terminal Illness")) {
          var band = __self.currBenefit.replace("Death Benefit", "")
          if (band == "") {
            var main = benefit.parentElement
            if (!(main.querySelector('.product-column.product-min-age').firstElementChild.classList.contains('disabled'))) {
              main.querySelector('.product-column.product-min-age').firstElementChild.value = copyData["product-min-age"]
            }
            if (!(main.querySelector('.product-column.product-max-age').firstElementChild.classList.contains('disabled'))) {
              main.querySelector('.product-column.product-max-age').firstElementChild.value = copyData["product-max-age"]
            }
            main.querySelector('.product-column.product-min-cover-ceasing-age').firstElementChild.value = copyData["product-min-cover-ceasing-age"]
            main.querySelector('.product-column.product-cover-ceasing-age').firstElementChild.value = copyData["product-cover-ceasing-age"]
            main.querySelector('.product-column.product-min-sum-assured').firstElementChild.value = copyData["product-min-sum-assured"]
            main.querySelector('.product-column.product-max-sum-assured').firstElementChild.value = copyData["product-max-sum-assured"]
            if (!(main.querySelector('.product-column.product-min-policy-term').firstElementChild.classList.contains('disabled'))) {
              main.querySelector('.product-column.product-min-policy-term').firstElementChild.value = copyData["product-min-policy-term"]
            }
            if (!(main.querySelector('.product-column.product-max-policy-term').firstElementChild.classList.contains('disabled'))) {
              main.querySelector('.product-column.product-max-policy-term').firstElementChild.value = copyData["product-max-policy-term"]
            }
          } else if (benefit.innerText.includes(band)) {
            var main = benefit.parentElement
            if (!(main.querySelector('.product-column.product-min-age').firstElementChild.classList.contains('disabled'))) {
              main.querySelector('.product-column.product-min-age').firstElementChild.value = copyData["product-min-age"]
            }
            if (!(main.querySelector('.product-column.product-max-age').firstElementChild.classList.contains('disabled'))) {
              main.querySelector('.product-column.product-max-age').firstElementChild.value = copyData["product-max-age"]
            }
            main.querySelector('.product-column.product-min-cover-ceasing-age').firstElementChild.value = copyData["product-min-cover-ceasing-age"]
            main.querySelector('.product-column.product-cover-ceasing-age').firstElementChild.value = copyData["product-cover-ceasing-age"]
            main.querySelector('.product-column.product-min-sum-assured').firstElementChild.value = copyData["product-min-sum-assured"]
            main.querySelector('.product-column.product-max-sum-assured').firstElementChild.value = copyData["product-max-sum-assured"]
            if (!(main.querySelector('.product-column.product-min-policy-term').firstElementChild.classList.contains('disabled'))) {
              main.querySelector('.product-column.product-min-policy-term').firstElementChild.value = copyData["product-min-policy-term"]
            }
            if (!(main.querySelector('.product-column.product-max-policy-term').firstElementChild.classList.contains('disabled'))) {
              main.querySelector('.product-column.product-max-policy-term').firstElementChild.value = copyData["product-max-policy-term"]
            }
          }
        }
      } else if (__self.currBenefit.startsWith("Accidental Death Benefit")) {
        if (benefit.innerText.startsWith("Enhanced ADB")) {
          var band = __self.currBenefit.replace("Accidental Death Benefit", "")
          if (band == "") {
            var main = benefit.parentElement
            if (!(main.querySelector('.product-column.product-min-age').firstElementChild.classList.contains('disabled'))) {
              main.querySelector('.product-column.product-min-age').firstElementChild.value = copyData["product-min-age"]
            }
            if (!(main.querySelector('.product-column.product-max-age').firstElementChild.classList.contains('disabled'))) {
              main.querySelector('.product-column.product-max-age').firstElementChild.value = copyData["product-max-age"]
            }
            main.querySelector('.product-column.product-min-cover-ceasing-age').firstElementChild.value = copyData["product-min-cover-ceasing-age"]
            main.querySelector('.product-column.product-cover-ceasing-age').firstElementChild.value = copyData["product-cover-ceasing-age"]
            main.querySelector('.product-column.product-min-sum-assured').firstElementChild.value = copyData["product-min-sum-assured"]
            main.querySelector('.product-column.product-max-sum-assured').firstElementChild.value = copyData["product-max-sum-assured"]
            if (!(main.querySelector('.product-column.product-min-policy-term').firstElementChild.classList.contains('disabled'))) {
              main.querySelector('.product-column.product-min-policy-term').firstElementChild.value = copyData["product-min-policy-term"]
            }
            if (!(main.querySelector('.product-column.product-max-policy-term').firstElementChild.classList.contains('disabled'))) {
              main.querySelector('.product-column.product-max-policy-term').firstElementChild.value = copyData["product-max-policy-term"]
            }
          } else if (benefit.innerText.includes(band)) {
            var main = benefit.parentElement
            if (!(main.querySelector('.product-column.product-min-age').firstElementChild.classList.contains('disabled'))) {
              main.querySelector('.product-column.product-min-age').firstElementChild.value = copyData["product-min-age"]
            }
            if (!(main.querySelector('.product-column.product-max-age').firstElementChild.classList.contains('disabled'))) {
              main.querySelector('.product-column.product-max-age').firstElementChild.value = copyData["product-max-age"]
            }
            main.querySelector('.product-column.product-min-cover-ceasing-age').firstElementChild.value = copyData["product-min-cover-ceasing-age"]
            main.querySelector('.product-column.product-cover-ceasing-age').firstElementChild.value = copyData["product-cover-ceasing-age"]
            main.querySelector('.product-column.product-min-sum-assured').firstElementChild.value = copyData["product-min-sum-assured"]
            main.querySelector('.product-column.product-max-sum-assured').firstElementChild.value = copyData["product-max-sum-assured"]
            if (!(main.querySelector('.product-column.product-min-policy-term').firstElementChild.classList.contains('disabled'))) {
              main.querySelector('.product-column.product-min-policy-term').firstElementChild.value = copyData["product-min-policy-term"]
            }
            if (!(main.querySelector('.product-column.product-max-policy-term').firstElementChild.classList.contains('disabled'))) {
              main.querySelector('.product-column.product-max-policy-term').firstElementChild.value = copyData["product-max-policy-term"]
            }
          }
        }
      }
    })

  }

  saveData() {
    this.policyList = environment.partnerDetails;
    this.partner_id = this.policyList[0].partner_id;
    this.partner_name = this.policyList[0].partner_name;
    this.partner_logo = this.policyList[0].partner_logo;
    var count = 0;
    var count2 = 0;
    var inputs = this.el.nativeElement.querySelectorAll('input');
    var minTerm = []
    var maxTerm = []
    var minSum = []
    var maxSum = []
    var minAge = []
    var maxAge = []
    var minCoverCeasing = []
    var maxCoverCeasing = []
    environment.minPolicyTerm = 2
    environment.maxPolicyTerm = environment.maxCoverageTerm
    this.el.nativeElement.querySelectorAll('.product-row:not(:first-child)').forEach(function (rows) {
      var benefit = rows.querySelector('#benefitName').innerText
      if (benefit.startsWith("Death Benefit")) {
        if (rows.querySelector('#minAge').value != "") {
          minAge.push(parseInt(rows.querySelector('#minAge').value))
          environment.minAge = Math.min(...minAge)
        }
        if (rows.querySelector('#maxAge').value != "") {
          maxAge.push(parseInt(rows.querySelector('#maxAge').value))
          environment.maxAge = Math.max(...maxAge)
        }
        if (rows.querySelector('#mincoverCeasingAge').value != "") {
          minCoverCeasing.push(parseInt(rows.querySelector('#mincoverCeasingAge').value))
          environment.minCoverCeasing = Math.min(...minCoverCeasing)
        }
        if (rows.querySelector('#coverCeasingAge').value != "") {
          maxCoverCeasing.push(parseInt(rows.querySelector('#coverCeasingAge').value))
          environment.maxCoverCeasing = Math.max(...maxCoverCeasing)
        }
        if (rows.querySelector('#minSumAssured').value != "") {
          minSum.push(parseInt(rows.querySelector('#minSumAssured').value))
          environment.minSumAssured = Math.min(...minSum)
        }
        if (rows.querySelector('#maxSumAssured').value != "") {
          maxSum.push(parseInt(rows.querySelector('#maxSumAssured').value))
          environment.maxSumAssured = Math.max(...maxSum)
        }
        if (rows.querySelector('#minPolicyTerm').value != "") {
          minTerm.push(parseInt(rows.querySelector('#minPolicyTerm').value))
          environment.minPolicyTerm = Math.min(...minTerm)
        }
        if (rows.querySelector('#maxPolicyTerm').value != "") {
          maxTerm.push(parseInt(rows.querySelector('#maxPolicyTerm').value))
          environment.maxPolicyTerm = Math.max(...maxTerm)
        }
      }
    })
    var __self = this
    this.el.nativeElement.querySelectorAll('input').forEach(function (item) {
      console.log(item);
      if (item.value != "") {
        item.value = parseInt(item.value)
      }
      if (item.parentElement.parentElement.classList.contains('product-row')) {
        var benefitName = item.parentElement.parentElement.firstElementChild.innerText;
        var min_age = parseInt(item.parentElement.parentElement.querySelector('.product-min-age').firstElementChild.value)
        var max_age = parseInt(item.parentElement.parentElement.querySelector('.product-max-age').firstElementChild.value)
        var min_cover = parseInt(item.parentElement.parentElement.querySelector('.product-min-cover-ceasing-age').firstElementChild.value)
        var max_cover = parseInt(item.parentElement.parentElement.querySelector('.product-cover-ceasing-age').firstElementChild.value)
        var min_sum = parseInt(item.parentElement.parentElement.querySelector('.product-min-sum-assured').firstElementChild.value)
        var max_sum = parseInt(item.parentElement.parentElement.querySelector('.product-min-sum-assured').firstElementChild.value)
        var min_term = parseInt(item.parentElement.parentElement.querySelector('.product-min-policy-term').firstElementChild.value)
        var max_term = parseInt(item.parentElement.parentElement.querySelector('.product-max-policy-term').firstElementChild.value)
        var min_cover_limit
        var max_cover_limit
      } else {
        var benefitName = item.parentElement.parentElement.firstElementChild.innerText;
        var min_age = parseInt(item.parentElement.parentElement.parentElement.querySelector('.product-min-age').firstElementChild.value)
        var max_age = parseInt(item.parentElement.parentElement.parentElement.querySelector('.product-max-age').firstElementChild.value)
        var min_cover = parseInt(item.parentElement.parentElement.parentElement.querySelector('.product-min-cover-ceasing-age').firstElementChild.value)
        var max_cover = parseInt(item.parentElement.parentElement.parentElement.querySelector('.product-cover-ceasing-age').firstElementChild.value)
        var min_sum = parseInt(item.parentElement.parentElement.parentElement.querySelector('.product-min-sum-assured').firstElementChild.value)
        var max_sum = parseInt(item.parentElement.parentElement.parentElement.querySelector('.product-min-sum-assured').firstElementChild.value)
        var min_term = parseInt(item.parentElement.parentElement.parentElement.querySelector('.product-min-policy-term').firstElementChild.value)
        var max_term = parseInt(item.parentElement.parentElement.parentElement.querySelector('.product-max-policy-term').firstElementChild.value)
        var min_cover_limit
        var max_cover_limit
      }
      if (isNaN(min_age)) {
        min_cover_limit = environment.fixedData[0].min_cover_ceasing_age
      } else {
        if (environment.groupLoanSecure) {
          min_cover_limit = min_age + 2;
          if (min_cover_limit > environment.fixedData[0].max_cover_ceasing_age) {
            min_cover_limit = environment.fixedData[0].max_cover_ceasing_age
          }
        } else if (environment.superProductCredit) {
          // min_cover_limit = min_age + __self.coverageTerm;
          min_cover_limit = min_age + 2;
          if (min_cover_limit > environment.fixedData[0].max_cover_ceasing_age) {
            min_cover_limit = environment.fixedData[0].max_cover_ceasing_age
          }
        }
      }
      if (isNaN(max_age)) {
        max_cover_limit = environment.fixedData[0].max_cover_ceasing_age
      } else {
        if (environment.groupLoanSecure) {
          max_cover_limit = max_age + 5;
          if (max_cover_limit > environment.fixedData[0].max_cover_ceasing_age) {
            max_cover_limit = environment.fixedData[0].max_cover_ceasing_age
          }
        } else if (environment.superProductCredit) {
          max_cover_limit = max_age + __self.coverageTerm;
          if (max_cover_limit > environment.fixedData[0].max_cover_ceasing_age) {
            max_cover_limit = environment.fixedData[0].max_cover_ceasing_age
          }
        }
      }
      if (item.value.trim() == "") {
        item.classList.add("error");
        swal({
          title: 'Please Fill all details',
          type: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#eb1826',
          cancelButtonColor: '#F4F1F1',
          confirmButtonText: 'OK'
        }).then((result) => {
          item.classList.add("error");
        })
      }
      else if (item.id == "minAge" || item.id == "maxAge") {
        if (benefitName.startsWith("Death Benefit")) {
          if (item.value < environment.fixedData[0].min_age_at_entry || item.value > environment.fixedData[0].max_age_at_entry) {
            swal({
              title: 'Please enter age between' + ' ' + environment.fixedData[0]["min_age_at_entry"] + ' to' + ' ' + environment.fixedData[0]["max_age_at_entry"],
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#eb1826',
              cancelButtonColor: '#F4F1F1',
              confirmButtonText: 'OK'
            }).then((result) => {
              item.classList.add("error");
            })
          }
          else {
            item.classList.remove("error");
            count++;
          }
        } else {
          if (item.value < environment.minAge || item.value > environment.maxAge) {
            swal({
              title: 'Please enter age between' + ' ' + environment.minAge + ' to' + ' ' + environment.maxAge,
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#eb1826',
              cancelButtonColor: '#F4F1F1',
              confirmButtonText: 'OK'
            }).then((result) => {
              item.classList.add("error");
            })
          }
          else {
            item.classList.remove("error");
            count++;
          }
        }
      }
      else if (item.id == "coverCeasingAge" || item.id == "mincoverCeasingAge") {
        if (benefitName.startsWith("Death Benefit")) {
          if (item.value < min_cover_limit || item.value > max_cover_limit) {
            swal({
              title: 'Please enter cover ceasing age between' + ' ' + min_cover_limit + ' to' + ' ' + max_cover_limit,
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#eb1826',
              cancelButtonColor: '#F4F1F1',
              confirmButtonText: 'OK'
            }).then((result) => {
              item.classList.add("error");
            })
          }
          else {
            item.classList.remove("error");
            count++;
          }
        } else {
          if (environment.minCoverCeasing > min_cover_limit) {
            min_cover_limit = environment.minCoverCeasing
          }
          if (environment.maxCoverCeasing < max_cover_limit) {
            max_cover_limit = environment.maxCoverCeasing
          }
          if (item.value < min_cover_limit || item.value > max_cover_limit) {
            swal({
              title: 'Please enter cover ceasing age between' + ' ' + min_cover_limit + ' to' + ' ' + max_cover_limit,
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#eb1826',
              cancelButtonColor: '#F4F1F1',
              confirmButtonText: 'OK'
            }).then((result) => {
              item.classList.add("error");
            })
          }
          else {
            item.classList.remove("error");
            count++;
          }
        }
      }
      else if (item.id == "minSumAssured" || item.id == "maxSumAssured") {
        if (benefitName.startsWith("Death Benefit")) {
          if (item.value < environment.fixedData[0].min_sum_assured) {
            swal({
              title: 'Minimum sum assured start from ' + ' ' + environment.fixedData[0]["min_sum_assured"],
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#eb1826',
              cancelButtonColor: '#F4F1F1',
              confirmButtonText: 'OK'
            }).then((result) => {
              item.classList.add("error");
            })
          }
          else {
            item.classList.remove("error");
            count++;
          }
        } else if (environment.maxSumAssured == 0) {
          if (benefitName.includes("EMI Waiver(Hosp)")) {
            if (item.value < environment.minSumAssured || item.value > environment.fixedData[0]["max_sum_assured_emi"]) {
              swal({
                title: 'Sum assured limit should be between ' + environment.minSumAssured + ' to ' + environment.fixedData[0]["max_sum_assured_emi"],
                type: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#eb1826',
                cancelButtonColor: '#F4F1F1',
                confirmButtonText: 'OK'
              }).then((result) => {
                item.classList.add("error");
              })
            }
            else {
              item.classList.remove("error");
              count++;
            }
          } else if (benefitName.includes("Cancer Protect")) {
            if (item.value < environment.minSumAssured || item.value > environment.fixedData[0]["max_sum_assured_cancer"]) {
              swal({
                title: 'Sum assured limit should be between ' + environment.minSumAssured + ' to ' + environment.fixedData[0]["max_sum_assured_cancer"],
                type: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#eb1826',
                cancelButtonColor: '#F4F1F1',
                confirmButtonText: 'OK'
              }).then((result) => {
                item.classList.add("error");
              })
            }
            else {
              item.classList.remove("error");
              count++;
            }
          } else {
            if (item.value < environment.minSumAssured) {
              swal({
                title: 'Minimum sum assured start from ' + ' ' + environment.minSumAssured,
                type: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#eb1826',
                cancelButtonColor: '#F4F1F1',
                confirmButtonText: 'OK'
              }).then((result) => {
                item.classList.add("error");
              })
            }
            else {
              item.classList.remove("error");
              count++;
            }
          }
        } else {
          if (benefitName.includes("EMI Waiver(Hosp)")) {
            var max_sum_emi = environment.fixedData[0]["max_sum_assured_emi"]
            if (environment.maxSumAssured < max_sum_emi) {
              max_sum_emi = environment.maxSumAssured
            }
            if (item.value < environment.minSumAssured || item.value > max_sum_emi) {
              swal({
                title: 'Sum assured limit should be between ' + environment.minSumAssured + ' to ' + max_sum_emi,
                type: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#eb1826',
                cancelButtonColor: '#F4F1F1',
                confirmButtonText: 'OK'
              }).then((result) => {
                item.classList.add("error");
              })
            }
            else {
              item.classList.remove("error");
              count++;
            }
          } else if (benefitName.includes("Cancer Protect")) {
            var max_sum_can = environment.fixedData[0]["max_sum_assured_cancer"]
            if (environment.maxSumAssured < max_sum_can) {
              max_sum_can = environment.maxSumAssured
            }
            if (item.value < environment.minSumAssured || item.value > max_sum_can) {
              swal({
                title: 'Sum assured limit should be between ' + environment.minSumAssured + ' to ' + max_sum_can,
                type: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#eb1826',
                cancelButtonColor: '#F4F1F1',
                confirmButtonText: 'OK'
              }).then((result) => {
                item.classList.add("error");
              })
            }
            else {
              item.classList.remove("error");
              count++;
            }
          } else {
            if (item.value < environment.minSumAssured || item.value > environment.maxSumAssured) {
              swal({
                title: 'Sum assured limit should be between ' + environment.minSumAssured + ' to ' + environment.maxSumAssured,
                type: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#eb1826',
                cancelButtonColor: '#F4F1F1',
                confirmButtonText: 'OK'
              }).then((result) => {
                item.classList.add("error");
              })
            }
            else {
              item.classList.remove("error");
              count++;
            }
          }
        }
      }
      else if (item.id == "minPolicyTerm" || item.id == "maxPolicyTerm") {
        item.value = parseInt(item.value);
        if (benefitName.startsWith("Death Benefit")) {
          if (item.value < environment.minPolicyTerm || item.value > environment.maxCoverageTerm) {
            swal({
              title: 'Please enter Policy Term between' + ' ' + environment.minPolicyTerm + ' to' + ' ' + environment.maxCoverageTerm,
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#eb1826',
              cancelButtonColor: '#F4F1F1',
              confirmButtonText: 'OK'
            }).then((result) => {
              item.classList.add("error");
            })
          }
          else {
            item.classList.remove("error");
            count++;
          }
        } else if ((benefitName.includes("Accelerated CI") || benefitName.includes("Additional CI"))) {
          var acimax
          environment.maxPolicyTerm > environment.acimaxPolicyTerm ? acimax = environment.acimaxPolicyTerm : acimax = environment.maxPolicyTerm;
          if (item.value < environment.minPolicyTerm || item.value > acimax) {
            swal({
              title: 'Please enter Policy Term between' + ' ' + environment.minPolicyTerm + ' to' + ' ' + acimax,
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#eb1826',
              cancelButtonColor: '#F4F1F1',
              confirmButtonText: 'OK'
            }).then((result) => {
              item.classList.add("error");
            })
          }
          else {
            item.classList.remove("error");
            count++;
          }
        } else if ((benefitName.includes("EMI Waiver(Hosp)") || benefitName.includes("Cancer Protect"))) {
          var canceremimax;
          environment.maxPolicyTerm > environment.canceremimaxPolicyTerm ? canceremimax = environment.canceremimaxPolicyTerm : canceremimax = environment.maxPolicyTerm;
          if (item.value < environment.minPolicyTerm || item.value > canceremimax) {
            swal({
              title: 'Please enter Policy Term between' + ' ' + environment.minPolicyTerm + ' to' + ' ' + canceremimax,
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#eb1826',
              cancelButtonColor: '#F4F1F1',
              confirmButtonText: 'OK'
            }).then((result) => {
              item.classList.add("error");
            })
          }
          else {
            item.classList.remove("error");
            count++;
          }
        } else {
          if (item.value < environment.minPolicyTerm || item.value > environment.maxPolicyTerm) {
            swal({
              title: 'Please enter Policy Term between' + ' ' + environment.minPolicyTerm + ' to' + ' ' + environment.maxPolicyTerm,
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#eb1826',
              cancelButtonColor: '#F4F1F1',
              confirmButtonText: 'OK'
            }).then((result) => {
              item.classList.add("error");
            })
          }
          else {
            item.classList.remove("error");
            count++;
          }
        }
      }
      else {
        item.classList.remove("error");
        count++;
      }
    });
    if (count == inputs.length) {
      this.el.nativeElement.querySelectorAll('input').forEach(function (item) {
        console.log(item);
        var benefitName = item.parentElement.parentElement.firstElementChild.innerText;
        if (item.parentElement.classList.contains('checkbox')) {
          var min_age = item.parentElement.parentElement.parentElement.querySelector('.product-min-age').firstElementChild.value
          var max_age = item.parentElement.parentElement.parentElement.querySelector('.product-max-age').firstElementChild.value
          var min_cover = item.parentElement.parentElement.parentElement.querySelector('.product-min-cover-ceasing-age').firstElementChild.value
          var max_cover = item.parentElement.parentElement.parentElement.querySelector('.product-cover-ceasing-age').firstElementChild.value
          var min_sum = item.parentElement.parentElement.parentElement.querySelector('.product-min-sum-assured').firstElementChild.value
          var max_sum = item.parentElement.parentElement.parentElement.querySelector('.product-max-sum-assured').firstElementChild.value
          var min_term = item.parentElement.parentElement.parentElement.querySelector('.product-min-policy-term').firstElementChild.value
          var max_term = item.parentElement.parentElement.parentElement.querySelector('.product-max-policy-term').firstElementChild.value
        } else {
          var min_age = item.parentElement.parentElement.querySelector('.product-min-age').firstElementChild.value
          var max_age = item.parentElement.parentElement.querySelector('.product-max-age').firstElementChild.value
          var min_cover = item.parentElement.parentElement.querySelector('.product-min-cover-ceasing-age').firstElementChild.value
          var max_cover = item.parentElement.parentElement.querySelector('.product-cover-ceasing-age').firstElementChild.value
          var min_sum = item.parentElement.parentElement.querySelector('.product-min-sum-assured').firstElementChild.value
          var max_sum = item.parentElement.parentElement.querySelector('.product-max-sum-assured').firstElementChild.value
          var min_term = item.parentElement.parentElement.querySelector('.product-min-policy-term').firstElementChild.value
          var max_term = item.parentElement.parentElement.querySelector('.product-max-policy-term').firstElementChild.value
        }
        min_age = parseInt(min_age)
        max_age = parseInt(max_age)
        min_cover = parseInt(min_cover)
        max_cover = parseInt(max_cover)
        min_sum = parseInt(min_sum)
        max_sum = parseInt(max_sum)
        min_term = parseInt(min_term)
        max_term = parseInt(max_term)
        //conflict check

        if (item.id == "minAge") {
          if (item.value > max_age && max_age != NaN) {
            swal({
              title: 'Min age cannot be greater than max age',
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#eb1826',
              cancelButtonColor: '#F4F1F1',
              confirmButtonText: 'OK'
            }).then((result) => {
              item.classList.add("error");
            })
          }
          else {
            item.classList.remove("error");
            count2++;
          }
        }
        else if (item.id == "maxAge") {
          if (item.value < min_age && min_age != NaN) {
            swal({
              title: 'Max age cannot be less than min age',
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#eb1826',
              cancelButtonColor: '#F4F1F1',
              confirmButtonText: 'OK'
            }).then((result) => {
              item.classList.add("error");
            })
          }
          else {
            item.classList.remove("error");
            count2++;
          }
        }
        else if (item.id == "mincoverCeasingAge") {
          if (item.value > max_cover && max_cover != NaN) {
            swal({
              title: 'Min cover ceasing age cannot be greater than max cover ceasing age',
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#eb1826',
              cancelButtonColor: '#F4F1F1',
              confirmButtonText: 'OK'
            }).then((result) => {
              item.classList.add("error");
            })
          }
          else {
            item.classList.remove("error"); count2++;
          }
        }
        else if (item.id == "coverCeasingAge") {
          if (item.value < min_cover && min_cover != NaN) {
            swal({
              title: 'Max cover ceasing age cannot be less than min cover ceasing age',
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#eb1826',
              cancelButtonColor: '#F4F1F1',
              confirmButtonText: 'OK'
            }).then((result) => {
              item.classList.add("error");
            })
          }
          else {
            item.classList.remove("error"); count2++;
          }
        }
        else if (item.id == "minSumAssured") {
          if (item.value > max_sum && max_sum != NaN) {
            swal({
              title: 'Min sum assured cannot be greater than max sum assured',
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#eb1826',
              cancelButtonColor: '#F4F1F1',
              confirmButtonText: 'OK'
            }).then((result) => {
              item.classList.add("error");
            })
          }
          else {
            item.classList.remove("error"); count2++;
          }
        }
        else if (item.id == "maxSumAssured") {
          if (item.value < min_sum && min_sum != NaN) {
            swal({
              title: 'Max sum assured cannot be less than min sum assured',
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#eb1826',
              cancelButtonColor: '#F4F1F1',
              confirmButtonText: 'OK'
            }).then((result) => {
              item.classList.add("error");
            })
          }
          else {
            item.classList.remove("error"); count2++;
          }
        }
        else if (item.id == "minPolicyTerm") {
          if (item.value > max_term && max_term != NaN) {
            swal({
              title: 'Min policy term cannot be greater than max policy term',
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#eb1826',
              cancelButtonColor: '#F4F1F1',
              confirmButtonText: 'OK'
            }).then((result) => {
              item.classList.add("error");
            })
          }
          else {
            item.classList.remove("error"); count2++;
          }
        }
        else if (item.id == "maxPolicyTerm") {
          if (item.value < min_term && min_term != NaN) {
            swal({
              title: 'Max policy term cannot be less than min policy term',
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#eb1826',
              cancelButtonColor: '#F4F1F1',
              confirmButtonText: 'OK'
            }).then((result) => {
              item.classList.add("error");
            })
          }
          else {
            item.classList.remove("error"); count2++;
          }
        }
        else {
          item.classList.remove("error");
          count2++;
        }


      });
    }
    if (count == inputs.length && count2 == inputs.length) {
      this.savingData = {}
      this.goNext = true;
      var row = document.querySelectorAll("div.product-row");
      this.productName = [];
      var check = 0
      var clear = false;
      for (var i = 0; i < row.length; i++) {
        this.productName = [];
        var column = row[i].querySelectorAll('.product-column');
        if (Object.keys(this.childData).length > 0) {
          console.log(this.name);
          this.savingData[this.name] = this.childData;
          this.productName = [];
          this.childData = {};
        }
        for (var j = 0; j < column.length; j++) {
          if (i > 0) {
            this.child = row[i].querySelectorAll('.product-column')[j];
            if (this.child.classList.value.includes("product-column product-name")) {
              this.productName.push({ "product_name": this.child.innerText });
              this.name = (this.productName[0].product_name).trim();
              for (var n = 0; n < this.benefits.length; n++) {
                if (this.name.startsWith(this.benefits[n].title)) {
                  if (this.benefits[n].bands != undefined && this.name.includes(this.benefits[n].bands)) {
                    this.name = this.benefits[n].title;
                    if (this.coverageType != undefined && this.coverageType != this.benefits[n].type) {
                      clear = true
                    }
                    this.coverageType = this.benefits[n].type;
                    this.bands = this.benefits[n].bands;
                    break;
                  } else if (this.benefits[n].type == 'Flat') {
                    this.name = this.benefits[n].title;
                    if (this.coverageType != undefined && this.coverageType != 'FlatWise') {
                      clear = true
                    }
                    this.coverageType = "FlatWise";
                    this.bands = this.benefits[n].bands;
                    break;
                  } else if (this.benefits[n].bands == '999') {
                    this.name = this.benefits[n].title;
                    if (this.coverageType != undefined && this.coverageType != this.benefits[n].type) {
                      clear = true
                    }
                    this.coverageType = this.benefits[n].type;
                    this.bands = this.benefits[n].bands;
                  }
                }
              }

              console.log(this.name);
            }
            else if (this.child.classList.value.includes("product-column product-min-age")) {
              this.childData["product-min-age"] = this.child.querySelector('input').value;
              check++
            }
            else if (this.child.classList.value.includes("product-column product-max-age")) {
              this.childData["product-max-age"] = this.child.querySelector('input').value;
              check++
            }
            else if (this.child.classList.value.includes("product-column product-min-cover-ceasing-age")) {
              this.childData["product-min-cover-ceasing-age"] = this.child.querySelector('input').value;
              check++
            }
            else if (this.child.classList.value.includes("product-column product-cover-ceasing-age")) {
              this.childData["product-cover-ceasing-age"] = this.child.querySelector('input').value;
              check++
            }
            else if (this.child.classList.value.includes("product-column product-min-sum-assured")) {
              this.childData["product-min-sum-assured"] = this.child.querySelector('input').value;
              check++
            }
            else if (this.child.classList.value.includes("product-column product-max-sum-assured")) {
              this.childData["product-max-sum-assured"] = this.child.querySelector('input').value;
              check++
            }
            else if (this.child.classList.value.includes("product-column product-min-policy-term")) {
              this.childData["product-min-policy-term"] = this.child.querySelector('input').value;
              check++
            }
            else if (this.child.classList.value.includes("product-column product-max-policy-term")) {
              this.childData["product-max-policy-term"] = this.child.querySelector('input').value;
              check++
            }
          }
          if (check == 8 && this.coverageType != undefined) {
            if (!(this.level3[this.coverageType].hasOwnProperty(this.name))) {
              this.level1 = {}
            }
            if (clear) {
              this.level2 = {}
            }
            if (this.coverageType == 'FlatWise') {
              this.level1['flat'] = this.childData
              this.level2[this.name] = this.level1
              this.level3[this.coverageType] = this.level2
            } else {
              this.level1[this.bands] = this.childData
              this.level2[this.name] = this.level1
              this.level3[this.coverageType] = this.level2
            }
            check = 0
            clear = false
          }
        }
      }
      this.savingData[this.name] = this.childData;
      console.log(this.name);
      console.log(this.savingData);
      if (this.coverageType == 'FlatWise') {
        this.level1['flat'] = this.childData
        this.level2[this.name] = this.level1
        this.level3[this.coverageType] = this.level2
      } else {
        this.level1[this.bands] = this.childData
        this.level2[this.name] = this.level1
        this.level3[this.coverageType] = this.level2
      }
      this.productName = [];
      this.childData = {};
      environment.savedProductBoundryCondition = [];
      environment.savedProductBoundryCondition = this.level3;
      console.log(environment.savedProductBenefit[0].benefit);
      this.savedData.push({
        "partner_id": environment.partnerDetails[0].partner_id, "partner_name": environment.partnerDetails[0].partner_name,
        "product_name": environment.savedProductMapping[0].product_name, "product_id": environment.savedProductMapping[0].product_id,
        "loan_type": environment.savedProductMapping[0].loan_type, "policy_name": environment.savedProductMapping[0].policy_name,
        "minimum_group": environment.savedProductMapping[0].minimum_group,
        "mortality_rate_type": environment.savedProductMapping[0].mortality_rate_type,
        "mortality_rate": environment.savedProductMapping[0].mortality_rate, "benefits": environment.savedProductBenefit[0].benefits,
        "boundary_condition": environment.savedProductBoundryCondition[0]
      });
      environment.savedTillProductBoundryCondition = this.savedData;
    }
    if (this.el.nativeElement.querySelectorAll('.disabled.error').length > 0) {
      swal({
        title: 'Please edit the "Death Benefit" according to the benefit boundaries to proceed further',
        type: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#eb1826',
        cancelButtonColor: '#F4F1F1',
        confirmButtonText: 'OK'
      }).then((result) => {
      })
    }
  }

  changeRoute() {
    // this.loader.show();
    this.routes.navigate(["partnerListing"]);
  }

  findIndexValue(arraytosearch, key, valuetosearch) {

    for (var i = 0; i < arraytosearch.length; i++) {

      if (arraytosearch[i][key] == valuetosearch) {
        return i;
      }
    }
    return null;
  }

}


import { Component, OnInit, ElementRef, AfterViewInit, ViewChild, NgModule } from '@angular/core';
import { RouterModule, Router, NavigationEnd, NavigationExtras } from '@angular/router';
import { environment } from '../../../environments/environment';
import { ConfigService } from '../../services/config.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { DataShareService } from '../../services/data-share.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-partner-page',
  templateUrl: './partner-page.component.html',
  styleUrls: ['./partner-page.component.scss']
})

export class PartnerPageComponent implements OnInit, AfterViewInit {
  @ViewChild('input') input: ElementRef;
  partnerListing;
  navigationSubscription;
  showPartnerList = false;
  renderHtml = false;
  searchText = "";
  searchValue = "";
  noPolicies = false
  totalPartners;
  products: any[] = [];
  selectedFsc: any[] = [];
  isPartnerActive = true;
  isFscActive = false;

  constructor(public routes: Router, public config: ConfigService, public loader: Ng4LoadingSpinnerService,
    public change: DataShareService, public el: ElementRef) {
    this.change.setPartnerConfig(false);
    if (environment.loginSuccessfully == true) {
      this.loader.show();
      this.clearData();
      this.showList();
      console.log(this.partnerListing);
    } else {
      this.routes.navigate(["login"]);
    }

    // this.navigationSubscription = this.routes.events.subscribe((e: any) => {
    //   // If it is a NavigationEnd event re-initalise the component
    //   console.log(e);
    //   if (e instanceof NavigationEnd) {
    //     this.searchValue = "";
    //     this.searchText = "";
    //     this.showList();
    //   }
    // });

  }
  

  showList() {
    var url = "/service/admin/" + environment.userid + "/lpartners";
    this.config.httpService('post', url, "").subscribe(
      res => {
        //debugger;
        this.partnerListing = res;
        environment.partnerListing = this.partnerListing;
        this.totalPartners = this.partnerListing.length
        if (this.partnerListing.length > 0) {
          this.showPartnerList = true;
          this.renderHtml = true;
          this.loader.hide();
        }
        else {
          this.showPartnerList = false;
          this.renderHtml = true;
          this.loader.hide();
        }
        this.loader.hide();
      },
      err => {
        debugger;
        this.loader.hide();
        swal({
          title: "Something went wrong.\nPlease try after some time.",
          type: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#eb1826',
          cancelButtonColor: '#F4F1F1',
          confirmButtonText: 'OK'
        }).then((result) => {
          this.routes.navigate(["login"]);
        })
      }
    );
  }

  clearData() {
    environment.savedProductMapping = []
    environment.savedProductBenefit = []
    environment.savedProductOtherBenefit = []
    environment.savedTillProductBoundryCondition = []
    environment.savedProductBoundryCondition = {}
    environment.savedProductScheme = []
    environment.groupLoanSecure = true
    environment.superProductCredit = false
  }

  check(event) {
    if (event.target.classList.contains('right')) {
      console.log(event.target);
      this.searchText = "";
      event.target.parentElement.childNodes[1].value = "";
      var partnerList = this.el.nativeElement.querySelectorAll('.productDetails')
      for (var i = 0; i < partnerList.length; i++) {
        partnerList[i].style.display = ""
        this.noPolicies = false
      }
    }
    this.totalPartners = this.partnerListing.length
  }
  // ngOnInit() {
  //   //this.showList();
  //   this.searchText = '';
  //   //this.showList();


  // }

  ngOnInit(): void {
    this.isPartnerActive = true;
    this.isFscActive = false;
    var url = "/service/fsc/all/";
    this.config.httpService("get", url, "").subscribe(
      res => {
        var data;
        data = res;
        this.products = data.products;
      },
      err => {
        console.log(err);
      }
    );
  }

  onCheckboxChange(fsc: any, event: any) {
    if (event.target.checked) {
      this.selectedFsc.push(fsc);
    } else {
      this.selectedFsc = this.selectedFsc.filter(selected => selected !== fsc);
    }
  }

  ngAfterViewInit() {
    //this.showList();
    var input = this.el.nativeElement.querySelectorAll("input");
    if (input.length > 1) {
      alert("got it!!");
      input[0].value = "";
    }
  }

  goToAddPartner() {
    environment.editDetails = false;
    this.routes.navigate(["addPartner"]);
  }

  goToAddPartnerFsc() {
    environment.editDetails = false;
    this.routes.navigate(["addPartnerFsc"]);
  }


  searchPartner(event) {
    this.searchText = event.target.parentElement.childNodes[1].value;
  }

  onKeydown(event) {
    this.searchText = event.target.parentElement.childNodes[1].value.toLowerCase();
    console.log(this.searchPartner);
    var partnerList = this.el.nativeElement.querySelectorAll('.productDetails')
    var count = 0
    var partners
    var totalPolicies = []
    for (var i = 0; i < partnerList.length; i++) {
      if (partnerList[i].querySelector('.policy-name') != null) {
        var partnerName = partnerList[i].querySelector('.partner-name').innerText.toLowerCase()
        var policyName = partnerList[i].querySelector('.policy-name').innerText.toLowerCase()
        var loanType = partnerList[i].querySelector('.loan-type').innerText.toLowerCase()
        var policyType = partnerList[i].querySelector('.policy-type').innerText.toLowerCase()
        if (policyName.indexOf(this.searchText) == -1 && policyType.indexOf(this.searchText) == -1 && partnerName.indexOf(this.searchText) == -1 && loanType.indexOf(this.searchText) == -1) {
          partnerList[i].style.display = "none"
        }
        else {
          partnerList[i].style.display = ""
          var data = partnerList[i].querySelector('.partner-name').innerText.toLowerCase();
          if (!totalPolicies.includes(data)) {
            totalPolicies.push(data)
          }
        }
      }
      else {
        var partnerName = partnerList[i].querySelector('.partner-name').innerText.toLowerCase()
        if (partnerName.indexOf(this.searchText) == -1) {
          partnerList[i].style.display = "none"
        }
        else {
          partnerList[i].style.display = ""
          var data = partnerList[i].querySelector('.partner-name').innerText.toLowerCase();
          if (!totalPolicies.includes(data)) {
            totalPolicies.push(data)
          }
        }
      }
      if (partnerList[i].style.display == "none") {
        count++
      }
    }
    this.totalPartners = totalPolicies.length
    if (count == partnerList.length) {
      this.noPolicies = true
    }
    else {
      this.noPolicies = false
    }

  }

  getPartnerDetails(event, id, page) {
    this.loader.show();
    if (event.target.parentElement.parentElement.parentElement.querySelector('.policy-name') != null) {
      environment.currentBenefit = event.target.parentElement.parentElement.parentElement.querySelector('.policy-name').dataset.policyname
    }
    if(event.target.childNodes[0].textContent == "ASSIGN FSC"){
      var partner_id = id.partner;
      var product_id = id.master_policy_id;      
      
      const navigationExtras: NavigationExtras = {
        queryParams: {
          partner_id: partner_id, 
          product_id: product_id,
          // Add more properties as needed
        },
      };    
      this.routes.navigate(['fscMapping'], navigationExtras);
    }
    else{
    var url = "/service/admin/" + environment.userid + "/lpartners/" + id;
    this.config.httpService("post", url, "").subscribe(
      res => {
        environment.partnerDetails = [];
        environment.currentPartnerDetails = [];
        var data = res;
        environment.editDetails = true;
        environment.currentPartnerDetails.push(data);
        environment.partnerDetails.push(data);
        if (page == "product-mapping") {
          this.routes.navigate(['product-mapping']);
        }
        else if (page == "addPartner") {
          this.routes.navigate(["addPartner"]);
        }
        else if (page == "partner-details") {
          this.routes.navigate(["partner-details"]);
        }
        this.loader.hide();
      },
      err => {
        this.loader.hide();
        swal({
          title: "Something went wrong.\nPlease try after some time.",
          type: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#eb1826',
          cancelButtonColor: '#F4F1F1',
          confirmButtonText: 'OK'
        }).then((result) => {
          this.routes.navigate(["login"]);
        })
      }
    )
    }
  }
}

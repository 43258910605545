import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DragScrollModule } from 'ngx-drag-scroll';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpHeaders, HttpClientModule, HttpClient } from '@angular/common/http';
//import { AtomSpinnerModule } from 'angular-epic-spinners'
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';


import { AppComponent } from './app.component';
import { AppRoutingModule } from './/app-routing.module';
import { HeaderComponent } from './components/header/header.component';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { CustomInputDirective } from './directives/custom-input.directive';
import { PartnerPageComponent } from './components/partner-page/partner-page.component';
import { AddPartnerComponent } from './components/add-partner/add-partner.component';
import { CustomModalComponent } from './components/custom-modal/custom-modal.component';
import { ProductMappingComponent } from './components/product-mapping/product-mapping.component';
import { PartnerDetailsComponent } from './components/partner-details/partner-details.component';
import { CustomGroupComponent } from './components/custom-group/custom-group.component';
import { KeyValuePipe } from './pipes/keyvalue.pipe';
import { ProductBenifitsComponent } from './components/product-benifits/product-benifits.component';
import { ProductBoundaryConditionComponent } from './components/product-boundary-condition/product-boundary-condition.component';
import { ProductSchemeComponent } from './components/product-scheme/product-scheme.component';
import { SearchPipe } from './pipes/search.pipe';
import { AddPartnerFscComponent } from './components/add-fsc/add-partner-fsc.component';
import { FscListingPageComponent } from './components/fsc-listing-page/fsc-listing-page.component';
import { AssignFscComponent } from './components/assign-fsc/assign-fsc.component';
import { FormsModule } from '@angular/forms';
import { FscDetailsComponent } from './components/fsc-details/fsc-details.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginPageComponent,
    CustomInputDirective,
    PartnerPageComponent,
    AddPartnerComponent,
    CustomModalComponent,
    ProductMappingComponent,
    PartnerDetailsComponent,
    CustomGroupComponent,
    KeyValuePipe,
    ProductBenifitsComponent,
    ProductBoundaryConditionComponent,
    ProductSchemeComponent,
    SearchPipe,

    AddPartnerFscComponent,

    FscListingPageComponent,

    AssignFscComponent,

    FscDetailsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DragScrollModule,
    HttpClientModule,
    Ng4LoadingSpinnerModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit, OnDestroy, ContentChild, TemplateRef, Input, ElementRef, ChangeDetectorRef, HostListener } from '@angular/core';

@Component({
  selector: 'app-custom-modal',
  templateUrl: './custom-modal.component.html',
  styleUrls: ['./custom-modal.component.scss']
})
export class CustomModalComponent implements OnDestroy {

  @ContentChild('modalHeader') header: TemplateRef<any>;
  @ContentChild('modalBody') body: TemplateRef<any>;
  @ContentChild('modalFooter') footer: TemplateRef<any>;
  @Input() closeOnOutsideClick = false;

  visible = false;
  visibleAnimate = false;

  constructor(
    private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnDestroy() {
    // Prevent modal from not executing its closing actions if the user navigated away (for example,
    // through a link).
    this.close("");
  }

  open(): void {
    document.body.classList.add('modal-open');
    document.getElementsByClassName('parentDiv')[0].classList.add('blur');

    this.visible = true;
    setTimeout(() => {
      this.visibleAnimate = true;
    });
  }

  close(event): void {
    console.log(event.target);
    // // if(event.target.innerHTML.trim() == "DONE" && (location.pathname == "" || location.pathname == "add-partner"))
    // if (event.target == undefined) {
    //   document.body.classList.remove('modal-open');
    //   document.getElementsByClassName('parentDiv')[0].classList.remove('blur');
    //   this.visibleAnimate = false;
    //   setTimeout(() => {
    //     this.visible = false;
    //     this.changeDetectorRef.markForCheck();
    //   }, 200);
    // }
    // else {
    document.body.classList.remove('modal-open');
    if (event.target)
      document.getElementsByClassName('parentDiv')[0].classList.remove('blur');

    this.visibleAnimate = false;
    setTimeout(() => {
      this.visible = false;
      this.changeDetectorRef.markForCheck();
    }, 200);


  }

  onClickOutside(event) {
    console.log(event);
  }

  @HostListener('click', ['$event'])
  onContainerClicked(event: MouseEvent): void {
     var keycode = event.which;
    if ((<HTMLElement>event.target).classList.contains('modal') && this.isTopMost() && this.closeOnOutsideClick ) {
      this.close("");
      document.getElementsByClassName('parentDiv')[0].classList.remove('blur');
    }
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDownHandler(event: KeyboardEvent) {
    // If ESC key and TOP MOST modal, close it.
    if (event.key === 'Escape' && this.isTopMost()) {
      this.close("");

    }
  }

  /**
   * Returns true if this modal is the top most modal.
   */
  isTopMost(): boolean {
    return !this.elementRef.nativeElement.querySelector(':scope modal > .modal');
  }
}

import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { DataShareService } from '../../services/data-share.service';
import { ConfigService } from '../../services/config.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { RouterModule, Router, ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2';
const partnerConfig = environment.partner_config;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isPartnerActive: boolean = true;
  isFscActive: boolean = false;
  partnerConfig = false;
  fileName: any = "";
  patnerAddress: any = "";
  partnerName: any = "";
  loginSuccessfully = false;
  constructor(public change: DataShareService, public route: Router, public config: ConfigService, public loader: Ng4LoadingSpinnerService) {
    this.loginSuccessfully = this.change.getHeader();
    this.change.newHeaderSubject.subscribe((data) => {
      this.loginSuccessfully = data;
    });
    this.partnerConfig = this.change.getPartnerConfig();
    this.change.newPartnerConfigSubject.subscribe((data) => {
      this.partnerConfig = data;
      console.log(this.partnerConfig);
      if (environment.partnerDetails.length > 0) {
        this.fileName = environment.partnerDetails[0].partner_logo;
        this.partnerName = environment.partnerDetails[0].partner_name;
        this.patnerAddress = environment.partnerDetails[0].partner_address;
      }
    });




  }

  ngOnInit() {
    if (environment.partnerDetails.length > 0) {
      this.fileName = environment.partnerDetails[0].partner_logo;
      this.partnerName = environment.partnerDetails[0].partner_name;
      this.patnerAddress = environment.partnerDetails[0].partner_address;
    }
  }

  ngAfterViewInit() {
    if (environment.partnerDetails.length > 0) {
      this.fileName = environment.partnerDetails[0].partner_logo;
      this.partnerName = environment.partnerDetails[0].partner_name;
      this.patnerAddress = environment.partnerDetails[0].partner_name;
    }
  }
  editPartner() {
    this.change.setPartnerConfig(false);
    environment.editDetails = true;
    this.route.navigate(["addPartner"]);
  }
  goToPartners(event) {
    if (this.route.url == "/partnerListing" || this.route.url == "/partner-details") {
      this.route.navigate(["partnerListing"]);
    }
    else if(this.route.url == "/fscListing") {
      this.route.navigate(["partnerListing"]);
    }
    else {
      swal({
        title: "All changes will be discarded! <br /> Are you sure?",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#eb1826',
        cancelButtonColor: '#F4F1F1',
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.value) {
          environment.savedProductMapping = [];
          environment.savedProductBoundryCondition = {};
          environment.savedProductBenefit = [];
          environment.savedProductOtherBenefit = [];
          environment.savedTillProductBoundryCondition = [];
          environment.savedProductScheme = [];
          environment.selectedReductionSchedule_Id = "";
          environment.selectedReductionScheduleCode = "";
          environment.reduction_sched = "";
          environment.groupLoanSecure = true;
          environment.superProductCredit = false;
          environment.shubhRakshaCredit = false;
          this.change.setAsseleratedCI("");
          this.change.setAdditionalCI("");
          this.change.setWaiverHospital("");
          this.change.setSelectedProduct("");
          this.change.setSelectedLoan("");
          this.change.setSelectedMortality("");
          this.change.setSelectedMortalityRate("");
          this.change.setSelectedCoverageOption("");
          this.change.setSelectedCriticalIllness("");
          this.change.setSelectedFrequency("");
          this.change.setSelectedPaymentTerm("");
          this.change.setSelectedNonMedicalRate("");
          this.change.setSelectedNonMedicalRateCI("");
          this.change.setSelectedNonMedical("");
          this.change.setReductionSchedule("");
          this.change.setInterestRate("");
          this.change.setsingleJointLife("");
          this.change.setCoverageTerm("");
          this.change.setcoverageOptions("");
          this.change.setmoratoriumPeriod("");
          this.change.setmoratoriumTerm("");
          this.change.setsingleJointLife("");
          this.change.setSelectedFlexibility("");
          this.change.setSelectedAcici("");
          this.change.setSelectedAcici("");
          this.change.setSelectedChannel("");
          this.change.setWaiverHospital("")
          this.change.setAdditionalCI("")
          this.change.setAsseleratedCI("")
          environment.editPolicy = false;
          if (this.route.url == "/partnerListing") {
            this.route.navigate(["/partnerListing",]);
          }
          else {
            this.route.navigate(["partnerListing"]);
          }
        }
        else {
          // alert("no");
        }
      })
    }

  }
  goToFsc(event) {
    if (event.currentTarget.getAttribute("data-type") == 'fsc') {
      this.route.navigate(["fscListing"]);
    }
    else if(this.route.url == "/partnerListing") {
      this.route.navigate(["fscListing"]);
    }
    else {
      // Handle the case when changes are unsaved in the current route
      // This part might be similar to the logic in goToPartners()
      // ...
    }
  }

  
  activateButton(button: string) {
    if (button === 'A') {
      this.isPartnerActive = true;
      this.isFscActive = false;
    } else if (button === 'B') {
      this.isPartnerActive = false;
      this.isFscActive = true;
    }
  }



  logout() {
    this.loader.show();
    environment.loginToken = "";
    environment.loginSuccessfully = false;
    this.change.setHeader(false);
    this.change.setPartnerConfig(false);
    this.loader.hide();
    this.route.navigate(["login"]);
  }
}

import { Component, OnInit, NgModule, ElementRef, NgZone, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { RouterModule, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { DataShareService } from '../../services/data-share.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {

  errorMessage = "";
  constructor(private routes: Router, public config: ConfigService, public loader: Ng4LoadingSpinnerService, public el: ElementRef,
    public change: DataShareService) {
    environment.loginToken = "";
    environment.loginSuccessfully = false;
  }

  ngOnInit() {
  }

  storeValue(event) {
    if (event.target.closest('span').attributes.id.value == 'userName') {
      environment.username = event.target.value;
    }
    else if (event.target.closest('span').attributes.id.value == 'password') {
      environment.password = event.target.value;
    }

  }

  loginEnter(event) {
    var keycode = (event.keyCode ? event.keyCode : event.which);
    if (keycode == '13') {
      this.login(event);
    }


  }

  login(event) {
    environment.username = event.target.parentElement.childNodes[1].childNodes[1].childNodes[0].childNodes[5].value;
    environment.password = event.target.parentElement.childNodes[1].childNodes[2].childNodes[0].childNodes[5].value;
    var inputs = document.querySelectorAll("input");
    var count = 0;
    this.el.nativeElement.querySelectorAll('input').forEach(function (item) {

      console.log(item.value);
      if (item.value.trim() == "") {
        item.parentElement.firstElementChild.classList.remove("hide");
        item.closest("fieldset").classList.add("error");
        console.log("Blank");
      }
      else {
        item.parentElement.firstElementChild.classList.add("hide");
        item.closest("fieldset").classList.remove("error");
        count = count + 1;

      }
    });
    if (count == inputs.length) {
      this.loader.show();
      //var url = "/service/login?username=" + environment.username + "&password=" + environment.password;
      let url = "/token/generate-token";
      let data = ({ "username": environment.username, "password": environment.password });
      // let encryted_data = btoa(environment.username) + ":" + btoa(environment.password);
      this.config.httpService('post', url, data).subscribe(
        res => {
          //environment.loginSuccessfully = true;
          console.log(res);
          var data;
          data = res;
          environment.loginToken = data.token;
          let url = "/service/admins";
          this.config.httpService("post", url, "").subscribe(
            res => {
              environment.loginSuccessfully = true;
              this.change.setHeader(true);
              var loginUserDetails = this.config.icici(res, "admin_email", environment.username);
              let loginUserId = this.config.getValues(loginUserDetails, "admin_id");
              environment.userid = loginUserId[0];
              environment.logingInUserId = loginUserDetails[0].admin_id;
              environment.logedInUserEmail = loginUserDetails[0].admin_email;
              environment.logedInUserName = loginUserDetails[0].admin_name;
              console.log(environment.userid);
              this.routes.navigate(["partnerListing"]);
            },
            err => {
              this.loader.hide();
              Swal({
                title: "Something went wrong.\nPlease try after some time.",
                type: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#eb1826',
                cancelButtonColor: '#F4F1F1',
                confirmButtonText: 'OK'
              }).then((result) => {
                this.routes.navigate(["login"]);
              })
            }
          )

        },
        err => {
          this.loader.hide();
          var el = event.target.parentElement.childNodes[1].childNodes[0];
          this.errorMessage = err.message;
          if (this.errorMessage != "Username and / or password is invalid") {
            Swal({
              title: "Something went wrong.\nPlease try after some time.",
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#eb1826',
              cancelButtonColor: '#F4F1F1',
              confirmButtonText: 'OK'
            }).then((result) => {
              this.routes.navigate(["login"]);
            })
          }
          else {
            environment.username = "";
            event.target.parentElement.childNodes[1].childNodes[1].childNodes[0].childNodes[5].value = "";
            environment.password = "";
            event.target.parentElement.childNodes[1].childNodes[2].childNodes[0].childNodes[5].value = "";
            el.classList.remove("hide");
          }
        }
      );
    }
  }
  toggleInput(event) {
    console.log(event.target);
    if (event.target.classList.contains('icon-eye')) {
      var sibling = event.target.previousElementSibling;
      if (sibling.type == "text") {
        sibling.type = "password";
        event.target.classList.remove("changeColor");
      }
      else if (sibling.type == "password") {
        sibling.type = "text";
        event.target.classList.add("changeColor");
      }
    }
  }

}

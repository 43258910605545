import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(items: any[], searchText: any): any[] {
    if (!items) return [];
    if (!searchText) return items;
    searchText = searchText.toLowerCase();

    return items.filter(it => {
      if (it.partner_name != undefined) {
        // return (it.partner_name.toLowerCase().includes(searchText));
        return (it.partner_name.toLowerCase());
      }
      // else if (it.MandatorySkill != undefined) {
      //   return (it.MandatorySkill.toLowerCase().includes(searchText) || it.RRID.toLowerCase().includes(searchText) || it.ClientLocation.toLowerCase().includes(searchText));
      // }
      // else if (it.mandatory_skill != undefined) {
      //   return (it.mandatory_skill.toLowerCase().includes(searchText) || it.rr_id.toLowerCase().includes(searchText) || it.client_location.toLowerCase().includes(searchText));
      // }
      //return null;
    });
  }
  // transform(items, searchText) {
  //   if (!items) return [];
  //   if (!searchText) return items;
  //   var filteredList = [];
  //   searchText = searchText.toLowerCase();
  //   console.log(items);
  //   return items.filter(it => {
  //     if (it.partner_name != undefined) {
  //       it.masterPolicies.forEach(function (element, index) {
  //         if (!element.product_name.toLowerCase().includes(searchText)) {
  //           it.masterPolicies = it.masterPolicies.splice(index, 1);
  //         }
  //       });
  //       if (it.partner_name.toLowerCase().includes(searchText) || it.masterPolicies.length > 0)
  //         return it;
  //     }
  //   });
  // }
}

import { Component, NgModule, ElementRef, NgZone, OnInit, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { DataShareService } from '../../services/data-share.service';
import { ConfigService } from '../../services/config.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import swal from 'sweetalert2';
b2cModal1: ElementRef;
@Component({
  selector: 'app-product-scheme',
  templateUrl: './product-scheme.component.html',
  styleUrls: ['./product-scheme.component.scss']
})
export class ProductSchemeComponent implements OnInit {
  b2cModal1;
  payment_term: any = [];
  coverage_option: any = [];
  pay_frequency: any = [];
  productData: any = [];
  nonMedicalLimit: any = [];
  acicinonMedicalLimit: any = [];
  channel: any = [];
  selectedAcici = "";
  selectedNonMedicalLimit: any = "";
  selectedPaymentTerm: string = "Single Pay";
  selectedCoverageOption: string;
  selectedPayFrequency: string;
  savedData: any = [];
  savedPaymentTerm: any = [];
  savedCoverageOption: any = [];
  savedPayFrequency: any = [];
  policyList: any;
  partner_id: any;
  product_id: any;
  loan_id: any;
  partner_name: any;
  partner_logo: any;
  loanType: any;
  policyName: any;
  finalData: any;
  selectedNonMedicalRate: any = [];
  selectedNonMedicalRateCI: any = [];
  selectedAcicicRate: any = [];
  nonMedicalLimitData: any;
  acicinonMedicalLimitData: any;
  masterPolicyId: any;
  acici = false;
  hospi = false;
  cancer = false;
  groupLoanSecure = environment.groupLoanSecure;
  superProductCredit = environment.superProductCredit;
  shubhRakshaCredit = environment.shubhRakshaCredit;
  selectedCancer = ""
  selectedHospital = ""
  correctVal = true
  disableSave = false

  @ViewChild("b2cModal1") set content(content: ElementRef) {
    this.b2cModal1 = content;
  }
  constructor(public routes: Router, public change: DataShareService, public el: ElementRef, public config: ConfigService, public loader: Ng4LoadingSpinnerService) {
    if (environment.loginSuccessfully == true) {
      if (environment.groupLoanSecure) {

        this.productData = environment.productData;
        console.log(this.productData);
        this.payment_term = this.productData.item_json.premium_payment_term.items;
        this.coverage_option = this.productData.item_json.coverage_option.items;
        this.pay_frequency = this.productData.item_json.frequency.items;
        this.nonMedicalLimit = this.productData.item_json.non_medical_limit.items;
        this.selectedPaymentTerm = this.change.getSelectedPaymentTerm();
        this.change.newselectedPaymentTermSubject.subscribe((data) => {
          this.selectedPaymentTerm = data[0].name;
          this.savedPaymentTerm = data;
          console.log(this.selectedPaymentTerm);
        });
        if (environment.savedProductScheme.length > 0) {
          if (environment.savedProductScheme[0].premium_payment_term.length > 0) {
            this.selectedPaymentTerm = environment.savedProductScheme[0].premium_payment_term[0].name;
          }
        }
        this.selectedCoverageOption = this.change.getSelectedCoverageOption();
        this.change.newselectedCoverageOptionSubject.subscribe((data) => {
          this.selectedCoverageOption = data[0].name;
          this.savedCoverageOption = data;
          console.log(this.selectedCoverageOption);
        });
        this.selectedPayFrequency = this.change.getSelectedFrequency();
        this.change.newselectedFrequencySubject.subscribe((data) => {
          this.selectedPayFrequency = data[0].name;
          this.savedPayFrequency = data;
          console.log(this.selectedPayFrequency);
        });
        this.selectedNonMedicalLimit = this.change.getSelectedNonMedical();
        this.change.newselectedNonMedicalSubject.subscribe((data) => {
          this.selectedNonMedicalLimit = data;
          console.log(this.selectedNonMedicalLimit);
        });

        this.selectedNonMedicalRate = this.change.getSelectedNonMedicalRate();
        this.change.newselectedNonMedicalRateSubject.subscribe((data) => {
          this.selectedNonMedicalRate = data;
          console.log(this.selectedNonMedicalRate);
        });
        this.selectedNonMedicalRateCI = this.change.getSelectedNonMedicalRateCI();
        this.change.newselectedNonMedicalRateSubjectCI.subscribe((data) => {
          this.selectedNonMedicalRateCI = data;
          console.log(this.selectedNonMedicalRateCI);
        });
      }
      else if (environment.superProductCredit) {
        var benefits = [];
        // var aci_ci = false;
        // benefits = environment.savedProductBenefit[0].benefits;
        // benefits.forEach(function (benefit) {
        //   if (benefit.name.includes("Accelerated CI") || benefit.name.includes("Additional CI")) {
        //     aci_ci = true;
        //   }
        // });
        // this.acici = aci_ci;
        this.checkacici();
        this.checkCancer();
        this.checkHospi();
        this.productData = environment.productData;
        this.nonMedicalLimit = this.productData.item_json.non_medical_limit.items;
        this.acicinonMedicalLimit = this.productData.item_json.acici_non_medical_limit.items;
        if (environment.savedProductScheme.length > 0) {
          var data = environment.savedProductScheme[0]
          this.selectedHospital = data.hosp_non_medical_limit;
          this.selectedCancer = data.cancer_non_medical_limit;
          this.selectedNonMedicalLimit = data.non_medical_limit
          this.selectedAcici = data.acici_non_medical_limit
          if (parseInt(this.selectedHospital) == 0) {
            this.selectedHospital = ""
          }
          if (parseInt(this.selectedCancer) == 0) {
            this.selectedCancer = ""
          }
          this.change.setSelectedNonMedicalRate(data.non_medical_limit_values)
          if (this.selectedAcici == "not_selected") {
            this.selectedAcici = ""
          }
          this.change.setSelectedNonMedicalRateCI(data.acici_non_medical_limit_values)
          this.change.setSelectedNonMedical(this.selectedNonMedicalLimit)
          this.change.setSelectedAcici(this.selectedAcici);
        }
        this.channel = [
          {
            "item_name": "Direct",
            "item_id": 1
          },
          {
            "item_name": "Broker",
            "item_id": 2
          },
          {
            "item_name": "CA",
            "item_id": 3
          }
        ];
        this.selectedNonMedicalLimit = this.change.getSelectedNonMedical();
        this.change.newselectedNonMedicalSubject.subscribe((data) => {
          this.selectedNonMedicalLimit = data;
          console.log(this.selectedNonMedicalLimit);
        });
        this.selectedNonMedicalRate = this.change.getSelectedNonMedicalRate();
        this.change.newselectedNonMedicalRateSubject.subscribe((data) => {
          this.selectedNonMedicalRate = data;
          console.log(this.selectedNonMedicalRate);
        });
        if (this.acici) {
          this.selectedAcici = this.change.getSelectedAcici();
          this.change.newselectedAciciSubject.subscribe((data) => {
            this.selectedAcici = data;
            console.log(this.selectedAcici);
          });
          this.selectedAcicicRate = this.change.getSelectedAciciRate();
          this.change.newselectedAciciRateSubject.subscribe((data) => {
            this.selectedAcicicRate = data;
            console.log(this.selectedAcicicRate);
          });
          this.selectedNonMedicalRateCI = this.change.getSelectedNonMedicalRateCI();
          this.change.newselectedNonMedicalRateSubjectCI.subscribe((data) => {
            this.selectedNonMedicalRateCI = data;
            console.log(this.selectedNonMedicalRateCI);
          });
        }
        if (this.selectedAcici == undefined) {
          this.selectedAcici = "";
          this.selectedAcicicRate = "";
        }
        if (this.selectedAcici == "not_selected") {
          this.selectedAcici = ""
        }
        this.change.setSelectedAcici(this.selectedAcici)
        this.change.setSelectedAciciRate(this.selectedAcicicRate)
      } else if (environment.shubhRakshaCredit) {
        this.selectedPaymentTerm = "Single Pay";
        this.productData = environment.productData;
        console.log(this.productData);
        this.payment_term = this.productData.item_json.premium_payment_term.items;
        this.coverage_option = this.productData.item_json.coverage_option.items;
        this.pay_frequency = this.productData.item_json.frequency.items;
        this.nonMedicalLimit = this.productData.item_json.non_medical_limit.items;

        this.selectedPaymentTerm = this.change.getSelectedPaymentTerm();
        this.change.newselectedPaymentTermSubject.subscribe((data) => {
          this.selectedPaymentTerm = data[0].name;
          this.savedPaymentTerm = data;
          console.log(this.selectedPaymentTerm);
        });
        this.selectedCoverageOption = this.change.getSelectedCoverageOption();
        this.change.newselectedCoverageOptionSubject.subscribe((data) => {
          this.selectedCoverageOption = data[0].name;
          this.savedCoverageOption = data;
          console.log(this.selectedCoverageOption);
        });
        this.selectedPayFrequency = this.change.getSelectedFrequency();
        this.change.newselectedFrequencySubject.subscribe((data) => {
          this.selectedPayFrequency = data[0].name;
          this.savedPayFrequency = data;
          console.log(this.selectedPayFrequency);
        });
        this.selectedNonMedicalLimit = this.change.getSelectedNonMedical();
        this.change.newselectedNonMedicalSubject.subscribe((data) => {
          this.selectedNonMedicalLimit = data;
          console.log(this.selectedNonMedicalLimit);
        });

        this.selectedNonMedicalRate = this.change.getSelectedNonMedicalRate();
        this.change.newselectedNonMedicalRateSubject.subscribe((data) => {
          this.selectedNonMedicalRate = data;
          console.log(this.selectedNonMedicalRate);
        })
        this.selectedNonMedicalRateCI = this.change.getSelectedNonMedicalRateCI();
        this.change.newselectedNonMedicalRateSubjectCI.subscribe((data) => {
          this.selectedNonMedicalRateCI = data;
          console.log(this.selectedNonMedicalRateCI);
        });
      }
      if (environment.savedProductScheme.length == 0) {
        this.selectedPaymentTerm = ""
        this.selectedPayFrequency = ""
        this.selectedAcici = ""
        this.selectedAcicicRate = []
        this.selectedCancer = ""
        this.selectedHospital = ""
        this.selectedCoverageOption = ""
        this.selectedNonMedicalRate = []
        this.selectedNonMedicalLimit = ""
        this.selectedNonMedicalRateCI = []
      }
    } else {
      this.routes.navigate(["login"]);
    }

  }
  ngOnInit() {
  }
  ngAfterViewInit() {
    var __self = this;
    setInterval(function () {
      __self.el.nativeElement.querySelectorAll('input').forEach(function (input) {
        input.onpaste = function (e) {
          e.preventDefault();
        }
      })
    }, 500)
    if (environment.savedProductScheme.length > 0) {
      if (environment.groupLoanSecure) {
        var data = environment.savedProductScheme[0];
        if (data.premium_payment_term.length > 0) {
          this.savedPaymentTerm = data.premium_payment_term;
          this.selectedPaymentTerm = data.premium_payment_term[0].name;
          //this.change.setSelectedPaymentTerm(this.selectedPaymentTerm);
        }
        else {
          this.selectedPaymentTerm = "";
        }
        if (data.coverage_option.length > 0) {
          this.savedCoverageOption = data.coverage_option;
          this.selectedCoverageOption = data.coverage_option[0].name;
          //this.change.setSelectedCoverageOption(this.selectedCoverageOption);
        }
        else {
          this.selectedCoverageOption = "";
        }
        if (data.frequency.length > 0) {
          // setTimeout(() => {
          this.savedPayFrequency = data.frequency;
          this.selectedPayFrequency = data.frequency[0].name;
          // }, 500);
          // this.change.setSelectedFrequency(this.selectedPayFrequency);
        }
        else {
          this.selectedPayFrequency = "";
        }
        this.selectedNonMedicalLimit = data.non_medical_limit;
        var paymentpay = document.querySelectorAll(".radio #paymentTermRadio");
        var coverageOpt = document.querySelectorAll(".radio #coverageRadio");
        var frequency = document.querySelectorAll(".radio #frequency-radio");

        for (var i = 0; i < paymentpay.length; i++) {
          // var paymentpayParent = paymentpay[i].parentElement;
          if (paymentpay[i].previousElementSibling.innerHTML == this.selectedPaymentTerm) {
            paymentpay[i].parentElement.classList.add("checked");
            // return;
          }
        }
        for (var i = 0; i < coverageOpt.length; i++) {
          if (coverageOpt[i].previousElementSibling.innerHTML == this.selectedCoverageOption) {
            coverageOpt[i].parentElement.classList.add("checked");
            // return;
          }
        }
        // setTimeout(() => {
        var frequency = document.querySelectorAll(".radio #frequency-radio");
        for (var i = 0; i < frequency.length; i++) {
          if (frequency[i].previousElementSibling.innerHTML == this.selectedPayFrequency) {
            frequency[i].parentElement.classList.add("checked");
            // return;
          }
        }
        this.change.setSelectedNonMedicalRate(data.non_medical_limit_values);
        this.change.setSelectedNonMedicalRateCI(data.non_medical_limit_values_ci)
        this.change.setSelectedNonMedical(this.selectedNonMedicalLimit);
        if (this.selectedAcici == "not_selected") {
          this.selectedAcici = ""
        }
        this.change.setSelectedAcici(this.selectedAcici);
        this.change.setSelectedAciciRate(data.non_medical_limit_values);
      }
      else if (environment.superProductCredit) {
        var data = environment.savedProductScheme[0];
        // this.selectedFlexibility = data.flexibility;
        this.selectedCancer = data.cancer_non_medical_limit;
        this.selectedHospital = data.hosp_non_medical_limit;
        if (parseInt(this.selectedHospital) == 0) {
          this.selectedHospital = ""
        }
        if (parseInt(this.selectedCancer) == 0) {
          this.selectedCancer = ""
        }
        this.change.setSelectedNonMedical(data.non_medical_limit);
        // this.change.setSelectedFlexibility(this.selectedFlexibility);
        if (this.selectedAcici == "not_selected") {
          this.selectedAcici = ""
        }
        this.change.setSelectedAcici(this.selectedAcici);
      }
    }
  }
  saveDataOnNext() {
    this.disableSave = true
    this.saveData();
    if (environment.groupLoanSecure) {
      var nonMediaclaCount;
      var matchCount;
      var count = 0;
      //this.routes.navigate(['product-scheme']);
      var paymentpay = document.querySelectorAll(".radio #paymentTermRadio");
      var coverageOpt = document.querySelectorAll(".radio #coverageRadio");
      var frequency = document.querySelectorAll(".radio #frequency-radio");
      for (var i = 0; i < paymentpay.length; i++) {
        // var paymentpayParent = paymentpay[i].parentElement;
        if (paymentpay[i].parentElement.classList.contains("checked") == true) {
          var ss = paymentpay[i].closest("fieldset");
          ss.firstElementChild['style'].display = "none";
          paymentpay[i].parentElement.closest("fieldset").classList.remove("error");
          count++;
          break;
          //return;
        }
        else {
          var ss = paymentpay[i].closest("fieldset");
          ss.firstElementChild['style'].display = "block";
          paymentpay[i].parentElement.closest("fieldset").classList.add("error");
        }
      }
      for (var i = 0; i < coverageOpt.length; i++) {
        if (coverageOpt[i].parentElement.classList.contains("checked") == true) {
          var ss = coverageOpt[i].closest("fieldset");
          ss.firstElementChild['style'].display = "none";
          coverageOpt[i].parentElement.closest("fieldset").classList.remove("error");
          count++;
          break;
          //return;
        }
        else {
          var ss = coverageOpt[i].closest("fieldset");
          ss.firstElementChild['style'].display = "block";
          coverageOpt[i].parentElement.closest("fieldset").classList.add("error");
        }
      }
      for (var i = 0; i < frequency.length; i++) {
        if (frequency[i].parentElement.classList.contains("checked") == true) {
          var ss = frequency[i].closest("fieldset");
          ss.firstElementChild['style'].display = "none";
          frequency[i].parentElement.closest("fieldset").classList.remove("error");
          count++;
          break;
          //return;
        }
        else {
          var ss = frequency[i].closest("fieldset");
          ss.firstElementChild['style'].display = "block";
          frequency[i].parentElement.closest("fieldset").classList.add("error");
        }
      }

      var inputs = this.el.nativeElement.querySelectorAll('input:not(.hidden)').length;
      var covRadio = this.el.nativeElement.querySelectorAll("fieldset#coverageRadio").length
      var payRadio = this.el.nativeElement.querySelectorAll("fieldset#paymentTermRadio").length
      var freqRadio = this.el.nativeElement.querySelectorAll("fieldset#frequency-radio").length
      this.el.nativeElement.querySelectorAll('input:not(.hidden)').forEach(function (item) {
        console.log(item.value);
        if (item.value.trim() == "") {
          // item.parentElement.firstElementChild.style.display = 'block';
          var ss = item.closest("fieldset");
          ss.firstElementChild['style'].display = "block";
          item.closest("fieldset").classList.add("error");
          console.log("Blank");
        }
        else {
          var ss = item.closest("fieldset");
          ss.firstElementChild['style'].display = "none";
          item.closest("fieldset").classList.remove("error");
          count++;

        }
      });
      if (count == inputs + covRadio + payRadio + freqRadio) {
        this.saveData()
        this.loader.show();
        this.policyList = environment.partnerDetails[0];
        this.partner_id = this.policyList.partner_id;
        var url = "/service/admin/" + environment.userid + "/partners/" + this.partner_id + "/policies/" + environment.selectedProduct_Id;
        this.config.httpService("post", url, this.finalData).subscribe(
          res => {
            environment.savedProductMapping = [];
            environment.savedProductBoundryCondition = {};
            environment.savedProductBenefit = [];
            environment.savedProductOtherBenefit = [];
            environment.savedTillProductBoundryCondition = [];
            environment.savedProductScheme = [];
            environment.selectedReductionSchedule_Id = "";
            environment.selectedReductionScheduleCode = "";
            environment.reduction_sched = "";
            environment.groupLoanSecure = true;
            environment.superProductCredit = false;
            environment.shubhRakshaCredit = false;
            this.change.setAsseleratedCI("");
            this.change.setAdditionalCI("");
            this.change.setWaiverHospital("");
            this.change.setSelectedProduct("");
            this.change.setSelectedLoan("");
            this.change.setSelectedMortality("");
            this.change.setSelectedMortalityRate("");
            this.change.setSelectedCoverageOption("");
            this.change.setSelectedCriticalIllness("");
            this.change.setSelectedFrequency("");
            this.change.setSelectedPaymentTerm("");
            this.change.setSelectedNonMedicalRate("");
            this.change.setSelectedNonMedicalRateCI("");
            this.change.setSelectedNonMedical("");
            this.change.setReductionSchedule("");
            this.change.setInterestRate("");
            this.change.setsingleJointLife("");
            this.change.setCoverageTerm("");
            this.change.setcoverageOptions("");
            this.change.setmoratoriumPeriod("");
            this.change.setmoratoriumTerm("");
            this.change.setsingleJointLife("");
            this.change.setSelectedFlexibility("");
            this.change.setSelectedAcici("");
            this.change.setSelectedAcici("");
            this.change.setSelectedChannel("");
            this.change.setWaiverHospital("")
            this.change.setAdditionalCI("")
            this.change.setAsseleratedCI("")
            environment.editPolicy = false;
            this.loader.hide();
            this.b2cModal1.open();
          },
          err => {
            this.loader.hide();
            this.disableSave = false;
            swal({
              title: "Something went wrong.\nPlease try after some time.",
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#eb1826',
              cancelButtonColor: '#F4F1F1',
              confirmButtonText: 'OK'
            }).then((result) => {
              //this.routes.navigate(["product-mapping"]);
            })
          }
        )
      } else {
        this.disableSave = false;
        swal({
          title: "Please fill all fields before saving.",
          type: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#eb1826',
          cancelButtonColor: '#F4F1F1',
          confirmButtonText: 'OK'
        }).then((result) => {
          //this.routes.navigate(["product-mapping"]);
        })
      }
    }
    else if (environment.superProductCredit) {
      var count = 0;
      var inputl = this.el.nativeElement.querySelectorAll('input:not(.hidden)').length
      this.el.nativeElement.querySelectorAll('input:not(.hidden)').forEach(function (item) {
        if (item.value.trim() == "") {
          // item.parentElement.firstElementChild.style.display = 'block';
          var ss = item.closest("fieldset");
          ss.firstElementChild['style'].display = "block";
          item.closest("fieldset").classList.add("error");
          console.log("Blank");
        }
        else {
          var ss = item.closest("fieldset");
          ss.firstElementChild['style'].display = "none";
          item.closest("fieldset").classList.remove("error");
          count++;
        }
      })
      if (count == inputl) {
        this.loader.show();
        this.policyList = environment.partnerDetails[0];
        this.partner_id = this.policyList.partner_id;
        if (this.partner_id == undefined) {
          this.partner_id = this.policyList[0].partner_id
        }
        // var url = "/service/admin/" + environment.userid + "/partners/" + this.partner_id + "/policies/" + environment.selectedProduct_Id;
        var url = "/service/admin/" + environment.userid + "/partners/" + this.partner_id + "/policies/" + environment.selectedProduct_Id;
        this.config.httpService("post", url, this.finalData).subscribe(
          res => {
            environment.savedProductMapping = [];
            environment.savedProductBoundryCondition = {};
            environment.savedProductBenefit = [];
            environment.savedProductOtherBenefit = [];
            environment.savedTillProductBoundryCondition = [];
            environment.savedProductScheme = [];
            environment.selectedReductionSchedule_Id = "";
            environment.selectedReductionScheduleCode = "";
            environment.reduction_sched = "";
            environment.groupLoanSecure = true;
            environment.superProductCredit = false;
            environment.shubhRakshaCredit = false;
            this.change.setAsseleratedCI("");
            this.change.setAdditionalCI("");
            this.change.setWaiverHospital("");
            this.change.setSelectedProduct("");
            this.change.setSelectedLoan("");
            this.change.setSelectedMortality("");
            this.change.setSelectedMortalityRate("");
            this.change.setSelectedCoverageOption("");
            this.change.setSelectedCriticalIllness("");
            this.change.setSelectedFrequency("");
            this.change.setSelectedPaymentTerm("");
            this.change.setSelectedNonMedicalRate("");
            this.change.setSelectedNonMedicalRateCI("");
            this.change.setSelectedNonMedical("");
            this.change.setReductionSchedule("");
            this.change.setInterestRate("");
            this.change.setsingleJointLife("");
            this.change.setCoverageTerm("");
            this.change.setcoverageOptions("");
            this.change.setmoratoriumPeriod("");
            this.change.setmoratoriumTerm("");
            this.change.setsingleJointLife("");
            this.change.setSelectedFlexibility("");
            this.change.setSelectedAcici("");
            this.change.setSelectedAcici("");
            this.change.setSelectedChannel("");
            environment.editPolicy = false;
            this.loader.hide();
            this.b2cModal1.open();

          },
          err => {
            this.disableSave = false;
            this.loader.hide();
            swal({
              title: "Something went wrong.\nPlease try after some time.",
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#eb1826',
              cancelButtonColor: '#F4F1F1',
              confirmButtonText: 'OK'
            }).then((result) => {
              //this.routes.navigate(["product-mapping"]);
            })
          }
        )
      } else {
        this.disableSave = false;
        swal({
          title: "Please fill all fields before saving.",
          type: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#eb1826',
          cancelButtonColor: '#F4F1F1',
          confirmButtonText: 'OK'
        }).then((result) => {
          //this.routes.navigate(["product-mapping"]);
        })
      }
    }
  }

  backbutton() {
    this.saveData();
    this.routes.navigate(['product-conditions']);
  }

  saveData() {
    this.policyList = environment.partnerDetails;
    this.partner_id = this.policyList[0].partner_id;
    this.partner_name = this.policyList[0].partner_name;
    this.partner_logo = this.policyList[0].partner_logo;
    this.loanType = (environment.savedProductMapping[0].loan_type).trim();
    this.loanType = this.loanType.toLowerCase();
    this.policyName = (environment.savedProductMapping[0].policy_name).toUpperCase();
    if (environment.editPolicy == true) {
      this.masterPolicyId = environment.editPolicyId;
    }
    else {
      this.masterPolicyId = 0;
    }
    if (this.selectedNonMedicalLimit != "Other") {
      this.selectedNonMedicalRate = [];
    }
    if (this.selectedAcici != "Other") {
      this.selectedNonMedicalRateCI = [];
    }
    var product: any;
    product = environment.productData;
    console.log(product);
    this.nonMedicalLimitData = this.config.icici(product.item_json.non_medical_limit.items, "item_name", this.selectedNonMedicalLimit);
    if (this.superProductCredit) {
      this.acicinonMedicalLimitData = this.config.icici(product.item_json.acici_non_medical_limit.items, "item_name", this.selectedAcici);
    }

    if (this.acici == false) {
      this.selectedAcici = "not_selected"
      this.acicinonMedicalLimitData = [{
        "item_name": "not_selected",
        "item_id": 5,
        "sub_items": [
          {
            "age_band": "not_selected",
            "sum_assured": "not_selected",
            "item_id": 1
          }
        ]
      }]
    }
    var hospiCash
    var cancerCash
    if (this.hospi == false) {
      hospiCash = 0
    }
    else { hospiCash = parseInt(this.selectedHospital) }
    if (this.cancer == false) {
      cancerCash = 0
    } else { cancerCash = parseInt(this.selectedCancer) }
    if (environment.groupLoanSecure) {
      this.finalData = {
        "product_name": environment.selectedProduct_Id,
        "product_id": environment.savedProductMapping[0].product_name,
        "loan_type": environment.selectedLoan_Id,
        "loan_id": environment.savedProductMapping[0].loan_type,
        "policy_name": environment.savedProductMapping[0].policy_name,
        "status": 1,
        "master_policy_id": this.masterPolicyId,
        "policy_config":
        {
          "master_configuration_b2b": {
            "Product Mapping": environment.savedProductMapping[0],
            "Benefits": environment.savedProductBenefit[0].benefits,
            "Boundary Conditions": environment.savedProductBoundryCondition,
            "SchemeSection": {
              "premium_payment_term": this.savedPaymentTerm,
              "frequency": this.savedPayFrequency,
              "coverage_option": this.savedCoverageOption,
              "non_medical_limit": this.selectedNonMedicalLimit,
              "non_medical_limit_data": this.nonMedicalLimitData,
              "non_medical_limit_values": this.selectedNonMedicalRate
            }
          }
        }
      }
    }
    else if (environment.superProductCredit) {
      this.finalData = {
        "product_name": environment.selectedProduct_Id,
        "product_id": environment.savedProductMapping[0].product_name,
        "loan_type": environment.selectedLoan_Id,
        "loan_id": environment.savedProductMapping[0].loan_type,
        "policy_name": environment.savedProductMapping[0].policy_name,
        "status": 1,
        "master_policy_id": this.masterPolicyId,
        "policy_config":
        {
          "master_configuration_b2b": {
            "Product Mapping": environment.savedProductMapping[0],
            "Benefits": environment.savedProductBenefit[0].benefits,
            "Boundary Conditions": environment.savedProductBoundryCondition,
            "SchemeSection": {
              "cancer_non_medical_limit": cancerCash,
              "hosp_non_medical_limit": hospiCash,
              "non_medical_limit": this.selectedNonMedicalLimit,
              "non_medical_limit_data": this.nonMedicalLimitData,
              "non_medical_limit_values": this.selectedNonMedicalRate,
              "acici_non_medical_limit": this.selectedAcici,
              "acici_non_medical_limit_data": this.acicinonMedicalLimitData,
              "acici_non_medical_limit_values": this.selectedNonMedicalRateCI
            }
          }
        }
      }
      // delete this.finalData["policy_config"]["master_configuration_b2b"]["Boundary Conditions"]["FlatWise"]["flat"]["Flat"]
    }
    this.savedData = []
    if (environment.groupLoanSecure) {
      this.savedData.push({
        "premium_payment_term": this.savedPaymentTerm, "frequency": this.savedPayFrequency,
        "coverage_option": this.savedCoverageOption, "non_medical_limit": this.selectedNonMedicalLimit,
        "non_medical_limit_values": this.selectedNonMedicalRate
      });
    } else if (environment.superProductCredit && this.acici) {
      this.savedData.push({
        "cancer_non_medical_limit": this.selectedCancer,
        "hosp_non_medical_limit": this.selectedHospital,
        // "flexibility": this.selectedFlexibility,
        "non_medical_limit": this.selectedNonMedicalLimit,
        "non_medical_limit_values": this.selectedNonMedicalRate,
        "acici_non_medical_limit": this.selectedAcici,
        "acici_non_medical_limit_values": this.selectedNonMedicalRateCI
      });
    } else if (environment.superProductCredit) {
      this.savedData.push({
        "cancer_non_medical_limit": (this.selectedCancer),
        "hosp_non_medical_limit": (this.selectedHospital),
        // "flexibility": this.selectedFlexibility,
        "non_medical_limit": this.selectedNonMedicalLimit,
        "non_medical_limit_values": this.selectedNonMedicalRate
      });
    }
    this.partner_name = this.partner_name.toUpperCase();
    environment.savedProductScheme = this.savedData;

  }
  changeRoute() {
    // this.loader.show();
    this.routes.navigate(["partnerListing"]);
  }
  checknumber(e) {
    var re = /^[0-9\ _.-]+$/;
    var str = e.target.value;
    if (e.keyCode != 45 && (e.keyCode < 48 || e.keyCode > 57)) {
      return false;
    }
  }
  storeValue(event) {
    if (event.target.closest('span').attributes.id.value == 'productHospitilization') {
      if (parseInt(event.target.value) < 5000 || parseInt(event.target.value) > 500000) {
        event.target.value = ""
        swal({
          title: "Hospitalization NML should be between 5000 and 500000.",
          type: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#eb1826',
          cancelButtonColor: '#F4F1F1',
          confirmButtonText: 'OK'
        }).then((result) => {
          //this.routes.navigate(["product-mapping"]);
        })
      }
      this.selectedHospital = event.target.value;
    } else if (event.target.closest('span').attributes.id.value == 'productCancer') {
      if (parseInt(event.target.value) < 5000 || parseInt(event.target.value) > 5000000) {
        event.target.value = ""
        swal({
          title: "Cancer NML should be between 5000 and 5000000.",
          type: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#eb1826',
          cancelButtonColor: '#F4F1F1',
          confirmButtonText: 'OK'
        }).then((result) => {
          //this.routes.navigate(["product-mapping"]);
        })
      }
      this.selectedCancer = event.target.value;
    }
  }

  includeIndexValue(arraytosearch, key, valuetosearch) {
    for (var i = 0; i < arraytosearch.length; i++) {
      if (arraytosearch[i][key].includes(valuetosearch)) {
        return i;
      }
    }
    return null;
  }

  checkacici() {
    var savedJson = environment.savedProductBenefit;
    var savedBenefits = savedJson[0].benefits
    var dbcovgtype
    var index
    var indexF = this.includeIndexValue(savedBenefits["Flat"], "name", "Accelerated CI")
    var indexA = this.includeIndexValue(savedBenefits["AgeBandWise"], "name", "Accelerated CI")
    var indexT = this.includeIndexValue(savedBenefits["TermWise"], "name", "Accelerated CI")
    if (indexF != null) {
      index = indexF
      dbcovgtype = "Flat"
    } else if (indexA != null) {
      index = indexA
      dbcovgtype = "AgeBandWise"
    } else if (indexT != null) {
      index = indexT
      dbcovgtype = "TermWise"
    }
    if (index != null) {
      this.acici = true
    } else {
      var indexF = this.includeIndexValue(savedBenefits["Flat"], "name", "Additional CI")
      var indexA = this.includeIndexValue(savedBenefits["AgeBandWise"], "name", "Additional CI")
      var indexT = this.includeIndexValue(savedBenefits["TermWise"], "name", "Additional CI")
      if (indexF != null) {
        index = indexF
        dbcovgtype = "Flat"
      } else if (indexA != null) {
        index = indexA
        dbcovgtype = "AgeBandWise"
      } else if (indexT != null) {
        index = indexT
        dbcovgtype = "TermWise"
      }
      if (index != null) {
        this.acici = true
      }
    }
  }

  checkCancer() {
    var savedJson = environment.savedProductBenefit;
    var savedBenefits = savedJson[0].benefits
    var dbcovgtype
    var index
    var indexF = this.includeIndexValue(savedBenefits["Flat"], "name", "Cancer Protect")
    var indexA = this.includeIndexValue(savedBenefits["AgeBandWise"], "name", "Cancer Protect")
    var indexT = this.includeIndexValue(savedBenefits["TermWise"], "name", "Cancer Protect")
    if (indexF != null) {
      index = indexF
      dbcovgtype = "Flat"
    } else if (indexA != null) {
      index = indexA
      dbcovgtype = "AgeBandWise"
    } else if (indexT != null) {
      index = indexT
      dbcovgtype = "TermWise"
    }
    if (index != null) {
      this.cancer = true
    }
  }

  checkHospi() {
    var savedJson = environment.savedProductBenefit;
    var savedBenefits = savedJson[0].benefits
    var dbcovgtype
    var index
    var indexF = this.includeIndexValue(savedBenefits["Flat"], "name", "EMI Waiver(Hosp)")
    var indexA = this.includeIndexValue(savedBenefits["AgeBandWise"], "name", "EMI Waiver(Hosp)")
    var indexT = this.includeIndexValue(savedBenefits["TermWise"], "name", "EMI Waiver(Hosp)")
    if (indexF != null) {
      index = indexF
      dbcovgtype = "Flat"
    } else if (indexA != null) {
      index = indexA
      dbcovgtype = "AgeBandWise"
    } else if (indexT != null) {
      index = indexT
      dbcovgtype = "TermWise"
    }
    if (index != null) {
      this.hospi = true
    }
  }
}

import { Directive, OnInit, Input, ElementRef } from '@angular/core';

@Directive({
  selector: '[appCustomInput]'
})
export class CustomInputDirective implements OnInit {

  @Input() type: string;
  @Input() legendText: string;
  @Input() inputType: string; //number , text , product
  @Input() placeholder: string;
  @Input() icon: string;
  @Input() position: string;
  @Input() svg: string;
  @Input() value: string = "";
  @Input() maxlength: any;
  @Input() tabIndex: any;

  constructor(private el: ElementRef) { }

  ngOnInit() {
    console.log("Hello Custom Input Directive");
    console.log(this.type);
    console.log(this.value);
    switch (this.type) {
      case 'legend-input':
        this.el.nativeElement.innerHTML = `<fieldset>
                                            <span class="hide">* required</span>
                                            <legend>${this.legendText}</legend>
                                            <input type="${this.inputType}" placeholder="${this.placeholder}" value="${this.value}" maxlength="${this.maxlength}" tabindex="${this.tabIndex}"/>
                                            </fieldset>`
        break;
      case 'normal-input':
        this.el.nativeElement.innerHTML = `<fieldset id='plain'>
        <span class="hide">* required</span>
        <input type="${this.inputType}" placeholder="${this.placeholder}" value="${this.value}" maxlength="${this.maxlength}" tabindex="${this.tabIndex}"/>
        </fieldset>`
        break;
      case 'legend-icon-input':
        this.position = "simple right";
        this.el.nativeElement.innerHTML = `<fieldset>
                                            <span class="hide">* required</span>
                                            <legend>${this.legendText}</legend>
                                            <input type="${this.inputType}" placeholder="${this.placeholder}" value="${this.value}" tabindex="${this.tabIndex}" maxlength="${this.maxlength}"/>
                                            <i class="${this.icon} icon ${this.position}"></i>
                                            </fieldset>`
        break;
      case 'search-input':
        this.svg = "assets/images/search_icon.svg";
        this.position = "left";
        this.el.nativeElement.innerHTML = `<fieldset class="full-width search">
                                          <input type="${this.inputType}" placeholder="${this.placeholder}" value="${this.value}" tabindex="${this.tabIndex}"/>
                                          <img src=${this.svg} class="icon searchIcon ${this.position}"/>
                                          <img style="max-width: 1.2rem;top:25%; opacity: 0.5;"src="assets/icons/Cancel.svg" class="icon right"/>
                                          </fieldset>`
        break;
      case 'checked':
        this.el.nativeElement.innerHTML = `<fieldset style="padding: 10px 15px;">
                                            <div class="checkbox">
                                            <label>${this.value}</label>
                                            <input class="hidden" type="checkbox">
                                            </div>
                                            </fieldset>`
        break;
      case 'default-checked':
        this.el.nativeElement.innerHTML = `<fieldset style="padding: 10px 15px;">
                                            <div class="checkbox checked    padding: 6px 20px; disabled" >
                                            <label>${this.value}</label>
                                            <input class="hidden" type="checkbox" class="disabled" >
                                            </div>
                                            </fieldset>`
        break;
      case 'checked-input':
        this.el.nativeElement.innerHTML = `<fieldset>
        <span class="hide">* required</span>
        <legend class="checkboxLegend checkbox textbox"><label class="checkboxLabel">${this.legendText}</label></legend>
        <input type="${this.inputType}" placeholder="${this.placeholder}" value="${this.value}" class="disabled" tabindex="${this.tabIndex}">
      </fieldset>`
        break;
      default:
        this.el.nativeElement.innerHTML = '<p>Default input</p>';
        break;
    }

  }
}

import { Component, OnInit, ElementRef, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { RouterModule, Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from '../../app.component';
import { environment } from '../../../environments/environment';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ConfigService } from '../../services/config.service';
import { DataShareService } from '../../services/data-share.service';
import swal from 'sweetalert2';
import { findIndex } from '../../../../node_modules/rxjs/operators';
import { TRANSITION_ID } from '../../../../node_modules/@angular/platform-browser/src/browser/server-transition';
import { empty } from '../../../../node_modules/rxjs';

@Component({
  selector: 'app-product-benifits',
  templateUrl: './product-benifits.component.html',
  styleUrls: ['./product-benifits.component.scss']
})
export class ProductBenifitsComponent implements OnInit {
  sub;
  id;
  DeathBenefitValue = true;
  criticalIllnessList: any;
  productData: any;
  benefitList: any;
  interestRates: any = [];
  otherBenifitsList: any;
  superProtectBenefitList: any;
  selectedCriticalIllness: any;
  adbBenefit = false;
  tpdBenefit = false;
  terminalIllnessBenefit = false;
  enchancedAdbBenefit = false;
  accidentalTpdBenefit = false;
  cancerProtectBenefit = false;
  accidentalCIBenefit = false;
  additionalCIBenefit = false;
  emiWaiverBenefit = false;
  jointLifeBenefit = false;
  saveData: any = [];
  newBenefitsData: any = [];
  newOtherBenefitsData: any = [];
  productMappingData: any;
  benefit: any = {};
  otherBenefit: any = [];
  selectedFloorRate: any = "";
  storeData: any = [];
  bandRange: any = [];
  groupLoanSecure = environment.groupLoanSecure;
  superProductCredit = environment.superProductCredit;
  shubhRakshaCredit = environment.shubhRakshaCredit;
  asseleratedCI: any;
  additionalCI: any;
  waiverHospital: any;
  selectedAcceleratedCI: any;
  selectedAdditionalCI: any;
  selectedADBValue: any;
  selectedwaiverHospitalDays: any;
  selectedCancerProtect: any;
  bandTableData: any = [];
  bandTableObject: any = [];
  benefitCoverageType: any = [];
  tableReference: any = []
  selectedMortality = "";
  selectedBandDB = [];
  selectedBandTI = [];
  selectedBandADB = [];
  selectedBandEADB = [];
  selectedBandAATPD = [];
  selectedBandCP = [];
  selectedBandACI = [];
  selectedBandCI = [];
  selectedBandEMI = [];
  selectedBandTPD = []
  selectedBandCRI = []
  selectedRateCRI = []
  selectedRateTPD = [];
  selectedRateADB = []
  selectedRateDB = [];
  selectedRateTI = [];
  selectedTableADB = [];
  selectedTableEADB = [];
  selectedTableAATPD = [];
  selectedTableCP = [];
  selectedTableACI = [];
  selectedTableCI = [];
  selectedTableEMI = [];
  index = "";
  event = "";
  value = "";
  addmore = [];
  copyDB = false;
  copyADB = false;
  adbcheck = false;
  correctVal = true;
  valuefinal = "";
  checkcheck = ""
  copytblADB = ""
  checked = []
  empty = ""
  emi7 = ""
  emi15 = ""
  emi = []
  constructor(public routes: Router, public route: ActivatedRoute, public app: AppComponent, public config: ConfigService,
    public change: DataShareService, public el: ElementRef, public loader: Ng4LoadingSpinnerService, private cdref: ChangeDetectorRef) {

    if (environment.loginSuccessfully == true) {
      //this.benefit = [];
      // this.benefit.push({ "name": "Death Benefit", "code": "Base" });
      this.sub = this.route.queryParams.subscribe(params => {
        console.log(params);
        this.id = params['id'];
        console.log(this.id);
      });
      if (environment.savedProductBenefit.length > 0) {
        var savedJson = environment.savedProductBenefit;
        var savedBenefits = [];
        savedBenefits = savedJson[0].benefits;
        this.benefit = savedBenefits;
        if (environment.superProductCredit == true) {
          var index = this.findIndexValue(this.benefit["Flat"], "name", "Death Benefit")
          if (index != null) {
            var value = this.benefit["Flat"][index]["Band"]["table_reference"]
            this.selectedMortality = value
            this.change.setDbMortality(this.selectedMortality)
          }
          else {
            this.selectedMortality = ""
            this.change.setDbMortality(this.selectedMortality)
          }
        }
      }

      this.productMappingData = environment.savedProductMapping;
      if (this.productMappingData[0].mortality_rate_type == "Age Band Wise" || this.productMappingData[0].mortality_rate_type == "Term Wise") {
        for (var i = 0; i < this.productMappingData[0].mortality_rate.length; i++) {
          var range = this.productMappingData[0].mortality_rate[i].Age.trim();
          this.bandRange.push(range)
        }
      }
      this.productData = environment.productData;
      if (environment.groupLoanSecure == true) {
        if (environment.savedProductBenefit.length == 0) {
          this.benefit["AgeBandWise"] = [];
        }
        var code;
        this.benefitList = this.productData.item_json.benefits.items;
        var criticalIllness = this.config.icici(this.benefitList, "item_name", "Critical Illness");
        this.criticalIllnessList = criticalIllness[0].sub_items;
        this.selectedCriticalIllness = this.change.getSelectedCriticalIllness();
        this.change.newselectedCriticalIllnessSubject.subscribe((data) => {
          console.log(data);
          this.selectedCriticalIllness = data;
          console.log(this.benefit);
          for (var i = 0; i < this.criticalIllnessList.length; i++) {
            if (this.criticalIllnessList[i].item_name == this.selectedCriticalIllness) {
              code = this.criticalIllnessList[i].item_code;
              console.log(this.benefit);
              break;
            }
          }
          var index = this.includeIndexValue(this.benefit["AgeBandWise"], "name", "Critical Illness")
          if (index != null) {
            var temp = this.benefit["AgeBandWise"][index]
            this.benefit["AgeBandWise"][index] = ({ "name": "Critical Illness " + this.selectedCriticalIllness, "code": code, "Band": temp.Band })
          }
        });
        //gls new
        this.bandTableData["Death Benefit"] = [{ "Band": "", "TableRef": "" }]
        this.bandTableData["Accidental Death Benefit"] = [{ "Band": "", "TableRef": "" }]
        this.bandTableData["Total and Permanent Disability"] = [{ "Band": "", "TableRef": "" }]
        this.bandTableData["Critical Illness"] = [{ "Band": "", "TableRef": "" }]
        this.selectedBandDB[0] = ""
        this.selectedRateDB[0] = ""
        this.selectedBandADB[0] = ""
        this.selectedRateADB[0] = ""
        this.selectedBandTPD[0] = ""
        this.selectedRateTPD[0] = ""
        this.selectedBandCRI[0] = ""
        this.selectedRateCRI[0] = ""
      } else if (environment.superProductCredit == true) {
        if (environment.savedProductBenefit.length == 0) {
          this.benefit["Flat"] = []
          this.benefit["AgeBandWise"] = [];
          this.benefit["TermWise"] = []
        }
        this.superProtectBenefitList = this.productData.item_json.benefits.benefit_items;
        this.benefitList = this.productData.item_json.benefits.benefit_items;
        this.otherBenifitsList = this.productData.item_json.benefits.other_items;
        this.tableReference = this.productData.item_json.table_reference.items;
        //this.interestRates = this.productData.item_json.benefits.other_items[1].sub_items;
        var asseleratedCI = this.config.icici(this.benefitList, "item_name", "Accelerated CI");
        this.asseleratedCI = asseleratedCI[0].sub_items;
        var additionalCI = this.config.icici(this.benefitList, "item_name", "Additional CI");
        this.additionalCI = additionalCI[0].sub_items;
        var waiverHospital = this.config.icici(this.benefitList, "item_name", "EMI Waiver(Hosp)");
        this.waiverHospital = waiverHospital[0].sub_items;
        this.emi = [
          {
            "item_name": "1",
            "item_id": 1
          },
          {
            "item_name": "2",
            "item_id": 2
          },
          {
            "item_name": "3",
            "item_id": 3
          },
          {
            "item_name": "4",
            "item_id": 4
          },
          {
            "item_name": "5",
            "item_id": 5
          },
          {
            "item_name": "6",
            "item_id": 6
          }
        ];
        this.selectedAcceleratedCI = this.change.getAsseleratedCI();
        this.change.newAsseleratedCISubject.subscribe((data) => {
          this.selectedAcceleratedCI = data;
          if (this.selectedAcceleratedCI != "") {
            var name = "Accelerated CI " + this.selectedAcceleratedCI
            for (var i = 0; i < this.benefit["Flat"].length; i++) {
              if (this.benefit["Flat"][i].name.includes("Accelerated CI")) {
                this.benefit["Flat"][i].name = name
              }
            }
            for (var i = 0; i < this.benefit["AgeBandWise"].length; i++) {
              if (this.benefit["AgeBandWise"][i].name.includes("Accelerated CI")) {
                this.benefit["AgeBandWise"][i].name = name
              }
            }
            for (var i = 0; i < this.benefit["TermWise"].length; i++) {
              if (this.benefit["TermWise"][i].name.includes("Accelerated CI")) {
                this.benefit["TermWise"][i].name = name
              }
            }
          }
        })

        this.selectedAdditionalCI = this.change.getAdditionalCI();
        this.change.newAdditionalCISubject.subscribe((data) => {
          this.selectedAdditionalCI = data;
          if (this.selectedAdditionalCI != "") {
            var name = "Additional CI " + this.selectedAdditionalCI
            for (var i = 0; i < this.benefit["Flat"].length; i++) {
              if (this.benefit["Flat"][i].name.includes("Additional CI")) {
                this.benefit["Flat"][i].name = name
              }
            }
            for (var i = 0; i < this.benefit["AgeBandWise"].length; i++) {
              if (this.benefit["AgeBandWise"][i].name.includes("Additional CI")) {
                this.benefit["AgeBandWise"][i].name = name
              }
            }
            for (var i = 0; i < this.benefit["TermWise"].length; i++) {
              if (this.benefit["TermWise"][i].name.includes("Additional CI")) {
                this.benefit["TermWise"][i].name = name
              }
            }
          }
        });

        this.selectedwaiverHospitalDays = this.change.getWaiverHospital();
        this.change.newWaiverHospitalSubject.subscribe((data) => {
          this.selectedwaiverHospitalDays = data;
          this.emi7 = ""
          this.emi15 = ""
          if (this.selectedwaiverHospitalDays != "") {
            for (var i = 0; i < this.benefit["Flat"].length; i++) {
              if (this.benefit["Flat"][i].name.includes("EMI Waiver(Hosp)")) {
                this.benefit["Flat"][i].name = "EMI Waiver(Hosp) " + this.selectedwaiverHospitalDays
              }
            }
            for (var i = 0; i < this.benefit["AgeBandWise"].length; i++) {
              if (this.benefit["AgeBandWise"][i].name.includes("EMI Waiver(Hosp)")) {
                this.benefit["AgeBandWise"][i].name = "EMI Waiver(Hosp) " + this.selectedwaiverHospitalDays
              }
            }
            for (var i = 0; i < this.benefit["TermWise"].length; i++) {
              if (this.benefit["TermWise"][i].name.includes("EMI Waiver(Hosp)")) {
                this.benefit["TermWise"][i].name = "EMI Waiver(Hosp) " + this.selectedwaiverHospitalDays
              }
            }
          }
        })

        this.selectedMortality = this.change.getDbMortality();
        this.change.newDbMortalitySubject.subscribe((data) => {
          this.selectedMortality = data;
        });

        this.emi7 = this.change.getemi7();
        this.change.newEmi7Subject.subscribe((data) => {
          this.emi7 = data;
          this.saveEmiValue();
        });
        this.emi15 = this.change.getemi15();
        this.change.newEmi15Subject.subscribe((data) => {
          this.emi15 = data;
          this.saveEmiValue();
        });

      }
      if (environment.savedProductBenefit.length == 0) {
        this.selectedMortality = ""
        this.selectedwaiverHospitalDays = ""
        this.selectedAcceleratedCI = ""
        this.selectedAdditionalCI = ""
      }
    } else {
      this.routes.navigate(["login"]);
    }


  }
  ngOnInit() {

  }
  ngAfterViewInit() {
    var __self = this;
    setInterval(function () {
      __self.el.nativeElement.querySelectorAll('input').forEach(function (input) {
        input.onpaste = function (e) {
          e.preventDefault();
        }
      })
    }, 500)
    if (environment.savedProductBenefit.length > 0) {
      var savedJson = environment.savedProductBenefit;
      var savedBenefits = [];
      var ageBandBenefits = [];
      var termBenefits = [];
      var flatBenefits = [];
      var benefitAge = [];
      var benefitTerm = [];
      var benefitFlat = [];
      //put condition
      savedJson = environment.savedProductBenefit
      if (environment.groupLoanSecure == true) {
        var __self = this
        savedBenefits = savedJson[0].benefits;
        this.benefit = savedBenefits;
        ageBandBenefits = savedBenefits["AgeBandWise"];
        console.log(this.benefit);
        benefitAge = this.config.getValues(ageBandBenefits, "name")
        benefitAge.forEach(function (ben) {
          if (ben.includes("Critical Illness")) {
            for (var i = 0; i < __self.criticalIllnessList.length; i++) {
              if (ben.includes(__self.criticalIllnessList[i].item_name)) {
                __self.selectedCriticalIllness = __self.criticalIllnessList[i].item_name
                __self.change.setSelectedCriticalIllness(__self.selectedCriticalIllness)
              }
            }
          }
        })
        savedBenefits = savedJson[0].benefits;
        this.benefit = savedBenefits;
        ageBandBenefits = savedBenefits["AgeBandWise"];
        var __self = this;
        console.log(this.benefit);
        benefitAge = this.config.getValues(ageBandBenefits, "name")
        this.selectedBandDB = []
        this.selectedRateDB = []
        this.selectedBandADB = []
        this.selectedRateADB = []
        this.selectedBandTPD = []
        this.selectedRateTPD = []
        this.selectedBandCRI = []
        this.selectedRateCRI = []
        this.el.nativeElement.querySelectorAll('.table-column.checkbox').forEach(function (benefit) {
          benefitAge.forEach(function (ben) {
            ben = ben.trim();
            if (ben.includes(benefit.innerText.trim()) && ben != "Accidental Death Benefit") {
              ben = benefit.innerText.trim()
              benefit.classList.add('checked')
              __self.benefitCoverageType[ben] = 'Age Band'
              __self.addmore[ben] = true;
              var main = benefit.parentElement.parentElement;
              main.querySelector('.product-column.border').classList.remove('disabled');
              if (ben == 'Death Benefit') {
                main.querySelector('.button.isSelected').classList.remove('isSelected');
              }
              main.querySelectorAll('.button')[0].classList.add('isSelected');
              main.querySelector('.benefitValue').classList.remove('disabled')
              var index = __self.includeIndexValue(ageBandBenefits, "name", ben)
              var band = Object.keys(ageBandBenefits[index].Band)
              var tblref = Object.values(ageBandBenefits[index].Band);
              __self.bandTableData[ben] = [{ "Band": band[0], "TableRef": tblref[0] }]
              __self.checked[ben] = true
              for (var k = 0; k < band.length; k++) {
                if (ben == 'Death Benefit') {
                  __self.selectedBandDB[k] = (band[k])
                  __self.selectedRateDB[k] = (tblref[k])
                } else if (ben == 'Total and Permanent Disability') {
                  __self.selectedBandTPD[k] = (band[k])
                  __self.selectedRateTPD[k] = (tblref[k])
                } else if (ben.includes('Critical Illness')) {
                  __self.selectedBandCRI[k] = (band[k])
                  __self.selectedRateCRI[k] = (tblref[k])
                }
              }
              for (var k = 1; k < band.length; k++) {
                __self.bandTableData[ben].push({ "Band": band[k], "TableRef": tblref[k] })
              }
            } else if (ben == "Accidental Death Benefit" && benefit.innerText.trim() == "Accidental Death Benefit") {
              ben = benefit.innerText.trim()
              benefit.classList.add('checked')
              __self.benefitCoverageType[ben] = 'Age Band'
              __self.addmore[ben] = true;
              var main = benefit.parentElement.parentElement;
              main.querySelector('.product-column.border').classList.remove('disabled');
              main.querySelectorAll('.button')[0].classList.add('isSelected');
              main.querySelector('.benefitValue').classList.remove('disabled')
              var index = __self.includeIndexValue(ageBandBenefits, "name", ben)
              var band = Object.keys(ageBandBenefits[index].Band)
              var tblref = Object.values(ageBandBenefits[index].Band);
              __self.bandTableData[ben] = [{ "Band": band[0], "TableRef": tblref[0] }]
              __self.checked[ben] = true
              for (var k = 0; k < band.length; k++) {
                __self.selectedBandADB[k] = (band[k])
                __self.selectedRateADB[k] = (tblref[k])
              }
              for (var k = 1; k < band.length; k++) {
                __self.bandTableData[ben].push({ "Band": band[k], "TableRef": tblref[k] })
              }
            }
          })
        })
        if (this.selectedBandADB.length == 0) {
          this.selectedBandADB[0] = ""
          this.selectedRateADB[0] = ""
        }
        if (this.selectedBandTPD.length == 0) {
          this.selectedBandTPD[0] = ""
          this.selectedRateTPD[0] = ""
        }
        if (this.selectedBandCRI.length == 0) {
          this.selectedBandCRI[0] = ""
          this.selectedRateCRI[0] = ""
        }
      }
      else if (environment.superProductCredit == true) {
        savedBenefits = savedJson[0].benefits;
        this.benefit = savedBenefits;
        ageBandBenefits = savedBenefits["AgeBandWise"];
        termBenefits = savedBenefits["TermWise"];
        flatBenefits = savedBenefits["Flat"];
        var __self = this;
        console.log(this.benefit);
        benefitAge = this.config.getValues(ageBandBenefits, "name")
        benefitTerm = this.config.getValues(termBenefits, "name")
        benefitFlat = this.config.getValues(flatBenefits, "name")
        benefitFlat.forEach(function (ben) {
          if (ben.includes("Accelerated CI")) {
            for (var i = 0; i < __self.asseleratedCI.length; i++) {
              if (ben.includes(__self.asseleratedCI[i].item_name)) {
                __self.selectedAcceleratedCI = __self.asseleratedCI[i].item_name
                __self.change.setAsseleratedCI(__self.selectedAcceleratedCI)
              }
            }
          }
          if (ben.includes("Additional CI")) {
            for (var i = 0; i < __self.additionalCI.length; i++) {
              if (ben.includes(__self.additionalCI[i].item_name)) {
                __self.selectedAdditionalCI = __self.additionalCI[i].item_name
                __self.change.setAdditionalCI(__self.selectedAdditionalCI)
              }
            }
          }
          for (var i = 0; i < __self.waiverHospital.length; i++) {
            if (ben.includes(__self.waiverHospital[i].item_name)) {
              __self.selectedwaiverHospitalDays = __self.waiverHospital[i].item_name
              __self.change.setWaiverHospital(__self.selectedwaiverHospitalDays)
            }
          }
        })
        benefitTerm.forEach(function (ben) {
          if (ben.includes("Accelerated CI")) {
            for (var i = 0; i < __self.asseleratedCI.length; i++) {
              if (ben.includes(__self.asseleratedCI[i].item_name)) {
                __self.selectedAcceleratedCI = __self.asseleratedCI[i].item_name
                __self.change.setAsseleratedCI(__self.selectedAcceleratedCI)
              }
            }
          }
          if (ben.includes("Additional CI")) {
            for (var i = 0; i < __self.additionalCI.length; i++) {
              if (ben.includes(__self.additionalCI[i].item_name)) {
                __self.selectedAdditionalCI = __self.additionalCI[i].item_name
                __self.change.setAdditionalCI(__self.selectedAdditionalCI)
              }
            }
          }
          for (var i = 0; i < __self.waiverHospital.length; i++) {
            if (ben.includes(__self.waiverHospital[i].item_name)) {
              __self.selectedwaiverHospitalDays = __self.waiverHospital[i].item_name
              __self.change.setWaiverHospital(__self.selectedwaiverHospitalDays)
            }
          }
        })
        benefitAge.forEach(function (ben) {
          if (ben.includes("Accelerated CI")) {
            for (var i = 0; i < __self.asseleratedCI.length; i++) {
              if (ben.includes(__self.asseleratedCI[i].item_name)) {
                __self.selectedAcceleratedCI = __self.asseleratedCI[i].item_name
                __self.change.setAsseleratedCI(__self.selectedAcceleratedCI)
              }
            }
          }
          if (ben.includes("Additional CI")) {
            for (var i = 0; i < __self.additionalCI.length; i++) {
              if (ben.includes(__self.additionalCI[i].item_name)) {
                __self.selectedAdditionalCI = __self.additionalCI[i].item_name
                __self.change.setAdditionalCI(__self.selectedAdditionalCI)
              }
            }
          }
          for (var i = 0; i < __self.waiverHospital.length; i++) {
            if (ben.includes(__self.waiverHospital[i].item_name)) {
              __self.selectedwaiverHospitalDays = __self.waiverHospital[i].item_name
              __self.change.setWaiverHospital(__self.selectedwaiverHospitalDays)
            }
          }
        })
        var emindexF = this.findIndexValue(savedJson[0].benefits["Flat"], "name", "")
        var emindexT = this.findIndexValue(savedJson[0].benefits["TermWise"], "name", "")
        var emindexA = this.findIndexValue(savedJson[0].benefits["AgeBandWise"], "name", "")
        if (emindexF != null) {
          savedJson[0].benefits["Flat"][emindexF].name = "EMI Waiver(Hosp) " + this.selectedwaiverHospitalDays
        } else if (emindexT != null) {
          savedJson[0].benefits["TermWise"][emindexT].name = "EMI Waiver(Hosp) " + this.selectedwaiverHospitalDays
        }
        else if (emindexA != null) {
          savedJson[0].benefits["AgeBandWise"][emindexA].name = "EMI Waiver(Hosp) " + this.selectedwaiverHospitalDays
        }
        savedBenefits = savedJson[0].benefits;
        this.benefit = savedBenefits;
        ageBandBenefits = savedBenefits["AgeBandWise"];
        termBenefits = savedBenefits["TermWise"];
        flatBenefits = savedBenefits["Flat"];
        var __self = this;
        console.log(this.benefit);
        benefitAge = this.config.getValues(ageBandBenefits, "name")
        benefitTerm = this.config.getValues(termBenefits, "name")
        benefitFlat = this.config.getValues(flatBenefits, "name")

        this.el.nativeElement.querySelectorAll('.table-column.checkbox').forEach(function (benefit) {
          benefitAge.forEach(function (ben) {
            ben = ben.trim();
            if (ben.includes(benefit.innerText.trim()) && ben != "Accidental Death Benefit") {
              ben = benefit.innerText.trim()
              benefit.classList.add('checked')
              __self.benefitCoverageType[ben] = 'Age Band'
              __self.addmore[ben] = true;
              var main = benefit.parentElement.parentElement;
              main.querySelector('.product-column.border').classList.remove('disabled');
              if (ben == 'Death Benefit') {
                main.querySelector('.button.isSelected').classList.remove('isSelected');
              }
              main.querySelectorAll('.button')[1].classList.add('isSelected');
              var index = __self.includeIndexValue(ageBandBenefits, "name", ben)
              var band = Object.keys(ageBandBenefits[index].Band)
              var tblref = Object.values(ageBandBenefits[index].Band);
              __self.bandTableData[ben] = [{ "Band": band[0], "TableRef": tblref[0] }]
              for (var k = 0; k < band.length; k++) {
                if (ben == 'Death Benefit') {
                  __self.selectedBandDB.push(band[k])
                  __self.selectedRateDB.push(tblref[k])
                } else if (ben == 'Terminal Illness') {
                  __self.copyDB = true
                  // __self.selectedBandTI.push(band[k])
                  // __self.selectedRateTI.push(tblref[k])
                } else if (ben == 'Accidental Death Benefit') {
                  __self.selectedBandADB.push(band[k])
                  __self.selectedTableADB.push(tblref[k])
                  __self.adbcheck = true
                } else if (ben == 'Enhanced ADB') {
                  __self.copyADB = true
                  __self.selectedBandEADB.push(band[k])
                  __self.selectedTableEADB.push(tblref[k])
                } else if (ben == 'Accelerated Accidental Total and Permanent Disability') {
                  __self.selectedBandAATPD.push(band[k])
                  __self.selectedTableAATPD.push(tblref[k])
                } else if (ben == 'Cancer Protect') {
                  __self.selectedBandCP.push(band[k])
                  __self.selectedTableCP.push(tblref[k])
                } else if (ben.includes('Accelerated CI')) {
                  __self.selectedBandACI.push(band[k])
                  __self.selectedTableACI.push(tblref[k])
                } else if (ben.includes('Additional CI')) {
                  __self.selectedBandCI.push(band[k])
                  __self.selectedTableCI.push(tblref[k])
                } else if (ben.includes('EMI Waiver(Hosp)')) {
                  __self.selectedBandEMI.push(band[k])
                  __self.selectedTableEMI.push(tblref[k])
                  var indexx = __self.includeIndexValue(ageBandBenefits, "name", "EMI Waiver(Hosp)")
                  if (__self.selectedwaiverHospitalDays == "7Days") {
                    __self.emi7 = ageBandBenefits[indexx].value1
                  } else if (__self.selectedwaiverHospitalDays == "15Days") {
                    __self.emi15 = ageBandBenefits[indexx].value2
                  } else if (__self.selectedwaiverHospitalDays == "7and15Days") {
                    __self.emi7 = ageBandBenefits[indexx].value1
                    __self.emi15 = ageBandBenefits[indexx].value2
                  }
                }
              }
              for (var k = 1; k < band.length; k++) {
                __self.bandTableData[ben].push({ "Band": band[k], "TableRef": tblref[k] })
              }
            } else if (ben == "Accidental Death Benefit" && benefit.innerText.trim() == "Accidental Death Benefit") {
              benefit.classList.add('checked')
              __self.benefitCoverageType[ben] = 'Age Band'
              __self.addmore[ben] = true;
              __self.adbcheck = true
              var main = benefit.parentElement.parentElement;
              main.querySelector('.product-column.border').classList.remove('disabled');
              if (ben == 'Death Benefit') {
                main.querySelector('.button.isSelected').classList.remove('isSelected');
              }
              main.querySelectorAll('.button')[1].classList.add('isSelected');
              var index = __self.includeIndexValue(ageBandBenefits, "name", ben)
              var band = Object.keys(ageBandBenefits[index].Band)
              var tblref = Object.values(ageBandBenefits[index].Band);
              __self.bandTableData[ben] = [{ "Band": band[0], "TableRef": tblref[0] }]
              for (var k = 0; k < band.length; k++) {
                __self.selectedBandADB.push(band[k])
                __self.selectedTableADB.push(tblref[k])
              }
              for (var k = 1; k < band.length; k++) {
                __self.bandTableData[ben].push({ "Band": band[k], "TableRef": tblref[k] })
              }
            }
          })
          benefitTerm.forEach(function (ben) {
            ben = ben.trim();
            if (ben.includes(benefit.innerText.trim()) && ben != "Accidental Death Benefit") {
              ben = benefit.innerText.trim()
              benefit.classList.add('checked')
              __self.benefitCoverageType[ben] = 'Term'
              __self.addmore[ben] = true;
              var main = benefit.parentElement.parentElement;
              main.querySelector('.product-column.border').classList.remove('disabled');
              if (ben == 'Death Benefit') {
                main.querySelector('.button.isSelected').classList.remove('isSelected');
              }
              main.querySelectorAll('.button')[2].classList.add('isSelected');
              var index = __self.includeIndexValue(termBenefits, "name", ben)
              var band = Object.keys(termBenefits[index].Band)
              var tblref = Object.values(termBenefits[index].Band);
              __self.bandTableData[ben] = [{ "Band": band[0], "TableRef": tblref[0] }]
              for (var k = 0; k < band.length; k++) {
                if (ben == 'Death Benefit') {
                  __self.selectedBandDB.push(band[k])
                  __self.selectedRateDB.push(tblref[k])
                } else if (ben == 'Terminal Illness') {
                  __self.copyDB = true
                  // __self.selectedBandTI.push(band[k])
                  // __self.selectedRateTI.push(tblref[k])
                } else if (ben == 'Accidental Death Benefit') {
                  __self.selectedBandADB.push(band[k])
                  __self.selectedTableADB.push(tblref[k])
                  __self.adbcheck = true
                } else if (ben == 'Enhanced ADB') {
                  __self.copyADB = true
                  __self.selectedBandEADB.push(band[k])
                  __self.selectedTableEADB.push(tblref[k])
                } else if (ben == 'Accelerated Accidental Total and Permanent Disability') {
                  __self.selectedBandAATPD.push(band[k])
                  __self.selectedTableAATPD.push(tblref[k])
                } else if (ben == 'Cancer Protect') {
                  __self.selectedBandCP.push(band[k])
                  __self.selectedTableCP.push(tblref[k])
                } else if (ben.includes('Accelerated CI')) {
                  __self.selectedBandACI.push(band[k])
                  __self.selectedTableACI.push(tblref[k])
                } else if (ben.includes('Additional CI')) {
                  __self.selectedBandCI.push(band[k])
                  __self.selectedTableCI.push(tblref[k])
                } else if (ben.includes('EMI Waiver(Hosp)')) {
                  __self.selectedBandEMI.push(band[k])
                  __self.selectedTableEMI.push(tblref[k])
                  var indexx = __self.includeIndexValue(termBenefits, "name", "EMI Waiver(Hosp)")
                  if (__self.selectedwaiverHospitalDays == "7Days") {
                    __self.emi7 = termBenefits[indexx].value1
                  } else if (__self.selectedwaiverHospitalDays == "15Days") {
                    __self.emi15 = termBenefits[indexx].value2
                  } else if (__self.selectedwaiverHospitalDays == "7and15Days") {
                    __self.emi7 = termBenefits[indexx].value1
                    __self.emi15 = termBenefits[indexx].value2
                  }
                }
              }
              for (var k = 1; k < band.length; k++) {
                __self.bandTableData[ben].push({ "Band": band[k], "TableRef": tblref[k] })
              }
            } else if (ben == "Accidental Death Benefit" && benefit.innerText.trim() == "Accidental Death Benefit") {
              benefit.classList.add('checked')
              __self.adbcheck = true
              __self.benefitCoverageType[ben] = 'Term'
              __self.addmore[ben] = true;
              var main = benefit.parentElement.parentElement;
              main.querySelector('.product-column.border').classList.remove('disabled');
              if (ben == 'Death Benefit') {
                main.querySelector('.button.isSelected').classList.remove('isSelected');
              }
              main.querySelectorAll('.button')[2].classList.add('isSelected');
              var index = __self.includeIndexValue(termBenefits, "name", ben)
              var band = Object.keys(termBenefits[index].Band)
              var tblref = Object.values(termBenefits[index].Band);
              __self.bandTableData[ben] = [{ "Band": band[0], "TableRef": tblref[0] }]
              for (var k = 0; k < band.length; k++) {
                __self.selectedBandADB.push(band[k])
                __self.selectedTableADB.push(tblref[k])
              }
              for (var k = 1; k < band.length; k++) {
                __self.bandTableData[ben].push({ "Band": band[k], "TableRef": tblref[k] })
              }
            }
          })
          benefitFlat.forEach(function (ben) {
            ben = ben.trim();
            if (ben.includes(benefit.innerText.trim()) && ben != "Accidental Death Benefit") {
              ben = benefit.innerText.trim()
              benefit.classList.add('checked')
              __self.benefitCoverageType[ben] = 'Flat'
              __self.addmore[ben] = true;
              var main = benefit.parentElement.parentElement;
              main.querySelector('.product-column.border').classList.remove('disabled');
              main.querySelectorAll('.button')[0].classList.add('isSelected');
              var index = __self.includeIndexValue(flatBenefits, "name", ben)
              var rate = flatBenefits[index].Band["table_reference"]
              var tblref = flatBenefits[index].Band["table_reference"]
              if (ben == 'Death Benefit') {
                // __self.selectedMortality = rate
                // __self.change.setDbMortality(__self.selectedMortality)
              } else if (ben == 'Terminal Illness') {
                __self.copyDB = true
                // __self.selectedRateTI.push(rate)
              } else if (ben == 'Accidental Death Benefit') {
                __self.selectedTableADB.push(tblref)
                __self.adbcheck = true
                if (__self.copyADB) {
                  __self.copytblADB = tblref
                }
              } else if (ben == 'Enhanced ADB') {
                __self.copyADB = true
                __self.selectedTableEADB = []
                __self.selectedTableEADB.push(tblref)
                __self.copytblADB = tblref
              } else if (ben == 'Accelerated Accidental Total and Permanent Disability') {
                __self.selectedTableAATPD = []
                __self.selectedTableAATPD.push(tblref)
              } else if (ben == 'Cancer Protect') {
                __self.selectedTableCP = []
                __self.selectedTableCP.push(tblref)
              } else if (ben.includes('Accelerated CI')) {
                __self.selectedTableACI = []
                __self.selectedTableACI.push(tblref)
              } else if (ben.includes('Additional CI')) {
                __self.selectedTableCI = []
                __self.selectedTableCI.push(tblref)
              } else if (ben.includes('EMI Waiver(Hosp)')) {
                __self.selectedTableEMI = []
                __self.selectedTableEMI.push(tblref)
                var indexx = __self.includeIndexValue(flatBenefits, "name", "EMI Waiver(Hosp)")
                if (__self.selectedwaiverHospitalDays == "7Days") {
                  __self.emi7 = flatBenefits[indexx].value1
                } else if (__self.selectedwaiverHospitalDays == "15Days") {
                  __self.emi15 = flatBenefits[indexx].value2
                } else if (__self.selectedwaiverHospitalDays == "7and15Days") {
                  __self.emi7 = flatBenefits[indexx].value1
                  __self.emi15 = flatBenefits[indexx].value2
                }
              }
            } else if (ben == "Accidental Death Benefit" && benefit.innerText.trim() == "Accidental Death Benefit") {
              benefit.classList.add('checked')
              __self.benefitCoverageType[ben] = 'Flat'
              __self.addmore[ben] = true;
              __self.adbcheck = true
              var main = benefit.parentElement.parentElement;
              main.querySelector('.product-column.border').classList.remove('disabled');
              main.querySelectorAll('.button')[0].classList.add('isSelected');
              var index = __self.includeIndexValue(flatBenefits, "name", ben)
              var rate = flatBenefits[index].Band["table_reference"]
              var tblref = flatBenefits[index].Band["table_reference"]
              __self.selectedTableADB = []
              __self.selectedTableADB.push(tblref)
              if (__self.copyADB) {
                __self.copytblADB = tblref
              }
            }
          })
        })
        var __self = this
      }
    }

  }

  checknumber(e) {
    var str = e.target.value;
    if (e.keyCode < 48 || e.keyCode > 57) {
      return false;
    }
  }

  checkrange(e) {
    var re = /^[0-9\ _.-]+$/;
    var str = e.target.value;
    if (e.keyCode != 45 && (e.keyCode < 48 || e.keyCode > 57)) {
      return false;
    }
  }

  checkdigits(e) {
    var re = /^[0-9\ _.-]+$/;
    var str = e.target.value;
    if (e.keyCode != 45 && (e.keyCode < 48 || e.keyCode > 57)) {
      return false;
    }
  }

  checkedInput(event) {
    var target;
    var code;
    console.log(event.target.value);
    var allCheckBox = this.el.nativeElement.querySelectorAll("div.checkbox");
    var index;
    target = (event.target.classList.contains('checkbox')) ? event.target : this.app.getAncestorByClassName(event.target, 'checkbox');
    if (event.target.nodeName != 'INPUT' && event.target.nodeName != 'FIELDSET')
      if (target.classList.contains('checked')) {
        target.classList.remove('checked');
        if (target.childNodes[1].innerText == "Accidental Death Benefit") {
          index = this.findIndexValue(this.benefit, "name", target.childNodes[1].innerText);
          this.benefit.splice(index, 1)
          this.adbBenefit = false;
          console.log(this.benefit);
          // allCheckBox.forEach(checkbox => {
          //   if (checkbox.childNodes[1].innerHTML == "Enhanced ADB") {
          //     checkbox.classList.remove("disabled");
          //   }
          // })
        }
        else if (target.childNodes[1].innerText == "Total and Permanent Disability") {
          index = this.findIndexValue(this.benefit, "name", target.childNodes[1].innerText);
          this.benefit.splice(index, 1)
          this.tpdBenefit = false;
          console.log(this.benefit);
        }
        else if (target.childNodes[1].innerText == "Terminal Illness") {
          index = this.findIndexValue(this.benefit, "name", target.childNodes[1].innerText);
          this.benefit.splice(index, 1)
          this.terminalIllnessBenefit = false;
          console.log(this.benefit);
        }
        else if (target.childNodes[1].innerText == "Enhanced ADB") {
          index = this.findIndexValue(this.benefit, "name", target.childNodes[1].innerText);
          this.benefit.splice(index, 1)
          this.enchancedAdbBenefit = false;
          console.log(this.benefit);
        }
        else if (target.childNodes[1].innerText == "Accelerated Accidental Total and Permanent Disability") {
          index = this.findIndexValue(this.benefit, "name", target.childNodes[1].innerText);
          this.benefit.splice(index, 1)
          this.accidentalTpdBenefit = false;
          console.log(this.benefit);
        }
        else if (target.childNodes[1].innerText == "Cancer Protect") {
          index = this.findIndexValue(this.benefit, "name", target.childNodes[1].innerText);
          this.benefit.splice(index, 1)
          this.cancerProtectBenefit = false;
          console.log(this.benefit);
        }
        else if (target.childNodes[1].innerText == "EMI Waiver(Hosp)") {
          index = this.findIndexValue(this.benefit, "name", target.childNodes[1].innerText);
          this.benefit.splice(index, 1)
          this.emiWaiverBenefit = false;
          console.log(this.benefit);
        }
        else if (target.childNodes[1].innerText == "Additional CI") {
          index = this.findIndexValue(this.benefit, "name", target.childNodes[1].innerText);
          this.benefit.splice(index, 1)
          this.additionalCIBenefit = false;
          console.log(this.benefit);
        }
        else if (target.childNodes[1].innerText == "Accelerated CI") {
          index = this.findIndexValue(this.benefit, "name", target.childNodes[1].innerText);
          this.benefit.splice(index, 1)
          this.accidentalCIBenefit = false;
          console.log(this.benefit);
        }
      }
      else {
        if (environment.groupLoanSecure) {
          for (var i = 0; i < this.benefitList.length; i++) {
            if (this.benefitList[i].item_name == target.childNodes[1].innerText) {
              code = this.benefitList[i].item_code;
              break;
            }
          }
        }
        else if (environment.superProductCredit) {
          for (var i = 0; i < this.superProtectBenefitList.length; i++) {
            if (this.superProtectBenefitList[i].item_name == target.childNodes[1].innerText) {
              code = this.superProtectBenefitList[i].item_code;
              break;
            }
          }
        }
        else if (environment.shubhRakshaCredit) {
          for (var i = 0; i < this.benefitList.length; i++) {
            if (this.benefitList[i].item_name == target.childNodes[1].innerText) {
              code = this.benefitList[i].item_code;
              break;
            }
          }
        }

        if (target.childNodes[1].innerText == "Accidental Death Benefit") {
          this.benefit.push({ "name": target.childNodes[1].innerText, "code": code })
          this.adbBenefit = true;
          console.log(this.benefit);
        }
        else if (target.childNodes[1].innerText == "Total and Permanent Disability") {
          this.benefit.push({ "name": target.childNodes[1].innerText, "code": code })
          this.tpdBenefit = true;
          console.log(this.benefit);
        }
        else if (target.childNodes[1].innerText == "Terminal Illness") {
          this.benefit.push({ "name": target.childNodes[1].innerText, "code": code })
          this.terminalIllnessBenefit = true;
          console.log(this.benefit);
        }
        else if (target.childNodes[1].innerText == "Enhanced ADB") {
          this.benefit.push({ "name": target.childNodes[1].innerText, "code": code })
          this.enchancedAdbBenefit = true;
          console.log(this.benefit);
        }
        else if (target.childNodes[1].innerText == "Accelerated Accidental Total and Permanent Disability") {
          this.benefit.push({ "name": target.childNodes[1].innerText, "code": code })
          this.accidentalTpdBenefit = true;
          console.log(this.benefit);
        }
        else if (target.childNodes[1].innerText == "Cancer Protect") {
          this.benefit.push({ "name": target.childNodes[1].innerText, "code": code })
          this.cancerProtectBenefit = true;
          console.log(this.benefit);
        }
        else if (target.childNodes[1].innerText == "Accidental Death Benefit") {
          this.benefit.push({ "name": target.childNodes[1].innerText, "code": code })
          this.adbBenefit = true;
          console.log(this.benefit);
        }
        target.classList.add('checked');
      }
  }



  checkedInputDropDown(event) {
    var target;
    var code;

    var dropDownCheckbox = this.el.nativeElement.querySelectorAll(".checkboxLegend");
    console.log(event.target.value);
    var index;
    target = (event.target.classList.contains('checkboxLabel')) ? event.target : this.app.getAncestorByClassName(event.target, 'checkboxLabel');
    if (event.target.nodeName != 'INPUT' && event.target.nodeName != 'FIELDSET' && event.target.nodeName != 'SPAN' && event.target.nodeName != 'IMG' && event.target.nodeName != 'DIV')
      if (target.parentElement.classList.contains('checked')) {
        target.parentElement.classList.remove('checked');
        target.parentElement.nextElementSibling.classList.add('disabled');
        if (event.target.childNodes[0].data == "Accelerated CI") {
          for (var i = 0; i < this.benefit.length; i++) {
            var name = this.benefit[i].name;
            if (name.includes(event.target.childNodes[0].data)) {
              var temp = name;
            }
          }
          index = this.findIndexValue(this.benefit, "name", temp);
          this.benefit.splice(index, 1);
          this.selectedAcceleratedCI = "";
          this.change.setAsseleratedCI(this.selectedAcceleratedCI);
          dropDownCheckbox.forEach(checkbox => {
            if (checkbox.childNodes[0].innerHTML == "Additional CI") {
              checkbox.classList.remove("disabled");
            }
          });
        }
        else if (event.target.childNodes[0].data == "Additional CI") {
          for (var i = 0; i < this.benefit.length; i++) {
            var name = this.benefit[i].name;
            if (name.includes(event.target.childNodes[0].data)) {
              var temp = name;
            }
          }
          this.selectedAdditionalCI = "";

          this.change.setAdditionalCI(this.selectedAdditionalCI);
          dropDownCheckbox.forEach(checkbox => {
            if (checkbox.childNodes[0].innerHTML == "Accelerated CI") {
              checkbox.classList.remove("disabled");
            }
          });
        }
        else if (event.target.childNodes[0].data == "EMI Waiver(Hosp)") {
          for (var i = 0; i < this.benefit.length; i++) {
            var name = this.benefit[i].name;
            if (name.includes(event.target.childNodes[0].data)) {
              var temp = name;
            }
          }
          index = this.findIndexValue(this.benefit, "name", temp);
          this.benefit.splice(index, 1);
          this.selectedwaiverHospitalDays = "";
          this.change.setWaiverHospital(this.selectedwaiverHospitalDays);
        }
      }
      else {
        if (environment.groupLoanSecure) {
          for (var i = 0; i < this.benefitList.length; i++) {
            if ((this.benefitList[i].item_name).toUpperCase() == (event.target.childNodes[0].data).toUpperCase()) {
              code = this.benefitList[i].item_code;
              break;
            }
          }
        }
        else if (environment.superProductCredit) {
          for (var i = 0; i < this.superProtectBenefitList.length; i++) {
            if ((this.superProtectBenefitList[i].item_name).toUpperCase() == (event.target.childNodes[0].data).toUpperCase()) {
              code = this.superProtectBenefitList[i].item_code;
              break;
            }
          }
          for (var i = 0; i < this.otherBenifitsList.length; i++) {
            if ((this.otherBenifitsList[i].item_name).toUpperCase() == (event.target.childNodes[0].data).toUpperCase()) {
              code = this.otherBenifitsList[i].item_code;
              break;
            }
          }
        }
        else if (environment.shubhRakshaCredit) {
          for (var i = 0; i < this.otherBenifitsList.length; i++) {
            if ((this.otherBenifitsList[i].item_name).toUpperCase() == (event.target.childNodes[0].data).toUpperCase()) {
              code = this.otherBenifitsList[i].item_code;
              break;
            }
          }
        }

        if (event.target.childNodes[0].data == "Accelerated CI") {
          this.benefit.push({ "name": event.target.childNodes[0].data, "code": code });
          if (this.selectedAcceleratedCI != "") {
            this.change.setAsseleratedCI(this.selectedAcceleratedCI);
          }
        }
        else if (event.target.childNodes[0].data == "Additional CI") {
          this.benefit.push({ "name": event.target.childNodes[0].data, "code": code });
          if (this.selectedAdditionalCI != "") {
            this.change.setAdditionalCI(this.selectedAdditionalCI);
          }
        }
        else if (event.target.childNodes[0].data == "EMI Waiver(Hosp)") {
          this.benefit.push({ "name": event.target.childNodes[0].data, "code": code })
          if (this.selectedwaiverHospitalDays != "") {
            this.change.setWaiverHospital(this.selectedwaiverHospitalDays);
          }
        }
        target.parentElement.classList.add('checked');
        target.parentElement.nextElementSibling.classList.remove('disabled');
      }
  }

  saveDataOnNext() {
    //validating input field
    var __self = this
    this.el.nativeElement.querySelectorAll('.isSelected').forEach(function (item) {
      var main = item.parentElement.parentElement.parentElement;
      var dbRow = __self.el.nativeElement.querySelector('.product-row:not(.head)')
      var dbCovg = dbRow.querySelector('.isSelected').innerText
      var dbValues = []
      var dbMinAge = environment.fixedData[0].min_age_at_entry
      var dbMaxAge = environment.fixedData[0].max_age_at_entry
      var dbMinTerm = environment.fixedData[0].min_policy_term
      var dbMaxTerm = environment.fixedData[0].max_policy_term
      dbRow.querySelectorAll('input:not(.hidden)').forEach(function (input) {
        if (input.placeholder == "Band") {
          dbValues.push(input.value)
        }
      })
      for (var j = 0; j < dbValues.length; j++) {
        if (dbValues[j].includes("-")) {
          dbValues.push(dbValues[j].split("-")[0])
          dbValues.push(dbValues[j].split("-")[1])
        }
      }
      for (var j = 0; j < dbValues.length; j++) {
        dbValues[j] = parseInt(dbValues[j])
      }
      if (dbCovg == "Age Band") {
        dbMinAge = Math.min(...dbValues)
        dbMaxAge = Math.max(...dbValues)
      } else if (dbCovg == "Term") {
        dbMinTerm = Math.min(...dbValues)
        dbMaxTerm = Math.max(...dbValues)
      }
      main.querySelectorAll('.product-column:not(.disabled) >.testModal:not(.disabled) span:not([style*="visibility: hidden"]) input:not(.hidden)').forEach(function (input) {
        if (input.placeholder == "Band") {
          var value = input.value;
          // var splitdata = [];
          var ben = input.closest('.product-row').querySelector('label').innerText
          var splitdata = value.split("-");
          for (var k = 0; k < splitdata.length; k++) {
            splitdata[k] = parseInt(splitdata[k])
          }
          if (item.innerText == "Age Band") {
            if (splitdata[0] != " " && ben.startsWith("Death Benefit")) {
              for (var l = 0; l < splitdata.length; l++) {
                splitdata[l] = parseInt(splitdata[l])
              }
              if (splitdata.length == 1) {
                if (splitdata[0] < environment.fixedData[0].min_age_at_entry || splitdata[0] > environment.fixedData[0].max_age_at_entry) {
                  __self.correctVal = false
                  input.value = "";
                  swal({
                    title: 'Please enter value between min age' + " " + environment.fixedData[0].min_age_at_entry + " " + 'and' + " " + 'max age' + " " + environment.fixedData[0].max_age_at_entry + "" + ' of the product.',
                    type: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#eb1826',
                    cancelButtonColor: '#F4F1F1',
                    confirmButtonText: 'OK'
                  }).then((result) => {
                    input.value = "";
                  })
                } else {
                }
              }
              else if (splitdata.length > 1) {
                if (parseInt(splitdata[0]) > parseInt(splitdata[1])) {
                  __self.correctVal = false
                  input.value = "";
                  swal({
                    title: "Min age cannot be greater than max age!",
                    type: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#eb1826',
                    cancelButtonColor: '#F4F1F1',
                    confirmButtonText: 'OK'
                  }).then((result) => {
                    input.value = "";
                  })
                }
                // else 4
                if ((splitdata[0] < environment.fixedData[0].min_age_at_entry || splitdata[0] > environment.fixedData[0].max_age_at_entry ||
                  splitdata[1] < environment.fixedData[0].min_age_at_entry || splitdata[1] > environment.fixedData[0].max_age_at_entry)) {
                  __self.correctVal = false
                  input.value = "";
                  swal({
                    title: 'Please enter value between min age' + " " + environment.fixedData[0].min_age_at_entry + " " + 'and' + " " + 'max age' + " " + environment.fixedData[0].max_age_at_entry + "" + ' of the product.',
                    type: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#eb1826',
                    cancelButtonColor: '#F4F1F1',
                    confirmButtonText: 'OK'
                  }).then((result) => {
                    input.value = "";
                  })
                }
              }
            } else if (splitdata[0] != " ") {
              for (var l = 0; l < splitdata.length; l++) {
                splitdata[l] = parseInt(splitdata[l])
              }
              if (splitdata.length == 1) {
                if (splitdata[0] < dbMinAge || splitdata[0] > dbMaxAge) {
                  __self.correctVal = false
                  input.value = "";
                  swal({
                    title: 'Please enter value between min age' + " " + dbMinAge + " " + 'and' + " " + 'max age' + " " + dbMaxAge + "" + ' of the product.',
                    type: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#eb1826',
                    cancelButtonColor: '#F4F1F1',
                    confirmButtonText: 'OK'
                  }).then((result) => {
                    input.value = "";
                  })
                } else {
                }
              }
              else if (splitdata.length > 1) {
                if (parseInt(splitdata[0]) > parseInt(splitdata[1])) {
                  __self.correctVal = false
                  input.value = "";
                  swal({
                    title: "Min age cannot be greater than max age!",
                    type: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#eb1826',
                    cancelButtonColor: '#F4F1F1',
                    confirmButtonText: 'OK'
                  }).then((result) => {
                    input.value = "";
                  })
                }
                // else 4
                if ((splitdata[0] < dbMinAge || splitdata[0] > dbMaxAge ||
                  splitdata[1] < dbMinAge || splitdata[1] > dbMaxAge)) {
                  __self.correctVal = false
                  input.value = "";
                  swal({
                    title: 'Please enter value between min age' + " " + dbMinAge + " " + 'and' + " " + 'max age' + " " + dbMaxAge + "" + ' of the product.',
                    type: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#eb1826',
                    cancelButtonColor: '#F4F1F1',
                    confirmButtonText: 'OK'
                  }).then((result) => {
                    input.value = "";
                  })
                }
              }
            }
          } else if (item.innerText == "Term") {
            if (splitdata[0] != " " && ben.startsWith("Death Benefit")) {
              if (splitdata.length == 1) {
                if (splitdata[0] < environment.fixedData[0].min_policy_term || splitdata[0] > environment.fixedData[0].max_policy_term) {
                  __self.correctVal = false
                  input.value = "";
                  swal({
                    title: 'Please enter value between min term' + " " + environment.fixedData[0].min_policy_term + " " + 'and' + " " + 'max term' + " " + environment.fixedData[0].max_policy_term + "" + ' of the product.',
                    type: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#eb1826',
                    cancelButtonColor: '#F4F1F1',
                    confirmButtonText: 'OK'
                  }).then((result) => {
                    input.value = "";
                  })
                }
              }
              else if (splitdata.length > 1) {
                if ((splitdata[0] < environment.fixedData[0].min_policy_term || splitdata[0] > environment.fixedData[0].max_policy_term ||
                  splitdata[1] < environment.fixedData[0].min_policy_term || splitdata[1] > environment.fixedData[0].max_policy_term)) {
                  __self.correctVal = false
                  input.value = "";
                  swal({
                    title: 'Please enter value between min term' + " " + environment.fixedData[0].min_policy_term + " " + 'and' + " " + 'max term' + " " + environment.fixedData[0].max_policy_term + "" + ' of the product.',
                    type: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#eb1826',
                    cancelButtonColor: '#F4F1F1',
                    confirmButtonText: 'OK'
                  }).then((result) => {
                    input.value = "";
                  })
                }

                if (parseInt(splitdata[0]) > parseInt(splitdata[1])) {
                  __self.correctVal = false
                  input.value = "";
                  swal({
                    title: "Min term cannot be greater than max term.",
                    type: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#eb1826',
                    cancelButtonColor: '#F4F1F1',
                    confirmButtonText: 'OK'
                  }).then((result) => {
                    input.value = "";
                  })
                }
              }
            } else if (splitdata[0] != " " && (ben.startsWith("EMI Waiver(Hosp)")) || (ben.startsWith("Cancer Protect"))) {
              if (dbMaxTerm > environment.canceremimaxPolicyTerm) {
                dbMaxTerm = environment.canceremimaxPolicyTerm
              }
              if (splitdata.length == 1) {
                if (splitdata[0] < dbMinTerm || splitdata[0] > dbMaxTerm) {
                  __self.correctVal = false
                  input.value = "";
                  swal({
                    title: 'Please enter value between min term' + " " + dbMinTerm + " " + 'and' + " " + 'max term' + " " + dbMaxTerm + "" + ' of the product.',
                    type: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#eb1826',
                    cancelButtonColor: '#F4F1F1',
                    confirmButtonText: 'OK'
                  }).then((result) => {
                    input.value = "";
                  })
                }
              }
              else if (splitdata.length > 1) {
                if ((splitdata[0] < dbMinTerm || splitdata[0] > dbMaxTerm ||
                  splitdata[1] < dbMinTerm || splitdata[1] > dbMaxTerm)) {
                  __self.correctVal = false
                  input.value = "";
                  swal({
                    title: 'Please enter value between min term' + " " + dbMinTerm + " " + 'and' + " " + 'max term' + " " + dbMaxTerm + "" + ' of the product.',
                    type: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#eb1826',
                    cancelButtonColor: '#F4F1F1',
                    confirmButtonText: 'OK'
                  }).then((result) => {
                    input.value = "";
                  })
                }

                if (parseInt(splitdata[0]) > parseInt(splitdata[1])) {
                  __self.correctVal = false
                  input.value = "";
                  swal({
                    title: "Min term cannot be greater than max term.",
                    type: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#eb1826',
                    cancelButtonColor: '#F4F1F1',
                    confirmButtonText: 'OK'
                  }).then((result) => {
                    input.value = "";
                  })
                }
              }
            } else if (splitdata[0] != " " && (ben.startsWith("Accelerated CI")) || (ben.startsWith("Additional CI"))) {
              if (dbMaxTerm > environment.acimaxPolicyTerm) {
                dbMaxTerm = environment.acimaxPolicyTerm
              }
              if (splitdata.length == 1) {
                if (splitdata[0] < dbMinTerm || splitdata[0] > dbMaxTerm) {
                  __self.correctVal = false
                  input.value = "";
                  swal({
                    title: 'Please enter value between min term' + " " + dbMinTerm + " " + 'and' + " " + 'max term' + " " + dbMaxTerm + "" + ' of the product.',
                    type: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#eb1826',
                    cancelButtonColor: '#F4F1F1',
                    confirmButtonText: 'OK'
                  }).then((result) => {
                    input.value = "";
                  })
                }
              }
              else if (splitdata.length > 1) {
                if ((splitdata[0] < dbMinTerm || splitdata[0] > dbMaxTerm ||
                  splitdata[1] < dbMinTerm || splitdata[1] > dbMaxTerm)) {
                  __self.correctVal = false
                  input.value = "";
                  swal({
                    title: 'Please enter value between min term' + " " + dbMinTerm + " " + 'and' + " " + 'max term' + " " + dbMaxTerm + "" + ' of the product.',
                    type: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#eb1826',
                    cancelButtonColor: '#F4F1F1',
                    confirmButtonText: 'OK'
                  }).then((result) => {
                    input.value = "";
                  })
                }

                if (parseInt(splitdata[0]) > parseInt(splitdata[1])) {
                  __self.correctVal = false
                  input.value = "";
                  swal({
                    title: "Min term cannot be greater than max term.",
                    type: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#eb1826',
                    cancelButtonColor: '#F4F1F1',
                    confirmButtonText: 'OK'
                  }).then((result) => {
                    input.value = "";
                  })
                }
              }
            }
          }
        }
      });
    });
    if (environment.groupLoanSecure) {
      var count = 0;
      var inputs = this.el.nativeElement.querySelectorAll('.product-column:not(.disabled):not([style*="visibility: hidden"]) >.testModal:not(.disabled) span:not([style*="visibility: hidden"]) input:not(.hidden)')
      var dropdown1 = this.el.nativeElement.querySelectorAll('.product-column:not(.disabled) >:not(.benefitDropdown) .dropdown');
      var dropdown2 = this.el.nativeElement.querySelectorAll('.benefitDropdown:not(.disabled) .dropdown');
      this.el.nativeElement.querySelectorAll('.product-column:not(.disabled) >:not(.benefitDropdown) .dropdown').forEach(function (item) {
        if (item.querySelector('.text.default-dropdown')) {
          item.parentNode.classList.add("error");
          var ss = item.parentNode;
          // ss.firstElementChild['style'].display = "block";
        }
        else {
          var ss = item.parentNode;
          item.parentNode.classList.remove("error");
          ss.firstElementChild['style'].display = "none";
          count++;
        }
      });
      this.el.nativeElement.querySelectorAll('.product-column:not(.disabled):not([style*="visibility: hidden"]) >.testModal:not(.disabled) span:not([style*="visibility: hidden"]) input:not(.hidden)').forEach(function (item) {
        console.log(item.value);
        if (item.value.trim() == "") {
          var ss = item.closest("fieldset");
          // ss.firstElementChild['style'].display = "block";
          item.closest("fieldset").classList.add("error");
          console.log("Blank");
          swal({
            title: 'Please Fill all the details',
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#eb1826',
            cancelButtonColor: '#F4F1F1',
            confirmButtonText: 'OK'
          }).then((result) => {
            // event.target.value = "";
            // this.selectedFloorRate = "";
          })
        }
        else {
          var ss = item.closest("fieldset");
          ss.firstElementChild['style'].display = "none";
          item.closest("fieldset").classList.remove("error");
          count++;
        }
      });
      this.el.nativeElement.querySelectorAll('.benefitDropdown:not(.disabled) .dropdown').forEach(function (item) {
        if (item.querySelector('.text.default-dropdown')) {
          item.parentNode.classList.add("error");
          var ss = item.parentNode;
          ss.firstElementChild['style'].display = "block";
        }
        else {
          var ss = item.parentNode;
          item.parentNode.classList.remove("error");
          ss.firstElementChild['style'].display = "none";
          count++;
        }
      });
      if (count == inputs["length"] + dropdown1["length"] + dropdown2["length"]) {
        this.saveBenefitData();
        this.routes.navigate(['product-conditions']);
      }
    } else if (environment.superProductCredit) {
      var count = 0;
      var inputs = this.el.nativeElement.querySelectorAll('.product-column:not(.disabled) >.testModal:not(.disabled) span:not([style*="visibility: hidden"]) input:not(.hidden)')
      var dropdown1 = this.el.nativeElement.querySelectorAll('.product-column:not(.disabled) >:not(.benefitDropdown) .dropdown');
      var dropdown2 = this.el.nativeElement.querySelectorAll('.benefitDropdown:not(.disabled) .dropdown');
      var eminput = this.el.nativeElement.querySelectorAll('.inputemi:not(.hidden) :not(.hidden)>input.val')
      this.el.nativeElement.querySelectorAll('.benefitDropdown:not(.disabled) .dropdown').forEach(function (item) {
        if (item.querySelector('.text.default-dropdown')) {
          item.parentNode.classList.add("error");
          var ss = item.parentNode;
          ss.firstElementChild['style'].display = "block";
        }
        else {
          var ss = item.parentNode;
          item.parentNode.classList.remove("error");
          ss.firstElementChild['style'].display = "none";
          count++;
        }
      });
      this.el.nativeElement.querySelectorAll('.product-column:not(.disabled) >:not(.benefitDropdown) .dropdown').forEach(function (item) {
        if (item.querySelector('.text.default-dropdown')) {
          item.parentNode.classList.add("error");
          var ss = item.parentNode;
          // ss.firstElementChild['style'].display = "block";
        }
        else {
          var ss = item.parentNode;
          item.parentNode.classList.remove("error");
          ss.firstElementChild['style'].display = "none";
          count++;
        }
      });
      this.el.nativeElement.querySelectorAll('.product-column:not(.disabled) >.testModal:not(.disabled) span:not([style*="visibility: hidden"]) input:not(.hidden)').forEach(function (item) {
        console.log(item.value);
        if (item.value.trim() == "") {
          var ss = item.closest("fieldset");
          // ss.firstElementChild['style'].display = "block";
          item.closest("fieldset").classList.add("error");
          console.log("Blank");
        }
        else {
          var ss = item.closest("fieldset");
          ss.firstElementChild['style'].display = "none";
          item.closest("fieldset").classList.remove("error");
          count++;
        }
      });
      this.el.nativeElement.querySelectorAll('.inputemi:not(.hidden) :not(.hidden)>input.val').forEach(function (input) {
        if (input.value == "") {
          input.classList.add("error")
        } else {
          input.classList.remove("error")
          count++
        }
      })
      if (count == inputs["length"] + dropdown1["length"] + dropdown2["length"] + eminput["length"]) {
        this.saveBenefitData();
        this.routes.navigate(['product-conditions']);
      }
    }
    else {
      this.saveBenefitData();
      this.routes.navigate(['product-conditions']);
    }

  }

  backbutton() {
    // this.loader.show();
    this.saveBenefitData();
    this.routes.navigate(['product-mapping']);
  }

  saveBenefitData() {
    if (environment.groupLoanSecure) {
      
      if(environment.savedProductMapping[0].loan_type == "Other Loan"){
      this.saveData.push({
        "partner_id": environment.partnerDetails[0].partner_id, "partner_name": environment.partnerDetails[0].partner_name,
        "product_name": environment.savedProductMapping[0].product_name, "product_id": environment.savedProductMapping[0].product_id,
        "loan_type": environment.savedProductMapping[0].loan_type,
        "other_loan_details":environment.savedProductMapping[0].other_loan_details,
        "policy_name": environment.savedProductMapping[0].policy_name,
        "minimum_group": environment.savedProductMapping[0].minimum_group,
        "mortality_rate_type": environment.savedProductMapping[0].mortality_rate_type,
        "mortality_rate": environment.savedProductMapping[0].mortality_rate, "benefits": this.benefit,
        "floor-rate": this.selectedFloorRate
      });
    }
      else{
        this.saveData.push({
          "partner_id": environment.partnerDetails[0].partner_id, "partner_name": environment.partnerDetails[0].partner_name,
          "product_name": environment.savedProductMapping[0].product_name, "product_id": environment.savedProductMapping[0].product_id,
          "loan_type": environment.savedProductMapping[0].loan_type, "policy_name": environment.savedProductMapping[0].policy_name,
          "minimum_group": environment.savedProductMapping[0].minimum_group,
          "mortality_rate_type": environment.savedProductMapping[0].mortality_rate_type,
          "mortality_rate": environment.savedProductMapping[0].mortality_rate, "benefits": this.benefit,
          "floor-rate": this.selectedFloorRate
        });
      }

    } else if (environment.superProductCredit) {
      this.saveTerminalIllness()
      //this.saveEadb()
      if(environment.savedProductMapping[0].loan_type == "Other Loan"){
        this.saveData.push({
          "partner_id": environment.partnerDetails[0].partner_id, "partner_name": environment.partnerDetails[0].partner_name,
          "product_name": environment.savedProductMapping[0].product_name, "product_id": environment.savedProductMapping[0].product_id,
          "loan_type": environment.savedProductMapping[0].loan_type, "other_loan_details":environment.savedProductMapping[0].other_loan_details,
          "policy_name": environment.savedProductMapping[0].policy_name,
          "minimum_group": environment.savedProductMapping[0].minimum_group,
          "mortality_rate_type": environment.savedProductMapping[0].mortality_rate_type,
          "mortality_rate": environment.savedProductMapping[0].mortality_rate, "benefits": this.benefit
        });
      }
      this.saveData.push({
        "partner_id": environment.partnerDetails[0].partner_id, "partner_name": environment.partnerDetails[0].partner_name,
        "product_name": environment.savedProductMapping[0].product_name, "product_id": environment.savedProductMapping[0].product_id,
        "loan_type": environment.savedProductMapping[0].loan_type, "policy_name": environment.savedProductMapping[0].policy_name,
        "minimum_group": environment.savedProductMapping[0].minimum_group,
        "mortality_rate_type": environment.savedProductMapping[0].mortality_rate_type,
        "mortality_rate": environment.savedProductMapping[0].mortality_rate, "benefits": this.benefit
      });
    } else if (environment.shubhRakshaCredit) {
      this.saveData.push({
        "partner_id": environment.partnerDetails[0].partner_id, "partner_name": environment.partnerDetails[0].partner_name,
        "product_name": environment.savedProductMapping[0].product_name, "product_id": environment.savedProductMapping[0].product_id,
        "loan_type": environment.savedProductMapping[0].loan_type, "policy_name": environment.savedProductMapping[0].policy_name,
        "minimum_group": environment.savedProductMapping[0].minimum_group,
        "mortality_rate_type": environment.savedProductMapping[0].mortality_rate_type,
        "mortality_rate": environment.savedProductMapping[0].mortality_rate, "benefits": this.benefit,
      });
    }


    console.log(this.saveData);
    environment.savedProductBenefit = this.saveData;
    console.log(this.saveData);
  }

  saveTerminalIllness() {
    var dbcovgtype
    var index
    var indexF = this.findIndexValue(this.benefit["Flat"], "name", "Terminal Illness")
    var indexA = this.findIndexValue(this.benefit["AgeBandWise"], "name", "Terminal Illness")
    var indexT = this.findIndexValue(this.benefit["TermWise"], "name", "Terminal Illness")
    if (indexF != null) {
      index = indexF
      dbcovgtype = "Flat"
    } else if (indexA != null) {
      index = indexA
      dbcovgtype = "AgeBandWise"
    } else if (indexT != null) {
      index = indexT
      dbcovgtype = "TermWise"
    }
    if (index != null) {
      this.benefit[dbcovgtype].splice(index, 1)
    }
    if (this.copyDB == true) {
      var dbcovgtype
      var index
      var indexF = this.findIndexValue(this.benefit["Flat"], "name", "Death Benefit")
      var indexA = this.findIndexValue(this.benefit["AgeBandWise"], "name", "Death Benefit")
      var indexT = this.findIndexValue(this.benefit["TermWise"], "name", "Death Benefit")
      if (indexF != null) {
        index = indexF
        dbcovgtype = "Flat"
      } else if (indexA != null) {
        index = indexA
        dbcovgtype = "AgeBandWise"
      } else if (indexT != null) {
        index = indexT
        dbcovgtype = "TermWise"
      }
      var code
      for (var c = 0; c < this.superProtectBenefitList.length; c++) {
        if (this.superProtectBenefitList[c].item_name.includes("Terminal Illness")) {
          code = this.superProtectBenefitList[c].item_code;
          break;
        }
      }
      if (dbcovgtype != undefined && index != undefined) {
        var term = this.benefit[dbcovgtype][index]
        this.benefit[dbcovgtype].push({ "name": "Terminal Illness", "code": code, "Band": term.Band })
      }
    }
  }

  saveEadb() {
    var dbcovgtype
    var index
    var indexF = this.findIndexValue(this.benefit["Flat"], "name", "Enhanced ADB")
    var indexA = this.findIndexValue(this.benefit["AgeBandWise"], "name", "Enhanced ADB")
    var indexT = this.findIndexValue(this.benefit["TermWise"], "name", "Enhanced ADB")
    if (indexF != null) {
      index = indexF
      dbcovgtype = "Flat"
    } else if (indexA != null) {
      index = indexA
      dbcovgtype = "AgeBandWise"
    } else if (indexT != null) {
      index = indexT
      dbcovgtype = "TermWise"
    }
    if (index != null) {
      this.benefit[dbcovgtype].splice(index, 1)
    }
    //if (this.copyADB == true) {
    var dbcovgtype
    var index
    var indexF = this.findIndexValue(this.benefit["Flat"], "name", "Accidental Death Benefit")
    var indexA = this.findIndexValue(this.benefit["AgeBandWise"], "name", "Accidental Death Benefit")
    var indexT = this.findIndexValue(this.benefit["TermWise"], "name", "Accidental Death Benefit")
    if (indexF != null) {
      index = indexF
      dbcovgtype = "Flat"
    } else if (indexA != null) {
      index = indexA
      dbcovgtype = "AgeBandWise"
    } else if (indexT != null) {
      index = indexT
      dbcovgtype = "TermWise"
    }
    var code
    for (var c = 0; c < this.superProtectBenefitList.length; c++) {
      if (this.superProtectBenefitList[c].item_name.includes("Enhanced ADB")) {
        code = this.superProtectBenefitList[c].item_code;
        break;
      }
    }
    if (dbcovgtype != undefined && index != undefined)
      var term = this.benefit[dbcovgtype][index]
    if (this.benefit[dbcovgtype])
      this.benefit[dbcovgtype].push({ "name": "Enhanced ADB", "code": code, "Band": term.Band })
  }
  // }

  findIndexValue(arraytosearch, key, valuetosearch) {

    for (var i = 0; i < arraytosearch.length; i++) {

      if (arraytosearch[i][key] == valuetosearch) {
        return i;
      }
    }
    return null;
  }

  includeIndexValue(arraytosearch, key, valuetosearch) {

    for (var i = 0; i < arraytosearch.length; i++) {

      if (arraytosearch[i][key].startsWith(valuetosearch)) {
        return i;
      }
    }
    return null;
  }

  checkbox(event) {
    var main = event.target.parentElement.parentElement.parentElement
    var benefitName = main.querySelector('.table-column.checkbox').innerText
    var covgType
    if (event.target.parentElement.classList.contains('checked')) {
      if (main.querySelectorAll('.isSelected').length > 0) {
        var covgType = main.querySelector('.isSelected').innerText
      }
      if (environment.groupLoanSecure) {
        covgType = "Age Band"
      }
      event.target.parentElement.classList.remove('checked')
      if (covgType == "Flat") {
        covgType = 'Flat'
      } else if (covgType == "Age Band") {
        covgType = 'AgeBandWise'
      } else if (covgType == "Term") {
        covgType = 'TermWise'
      }
      var indexn = this.includeIndexValue(this.benefit[covgType], "name", benefitName)
      if (indexn != null) {
        this.benefit[covgType].splice(indexn, 1)
      }
      if (this.superProductCredit) {
        if (benefitName == "Death Benefit") {
          this.selectedBandDB = []
          this.selectedRateDB = []
        } else if (benefitName == "Accidental Death Benefit") {
          this.selectedBandADB = []
          this.selectedTableADB = []
          this.copytblADB = ""
          if (this.copyADB) {
            // var eadbmain = main.nextElementSibling
            // if (eadbmain.querySelector('.checkbox').classList.contains('checked')) {
            //   eadbmain.querySelector('.checkbox').classList.remove('checked')
            // }
            // eadbmain.querySelector('.benefitValue').classList.add('disabled')
            // eadbmain.querySelector('.isSelected').classList.remove('isSelected')
          }
          this.adbcheck = false
          this.copyADB = false
        } else if (benefitName == "Enhanced ADB") {
          this.copyADB = false
          this.selectedTableEADB = [];
          this.selectedBandEADB = [];
          this.copytblADB = ''
          this.empty = ''
          this.value = '';
          this.selectedTableADB = [];
          main.querySelector('.product-column.benefitValue').classList.add("disabled")
        } else if (benefitName == "Accelerated Accidental Total and Permanent Disability") {
          this.selectedBandAATPD = []
          this.selectedTableAATPD = []
        } else if (benefitName == "Cancer Protect") {
          this.selectedBandCP = []
          this.selectedTableCP = []
        } else if (benefitName.includes("Additional CI")) {
          this.selectedAdditionalCI = ""
          this.change.setAdditionalCI(this.selectedAdditionalCI)
          this.selectedTableCI = [];
          this.selectedBandCI = [];
        } else if (benefitName.includes("Accelerated CI")) {
          this.selectedAcceleratedCI = ""
          this.change.setAsseleratedCI(this.selectedAcceleratedCI)
          this.selectedBandACI = [];
          this.selectedTableACI = [];
        } else if (benefitName.includes("EMI Waiver(Hosp)")) {
          this.selectedwaiverHospitalDays = ""
          this.change.setWaiverHospital(this.selectedwaiverHospitalDays)
          this.selectedBandEMI = [];
          this.selectedTableEMI = [];
        }

        if (benefitName == 'Terminal Illness') {
          this.copyDB = false
          main.querySelector('.isSelected').classList.remove('isSelected')
        } else {
          event.target.parentElement.classList.remove('checked')
          main.querySelector('.isSelected').classList.remove('isSelected')
          delete this.benefitCoverageType[event.target.innerText];
          main.querySelector('.product-column.border').classList.add('disabled');

          if (benefitName == 'Accelerated CI') {
            main.firstElementChild.querySelector('.dropdown').classList.add('disabled')
          }
        }
        if (benefitName == "Accelerated CI") {
          this.selectedAcceleratedCI = ""
          this.change.setAsseleratedCI(this.selectedAcceleratedCI)
        }
        else if (benefitName == "Additional CI") {
          this.selectedAdditionalCI = ""
          this.change.setAdditionalCI(this.selectedAdditionalCI)
        }
        else if (benefitName == "EMI Waiver(Hosp)") {
          this.selectedwaiverHospitalDays = ""
          this.change.setWaiverHospital(this.selectedwaiverHospitalDays)
        }
      } else if (this.groupLoanSecure) {
        main.querySelector('.benefitValue').classList.add('disabled')
        main.querySelector('.isSelected').classList.remove('isSelected')
        if (benefitName.includes("Accidental Death Benefit")) {
          this.selectedRateADB = [];
          this.selectedBandADB = [];
          this.selectedRateADB.push("")
          this.selectedBandADB.push("")
        } else if (benefitName.includes("Total and Permanent Disability")) {
          this.selectedRateTPD = [];
          this.selectedBandTPD = [];
          this.selectedRateTPD.push("")
          this.selectedBandTPD.push("")
        } else if (benefitName.includes("Critical Illness")) {
          this.selectedCriticalIllness = ""
          this.change.setSelectedCriticalIllness(this.selectedCriticalIllness)
          this.selectedRateCRI = [];
          this.selectedBandCRI = [];
          this.selectedRateCRI.push("")
          this.selectedBandCRI.push("")
        }
      }
      this.checked[benefitName] = false
      this.bandTableData[benefitName] = [{ "Band": "", "TableRef": "" }]

    } else {
      event.target.parentElement.classList.add('checked')
      if (this.superProductCredit) {
        if (benefitName == 'Terminal Illness') {
          var main = event.target.parentElement.parentElement.parentElement.querySelector('.border')
          this.copyDB = true
          if (!this.benefitCoverageType.hasOwnProperty("Death Benefit")) {
            main.querySelectorAll('.button')[0].classList.add('isSelected')
          }
          else if (this.benefitCoverageType["Death Benefit"] == "" || this.benefitCoverageType["Death Benefit"] == "Flat") {
            main.querySelectorAll('.button')[0].classList.add('isSelected')
          } else if (this.benefitCoverageType["Death Benefit"] == "Age Band") {
            main.querySelectorAll('.button')[1].classList.add('isSelected')
          } else if (this.benefitCoverageType["Death Benefit"] == "Term") {
            main.querySelectorAll('.button')[2].classList.add('isSelected')
          }
        }
        else if (benefitName == "Enhanced ADB") {
          main.querySelectorAll('.product-column')[1].classList.remove("disabled")
          main.querySelectorAll('.product-column')[2].classList.remove("disabled")
          main.querySelectorAll('.product-column .mortality-rate')[0].classList.remove("disabled")
          this.copytblADB = ""
          this.selectedBandADB = []
          this.selectedTableADB = []
          this.adbcheck = false
          this.copyADB = false
          delete this.benefitCoverageType["Accidental Death Benefit"];
          this.checked["Accidental Death Benefit"] = false
          this.bandTableData["Accidental Death Benefit"] = [{ "Band": "", "TableRef": "" }]
          //this.copyADB = true;
          // if (this.selectedTableEADB[0] != undefined) {
          //   this.copytblADB = this.selectedTableEADB[0]
          // }
          //if (!this.benefitCoverageType.hasOwnProperty("Accidental Death Benefit")) {
          main.querySelectorAll('.button')[0].classList.add('isSelected')
          //}
          // else if (this.benefitCoverageType["Accidental Death Benefit"] == "" || this.benefitCoverageType["Accidental Death Benefit"] == "Flat") {
          //   main.querySelectorAll('.button')[0].classList.add('isSelected')
          // } else if (this.benefitCoverageType["Accidental Death Benefit"] == "Age Band") {
          //   main.querySelectorAll('.button')[1].classList.add('isSelected')
          // } else if (this.benefitCoverageType["Accidental Death Benefit"] == "Term") {
          //   main.querySelectorAll('.button')[2].classList.add('isSelected')
          //   if (main.querySelector('.button.isSelected').innerText == "Flat") {
          //     this.copytblADB = this.selectedTableADB[0]
          //   }
          // }
          var adbmain = main.previousElementSibling;
          try {
            var covgType = adbmain.querySelector('.isSelected').innerText
            var indexn = this.includeIndexValue(this.benefit[covgType], "name", "Accidental Death Benefit")
            if (indexn != null) {
              this.benefit[covgType].splice(indexn, 1)
            }
          }
          catch (e) { }
          if (adbmain.querySelector('.checkbox').classList.contains('checked')) {
            adbmain.querySelector('.checkbox').classList.remove('checked')
          }
          adbmain.querySelector('.benefitValue').classList.add('disabled')
          if (adbmain.querySelector('.isSelected'))
            adbmain.querySelector('.isSelected').classList.remove('isSelected')
          main.querySelector('.benefitValue').classList.remove('disabled')

        }
        else {
          if (benefitName == "Accidental Death Benefit") {
            this.adbcheck = true
            var eadbmain = main.nextElementSibling
            try {
              var covgType = eadbmain.querySelector('.isSelected').innerText
              var indexn = this.includeIndexValue(this.benefit[covgType], "name", "Enhanced ADB")
              if (indexn != null) {
                this.benefit[covgType].splice(indexn, 1)
              }
              this.copyADB = false
              this.selectedTableEADB = [];
              this.selectedBandEADB = [];
              this.copytblADB = ''
              this.empty = ''
              this.value = '';
              this.selectedTableADB = [];
            }
            catch (e) { }
            // Ali & Himanshu Commented
            // if (eadbmain.querySelector('.checkbox').classList.contains('checked')) {
            //   eadbmain.querySelector('.checkbox').classList.remove('checked')
            // }
            delete this.benefitCoverageType["Enhanced ADB"];
            this.benefitCoverageType["Enhanced ADB"] = undefined;
            main.querySelector('.benefitValue').classList.remove('disabled')
            // eadbmain.querySelector('.benefitValue').classList.add('disabled')
            // if (eadbmain.querySelector('.isSelected'))
            //   eadbmain.querySelector('.isSelected').classList.remove('isSelected')
            // if (eadbmain.querySelector('.benefitValue  .benefitValue'))
            //   eadbmain.querySelector('.benefitValue .benefitValue').classList.add('disabled')

          }
          if (benefitName == 'Additional CI') {
            // un-selecting accelrating ci
            try {
              let covg_type = '';
              let aci_target = event.target.parentElement.parentElement.parentElement.previousElementSibling.firstElementChild.firstElementChild.firstElementChild;
              let aci_main = event.target.parentElement.parentElement.parentElement.previousElementSibling;
              aci_target.parentElement.classList.remove('checked')
              let ben_name = aci_main.firstElementChild.firstElementChild.innerText;
              if (aci_main.querySelectorAll('.isSelected').length > 0) {
                covg_type = aci_main.querySelector('.isSelected').innerText
              }
              try {
                var indexn = this.includeIndexValue(this.benefit[covg_type], "name", ben_name)
                if (indexn != null) {
                  this.benefit[covg_type].splice(indexn, 1)
                }
              }
              catch (e) { }
              this.selectedAcceleratedCI = ""
              this.change.setAsseleratedCI(this.selectedAcceleratedCI)
              this.selectedBandACI = [];
              this.selectedTableACI = [];
              aci_target.parentElement.classList.remove('checked')
              aci_main.querySelector('.isSelected').classList.remove('isSelected')
              delete this.benefitCoverageType[aci_target.innerText];
              aci_main.querySelector('.product-column.border').classList.add('disabled');
              //aci_main.firstElementChild.querySelector('.dropdown').classList.add('disabled')
              this.selectedAcceleratedCI = ""
              this.change.setAsseleratedCI(this.selectedAcceleratedCI)
              this.checked[ben_name] = false
              this.bandTableData[ben_name] = [{ "Band": "", "TableRef": "" }]
            }
            catch (e) { console.log(e) }
          }
          if (benefitName == 'Accelerated CI') {
            // un-selecting Additional CI
            try {
              let covg_type = '';
              let aaci_target = event.target.parentElement.parentElement.parentElement.nextElementSibling.firstElementChild.firstElementChild.firstElementChild;
              let aaci_main = event.target.parentElement.parentElement.parentElement.nextElementSibling;
              let ben_name = aaci_main.firstElementChild.firstElementChild.innerText;
              if (aaci_main.querySelectorAll('.isSelected').length > 0) {
                covg_type = aaci_main.querySelector('.isSelected').innerText
              }
              aaci_target.parentElement.classList.remove('checked')
              try {
                var indexn = this.includeIndexValue(this.benefit[covg_type], "name", ben_name)
                if (indexn != null) {
                  this.benefit[covg_type].splice(indexn, 1)
                }
              }
              catch (e) { }
              this.checked[ben_name] = false
              this.bandTableData[ben_name] = [{ "Band": "", "TableRef": "" }]
              this.change.setAdditionalCI(this.selectedAdditionalCI)
              this.selectedTableCI = [];
              this.selectedBandCI = [];
              aaci_target.parentElement.classList.remove('checked')
              aaci_main.querySelector('.isSelected').classList.remove('isSelected')
              delete this.benefitCoverageType[aaci_target.innerText];
              aaci_main.querySelector('.product-column.border').classList.add('disabled');
              this.selectedAdditionalCI = ""
              this.change.setAdditionalCI(this.selectedAdditionalCI)
            }
            catch (e) { console.log(e) }
          }
          main.querySelector('.mortality-rate').firstElementChild.classList.add('isSelected')
          this.benefitCoverageType[event.target.innerText] = 'Flat';
          main.querySelector('.product-column.border').classList.remove('disabled');
        }
      } else if (this.groupLoanSecure) {
        var code
        for (var c = 0; c < this.benefitList.length; c++) {
          if (this.benefitList[c].item_name.includes(benefitName)) {
            code = this.benefitList[c].item_code;
            break;
          }
        }
        main.querySelector('.mortality-rate').firstElementChild.classList.add('isSelected')
        this.benefitCoverageType[event.target.innerText] = 'Age Band Wise';
        main.querySelector('.product-column.border').classList.remove('disabled');
        main.querySelector('.benefitValue').classList.remove('disabled')
        this.checked[benefitName] = true
        var bandData
        bandData = ({ "999": "NA" })
        if (benefitName == "Accidental Death Benefit") {
          this.benefit["AgeBandWise"].push({ "name": benefitName, "code": code, "Band": bandData })
        } else if (benefitName == "Total and Permanent Disability") {
          this.benefit["AgeBandWise"].push({ "name": benefitName, "code": code, "Band": bandData })
        }
      }
    }
  }

  selectType(event) {
    var benefitName = event.target.parentElement.parentElement.parentElement.firstElementChild.firstElementChild.innerText;
    var main = event.target.parentElement
    if (event.target.parentElement.querySelector('.isSelected') != null) {
      var prevType = main.querySelector('.isSelected').innerText;
      main.querySelector('.isSelected').classList.remove('isSelected');
      if (prevType == "Flat") {
        prevType = 'Flat'
      } else if (prevType == "Age Band") {
        prevType = 'AgeBandWise'
      } else if (prevType == "Term") {
        prevType = 'TermWise'
      }
      if (environment.savedProductBoundryCondition.hasOwnProperty(prevType)) {
        if (environment.savedProductBoundryCondition[prevType].hasOwnProperty(benefitName)) {
          delete environment.savedProductBoundryCondition[prevType][benefitName]
        }
      }
      var idx = this.findIndexValue(this.benefit[prevType], "name", benefitName)
      if (idx != null) {
        this.benefit[prevType].splice(idx, 1)
      }
      event.target.classList.add('isSelected')
      if (this.benefitCoverageType[benefitName] != event.target.innerText) {
        this.benefitCoverageType[benefitName] = event.target.innerText;
        if (benefitName == 'Death Benefit') {
          this.selectedBandDB = []
          this.selectedRateDB = []
          this.selectedMortality = ""
          //reset min and max values

        } else if (benefitName == 'Terminal Illness') {
          this.selectedBandTI = []
          this.selectedRateTI = []
          environment.dbMaxAge = environment.fixedData["min_age_at_entry"]
          environment.dbMinAge = environment.fixedData["max_age_at_entry"]
          environment.dbMinTerm = environment.fixedData["min_policy_term"]
          environment.dbMaxTerm = environment.fixedData["max_policy_term"]
        } else if (benefitName == 'Accidental Death Benefit') {
          this.selectedBandADB = []
          this.selectedTableADB = []
          this.copytblADB = ""
        } else if (benefitName == 'Enhanced ADB') {
          this.selectedBandEADB = []
          this.selectedTableEADB = []
        } else if (benefitName == 'Accelerated Accidental Total and Permanent Disability') {
          this.selectedBandAATPD = []
          this.selectedTableAATPD = []
        } else if (benefitName == 'Cancer Protect') {
          this.selectedBandCP = []
          this.selectedTableCP = []
        } else if (benefitName == 'Accelerated CI') {
          this.selectedBandACI = []
          this.selectedTableACI = []
        } else if (benefitName == 'Additional CI') {
          this.selectedBandCI = []
          this.selectedTableCI = []
        } else if (benefitName == 'EMI Waiver(Hosp)') {
          this.selectedBandEMI = []
          this.selectedTableEMI = []
        }
        if (this.benefitCoverageType[benefitName] == 'Flat') {
          delete this.bandTableData[benefitName];
          this.addmore[benefitName] = true;
        } else if (this.benefitCoverageType[benefitName] == 'Age Band') {
          this.bandTableData[benefitName] = [{ "Band": "", "TableRef": "" }]
          this.addmore[benefitName] = true;
          if (benefitName == 'Death Benefit') {
            this.selectedBandDB.push("")
            this.selectedRateDB.push("")
          } else if (benefitName == 'Terminal Illness') {
            this.selectedBandTI.push("")
            this.selectedRateTI.push("")
          } else if (benefitName == 'Accidental Death Benefit') {
            this.selectedBandADB.push("")
            this.selectedTableADB.push("")
          } else if (benefitName == 'Enhanced ADB') {
            this.selectedBandEADB.push("")
            this.selectedTableEADB.push("")
          } else if (benefitName == 'Accelerated Accidental Total and Permanent Disability') {
            this.selectedBandAATPD.push("")
            this.selectedTableAATPD.push("")
          } else if (benefitName == 'Cancer Protect') {
            this.selectedBandCP.push("")
            this.selectedTableCP.push("")
          } else if (benefitName == 'Accelerated CI') {
            this.selectedBandACI.push("")
            this.selectedTableACI.push("")
          } else if (benefitName == 'Additional CI') {
            this.selectedBandCI.push("")
            this.selectedTableCI.push("")
          } else if (benefitName == 'EMI Waiver(Hosp)') {
            this.selectedBandEMI.push("")
            this.selectedTableEMI.push("")
          } else if (benefitName == 'Critical Illness') {
            this.selectedBandCRI.push("")
            this.selectedRateCRI.push("")
          } else if (benefitName == 'Total and Permanant Disability') {
            this.selectedBandTPD.push("")
            this.selectedRateTPD.push("")
          }
        } else if (this.benefitCoverageType[benefitName] == 'Term') {
          this.bandTableData[benefitName] = [{ "Band": "", "TableRef": "" }]
          this.addmore[benefitName] = true;
          if (benefitName == 'Death Benefit') {
            this.selectedBandDB.push("")
            this.selectedRateDB.push("")
          } else if (benefitName == 'Terminal Illness') {
            this.selectedBandTI.push("")
            this.selectedRateTI.push("")
          } else if (benefitName == 'Accidental Death Benefit') {
            this.selectedBandADB.push("")
            this.selectedTableADB.push("")
          } else if (benefitName == 'Enhanced ADB') {
            this.selectedBandEADB.push("")
            this.selectedTableEADB.push("")
          } else if (benefitName == 'Accelerated Accidental Total and Permanent Disability') {
            this.selectedBandAATPD.push("")
            this.selectedTableAATPD.push("")
          } else if (benefitName == 'Cancer Protect') {
            this.selectedBandCP.push("")
            this.selectedTableCP.push("")
          } else if (benefitName == 'Accelerated CI') {
            this.selectedBandACI.push("")
            this.selectedTableACI.push("")
          } else if (benefitName == 'Additional CI') {
            this.selectedBandCI.push("")
            this.selectedTableCI.push("")
          } else if (benefitName == 'EMI Waiver(Hosp)') {
            this.selectedBandEMI.push("")
            this.selectedTableEMI.push("")
          }
        }
      }
    }
    if (benefitName == "Death Benefit" && this.copyDB == true) {
      // var covType = main.querySelector('.isSelected').innerText;
      main = main.parentElement.parentElement.parentElement.querySelector('.product-row.Terminal')
      if (main.querySelector('.isSelected') != null) {
        main.querySelector('.isSelected').classList.remove('isSelected');
      }
      if (event.target.innerText == "Flat") {
        main.querySelectorAll('.button')[0].classList.add('isSelected');
      } else if (event.target.innerText == "Age Band") {
        main.querySelectorAll('.button')[1].classList.add('isSelected');
      } else if (event.target.innerText == "Term") {
        main.querySelectorAll('.button')[2].classList.add('isSelected');
      }
    }
    if (benefitName == "Accidental Death Benefit" && this.copyADB == true) {
      // var covType = main.querySelector('.isSelected').innerText;
      main = main.parentElement.parentElement.parentElement.querySelector('.product-row.EADB')
      if (main.querySelector('.isSelected') != null) {
        main.querySelector('.isSelected').classList.remove('isSelected');
      }
      if (event.target.innerText == "Flat") {
        main.querySelectorAll('.button')[0].classList.add('isSelected');
      } else if (event.target.innerText == "Age Band") {
        main.querySelectorAll('.button')[1].classList.add('isSelected');
      } else if (event.target.innerText == "Term") {
        main.querySelectorAll('.button')[2].classList.add('isSelected');
      }
    }
    if (environment.savedProductBenefit.length > 0) {
      var indexfind = this.includeIndexValue(environment.savedProductBenefit[0].benefits[prevType], "name", benefitName)
      if (indexfind != null) {
        environment.savedProductBenefit[0].benefits[prevType].splice(indexfind, 1)
      }
    }
  }

  addMore(event) {
    var valuesFilled = true
    var checkfields = event.target.parentElement.parentElement;
    checkfields.querySelectorAll('input').forEach(function (input) {
      if (input.value == "") {
        valuesFilled = false
        input.closest("fieldset").classList.add("error");
      }
    })
    if (valuesFilled) {
      var benefitName
      if (event.target.parentElement.parentElement.parentElement.parentElement.classList.contains("product-row"))
        benefitName = event.target.parentElement.parentElement.parentElement.parentElement.firstElementChild.firstElementChild.innerText
      else
        benefitName = event.target.parentElement.parentElement.parentElement.firstElementChild.firstElementChild.innerText;
      this.bandTableData[benefitName].push({ "Band": "", "TableRef": "" })
      if (this.bandTableData[benefitName].length == 5) {
        this.addmore[benefitName] = false;
      }
      if (this.superProductCredit) {
        if (benefitName == 'Death Benefit') {
          console.log(this.selectedBandDB);
          this.selectedBandDB.push("")
          this.selectedRateDB.push("")
          // }
        } else if (benefitName == 'Terminal Illness') {
          this.selectedBandTI.push("")
          this.selectedRateTI.push("")
        } else if (benefitName == 'Accidental Death Benefit') {
          this.selectedBandADB.push("")
          this.selectedTableADB.push("")
        } else if (benefitName == 'Enhanced ADB') {
          this.selectedBandEADB.push("")
          this.selectedTableEADB.push("")
        } else if (benefitName == 'Accelerated Accidental Total and Permanent Disability') {
          this.selectedBandAATPD.push("")
          this.selectedTableAATPD.push("")
        } else if (benefitName == 'Cancer Protect') {
          this.selectedBandCP.push("")
          this.selectedTableCP.push("")
        } else if (benefitName == 'Accelerated CI') {
          this.selectedBandACI.push("")
          this.selectedTableACI.push("")
        } else if (benefitName == 'Additional CI') {
          this.selectedBandCI.push("")
          this.selectedTableCI.push("")
        } else if (benefitName == 'EMI Waiver(Hosp)') {
          this.selectedBandEMI.push("")
          this.selectedTableEMI.push("")
        }
      } else if (this.groupLoanSecure) {
        if (benefitName == 'Death Benefit') {
          this.selectedBandDB.push("")
          this.selectedRateDB.push("")
        } else if (benefitName == 'Accidental Death Benefit') {
          this.selectedBandADB.push("")
          this.selectedRateADB.push("")
        } else if (benefitName == 'Total and Permanent Disability') {
          this.selectedBandTPD.push("")
          this.selectedRateTPD.push("")
        } else if (benefitName == 'Critical Illness') {
          this.selectedBandCRI.push("")
          this.selectedRateCRI.push("")
        }

      }
      if (benefitName == 'Death Benefit') {
        var selectedCoverageType
        selectedCoverageType = this.el.nativeElement.querySelector('.isSelected').innerText
        if (selectedCoverageType == "Flat") {
          selectedCoverageType = 'Flat'
        } else if (selectedCoverageType == "Age Band") {
          selectedCoverageType = 'AgeBandWise'
        } else if (selectedCoverageType == "Term") {
          selectedCoverageType = 'TermWise'
        }
      }
    } else {
      swal({
        title: 'Please fill existing fields before adding more',
        type: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#eb1826',
        cancelButtonColor: '#F4F1F1',
        confirmButtonText: 'OK'
      }).then((result) => {
        // event.target.value = "";
      })
    }
  }

  deleteOne(event) {
    var benefitName;
    var main;
    if (event.target.parentElement.parentElement.parentElement.classList.contains("product-row")) {
      benefitName = event.target.parentElement.parentElement.parentElement.firstElementChild.firstElementChild.innerText
      main = event.target.parentElement.parentElement.parentElement
    }
    else {
      benefitName = event.target.parentElement.parentElement.firstElementChild.firstElementChild.innerText
      main = event.target.parentElement.parentElement
    }

    var covType = main.querySelector('.isSelected').innerText;
    var code;
    var benName = benefitName;
    if (covType == "Age Band") {
      covType = 'AgeBandWise'
    } else if (covType == "Term") {
      covType = 'TermWise'
    }
    if (this.bandTableData[benefitName].length == 1) {
      //hide button

    } else if (this.bandTableData[benefitName].length > 1) {
      this.addmore[benefitName] = true;
      this.bandTableData[benefitName].splice(-1);
      this.bandTableObject = {}
      for (var k = 0; k < this.bandTableData[benefitName].length; k++) {
        var key = this.bandTableData[benefitName][k].Band
        var value = this.bandTableData[benefitName][k].TableRef
        this.bandTableObject[key] = value
      }
      if (benefitName == "Critical Illness") {
        if (this.selectedCriticalIllness != "") {
          benName = benefitName + ' ' + this.selectedCriticalIllness
        }
      } else if (benefitName == "Accelerated CI") {
        if (this.selectedAcceleratedCI != "") {
          benName = benefitName + ' ' + this.selectedAcceleratedCI
        }
      } else if (benefitName == "Additional CI") {
        if (this.selectedAdditionalCI != "") {
          benName = benefitName + ' ' + this.selectedAdditionalCI
        }
      } else if (benefitName == "EMI Waiver(Hosp)") {
        if (this.selectedwaiverHospitalDays != "") {
          benName = benefitName + ' ' + this.selectedwaiverHospitalDays
        }
      }
      var indexfn = this.findIndexValue(this.benefit[covType], "name", benName)
      if (indexfn != null) {
        this.benefit[covType][indexfn] = ({ "name": benName, "code": code, "Band": this.bandTableObject })
      } else {
        this.benefit[covType].push({ "name": benName, "code": code, "Band": this.bandTableObject })
      }
      if (benefitName == 'Death Benefit') {
        this.selectedBandDB.splice(-1);
        this.selectedRateDB.splice(-1);
      } else if (benefitName == 'Terminal Illness') {
        this.selectedBandTI.splice(-1);
        this.selectedRateTI.splice(-1);
      } else if (benefitName == 'Accidental Death Benefit') {
        this.selectedBandADB.splice(-1);
        this.selectedTableADB.splice(-1);
      } else if (benefitName == 'Enhanced ADB') {
        this.selectedBandEADB.splice(-1);
        this.selectedTableEADB.splice(-1);
      } else if (benefitName == 'Accelerated Accidental Total and Permanent Disability') {
        this.selectedBandAATPD.splice(-1);
        this.selectedTableAATPD.splice(-1);
      } else if (benefitName == 'Cancer Protect') {
        this.selectedBandCP.splice(-1);
        this.selectedTableCP.splice(-1);
      } else if (benefitName == 'Accelerated CI') {
        this.selectedBandACI.splice(-1);
        this.selectedTableACI.splice(-1);
      } else if (benefitName == 'Additional CI') {
        this.selectedBandCI.splice(-1);
        this.selectedTableCI.splice(-1);
      } else if (benefitName == 'EMI Waiver(Hosp)') {
        this.selectedBandEMI.splice(-1);
        this.selectedTableEMI.splice(-1);
      }
    }
    if (benefitName == 'Death Benefit') {
      var selectedCoverageType
      selectedCoverageType = this.el.nativeElement.querySelector('.isSelected').innerText
      if (selectedCoverageType == "Flat") {
        selectedCoverageType = 'Flat'
      } else if (selectedCoverageType == "Age Band") {
        selectedCoverageType = 'AgeBandWise'
      } else if (selectedCoverageType == "Term") {
        selectedCoverageType = 'TermWise'
      }
    }
  }

  storeValue(event, i) {
    this.correctVal = true
    var currentRow;
    var benefitName;
    if (event.target.parentElement.parentElement.parentElement.parentElement.parentElement.classList.contains("benefitValue")) {
      benefitName = event.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.firstElementChild.firstElementChild.innerText;
      currentRow = event.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;
    } else {
      benefitName = event.target.parentElement.parentElement.parentElement.parentElement.parentElement.firstElementChild.firstElementChild.innerText
      currentRow = event.target.parentElement.parentElement.parentElement.parentElement.parentElement;
    }
    var code;
    var selectedCoverageType = currentRow.querySelector('.isSelected').innerText;
    this.checkAgeBandLimit(event, selectedCoverageType, benefitName, i);
    if (this.correctVal == true) {
      var code;
      if (selectedCoverageType == "Flat") {
        selectedCoverageType = 'Flat'
      } else if (selectedCoverageType == "Age Band") {
        selectedCoverageType = 'AgeBandWise'
      } else if (selectedCoverageType == "Term") {
        selectedCoverageType = 'TermWise'
      }
      if (this.superProductCredit) {
        if (benefitName == 'Accidental Death Benefit') {
          this.selectedBandADB[i] = event.target.value
          this.selectedTableADB[i] = ""
          this.copytblADB = ""
          if (this.copyADB == true) {
            var mainEA = this.el.nativeElement.querySelector('.product-row.EADB')
            setTimeout(function () {
              mainEA.querySelector('.checkbox').firstElementChild.click()
            }, 500)
            setTimeout(function () {
              mainEA.querySelector('.checkbox').firstElementChild.click()
            }, 500)
          }
        } else if (benefitName == 'Enhanced ADB') {
          this.selectedBandEADB[i] = event.target.value
          this.selectedTableEADB[i] = ""
        } else if (benefitName == 'Accelerated Accidental Total and Permanent Disability') {
          this.selectedBandAATPD[i] = event.target.value
          this.selectedTableAATPD[i] = ""
        } else if (benefitName == 'Cancer Protect') {
          this.selectedBandCP[i] = event.target.value
          this.selectedTableCP[i] = ""
        } else if (benefitName == 'Accelerated CI') {
          this.selectedBandACI[i] = event.target.value
          this.selectedTableACI[i] = ""
        } else if (benefitName == 'Additional CI') {
          this.selectedBandCI[i] = event.target.value
          this.selectedTableCI[i] = ""
        } else if (benefitName == 'EMI Waiver(Hosp)') {
          this.selectedBandEMI[i] = event.target.value
          this.selectedTableEMI[i] = ""
        }
        if (i != undefined && event.target.placeholder == "Band") {
          this.bandTableData[benefitName][i].Band = event.target.value;
        }
        if (selectedCoverageType == 'Flat') {
          var rate = ({ "table_reference": event.target.value })
          var index = this.findIndexValue(this.benefit['Flat'], "name", benefitName)
          if (index != null) {
            this.benefit['Flat'].splice(index, 1)
          }
          this.selectedMortality = event.target.value;
          this.benefit['Flat'].push({ "name": benefitName, "code": "Base", "Band": rate })
        } else if (benefitName == 'Death Benefit' && selectedCoverageType != 'Flat') {
          if (event.target.placeholder == "Band") {
            // this.selectedRateDB[i] = ""
            this.selectedBandDB[i] = event.target.value;
            if (i == undefined) {
            } else {
              this.bandTableData[benefitName][i].Band = event.target.value;
            }
          } else if (event.target.placeholder == "Rate") {
            this.selectedRateDB[i] = event.target.value;
            this.bandTableData[benefitName][i]["TableRef"] = event.target.value;
          }
          this.bandTableObject = {}
          for (var k = 0; k < this.bandTableData[benefitName].length; k++) {
            var key = this.bandTableData[benefitName][k].Band
            var value = this.bandTableData[benefitName][k].TableRef
            this.bandTableObject[key] = value
          }
          var indexfn = this.findIndexValue(this.benefit[selectedCoverageType], "name", benefitName)
          if (indexfn != null) {
            this.benefit[selectedCoverageType][indexfn] = ({ "name": benefitName, "code": "Base", "Band": this.bandTableObject })
          } else {
            this.benefit[selectedCoverageType].push({ "name": benefitName, "code": code, "Band": this.bandTableObject })
          }
          if (this.copyDB == true) {
            var mainTI = this.el.nativeElement.querySelector('.product-row.Terminal')
            mainTI.querySelector('.checkbox').firstElementChild.click()
            mainTI.querySelector('.checkbox').firstElementChild.click()
          }
        }
        if (benefitName == 'Death Benefit') {
          if (this.copyDB == true) {
            var mainTI = this.el.nativeElement.querySelector('.product-row.Terminal')
            setTimeout(function () {
              mainTI.querySelector('.checkbox').firstElementChild.click()
            }, 500)
            setTimeout(function () {
              mainTI.querySelector('.checkbox').firstElementChild.click()
            }, 500)
          }
        }
        for (var c = 0; c < this.superProtectBenefitList.length; c++) {
          if (this.superProtectBenefitList[c].item_name.includes(benefitName)) {
            code = this.superProtectBenefitList[c].item_code;
            break;
          }
        }
      } else if (this.groupLoanSecure) {
        for (var c = 0; c < this.benefitList.length; c++) {
          if (this.benefitList[c].item_name.includes(benefitName)) {
            code = this.benefitList[c].item_code;
            break;
          }
        }
        if (benefitName == 'Death Benefit') {
          if (event.target.placeholder == "Band") {
            // this.selectedRateADB[i] = ""
            this.selectedBandDB[i] = event.target.value;
            this.bandTableData[benefitName][i].Band = event.target.value;
          } else if (event.target.placeholder == "Rate") {
            this.selectedRateDB[i] = event.target.value;
            this.bandTableData[benefitName][i]["TableRef"] = event.target.value;
          }
        } else if (benefitName == 'Accidental Death Benefit') {
          if (event.target.placeholder == "Band") {
            // this.selectedRateDB[i] = ""
            this.selectedBandADB[i] = event.target.value;
            this.bandTableData[benefitName][i].Band = event.target.value;
          } else if (event.target.placeholder == "Rate") {
            this.selectedRateADB[i] = event.target.value;
            this.bandTableData[benefitName][i]["TableRef"] = event.target.value;
          }
        } else if (benefitName == 'Critical Illness') {
          for (var c = 0; c < this.criticalIllnessList.length; c++) {
            if (this.criticalIllnessList[c].item_name == this.selectedCriticalIllness) {
              code = this.criticalIllnessList[c].item_code;
              break;
            }
          }
          if (event.target.placeholder == "Band") {
            // this.selectedRateDB[i] = ""
            this.selectedBandCRI[i] = event.target.value;
            this.bandTableData[benefitName][i].Band = event.target.value;
          } else if (event.target.placeholder == "Rate") {
            this.selectedRateCRI[i] = event.target.value;
            this.bandTableData[benefitName][i]["TableRef"] = event.target.value;
          }
        } else if (benefitName == 'Total and Permanent Disability') {
          if (event.target.placeholder == "Band") {
            // this.selectedRateDB[i] = ""
            this.selectedBandTPD[i] = event.target.value;
            this.bandTableData[benefitName][i].Band = event.target.value;
          } else if (event.target.placeholder == "Rate") {
            this.selectedRateTPD[i] = event.target.value;
            this.bandTableData[benefitName][i]["TableRef"] = event.target.value;
          }
        }
      }

      if (selectedCoverageType != 'Flat') {
        this.bandTableObject = {}
        for (var k = 0; k < this.bandTableData[benefitName].length; k++) {
          var key = this.bandTableData[benefitName][k].Band
          var value = this.bandTableData[benefitName][k].TableRef
          this.bandTableObject[key] = value
        }
        var indexfn = this.includeIndexValue(this.benefit[selectedCoverageType], "name", benefitName)
        if (benefitName == "Critical Illness") {
          benefitName = benefitName + " " + this.selectedCriticalIllness
        }
        if (indexfn != null) {
          this.benefit[selectedCoverageType][indexfn] = ({ "name": benefitName, "code": code, "Band": this.bandTableObject })
        } else {
          this.benefit[selectedCoverageType].push({ "name": benefitName, "code": code, "Band": this.bandTableObject })
        }
      }
    }
  }

  checkAgeBandLimit(event, covg, ben, i) {
    if (event.target.value != "") {
      var dbRow = this.el.nativeElement.querySelector('.product-row:not(.head)')
      var dbCovg = dbRow.querySelector('.isSelected').innerText
      var dbValues = []
      var dbMinAge = environment.fixedData[0].min_age_at_entry
      var dbMaxAge = environment.fixedData[0].max_age_at_entry
      var dbMinTerm = environment.fixedData[0].min_policy_term
      var dbMaxTerm = environment.fixedData[0].max_policy_term
      dbRow.querySelectorAll('input:not(.hidden)').forEach(function (input) {
        if (input.placeholder == "Band") {
          dbValues.push(input.value)
        }
      })
      for (var j = 0; j < dbValues.length; j++) {
        if (dbValues[j].includes("-")) {
          dbValues.push(dbValues[j].split("-")[0])
          dbValues.push(dbValues[j].split("-")[1])
        }
      }
      for (var j = 0; j < dbValues.length; j++) {
        dbValues[j] = parseInt(dbValues[j])
      }
      if (dbCovg == "Age Band") {
        dbMinAge = Math.min(...dbValues)
        dbMaxAge = Math.max(...dbValues)
      } else if (dbCovg == "Term") {
        dbMinTerm = Math.min(...dbValues)
        dbMaxTerm = Math.max(...dbValues)
      }
      if (event.target.placeholder == "Rate") {
        if (environment.groupLoanSecure) {
          if (event.target.value > environment.fixedData[0]["max_mortality_rate"] || event.target.value < environment.fixedData[0]["min_mortality_rate"]) {
            this.correctVal = false
            event.target.value = "";
            swal({
              title: 'Please enter mortality rate between ' + environment.fixedData[0]["min_mortality_rate"] + " " + 'to' + " " + environment.fixedData[0]["max_mortality_rate"],
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#eb1826',
              cancelButtonColor: '#F4F1F1',
              confirmButtonText: 'OK'
            }).then((result) => {
              event.target.value = "";
            })
          }
          else if (event.target.value < 100 && ((event.target.value % 5) != 0)) {
            this.correctVal = false
            event.target.value = "";
            swal({
              title: 'Please enter mortality rate in multiple of 5, when mortality rate is below 100',
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#eb1826',
              cancelButtonColor: '#F4F1F1',
              confirmButtonText: 'OK'
            }).then((result) => {
              event.target.value = "";
            })
          }
          else if (event.target.value > 100 && event.target.value < 200 && ((event.target.value % 10) != 0)) {
            this.correctVal = false
            event.target.value = "";
            swal({
              title: 'Please enter mortality rate in multiple of 10, when mortality rate ranges from 100 to 200',
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#eb1826',
              cancelButtonColor: '#F4F1F1',
              confirmButtonText: 'OK'
            }).then((result) => {
              event.target.value = "";
            })
          }
          else if (event.target.value > 200 && event.target.value < 300 && ((event.target.value % 25) != 0)) {
            this.correctVal = false
            event.target.value = "";
            swal({
              title: 'Please enter mortality rate in multiple of 25, when mortality rate ranges from 200 to 300',
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#eb1826',
              cancelButtonColor: '#F4F1F1',
              confirmButtonText: 'OK'
            }).then((result) => {
              event.target.value = "";
            })
          }
        } else if (environment.superProductCredit) {
          if (event.target.value > environment.fixedData[0]["max_mortality_rate"] || event.target.value < environment.fixedData[0]["min_mortality_rate"]) {
            this.correctVal = false
            event.target.value = "";
            swal({
              title: 'Please enter mortality rate between ' + environment.fixedData[0]["min_mortality_rate"] + " " + 'to' + " " + environment.fixedData[0]["max_mortality_rate"],
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#eb1826',
              cancelButtonColor: '#F4F1F1',
              confirmButtonText: 'OK'
            }).then((result) => {
              event.target.value = "";
            })
          }
          else if (event.target.value < 100 && ((event.target.value % 5) != 0)) {
            this.correctVal = false
            event.target.value = "";
            swal({
              title: 'Please enter mortality rate in multiple of 5, when mortality rate is below 100',
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#eb1826',
              cancelButtonColor: '#F4F1F1',
              confirmButtonText: 'OK'
            }).then((result) => {
              event.target.value = "";
            })
          }
          else if (event.target.value > 100 && event.target.value < 500 && ((event.target.value % 10) != 0)) {
            this.correctVal = false
            event.target.value = "";
            swal({
              title: 'Please enter mortality rate in multiple of 10, when mortality rate ranges from 100 to 500',
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#eb1826',
              cancelButtonColor: '#F4F1F1',
              confirmButtonText: 'OK'
            }).then((result) => {
              event.target.value = "";
            })
          }
        }
      } else if (event.target.placeholder == "Band") {
        var value = event.target.value;
        var splitdata = value.split("-");
        if (covg == "Age Band") {
          if (splitdata[0] != " " && ben.startsWith("Death Benefit")) {
            for (var l = 0; l < splitdata.length; l++) {
              splitdata[l] = parseInt(splitdata[l])
            }
            if (splitdata.length == 1) {
              if (splitdata[0] < environment.fixedData[0].min_age_at_entry || splitdata[0] > environment.fixedData[0].max_age_at_entry) {
                this.correctVal = false
                event.target.value = "";
                swal({
                  title: 'Please enter value between min age' + " " + environment.fixedData[0].min_age_at_entry + " " + 'and' + " " + 'max age' + " " + environment.fixedData[0].max_age_at_entry + "" + ' of the product.',
                  type: 'warning',
                  showCancelButton: false,
                  confirmButtonColor: '#eb1826',
                  cancelButtonColor: '#F4F1F1',
                  confirmButtonText: 'OK'
                }).then((result) => {
                  event.target.value = "";
                })
              } else {
              }
            }
            else if (splitdata.length > 1) {
              if (parseInt(splitdata[0]) > parseInt(splitdata[1])) {
                this.correctVal = false
                event.target.value = "";
                swal({
                  title: "Min age cannot be greater than max age!",
                  type: 'warning',
                  showCancelButton: false,
                  confirmButtonColor: '#eb1826',
                  cancelButtonColor: '#F4F1F1',
                  confirmButtonText: 'OK'
                }).then((result) => {
                  event.target.value = "";
                })
              }
              // else 4
              if ((splitdata[0] < environment.fixedData[0].min_age_at_entry || splitdata[0] > environment.fixedData[0].max_age_at_entry ||
                splitdata[1] < environment.fixedData[0].min_age_at_entry || splitdata[1] > environment.fixedData[0].max_age_at_entry)) {
                this.correctVal = false
                event.target.value = "";
                swal({
                  title: 'Please enter value between min age' + " " + environment.fixedData[0].min_age_at_entry + " " + 'and' + " " + 'max age' + " " + environment.fixedData[0].max_age_at_entry + "" + ' of the product.',
                  type: 'warning',
                  showCancelButton: false,
                  confirmButtonColor: '#eb1826',
                  cancelButtonColor: '#F4F1F1',
                  confirmButtonText: 'OK'
                }).then((result) => {
                  event.target.value = "";
                })
              }
            }
          } else if (splitdata[0] != " ") {
            for (var l = 0; l < splitdata.length; l++) {
              splitdata[l] = parseInt(splitdata[l])
            }
            if (splitdata.length == 1) {
              if (splitdata[0] < dbMinAge || splitdata[0] > dbMaxAge) {
                this.correctVal = false
                event.target.value = "";
                swal({
                  title: 'Please enter value between min age' + " " + dbMinAge + " " + 'and' + " " + 'max age' + " " + dbMaxAge + "" + ' of the product.',
                  type: 'warning',
                  showCancelButton: false,
                  confirmButtonColor: '#eb1826',
                  cancelButtonColor: '#F4F1F1',
                  confirmButtonText: 'OK'
                }).then((result) => {
                  event.target.value = "";
                })
              } else {
              }
            }
            else if (splitdata.length > 1) {
              if (parseInt(splitdata[0]) > parseInt(splitdata[1])) {
                this.correctVal = false
                event.target.value = "";
                swal({
                  title: "Min age cannot be greater than max age!",
                  type: 'warning',
                  showCancelButton: false,
                  confirmButtonColor: '#eb1826',
                  cancelButtonColor: '#F4F1F1',
                  confirmButtonText: 'OK'
                }).then((result) => {
                  event.target.value = "";
                })
              }
              // else 4
              if ((splitdata[0] < dbMinAge || splitdata[0] > dbMaxAge ||
                splitdata[1] < dbMinAge || splitdata[1] > dbMaxAge)) {
                this.correctVal = false
                event.target.value = "";
                swal({
                  title: 'Please enter value between min age' + " " + dbMinAge + " " + 'and' + " " + 'max age' + " " + dbMaxAge + "" + ' of the product.',
                  type: 'warning',
                  showCancelButton: false,
                  confirmButtonColor: '#eb1826',
                  cancelButtonColor: '#F4F1F1',
                  confirmButtonText: 'OK'
                }).then((result) => {
                  event.target.value = "";
                })
              }
            }
          }
        } else if (covg == "Term") {
          if (splitdata[0] != " " && ben.startsWith("Death Benefit")) {
            if (splitdata.length == 1) {
              if (splitdata[0] < environment.fixedData[0].min_policy_term || splitdata[0] > environment.fixedData[0].max_policy_term) {
                this.correctVal = false
                event.target.value = "";
                swal({
                  title: 'Please enter value between min term' + " " + environment.fixedData[0].min_policy_term + " " + 'and' + " " + 'max term' + " " + environment.fixedData[0].max_policy_term + "" + ' of the product.',
                  type: 'warning',
                  showCancelButton: false,
                  confirmButtonColor: '#eb1826',
                  cancelButtonColor: '#F4F1F1',
                  confirmButtonText: 'OK'
                }).then((result) => {
                  event.target.value = "";
                })
              }
            }
            else if (splitdata.length > 1) {
              if ((splitdata[0] < environment.fixedData[0].min_policy_term || splitdata[0] > environment.fixedData[0].max_policy_term ||
                splitdata[1] < environment.fixedData[0].min_policy_term || splitdata[1] > environment.fixedData[0].max_policy_term)) {
                this.correctVal = false
                event.target.value = "";
                swal({
                  title: 'Please enter value between min term' + " " + environment.fixedData[0].min_policy_term + " " + 'and' + " " + 'max term' + " " + environment.fixedData[0].max_policy_term + "" + ' of the product.',
                  type: 'warning',
                  showCancelButton: false,
                  confirmButtonColor: '#eb1826',
                  cancelButtonColor: '#F4F1F1',
                  confirmButtonText: 'OK'
                }).then((result) => {
                  event.target.value = "";
                })
              }

              if (parseInt(splitdata[0]) > parseInt(splitdata[1])) {
                this.correctVal = false
                event.target.value = "";
                swal({
                  title: "Min term cannot be greater than max term.",
                  type: 'warning',
                  showCancelButton: false,
                  confirmButtonColor: '#eb1826',
                  cancelButtonColor: '#F4F1F1',
                  confirmButtonText: 'OK'
                }).then((result) => {
                  event.target.value = "";
                })
              }
            }
          } else if (splitdata[0] != " " && (ben.startsWith("EMI Waiver(Hosp)")) || (ben.startsWith("Cancer Protect"))) {
            if (dbMaxTerm > environment.canceremimaxPolicyTerm) {
              dbMaxTerm = environment.canceremimaxPolicyTerm
            }
            if (splitdata.length == 1) {
              if (splitdata[0] < dbMinTerm || splitdata[0] > dbMaxTerm) {
                this.correctVal = false
                event.target.value = "";
                swal({
                  title: 'Please enter value between min term' + " " + dbMinTerm + " " + 'and' + " " + 'max term' + " " + dbMaxTerm + "" + ' of the product.',
                  type: 'warning',
                  showCancelButton: false,
                  confirmButtonColor: '#eb1826',
                  cancelButtonColor: '#F4F1F1',
                  confirmButtonText: 'OK'
                }).then((result) => {
                  event.target.value = "";
                })
              }
            }
            else if (splitdata.length > 1) {
              if ((splitdata[0] < dbMinTerm || splitdata[0] > dbMaxTerm ||
                splitdata[1] < dbMinTerm || splitdata[1] > dbMaxTerm)) {
                this.correctVal = false
                event.target.value = "";
                swal({
                  title: 'Please enter value between min term' + " " + dbMinTerm + " " + 'and' + " " + 'max term' + " " + dbMaxTerm + "" + ' of the product.',
                  type: 'warning',
                  showCancelButton: false,
                  confirmButtonColor: '#eb1826',
                  cancelButtonColor: '#F4F1F1',
                  confirmButtonText: 'OK'
                }).then((result) => {
                  event.target.value = "";
                })
              }

              if (parseInt(splitdata[0]) > parseInt(splitdata[1])) {
                this.correctVal = false
                event.target.value = "";
                swal({
                  title: "Min term cannot be greater than max term.",
                  type: 'warning',
                  showCancelButton: false,
                  confirmButtonColor: '#eb1826',
                  cancelButtonColor: '#F4F1F1',
                  confirmButtonText: 'OK'
                }).then((result) => {
                  event.target.value = "";
                })
              }
            }
          } else if (splitdata[0] != " " && (ben.startsWith("Accelerated CI")) || (ben.startsWith("Additional CI"))) {
            if (dbMaxTerm > environment.acimaxPolicyTerm) {
              dbMaxTerm = environment.acimaxPolicyTerm
            }
            if (splitdata.length == 1) {
              if (splitdata[0] < dbMinTerm || splitdata[0] > dbMaxTerm) {
                this.correctVal = false
                event.target.value = "";
                swal({
                  title: 'Please enter value between min term' + " " + dbMinTerm + " " + 'and' + " " + 'max term' + " " + dbMaxTerm + "" + ' of the product.',
                  type: 'warning',
                  showCancelButton: false,
                  confirmButtonColor: '#eb1826',
                  cancelButtonColor: '#F4F1F1',
                  confirmButtonText: 'OK'
                }).then((result) => {
                  event.target.value = "";
                })
              }
            }
            else if (splitdata.length > 1) {
              if ((splitdata[0] < dbMinTerm || splitdata[0] > dbMaxTerm ||
                splitdata[1] < dbMinTerm || splitdata[1] > dbMaxTerm)) {
                this.correctVal = false
                event.target.value = "";
                swal({
                  title: 'Please enter value between min term' + " " + dbMinTerm + " " + 'and' + " " + 'max term' + " " + dbMaxTerm + "" + ' of the product.',
                  type: 'warning',
                  showCancelButton: false,
                  confirmButtonColor: '#eb1826',
                  cancelButtonColor: '#F4F1F1',
                  confirmButtonText: 'OK'
                }).then((result) => {
                  event.target.value = "";
                })
              }

              if (parseInt(splitdata[0]) > parseInt(splitdata[1])) {
                this.correctVal = false
                event.target.value = "";
                swal({
                  title: "Min term cannot be greater than max term.",
                  type: 'warning',
                  showCancelButton: false,
                  confirmButtonColor: '#eb1826',
                  cancelButtonColor: '#F4F1F1',
                  confirmButtonText: 'OK'
                }).then((result) => {
                  event.target.value = "";
                })
              }
            }
          } else {
            if (splitdata.length == 1) {
              if (splitdata[0] < dbMinTerm || splitdata[0] > dbMaxTerm) {
                this.correctVal = false
                event.target.value = "";
                swal({
                  title: 'Please enter value between min term' + " " + dbMinTerm + " " + 'and' + " " + 'max term' + " " + dbMaxTerm + "" + ' of the product.',
                  type: 'warning',
                  showCancelButton: false,
                  confirmButtonColor: '#eb1826',
                  cancelButtonColor: '#F4F1F1',
                  confirmButtonText: 'OK'
                }).then((result) => {
                  event.target.value = "";
                })
              }
            }
            else if (splitdata.length > 1) {
              if ((splitdata[0] < dbMinTerm || splitdata[0] > dbMaxTerm ||
                splitdata[1] < dbMinTerm || splitdata[1] > dbMaxTerm)) {
                this.correctVal = false
                event.target.value = "";
                swal({
                  title: 'Please enter value between min term' + " " + dbMinTerm + " " + 'and' + " " + 'max term' + " " + dbMaxTerm + "" + ' of the product.',
                  type: 'warning',
                  showCancelButton: false,
                  confirmButtonColor: '#eb1826',
                  cancelButtonColor: '#F4F1F1',
                  confirmButtonText: 'OK'
                }).then((result) => {
                  event.target.value = "";
                })
              }

              if (parseInt(splitdata[0]) > parseInt(splitdata[1])) {
                this.correctVal = false
                event.target.value = "";
                swal({
                  title: "Min term cannot be greater than max term.",
                  type: 'warning',
                  showCancelButton: false,
                  confirmButtonColor: '#eb1826',
                  cancelButtonColor: '#F4F1F1',
                  confirmButtonText: 'OK'
                }).then((result) => {
                  event.target.value = "";
                })
              }
            }
          }
        }

        //overlap check
        var inputRange = event.target.parentElement.parentElement.parentElement.parentElement
        var ranges = []
        var values = []
        inputRange.querySelectorAll('input').forEach(function (input) {
          if (input != event.target && input.placeholder == "Band") {
            if (input.value.includes("-")) {
              ranges.push(input.value)
            }
          }
        })
        inputRange.querySelectorAll('input').forEach(function (input) {
          if (input != event.target && input.placeholder == "Band") {
            if (!(input.value.includes("-"))) {
              values.push(input.value)
            }
          }
        })
        if (event.target.value.includes("-")) {
          var currRange = event.target.value.split("-")
          currRange[0] = parseInt(currRange[0])
          currRange[1] = parseInt(currRange[1])
          for (var j = 0; j < ranges.length; j++) {
            var splitRange = ranges[j].split("-")
            splitRange[0] = parseInt(splitRange[0])
            splitRange[1] = parseInt(splitRange[1])
            if (splitRange[0] <= currRange[0] && splitRange[1] >= currRange[0] ||
              splitRange[0] <= currRange[1] && splitRange[1] >= currRange[1]) {
              event.target.value = ""
              swal({
                title: 'Range cannot overlap!',
                type: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#eb1826',
                cancelButtonColor: '#F4F1F1',
                confirmButtonText: 'OK'
              }).then((result) => {
                event.target.value = "";
              })
            }
          }
          for (var k = 0; k < values.length; k++) {
            values[k] = parseInt(values[k])
            if (currRange[0] <= values[k] && currRange[1] >= values[k]) {
              event.target.value = ""
              swal({
                title: 'Range cannot overlap!',
                type: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#eb1826',
                cancelButtonColor: '#F4F1F1',
                confirmButtonText: 'OK'
              }).then((result) => {
                event.target.value = "";
              })
            }
          }
        } else {
          var currValue = parseInt(event.target.value);
          for (var j = 0; j < ranges.length; j++) {
            var splitRange = ranges[j].split("-")
            splitRange[0] = parseInt(splitRange[0])
            splitRange[1] = parseInt(splitRange[1])
            if (splitRange[0] <= currValue && splitRange[1] >= currValue) {
              event.target.value = ""
              swal({
                title: 'Value cannot lie between already defined range!',
                type: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#eb1826',
                cancelButtonColor: '#F4F1F1',
                confirmButtonText: 'OK'
              }).then((result) => {
                event.target.value = "";
              })
            }
          }
          for (var k = 0; k < values.length; k++) {
            if (values[k] == currValue) {
              values[k] = parseInt(values[k])
              event.target.value = ""
              swal({
                title: 'Duplicate value. Please select a different range!',
                type: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#eb1826',
                cancelButtonColor: '#F4F1F1',
                confirmButtonText: 'OK'
              }).then((result) => {
                event.target.value = "";
              })
            }
          }
        }
      }
    }
  }

  getIndex(event, i) {
    if (event.target.parentElement.parentElement.parentElement != null &&
      event.target.parentElement.parentElement.parentElement.parentElement != null) {
      this.index = i
      if (event.target.parentElement.parentElement.parentElement.parentElement.classList.contains('product-row')) {
        this.event = event.target.parentElement.parentElement.parentElement.parentElement
      } else if (event.target.parentElement.parentElement.parentElement.parentElement.parentElement.classList.contains('product-row')) {
        this.event = event.target.parentElement.parentElement.parentElement.parentElement.parentElement
      }
      else if (event.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.classList.contains("product-row")) {
        this.event = event.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;
      }
      else if (event.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.classList.contains("product-row")) {
        this.event = event.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;
      }
      else
        this.event = event.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement
    }
  }

  getDropdownValue(value) {
    this.value = value
    this.saveDropdownValue(this.event, this.index, this.value)
  }

  saveEmiValue() {
    var ben = "EMI Waiver(Hosp)"
    var indexF = this.includeIndexValue(this.benefit["Flat"], "name", ben)
    var indexA = this.includeIndexValue(this.benefit["AgeBandWise"], "name", ben)
    var indexT = this.includeIndexValue(this.benefit["TermWise"], "name", ben)
    var covgType
    var index
    var tempData
    if (indexF != null) {
      covgType = "Flat"
      index = indexF
    } else if (indexA != null) {
      covgType = "AgeBandWise"
      index = indexA
    } else if (indexT != null) {
      covgType = "TermWise"
      index = indexT
    }
    if (index != undefined) {
      var code;
      for (var i = 0; i < this.superProtectBenefitList.length; i++) {
        if (this.superProtectBenefitList[i].item_name.includes(ben)) {
          code = this.superProtectBenefitList[i].item_code;
          break;
        }
      }
      tempData = this.benefit[covgType][index]
      var mixValue
      var indicator
      if (this.selectedwaiverHospitalDays == "7Days") {
        indicator = "1"
        this.emi15 = ""
        mixValue = indicator + this.emi7
      } else if (this.selectedwaiverHospitalDays == "15Days") {
        indicator = "2"
        this.emi7 = ""
        mixValue = indicator + this.emi15
      } else if (this.selectedwaiverHospitalDays == "7and15Days") {
        indicator = "3"
        mixValue = indicator + this.emi7 + this.emi15
      }
      this.benefit[covgType][index] = ({ "name": ben + " " + this.selectedwaiverHospitalDays, "value1": this.emi7, "value2": this.emi15, "value": mixValue, "code": code, "Band": tempData.Band })
    }
  }

  saveDropdownValue(event, index, value) {
    var benefitName = event.firstElementChild.firstElementChild.innerText;
    if (value != "") {
      var code;
      for (var i = 0; i < this.superProtectBenefitList.length; i++) {
        if (this.superProtectBenefitList[i].item_name.includes(benefitName)) {
          code = this.superProtectBenefitList[i].item_code;
          break;
        }
      }
      var selectedCoverageType = event.querySelector('.isSelected').innerText;
      if (selectedCoverageType == "Flat") {
        selectedCoverageType = 'Flat'
      } else if (selectedCoverageType == "Age Band") {
        selectedCoverageType = 'AgeBandWise'
      } else if (selectedCoverageType == "Term") {
        selectedCoverageType = 'TermWise'
      }
      var indexfn = this.includeIndexValue(this.benefit[selectedCoverageType], "name", benefitName)
      if (selectedCoverageType == "Flat") {
        var tableref = ({ "table_reference": value })
        if (benefitName == 'Accelerated CI') {
          benefitName = benefitName + " " + this.selectedAcceleratedCI
        } else if (benefitName == 'Additional CI') {
          benefitName = benefitName + " " + this.selectedAdditionalCI
        } else if (benefitName == 'EMI Waiver(Hosp)') {
          benefitName = benefitName + " " + this.selectedwaiverHospitalDays
        }
        if (benefitName.includes("EMI Waiver(Hosp)")) {
          var mixValue
          var indicator
          if (this.selectedwaiverHospitalDays == "7Days") {
            indicator = "1"
            this.emi15 = ""
            mixValue = indicator + this.emi7
          } else if (this.selectedwaiverHospitalDays == "15Days") {
            indicator = "2"
            this.emi15 = ""
            mixValue = indicator + this.emi7
          } else if (this.selectedwaiverHospitalDays == "7and15Days") {
            indicator = "3"
            mixValue = indicator + this.emi7 + this.emi15
          }
          if (indexfn != null) {
            this.benefit[selectedCoverageType][indexfn] = ({ "name": "EMI Waiver(Hosp) " + this.selectedwaiverHospitalDays, "value1": this.emi7, "value2": this.emi15, "value": mixValue, "code": code, "Band": tableref })
          } else {
            this.benefit[selectedCoverageType].push({ "name": "EMI Waiver(Hosp) " + this.selectedwaiverHospitalDays, "value1": this.emi7, "value2": this.emi15, "value": mixValue, "code": code, "Band": tableref })
          }
        } else {
          if (indexfn != null) {
            this.benefit[selectedCoverageType][indexfn] = ({ "name": benefitName, "code": code, "Band": tableref })
          } else {
            this.benefit[selectedCoverageType].push({ "name": benefitName, "code": code, "Band": tableref })
          }
        }
        if (benefitName == 'Accidental Death Benefit') {
          if (index == undefined) {
            // this.copytblADB = value
            //this.selectedTableEADB[0] = value
          }
        }
      } else {
        if (benefitName == 'Accidental Death Benefit') {
          this.selectedTableADB[index] = value
        } else if (benefitName == 'Enhanced ADB') {
          this.selectedTableEADB[index] = value
        } else if (benefitName == 'Accelerated Accidental Total and Permanent Disability') {
          this.selectedTableAATPD[index] = value
        } else if (benefitName == 'Cancer Protect') {
          this.selectedTableCP[index] = value
        } else if (benefitName == 'Accelerated CI') {
          this.selectedTableACI[index] = value
        } else if (benefitName == 'Additional CI') {
          this.selectedTableCI[index] = value
        } else if (benefitName == 'EMI Waiver(Hosp)') {
          this.selectedTableEMI[index] = value
        }
        this.bandTableData[benefitName][index].TableRef = value
        this.bandTableObject = {}
        for (var k = 0; k < this.bandTableData[benefitName].length; k++) {
          var key = this.bandTableData[benefitName][k].Band
          var value = this.bandTableData[benefitName][k].TableRef
          this.bandTableObject[key] = value
        }
        if (benefitName == 'Accelerated CI') {
          benefitName = benefitName + " " + this.selectedAcceleratedCI
        } else if (benefitName == 'Additional CI') {
          benefitName = benefitName + " " + this.selectedAdditionalCI
        } else if (benefitName == 'EMI Waiver(Hosp)') {
          benefitName = benefitName + " " + this.selectedwaiverHospitalDays
        }
        if ((benefitName.includes("EMI Waiver(Hosp)"))) {
          var mixValue
          var indicator
          if (this.selectedwaiverHospitalDays == "7Days") {
            indicator = "1"
            this.emi15 = ""
            mixValue = indicator + this.emi7
          } else if (this.selectedwaiverHospitalDays == "15Days") {
            indicator = "2"
            this.emi7 = ""
            mixValue = indicator + this.emi15
          } else if (this.selectedwaiverHospitalDays == "7and15Days") {
            indicator = "3"
            mixValue = indicator + this.emi7 + this.emi15
          }
          if (indexfn != null) {
            this.benefit[selectedCoverageType][indexfn] = ({ "name": "EMI Waiver(Hosp) " + this.selectedwaiverHospitalDays, "value1": this.emi7, "value2": this.emi15, "value": mixValue, "code": code, "Band": this.bandTableObject })
          } else {
            this.benefit[selectedCoverageType].push({ "name": "EMI Waiver(Hosp) " + this.selectedwaiverHospitalDays, "value1": this.emi7, "value2": this.emi15, "value": mixValue, "code": code, "Band": this.bandTableObject })
          }
        } else {
          if (indexfn != null) {
            this.benefit[selectedCoverageType][indexfn] = ({ "name": benefitName, "code": code, "Band": this.bandTableObject })
          } else {
            this.benefit[selectedCoverageType].push({ "name": benefitName, "code": code, "Band": this.bandTableObject })
          }
        }
      }
    } else if (value == "" && benefitName == 'Accidental Death Benefit') {
      this.selectedTableEADB[0] = value
      this.copytblADB = value
    }
  }
}

import { Component, NgModule, ElementRef, NgZone, OnInit, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import { AppComponent } from '../../app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataShareService } from '../../services/data-share.service';
import { RouterModule, Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { ConfigService } from '../../services/config.service';
import { Subscriber } from 'rxjs';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import Swal from 'sweetalert2';
import swal from 'sweetalert2';
import { resolve } from '../../../../node_modules/@types/q';
import { reject } from '../../../../node_modules/@types/q';
import { load } from '../../../../node_modules/@angular/core/src/render3/instructions';

@Component({
  selector: 'app-product-mapping',
  templateUrl: './product-mapping.component.html',
  styleUrls: ['./product-mapping.component.scss']
})
export class ProductMappingComponent implements OnInit {
  dropDownEnable: boolean = false;
  products: any = [];
  payment_term: any = [];
  coverage_option: any = [];
  pay_frequency: any = [];
  loanType: any = [];
  entityTypes: any = [];
  selectedEntityType = "";
  selectedProduct = "";
  selectedLoan = "";
  selectedOtherLoanDetails = "";
  selectedProductName = "";
  selectedMasterCode:String = "";
  selectedSchedule = ""
  selectedMinimumGroup = "";
  selectedNonMedicalLimit = "";
  selectedreductionSchedule = "";
  selectedreductionScheduleCode = "";
  otherBen: any = [];
  reductionSchedule: "";
  coverageOptions: any;
  singleJointLife: any;
  coverageTerm: any;
  interestRates: any;
  // moratoriumPeriod: any;
  moratoriumTerm: any;
  coverageType: any = [];
  saveData: any = [];
  hideLoanType: any = [];
  configuredPolicys: any = [];
  partnerDetails = environment.partnerDetails;
  processFuther = false;
  selectedFlatRate: any = "";
  shubhRakshaCredit = environment.shubhRakshaCredit;
  groupLoanSecure = environment.groupLoanSecure;
  superProductCredit = environment.superProductCredit;
  goNext = true;
  otherBenifitsList: any;
  productData: any;
  selectedInterestRate: any = "";
  selectedChooseLife: any = "";
  selectedCoverageTerm: any;
  // selectedmoratoriumPeriod: any = "";
  selectedMoratoriumTerm: any = "";
  selectedMoratoriumTermValue: any;
  selectedCoverageOption: any = "";
  others: any;
  selectedChannel: any = "";
  channel: any = [];
  selectedFloorRate = ""
  checkTerms = false
  
  constructor(public app: AppComponent, public change: DataShareService, public routes: Router, public readData: ActivatedRoute,
    public config: ConfigService, public loader: Ng4LoadingSpinnerService, public el: ElementRef) {
    console.log(this.routes.url);
    if (environment.loginSuccessfully == true) {
      this.loader.show();
      var url = "/service/master";
     
      
      this.config.httpService("get", url, "").subscribe(
        res => {
          var data;
          data = res;
          this.products = data.products;
          // this.loanType = data.loanTypes;
          console.log(res);
        },
        err => {
          console.log(err);
        }
      )
      this.channel = [
        {
          "item_name": "Direct",
          "item_id": 1
        },
        {
          "item_name": "Broker",
          "item_id": 2
        },
        {
          "item_name": "CA",
          "item_id": 3
        }
      ];
      this.selectedInterestRate = this.change.getInterestRate();
      this.change.newInterestRateSubject.subscribe((data) => {
        this.selectedInterestRate = data;
        if (this.selectedInterestRate != "") {
          var code;
          for (var i = 0; i < this.interestRates.length; i++) {
            if (this.interestRates[i].item_name == this.selectedInterestRate) {
              code = this.interestRates[i].item_code;
              break;
            }
          }
          this.others["interest_rate"] = ([{ "name": this.selectedInterestRate, "code": code }]);
          console.log(this.others);
        }
      });

      this.selectedreductionSchedule = this.change.getReductionSchedule();
      this.change.newselectedReductionSchedule.subscribe((data) => {
        this.selectedreductionSchedule = data;
        console.log(this.selectedreductionSchedule);
      });

      this.selectedCoverageTerm = this.change.getCoverageTerm();
      this.change.newCoverageTermSubject.subscribe((data) => {
        this.selectedCoverageTerm = data;
        if (this.selectedCoverageTerm != "") {
          var parentCode;
          for (var i = 0; i < this.otherBenifitsList.length; i++) {
            if (this.otherBenifitsList[i].item_name == "CoverageTerm") {
              parentCode = this.otherBenifitsList[i].item_code;
              break;
            }
          }
          this.others["coverage_term"] = ([{ "name": this.selectedCoverageTerm, "code": this.selectedCoverageTerm }]);
          console.log(this.others);
        } else {
          this.others["coverage_term"] = ([{ "name": "", "code": "" }]);
        }
      });

      this.selectedCoverageOption = this.change.getcoverageOptions();
      this.change.newCoverageOptionsSubject.subscribe((data) => {
        this.selectedCoverageOption = data;
        if (this.selectedCoverageOption != "") {
          var code;
          for (var i = 0; i < this.coverageOptions.length; i++) {
            if (this.coverageOptions[i].item_name == this.selectedCoverageOption) {
              code = this.coverageOptions[i].item_code;
              break;
            }
          }
          this.others["coverage_option"] = ([{ "name": this.selectedCoverageOption, "code": code }]);
          console.log(this.others);
        }
      });

      this.selectedChooseLife = this.change.getsingleJointLife();
      this.change.newSingleJointLifeSubject.subscribe((data) => {
        this.selectedChooseLife = data;
        if (this.selectedChooseLife != "") {
          var code;
          for (var i = 0; i < this.singleJointLife.length; i++) {
            if (this.singleJointLife[i].item_name == this.selectedChooseLife) {
              code = this.singleJointLife[i].item_code;
              break;
            }
          }
          this.others["selected_life"] = ([{ "name": this.selectedChooseLife, "code": code }]);
          console.log(this.others)
        }
      });

      // this.selectedmoratoriumPeriod = this.change.getmoratoriumPeriod();
      // this.change.newMoratoriumPeriodSubject.subscribe((data) => {
      //   this.selectedmoratoriumPeriod = data;
      //   if (this.selectedmoratoriumPeriod != "") {
      //     var code;
      //     for (var i = 0; i < this.moratoriumPeriod.length; i++) {
      //       if (this.moratoriumPeriod[i].item_name == this.selectedmoratoriumPeriod) {
      //         code = this.moratoriumPeriod[i].item_code;
      //         break;
      //       }
      //     }
      //     this.others["moratorium_period"] = ([{ "name": this.selectedmoratoriumPeriod, "code": code }]);
      //     console.log(this.others);
      //   }
      // });

      this.selectedMoratoriumTerm = this.change.getmoratoriumTerm();
      this.change.newMoratoriumTermSubject.subscribe((data) => {
        this.selectedMoratoriumTerm = data;
        if (this.selectedMoratoriumTerm != "") {
          var code;
          for (var i = 0; i < this.moratoriumTerm.length; i++) {
            if (this.moratoriumTerm[i].item_name == this.selectedMoratoriumTerm) {
              code = this.moratoriumTerm[i].item_code;
              break;
            }
          }
          this.others["moratorium_term"] = ([{ "name": this.selectedMoratoriumTerm, "code": code }]);
          console.log(this.others);
        }
      });
      this.change.setPartnerConfig(true);
      if (environment.savedProductMapping.length > 0) {

        if (environment.editPolicy == true) {
          this.selectedProductName = environment.savedProductMapping[0].partner_product_name;
          if (this.selectedProductName == "" || this.selectedProductName == undefined) {
            this.selectedProductName = environment.savedProductMapping[0].policy_name;
          }

          environment.selectedProduct_Id = environment.savedProductMapping[0].product_id;
          this.selectedProduct = environment.savedProductMapping[0].product_name;
          this.selectedLoan = environment.savedProductMapping[0].loan_type;
          this.selectedOtherLoanDetails = environment.savedProductMapping[0].other_loan_details;
          this.selectedChannel = environment.savedProductMapping[0].channel
          if (environment.groupLoanSecure) {
            this.selectedFloorRate = environment.savedProductMapping[0]['floor_rate']
          }
          else if (this.superProductCredit) {
            var savedJson = environment.savedProductBenefit
            this.selectedSchedule = environment.savedProductMapping[0].reduction_schedule_value
            this.selectedCoverageTerm = environment.savedProductMapping[0].coverage_term[0].name
            this.selectedChooseLife = environment.savedProductMapping[0].selected_life[0].name;
            this.selectedCoverageOption = environment.savedProductMapping[0].coverage_option[0].name;
            this.selectedCoverageTerm = environment.savedProductMapping[0].coverage_term[0].name;
            this.selectedInterestRate = environment.savedProductMapping[0].interest_rate[0].name;
            // this.selectedmoratoriumPeriod = environment.savedProductMapping[0].moratorium_period[0].name;
            this.selectedMoratoriumTerm = environment.savedProductMapping[0].moratorium_term[0].name;
            this.selectedreductionSchedule = environment.savedProductMapping[0].reduction_schedule;
            if (savedJson[0].benefits["TermWise"] != "" && savedJson[0].benefits["TermWise"] != undefined && this.selectedCoverageTerm == "") {
              this.checkTerms = true
            }
            this.change.setReductionSchedule(this.selectedreductionSchedule);
            this.change.setSelectedChannel(this.selectedChannel)
          }
          this.change.setSelectedProduct(this.selectedProduct);
          this.change.setSelectedLoan(this.selectedLoan);
          // this.change.setSelectedOtherLoanDetails(this.selectedOtherLoanDetails);
          var code
          // this.others = {
          //   "interest_rate": [{ "name": this.selectedInterestRate, "code": code }],
          //   "selected_life": [{ "name": this.selectedChooseLife, "code": code }],
          //   "coverage_option": [{ "name": this.selectedCoverageOption, "code": code }],
          //   "moratorium_period": [{ "name": this.selectedmoratoriumPeriod, "code": code }],
          //   "coverage_term": [{ "name": this.selectedCoverageTerm, "code": this.selectedCoverageTerm }],
          //   "moratorium_term": [{ "name": this.selectedMoratoriumTerm, "code": code }]
          // };
          var __self = this;
          __self.recallMasterData().then(function () {
            __self.loader.show()
            if (environment.superProductCredit) {
              __self.selectedChooseLife = environment.savedProductMapping[0].selected_life[0].name;
              __self.selectedCoverageOption = environment.savedProductMapping[0].coverage_option[0].name;
              __self.selectedCoverageTerm = environment.savedProductMapping[0].coverage_term[0].name;
              __self.selectedInterestRate = environment.savedProductMapping[0].interest_rate[0].name;
              // __self.selectedmoratoriumPeriod = environment.savedProductMapping[0].moratorium_period[0].name;
              __self.selectedMoratoriumTerm = environment.savedProductMapping[0].moratorium_term[0].name;
              __self.productData = environment.productData;
              __self.otherBenifitsList = __self.productData.item_json.benefits.other_items;
              var interestrate = __self.config.icici(__self.otherBenifitsList, "item_name", "Interest Rate");
              __self.interestRates = interestrate[0].sub_items;
              var coverageOptions = __self.config.icici(__self.otherBenifitsList, "item_name", "Coverage Option");
              __self.coverageOptions = coverageOptions[0].sub_items;
              var singleJointLife = __self.config.icici(__self.otherBenifitsList, "item_name", "Single/JointLife");
              __self.singleJointLife = singleJointLife[0].sub_items;
              // var moratoriumPeriod = __self.config.icici(__self.otherBenifitsList, "item_name", "Moratorium Period");
              // __self.moratoriumPeriod = moratoriumPeriod[0].sub_items;
              var moratoriumTerm = __self.config.icici(__self.otherBenifitsList, "item_name", "Moratorium Term");
              __self.moratoriumTerm = moratoriumTerm[0].sub_items;
              var coverageTerm = __self.config.icici(__self.otherBenifitsList, "item_name", "Coverage Term");
              __self.coverageTerm = coverageTerm[0].sub_items;
              __self.selectedreductionSchedule = environment.savedProductMapping[0].reduction_schedule;
              __self.change.setReductionSchedule(__self.selectedreductionSchedule);
              var index = environment.savedProductOtherBenefit.length - 1;

              if (__self.selectedInterestRate != "") {
                var code;
                for (var i = 0; i < __self.interestRates.length; i++) {
                  if (__self.interestRates[i].item_name == __self.selectedInterestRate) {
                    code = __self.interestRates[i].item_code;
                    break;
                  }
                }
                __self.others["interest_rate"] = ([{ "name": __self.selectedInterestRate, "code": code }]);
                console.log(__self.others);
              }

              if (__self.selectedCoverageTerm != "") {
                var parentCode;
                for (var i = 0; i < __self.otherBenifitsList.length; i++) {
                  if (__self.otherBenifitsList[i].item_name == "CoverageTerm") {
                    parentCode = __self.otherBenifitsList[i].item_code;
                    break;
                  }
                }
                __self.others["coverage_term"] = ([{ "name": __self.selectedCoverageTerm, "code": __self.selectedCoverageTerm }]);
                console.log(__self.others);
              } else {
                __self.others["coverage_term"] = ([{ "name": "", "code": "" }]);
              }


              if (__self.selectedCoverageOption != "") {
                var code;
                for (var i = 0; i < __self.coverageOptions.length; i++) {
                  if (__self.coverageOptions[i].item_name == __self.selectedCoverageOption) {
                    code = __self.coverageOptions[i].item_code;
                    break;
                  }
                }
                __self.others["coverage_option"] = ([{ "name": __self.selectedCoverageOption, "code": code }]);
                console.log(__self.others);
              }

              if (__self.selectedChooseLife != "") {
                var code;
                for (var i = 0; i < __self.singleJointLife.length; i++) {
                  if (__self.singleJointLife[i].item_name == __self.selectedChooseLife) {
                    code = __self.singleJointLife[i].item_code;
                    break;
                  }
                }
                __self.others["selected_life"] = ([{ "name": __self.selectedChooseLife, "code": code }]);
                console.log(__self.others)
              }

              // if (__self.selectedmoratoriumPeriod != "") {
              //   var code;
              //   for (var i = 0; i < __self.moratoriumPeriod.length; i++) {
              //     if (__self.moratoriumPeriod[i].item_name == __self.selectedmoratoriumPeriod) {
              //       code = __self.moratoriumPeriod[i].item_code;
              //       break;
              //     }
              //   }
              //   __self.others["moratorium_period"] = ([{ "name": __self.selectedmoratoriumPeriod, "code": code }]);
              //   console.log(__self.others);
              // }

              if (__self.selectedMoratoriumTerm != "") {
                var code;
                for (var i = 0; i < __self.moratoriumTerm.length; i++) {
                  if (__self.moratoriumTerm[i].item_name == __self.selectedMoratoriumTerm) {
                    code = __self.moratoriumTerm[i].item_code;
                    break;
                  }
                }
                __self.others["moratorium_term"] = ([{ "name": __self.selectedMoratoriumTerm, "code": code }]);
                console.log(__self.others);
              }
              __self.channel = [
                {
                  "item_name": "Direct",
                  "item_id": 1
                },
                {
                  "item_name": "Broker",
                  "item_id": 2
                },
                {
                  "item_name": "CA",
                  "item_id": 3
                }
              ];
            }
            else if (environment.shubhRakshaCredit) {
              __self.change.setEntityType(__self.selectedEntityType);
            }
            __self.loader.hide()
          });

        }
        else if (environment.superProductCredit && environment.editPolicy == false) {
          this.others = environment.savedProductOtherBenefit;
          this.productData = environment.productData;
          this.reductionSchedule = this.productData.item_json.reduction_schedule.items;
          this.otherBenifitsList = this.productData.item_json.benefits.other_items;
          //this.interestRates = this.productData.item_json.benefits.other_items[1].sub_items;
          var interestrate = this.config.icici(this.otherBenifitsList, "item_name", "Interest Rate");
          this.interestRates = interestrate[0].sub_items;
          var coverageOptions = this.config.icici(this.otherBenifitsList, "item_name", "Coverage Option");
          this.coverageOptions = coverageOptions[0].sub_items;
          var singleJointLife = this.config.icici(this.otherBenifitsList, "item_name", "Single/JointLife");
          this.singleJointLife = singleJointLife[0].sub_items;
          // var moratoriumPeriod = this.config.icici(this.otherBenifitsList, "item_name", "Moratorium Period");
          // this.moratoriumPeriod = moratoriumPeriod[0].sub_items;
          var moratoriumTerm = this.config.icici(this.otherBenifitsList, "item_name", "Moratorium Term");
          this.moratoriumTerm = moratoriumTerm[0].sub_items;
          var coverageTerm = this.config.icici(this.otherBenifitsList, "item_name", "Coverage Term");
          this.coverageTerm = coverageTerm[0].sub_items;
          // if (this.others.hasOwnProperty('coverage_term')) {
          //   if (this.others["coverage_term"][0].name != "") {
          //     setTimeout(function () {
          //       document.querySelector('.checkbox').classList.add('checked');
          //       document.querySelector('.checkbox').parentElement.lastElementChild.classList.remove('disabled');
          //     }, 10)
          //   }
          // }
        }
        this.selectedProduct = environment.savedProductMapping[0].product_name;
        this.selectedLoan = environment.savedProductMapping[0].loan_type;
        this.selectedOtherLoanDetails = environment.savedProductMapping[0].other_loan_details;
        this.selectedProductName = environment.savedProductMapping[0].policy_name;
        this.selectedMinimumGroup = environment.savedProductMapping[0].minimum_group;
        this.selectedSchedule = environment.savedProductMapping[0].reduction_schedule_value
        this.selectedFloorRate = environment.savedProductMapping[0]['floor_rate']
      }
      this.selectedProduct = this.change.getSelectedProduct();
      this.change.newselectedProductSubject.subscribe((data) => {

        this.selectedProduct = data;
        if (this.selectedProduct != "") {
          // this.loader.show();
          this.callMasterData();
        }

      });
      this.selectedChannel = this.change.getSelectedChannel();
      this.change.newselectedChannelSubject.subscribe((data) => {
        this.selectedChannel = data;
        console.log(this.selectedChannel);
      });
      this.selectedLoan = this.change.getSelectedLoan();
      // this.selectedOtherLoanDetails = this.change.getSelectedOtherLoanDetails();
      this.change.newselectedLoanSubject.subscribe((data) => {
        this.selectedLoan = data;
        console.log(this.selectedLoan);
      });
      // this.change.newSelectedOtherLoanDetails.subscribe((data) => {
      //   this.selectedOtherLoanDetails = data;
      //   console.log(this.selectedOtherLoanDetails);
      // });
      if (environment.superProductCredit) {
        this.selectedreductionSchedule = this.change.getReductionSchedule();
        this.change.newselectedReductionSchedule.subscribe((data) => {
          this.selectedreductionSchedule = data;
          console.log(this.selectedreductionSchedule);
        });
        var savedJson = environment.savedProductBenefit
        if (savedJson[0].benefits["TermWise"] != "" && savedJson[0].benefits["TermWise"] != undefined && this.selectedCoverageTerm == "") {
          this.checkTerms = true
        }
      } else if (environment.shubhRakshaCredit) {
        this.selectedEntityType = this.change.getEntityType();
        this.change.newselectedEntityType.subscribe((data) => {
          this.selectedEntityType = data;
          console.log(this.selectedEntityType);
        });
      }

      if (environment.savedProductMapping.length == 0) {
        this.selectedProduct = "";
        this.selectedProductName = "";
        this.selectedLoan = "";
        this.selectedMinimumGroup = "";
        this.selectedCoverageOption = "";
        this.selectedChooseLife = "";
        this.selectedCoverageTerm = ""
        this.selectedSchedule = "";
        this.selectedreductionSchedule = "";
        this.selectedreductionScheduleCode = "";
        this.selectedChannel = "";
        // this.selectedmoratoriumPeriod = "";
        this.selectedMoratoriumTerm = "";
        this.selectedMoratoriumTermValue = "";
        this.selectedInterestRate = "";
        this.selectedOtherLoanDetails = "";
      }

    }

    else {
      this.routes.navigate(["login"]);
    }

  }

  recallMasterData() {
    var url = "/service/products/" + environment.selectedProduct_Id;

    function storeOtherLoanDetails(event) {
      this.selectedOtherLoanDetails = event.target.value;
    }
    const promise = new Promise((resolve, reject) => {
      this.config.httpService("get", url, "").subscribe(
        res => {
          var data;
          data = res;
          if (this.selectedProduct == "Group Loan Secure") {
            environment.glSData = data;
            environment.productData = data;
            environment.fixedData = environment.glsFixedData
            var glsData: any;
            glsData = environment.productData;
            environment.groupLoanSecure = true;
            environment.shubhRakshaCredit = false;
            environment.superProductCredit = false;
            this.groupLoanSecure = true;
            this.superProductCredit = false;
            this.shubhRakshaCredit = false;
            this.loanType = glsData.item_json.loan_types
            // this.loader.hide();
          }
          else if (this.selectedProduct == "Shubh Raksha One") {
            environment.sroData = data;
            environment.productData = data;
            environment.groupLoanSecure = true;
            environment.shubhRakshaCredit = false;
            environment.superProductCredit = false;
            this.groupLoanSecure = true;
            this.superProductCredit = false;
            this.shubhRakshaCredit = false;

            // this.loader.hide();
          }
          else if (this.selectedProduct == "Shubh Raksha Life") {
            environment.srlData = data;
            environment.productData = data;
            environment.groupLoanSecure = true;
            environment.shubhRakshaCredit = false;
            environment.superProductCredit = false;
            this.groupLoanSecure = true;
            this.superProductCredit = false;
            this.shubhRakshaCredit = false;

            // this.loader.hide();
          }
          else if (this.selectedProduct == "Shubh Raksha Credit") {
            environment.srcData = data;
            environment.productData = data;
            var srcData: any;
            srcData = environment.productData;
            this.entityTypes = srcData.item_json.entity_type.items;
            environment.groupLoanSecure = false;
            environment.superProductCredit = false;
            environment.shubhRakshaCredit = true;
            this.groupLoanSecure = false;
            this.superProductCredit = false;
            this.shubhRakshaCredit = true;

            // this.loader.hide();

          }
          else if (this.selectedProduct == "Super Protect Credit") {
            environment.spcData = data;
            environment.productData = data;
            environment.fixedData = environment.spcFixedData
            var spcData: any;
            spcData = environment.productData;
            this.reductionSchedule = spcData.item_json.reduction_schedule.items;
            this.productData = environment.productData;
            this.loanType = spcData.item_json.loan_types
            this.otherBenifitsList = this.productData.item_json.benefits.other_items;
            //this.interestRates = this.productData.item_json.benefits.other_items[1].sub_items;
            var interestrate = this.config.icici(this.otherBenifitsList, "item_name", "Interest Rate");
            this.interestRates = interestrate[0].sub_items;
            var coverageOptions = this.config.icici(this.otherBenifitsList, "item_name", "Coverage Option");
            this.coverageOptions = coverageOptions[0].sub_items;
            var singleJointLife = this.config.icici(this.otherBenifitsList, "item_name", "Single/JointLife");
            this.singleJointLife = singleJointLife[0].sub_items;
            // var moratoriumPeriod = this.config.icici(this.otherBenifitsList, "item_name", "Moratorium Period");
            // this.moratoriumPeriod = moratoriumPeriod[0].sub_items;
            var moratoriumTerm = this.config.icici(this.otherBenifitsList, "item_name", "Moratorium Term");
            this.moratoriumTerm = moratoriumTerm[0].sub_items;
            var coverageTerm = this.config.icici(this.otherBenifitsList, "item_name", "Coverage Term");
            this.coverageTerm = coverageTerm[0].sub_items;
            var code;
            this.others = {
              "interest_rate": [{ "name": this.selectedInterestRate, "code": code }],
              "selected_life": [{ "name": this.selectedChooseLife, "code": code }],
              "coverage_option": [{ "name": this.selectedCoverageOption, "code": code }],
              // "moratorium_period": [{ "name": this.selectedmoratoriumPeriod, "code": code }],
              "coverage_term": [{ "name": this.selectedCoverageTerm, "code": this.selectedCoverageTerm }],
              "moratorium_term": [{ "name": this.selectedMoratoriumTerm, "code": code }]
            };
            environment.groupLoanSecure = false;
            environment.superProductCredit = true;
            environment.shubhRakshaCredit = false;
            this.groupLoanSecure = false;
            this.superProductCredit = true;
            this.shubhRakshaCredit = false;
            // this.loader.hide();
          }
          else if (this.selectedProduct == "Super Protect Life") {
            environment.splData = data;
            environment.productData = data;
            environment.groupLoanSecure = true;
            environment.shubhRakshaCredit = false;
            environment.superProductCredit = false;
            this.groupLoanSecure = true;
            this.superProductCredit = false;
            this.shubhRakshaCredit = false;
            // this.loader.hide();
          }
          else if (this.selectedProduct == "Super Protect OYRT") {
            environment.spoData = data;
            environment.productData = data;
            environment.groupLoanSecure = true;
            environment.shubhRakshaCredit = false;
            environment.superProductCredit = false;
            this.groupLoanSecure = true;
            this.superProductCredit = false;
            this.shubhRakshaCredit = false;
            // this.loader.hide();
          }

          //to reset the data
          if (environment.savedProductMapping.length > 0 && environment.savedProductMapping[0].product_name != this.selectedProduct) {
            environment.savedProductMapping = [];
            environment.savedProductBenefit = [];
            for (let el of Array.from(document.querySelectorAll('input'))) {
              el.value = '';
            }
          }
          //end

          var url = "/service/partner/" + this.partnerDetails[0].partner_id + "/configured";
          this.config.httpService("get", url, "").subscribe(
            res => {
              console.log(res["loanTypes"]);
              var data = [];
              data = res["policies"];
              this.configuredPolicys = data;
            },
            err => {
              console.log(err);
            }
          )
          return resolve();
        },
        err => {
          console.log("err");
          // this.loader.hide();
          return reject();
        }
      )
    })
    return promise;
  }

  callMasterData() {
    var url = "/service/products/" + environment.selectedProduct_Id;
    function storeOtherLoanDetails(event) {
      this.selectedOtherLoanDetails = event.target.value;
    }
    this.config.httpService("get", url, "").subscribe(
      res => {
        var data;
        data = res;
        if (this.selectedProduct == "Group Loan Secure") {
          environment.glSData = data;
          environment.productData = data;
          var glsData: any;
          glsData = environment.productData;
          environment.groupLoanSecure = true;
          environment.shubhRakshaCredit = false;
          environment.superProductCredit = false;
          this.groupLoanSecure = true;
          this.superProductCredit = false;
          this.shubhRakshaCredit = false;
          this.loanType = glsData.item_json.loan_types
          environment.fixedData = environment.glsFixedData
          // this.loader.hide();
        }
        else if (this.selectedProduct == "Shubh Raksha One") {
          environment.sroData = data;
          environment.productData = data;
          environment.groupLoanSecure = true;
          environment.shubhRakshaCredit = false;
          environment.superProductCredit = false;
          this.groupLoanSecure = true;
          this.superProductCredit = false;
          this.shubhRakshaCredit = false;
          // this.loader.hide();
        }
        else if (this.selectedProduct == "Shubh Raksha Life") {
          environment.srlData = data;
          environment.productData = data;
          environment.groupLoanSecure = true;
          environment.shubhRakshaCredit = false;
          environment.superProductCredit = false;
          this.groupLoanSecure = true;
          this.superProductCredit = false;
          this.shubhRakshaCredit = false;
          // this.loader.hide();
        }
        else if (this.selectedProduct == "Shubh Raksha Credit") {
          environment.srcData = data;
          environment.productData = data;
          var srcData: any;
          srcData = environment.productData;
          this.entityTypes = srcData.item_json.entity_type.items;
          environment.groupLoanSecure = false;
          environment.superProductCredit = false;
          environment.shubhRakshaCredit = true;
          this.groupLoanSecure = false;
          this.superProductCredit = false;
          this.shubhRakshaCredit = true;
          // this.loader.hide();

        }
        else if (this.selectedProduct == "Super Protect Credit") {
          environment.spcData = data;
          environment.productData = data;
          environment.fixedData = environment.spcFixedData
          var spcData: any;
          spcData = environment.productData;
          this.reductionSchedule = spcData.item_json.reduction_schedule.items;
          this.productData = environment.productData;
          this.loanType = data.item_json.loan_types
          this.otherBenifitsList = this.productData.item_json.benefits.other_items;
          //this.interestRates = this.productData.item_json.benefits.other_items[1].sub_items;
          var interestrate = this.config.icici(this.otherBenifitsList, "item_name", "Interest Rate");
          this.interestRates = interestrate[0].sub_items;
          var coverageOptions = this.config.icici(this.otherBenifitsList, "item_name", "Coverage Option");
          this.coverageOptions = coverageOptions[0].sub_items;
          var singleJointLife = this.config.icici(this.otherBenifitsList, "item_name", "Single/JointLife");
          this.singleJointLife = singleJointLife[0].sub_items;
          // var moratoriumPeriod = this.config.icici(this.otherBenifitsList, "item_name", "Moratorium Period");
          // this.moratoriumPeriod = moratoriumPeriod[0].sub_items;
          var moratoriumTerm = this.config.icici(this.otherBenifitsList, "item_name", "Moratorium Term");
          this.moratoriumTerm = moratoriumTerm[0].sub_items;
          var coverageTerm = this.config.icici(this.otherBenifitsList, "item_name", "Coverage Term");
          this.coverageTerm = coverageTerm[0].sub_items;
          var code;
          this.others = {
            "interest_rate": [{ "name": this.selectedInterestRate, "code": code }],
            "selected_life": [{ "name": this.selectedChooseLife, "code": code }],
            "coverage_option": [{ "name": this.selectedCoverageOption, "code": code }],
            // "moratorium_period": [{ "name": this.selectedmoratoriumPeriod, "code": code }],
            "coverage_term": [{ "name": this.selectedCoverageTerm, "code": this.selectedCoverageTerm }],
            "moratorium_term": [{ "name": this.selectedMoratoriumTerm, "code": code }]
          };
          environment.groupLoanSecure = false;
          environment.superProductCredit = true;
          environment.shubhRakshaCredit = false;
          this.groupLoanSecure = false;
          this.superProductCredit = true;
          this.shubhRakshaCredit = false;
          // this.loader.hide();
        }
        else if (this.selectedProduct == "Super Protect Life") {
          environment.splData = data;
          environment.productData = data;
          environment.groupLoanSecure = true;
          environment.shubhRakshaCredit = false;
          environment.superProductCredit = false;
          this.groupLoanSecure = true;
          this.superProductCredit = false;
          this.shubhRakshaCredit = false;
          // this.loader.hide();
        }
        else if (this.selectedProduct == "Super Protect OYRT") {
          environment.spoData = data;
          environment.productData = data;
          environment.groupLoanSecure = true;
          environment.shubhRakshaCredit = false;
          environment.superProductCredit = false;
          this.groupLoanSecure = true;
          this.superProductCredit = false;
          this.shubhRakshaCredit = false;
          // this.loader.hide();
        }

        //to reset the data
        if (environment.savedProductMapping.length > 0 && environment.savedProductMapping[0].product_name != this.selectedProduct) {
          environment.savedProductMapping = [];
          environment.savedProductBenefit = [];
          for (let el of Array.from(document.querySelectorAll('input'))) {
            el.value = '';
          }
        }
        //end

        var url = "/service/partner/" + this.partnerDetails[0].partner_id + "/configured";
        this.config.httpService("get", url, "").subscribe(
          res => {
            console.log(res["loanTypes"]);
            var data = [];
            data = res["policies"];
            this.configuredPolicys = data;
          },
          err => {
            console.log(err);
          }
        )

      },
      err => {
        console.log("err");
        // this.loader.hide();
      }
    )
  }

  checknumber(e) {
    var re = /^[0-9\ _.-]+$/;
    var str = e.target.value;
    if (e.keyCode != 45 && (e.keyCode < 48 || e.keyCode > 57)) {
      return false;
    }
  }
  ngOnInit() {
  }

  ngAfterViewInit() {
    // this.loader.show();
    var __self = this;
    setInterval(function () {
      __self.el.nativeElement.querySelectorAll('input').forEach(function (input) {
        input.onpaste = function (e) {
          e.preventDefault();
        }
      })
    }, 500)

    setTimeout(function () {
      __self.loader.hide();
    }, 1000)
    // if (this.selectedCoverageTerm != "") {
    //   var tt = this.el.nativeElement.querySelector('.checkbox.textbox');
    //   tt.classList.add('checked');
    //   tt.parentElement.lastElementChild.classList.remove('disabled');
    //   this.change.setCoverageTerm(this.selectedCoverageTerm);
    // }

  }

  checkedInput(event) {
    var target;
    console.log(event.target);
    target = (event.target.classList.contains('checkbox')) ? event.target : this.app.getAncestorByClassName(event.target, 'checkbox');
    if (event.target.nodeName != 'INPUT' && event.target.nodeName != 'FIELDSET')
      if (target.classList.contains('checked')) {
        target.classList.remove('checked');
        target.nextElementSibling.classList.add('disabled');
      }
      else {
        target.classList.add('checked');
        target.nextElementSibling.classList.remove('disabled');
      }
  }

  storeValue(event) {
    if (event.target.closest('span').attributes.id.value == 'productNameMapping') {
      if (this.configuredPolicys.length > 0 && event.target.value != this.selectedProductName) {
        for (var i = 0; i < this.configuredPolicys.length; i++) {
          if (this.configuredPolicys[i].toLowerCase().trim() == event.target.value.toLowerCase().trim()) {
            event.target.value = "";
            Swal({
              title: "This product name is already configured. Please use a different name",
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#eb1826',
              cancelButtonColor: '#F4F1F1',
              confirmButtonText: 'OK'
            }).then((result) => {

            })

          }
          else {
            this.selectedProductName = event.target.value;
          }
        }

      }
      else {
        this.selectedProductName = event.target.value;
      }
    }
    else if (event.target.closest('span').attributes.id.value == 'minimumGroupMapping') {
      this.selectedMinimumGroup = event.target.value;
      if ((event.target.value > 50 || event.target.value < 20) && event.target.value != "") {
        Swal({
          title: "Please enter value between 20 to 50",
          type: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#eb1826',
          cancelButtonColor: '#F4F1F1',
          confirmButtonText: 'OK'
        }).then((result) => {
          event.target.value = "";
          this.selectedMinimumGroup = "";
        })
      }
    }
    else if (event.target.closest('span').attributes.id.value == 'otherLoanMapping') {
      this.selectedOtherLoanDetails = event.target.value;
      if (event.target.value == "") {
        Swal({
          title: "Please enter value",
          type: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#eb1826',
          cancelButtonColor: '#F4F1F1',
          confirmButtonText: 'OK'
        }).then((result) => {
          event.target.value = "";
          this.selectedOtherLoanDetails = "";
        })
      }
    }
    else if (event.target.closest("span").attributes.id.value == "coverageTerm") {
      this.selectedCoverageTerm = event.target.value;
      if (this.selectedCoverageTerm > environment.fixedData[0]["max_coverage_term"] ||
        this.selectedCoverageTerm < environment.fixedData[0]["min_coverage_term"]) {
        swal({
          title: 'Please enter coverage term between ' + environment.fixedData[0]["min_coverage_term"] + ' to ' + environment.fixedData[0]["max_coverage_term"],
          type: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#eb1826',
          cancelButtonColor: '#F4F1F1',
          confirmButtonText: 'OK'
        }).then((result) => {
          event.target.value = "";
          this.selectedCoverageTerm = "";
        })
      }
      this.others["coverage_term"] = ([{ "name": this.selectedCoverageTerm, "code": this.selectedCoverageTerm }]);
      this.change.setCoverageTerm(this.selectedCoverageTerm);
    }
    else if (event.target.closest("span").attributes.id.value == "scheduleMapping") {
      this.selectedSchedule = event.target.value;
      this.change.setCoverageTerm(this.selectedCoverageTerm);
    } else if (event.target.closest("span").attributes.id.value == "floorRateMapping") {
      this.selectedFloorRate = event.target.value
      var floor_rate = parseInt(event.target.value);
      if (floor_rate > environment.fixedData[0]["max_mortality_rate"]) {
        swal({
          title: 'Please enter mortality rate between 1 to ' + environment.fixedData[0]["max_mortality_rate"],
          type: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#eb1826',
          cancelButtonColor: '#F4F1F1',
          confirmButtonText: 'OK'
        }).then((result) => {
          event.target.value = "";
          this.selectedFloorRate = "";
        })
      }
    }
    else if (event.target.closest("span").attributes.id.value == "masterCode") {
      //this.selectedMasterCode = event.target.value;
      if (this.configuredPolicys.length > 0 && event.target.value != this.selectedMasterCode) {
        for (var i = 0; i < this.configuredPolicys.length; i++) {
          if (this.configuredPolicys[i].toLowerCase().trim() == event.target.value.toLowerCase().trim()) {
            event.target.value = "";
            Swal({
              title: "This Master Code is already configured. Please use a different code",
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#eb1826',
              cancelButtonColor: '#F4F1F1',
              confirmButtonText: 'OK'
            }).then((result) => {

            })

          }
          else {
            this.selectedMasterCode = event.target.value;
          }
        }

      }
      else {
        this.selectedMasterCode = event.target.value;
      }
    }
  }

  saveDataOnNext() {
    if (this.goNext == true) {
      var count = 0;
      // var inputs = this.el.nativeElement.querySelectorAll('input:not(.disabled)');
      var inputs = this.el.nativeElement.querySelectorAll('div.product-column:not(.optional) input:not(.disabled)');
      var dropdowns = this.el.nativeElement.querySelectorAll('.dropdown:not(.disabled)')
      this.el.nativeElement.querySelectorAll('div.product-column:not(.optional) input:not(.disabled)').forEach(function (item) {
        console.log(item.value);
        if (item.value.trim() == "") {
          var ss = item.closest("fieldset");
          ss.firstElementChild['style'].display = "block";
          item.closest("fieldset").classList.add("error");
          console.log("Blank");
          swal({
            title: 'Please Fill all the details',
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#eb1826',
            cancelButtonColor: '#F4F1F1',
            confirmButtonText: 'OK'
          }).then((result) => {
            // event.target.value = "";
            // this.selectedFloorRate = "";
          })
        }
        else {
          var ss = item.closest("fieldset");
          ss.firstElementChild['style'].display = "none";
          item.closest("fieldset").classList.remove("error");
          count++;
        }
      });
      this.el.nativeElement.querySelectorAll('.dropdown:not(.disabled)').forEach(function (item) {
        if (item.querySelector('.text.default-dropdown')) {
          item.parentNode.classList.add("error");
          var ss = item.parentNode;
          ss.firstElementChild['style'].display = "block";
        }
        else {
          var ss = item.parentNode;
          item.parentNode.classList.remove("error");
          ss.firstElementChild['style'].display = "none";
          count++;
        }

      });
      if (count == inputs.length + dropdowns.length) {
        // if (count > 3) {
        environment.maxCoverageTerm = 30
        environment.minAge = environment.fixedData[0].min_age_at_entry
        environment.maxAge = environment.fixedData[0].max_age_at_entry
        environment.minCoverCeasing = environment.fixedData[0].max_cover_ceasing_age
        environment.maxCoverCeasing = environment.fixedData[0].min_cover_ceasing_age
        environment.minSumAssured = environment.fixedData[0].min_sum_assured
        if (environment.groupLoanSecure) {
          if(this.selectedLoan == "Other Loan"){
            this.saveData.push({
              "partner_id": environment.partnerDetails[0].partner_id, "partner_name": environment.partnerDetails[0].partner_name,
              "product_name": this.selectedProduct, "product_id": environment.selectedProduct_Id, 
              "loan_type": this.selectedLoan,
              "policy_name": this.selectedProductName, "minimum_group": this.selectedMinimumGroup,
              "floor_rate": this.selectedFloorRate,
              "other_loan_details":this.selectedOtherLoanDetails
            });
          }
          else{
            this.saveData.push({
              "partner_id": environment.partnerDetails[0].partner_id, "partner_name": environment.partnerDetails[0].partner_name,
              "product_name": this.selectedProduct, "product_id": environment.selectedProduct_Id, "loan_type": this.selectedLoan,
              "policy_name": this.selectedProductName, "minimum_group": this.selectedMinimumGroup, "floor_rate": this.selectedFloorRate
            });
          }
          environment.maxCoverageTerm = 30
        } else if (environment.superProductCredit) {
          if (this.selectedCoverageOption != 'Reducing with Moratorium: 1-7 years') {
            this.others["moratorium_term"] = ([{ "name": "", "code": "" }]);
          }
          if (this.selectedCoverageOption == 'Level') {
            this.others["moratorium_period"] = ([{ "name": "", "code": "" }]);
          } else if (this.selectedCoverageOption == 'Reducing') {
            this.others["moratorium_period"] = ([{ "name": "No", "code": "N" }]);
          } else if (this.selectedCoverageOption == 'Reducing with Moratorium: 1-7 years') {
            this.others["moratorium_period"] = ([{ "name": "Yes", "code": "Y" }]);
          }
          if (this.selectedreductionSchedule != "Other") {
            this.selectedSchedule = ""
          }
          var index = this.findIndexValue(this.reductionSchedule, "item_code", environment.selectedReductionScheduleCode);
          if (index != null) {
            this.selectedreductionSchedule = this.reductionSchedule[index]['item_name'];
          }
          var index = this.findIndexValue(this.reductionSchedule, "item_name", this.selectedreductionSchedule);
          if (index != null) {
            environment.selectedReductionScheduleCode = this.reductionSchedule[index]['item_code'];
          }
          environment.reduction_sched = this.selectedreductionSchedule;
          if(this.selectedLoan == "Other Loan"){
            this.saveData.push({
              "partner_id": environment.partnerDetails[0].partner_id, "partner_name": environment.partnerDetails[0].partner_name,
              "product_name": this.selectedProduct, "product_id": environment.selectedProduct_Id, "loan_type": this.selectedLoan,
              "policy_name": this.selectedProductName, "minimum_group": this.selectedMinimumGroup,"other_loan_details":this.selectedOtherLoanDetails,
              "reduction_schedule": this.selectedreductionSchedule,
              "reduction_schedule_id": environment.selectedReductionScheduleCode,
              "reduction_schedule_value": this.selectedSchedule,
              "selected_life": this.others["selected_life"], "moratorium_period": this.others["moratorium_period"],
              "interest_rate": this.others["interest_rate"], "coverage_option": this.others["coverage_option"],
              "coverage_term": this.others["coverage_term"],  
              "moratorium_term": this.others["moratorium_term"], "channel": this.selectedChannel
            });
          }
          else{
          this.saveData.push({
            "partner_id": environment.partnerDetails[0].partner_id, "partner_name": environment.partnerDetails[0].partner_name,
            "product_name": this.selectedProduct, "product_id": environment.selectedProduct_Id, "loan_type": this.selectedLoan,
            "policy_name": this.selectedProductName, "minimum_group": this.selectedMinimumGroup,
            "master_code": this.selectedMasterCode,
            "reduction_schedule": this.selectedreductionSchedule,
            "reduction_schedule_id": environment.selectedReductionScheduleCode,
            "reduction_schedule_value": this.selectedSchedule,
            "selected_life": this.others["selected_life"], "moratorium_period": this.others["moratorium_period"],
            "interest_rate": this.others["interest_rate"], "coverage_option": this.others["coverage_option"],
            "coverage_term": this.others["coverage_term"],
            "moratorium_term": this.others["moratorium_term"], "channel": this.selectedChannel
          });
          }
          if (this.others["coverage_term"][0].name != "") {
            environment.fixedData[0]["max_policy_term"] = parseInt(this.others["coverage_term"][0].name)
            environment.maxCoverageTerm = parseInt(this.others["coverage_term"][0].name)
            environment.dbMaxTerm = parseInt(this.others["coverage_term"][0].name)
          } else {
            environment.fixedData[0]["max_policy_term"] = 30
            environment.maxCoverageTerm = 30
          }
        }
        else if (environment.shubhRakshaCredit) {

          this.saveData.push({
            "partner_id": environment.partnerDetails[0].partner_id, "partner_name": environment.partnerDetails[0].partner_name,
            "product_name": this.selectedProduct, "product_id": environment.selectedProduct_Id, "loan_type": this.selectedLoan,
            "policy_name": this.selectedProductName, "minimum_group": this.selectedMinimumGroup,
            "entity_type": environment.selectedEntityId, "entity_type_id": environment.selectedEntityName
          });
        }
        console.log(this.saveData);
        environment.savedProductMapping = this.saveData;
        environment.savedProductOtherBenefit = this.others;
        console.log(environment.savedProductMapping);
        this.processFuther = false;
        this.routes.navigate(['product-benefits']);

      }
    }
  }

  findIndexValue(arraytosearch, key, valuetosearch) {

    for (var i = 0; i < arraytosearch.length; i++) {

      if (arraytosearch[i][key] == valuetosearch) {
        return i;
      }
    }
    return null;
  }

  checkedInputDropDown(event) {
    var target;
    var code;

    var dropDownCheckbox = this.el.nativeElement.querySelectorAll(".checkboxLegend");
    console.log(event.target.value);
    var index;
    target = (event.target.classList.contains('checkboxLabel')) ? event.target : this.app.getAncestorByClassName(event.target, 'checkboxLabel');
    if (event.target.nodeName != 'INPUT' && event.target.nodeName != 'FIELDSET' && event.target.nodeName != 'SPAN' && event.target.nodeName != 'IMG' && event.target.nodeName != 'DIV')
      if (target.parentElement.classList.contains('checked')) {
        target.parentElement.classList.remove('checked');
        target.parentElement.nextElementSibling.classList.add('disabled');
        if (event.target.childNodes[0].data == "Coverage Term") {
          index = this.findIndexValue(this.others, "name", event.target.childNodes[0].data);
          event.target.parentElement.parentElement.lastElementChild.value = ""
          this.selectedCoverageTerm = "";
          this.others["coverage_term"] = ([{ "name": "", "code": "" }]);
          var savedJson = environment.savedProductBenefit
          if (savedJson[0].benefits["TermWise"] != "" && savedJson[0].benefits["TermWise"] != undefined && this.selectedCoverageTerm == "") {
            this.checkTerms = true
          }
          this.change.setCoverageTerm(this.selectedCoverageTerm);
        }
      }
      else {
        if (event.target.childNodes[0].data == "Coverage Term") {
          this.change.setCoverageTerm(this.selectedCoverageTerm);
        }
        target.parentElement.classList.add('checked');
        target.parentElement.nextElementSibling.classList.remove('disabled');
      }
  }

}

import { Component, NgModule, ElementRef, NgZone, OnInit, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import { RouterModule, Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { DragScrollModule } from 'ngx-drag-scroll';
import { DragScrollComponent } from 'ngx-drag-scroll';
import { ConfigService } from '../../services/config.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { DataShareService } from '../../services/data-share.service';
import SwalLib from 'sweetalert2';
import Swal from 'sweetalert2';
//let Swals: any = require("sweetalert2");
import { HttpHeaders } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';

@NgModule({
  imports: [
    // ... other imports
    DragScrollModule,
  ],
  declarations: [
    // ... other declarations
    DragScrollComponent,
  ],
})

@Component({
  selector: 'app-assign-fsc',
  templateUrl: './assign-fsc.component.html',
  styleUrls: ['./assign-fsc.component.scss']
})
export class AssignFscComponent implements OnInit {
  @ViewChild('nav', { read: DragScrollComponent }) ds: DragScrollComponent;
  
  b2cModal;
  partnerName: string = "";
  partnerAddress: string = "";
  fileData;
  partnerLogo;
  partnerLoginId;
  partnerLoginPwd;
  partnerLoginCPwd;
  fileName = "assets/images/add_icon.svg";
  id;
  errMsg = "";
  editDetails = false;
  configEmail = "";
  configPassword = "";
  uniquePartner = true;
  currName = "";
  currId = "";
  partnerId = 0;
  click = false;
  http: any;
  products: any[] = [];
  selectedFsc: any[] = [];
  partnerEmail: any;
  product_id: any ;
  partner_id: any;
  user_id: any;
  selectedIds: string[] = []; 
  // renderHtml = false;
  @ViewChild("b2cModal") set content(content: ElementRef) {
    this.b2cModal = content;
  }
  
  //@ViewChild("b2cModal") ModalElementRef: ElementRef;
  constructor(private routes: Router, public config: ConfigService, public loader: Ng4LoadingSpinnerService, public route: ActivatedRoute,
    public el: ElementRef, public cdr: ChangeDetectorRef, public change: DataShareService) {
    if (environment.loginSuccessfully == true) {
      this.change.setPartnerConfig(false);
      if (environment.editDetails == true) {
        this.editDetails = environment.editDetails;
        if (environment.currentPartnerDetails.length > 0) {
          console.log(environment.currentPartnerDetails);
          this.fileName = environment.currentPartnerDetails[0].partner_logo;
          this.partnerName = environment.currentPartnerDetails[0].partner_name;
          this.partnerAddress = environment.currentPartnerDetails[0].partner_address;
          this.partnerId = environment.currentPartnerDetails[0].partner_id;
          this.configPassword = environment.currentPartnerDetails[0].b2cPassword;
          this.configEmail = environment.currentPartnerDetails[0].b2cEmail;
          this.currName = this.partnerName;
          this.currId = this.configEmail
        }
      }
    } else {
      this.routes.navigate(["login"]);
    }


  }
  ngAfterViewInit() {
    if (environment.editDetails == true) {
      const substring = "add_icon.svg";
      if (this.fileName.includes(substring) == false) {
        var imgDiv = document.querySelectorAll(".blue-back.circle");
        imgDiv[0].classList.remove("blue-back", "circle");
        imgDiv[0].firstElementChild.classList.add("fullWidth");
      }
    }
  }
  // ngOnInit(): void {
  //   //debugger
    
  //   this.route.queryParams.subscribe(params => {
  //     this.product_id = JSON.parse(params['product_id']);
  //     this.partner_id = JSON.parse(params['partner_id']);
  //   });
  //   var url = "/service/fsc/all/";
  //   this.config.httpService("get", url, "").subscribe(
  //     res => {
  //       var data;
  //       data = res;
  //       this.products = data;
  //     },
  //     err => {
  //       console.log(err);
  //     }
  //   );

  //   var url = "/service/fsc/byPartnerAndProduct?productId=" + this.product_id + "&partnerId=" + this.partner_id;
  //   var data ={};
  //   this.config.httpService("get", url, data).subscribe(
  //     res => {
  //       var data;
  //       data = res;
  //       this.selectedIds = data;
  //    var dataInnum = data.map((num=>Number(num)))
  //    this.products = this.products.map(elm=>{
  //       if(dataInnum.includes(elm.partner_id)){
  //         elm["isSelected"] = true;
  //       }
  //     return elm
  //     })
  //     },
  //     err => {
  //       console.log(err);
  //     }
  //   );
  // }
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.product_id = JSON.parse(params['product_id']);
      this.partner_id = JSON.parse(params['partner_id']);
    });
  
    const getAllFscUrl = "/service/fsc/all/";
    this.config.httpService("get", getAllFscUrl, "").pipe(
      switchMap((allFscData: any) => {
        this.products = allFscData;
  
        const getByPartnerAndProductUrl = "/service/fsc/byPartnerAndProduct?productId=" + this.product_id + "&partnerId=" + this.partner_id;
        return this.config.httpService("get", getByPartnerAndProductUrl, {});
      })
    // ).subscribe(
    //   (selectedIdsData: any) => {
    //     this.selectedIds = selectedIdsData;
    //     const selectedIdsInnum = this.selectedIds.map(num => Number(num));
    //     this.products = this.products.map(elm => {
    //       if (selectedIdsInnum.includes(elm.partner_id)) {
    //         elm["isSelected"] = true;
    //       }
    //       return elm;
    //     });
    //   },
    //   err => {
    //     console.log(err);
    //   }
    // );
      ).subscribe(
        (selectedIdsData: any) => {
          this.selectedIds = selectedIdsData;
    
          // Convert string IDs to numbers
          const selectedIdsInnum = this.selectedIds.map(num => Number(num));
    
          this.products = this.products.map(elm => {
            // Check if the ID is present in the selected IDs array
            elm["isSelected"] = selectedIdsInnum.includes(Number(elm[0]));
            return elm;
          });
        },
        err => {
          console.log(err);
        }
      );
  }
  
  onCheckboxChange(partnerId: string, event: any) {
    if (event.target.checked) {
      // Checkbox is checked, add the partnerId to selectedIds
      this.selectedIds.push(partnerId);
    } else {
      // Checkbox is unchecked, remove the partnerId from selectedIds
      this.selectedIds = this.selectedIds.filter(id => id !== partnerId);
    }
  }  

  onCheckboxChangeForall(fsc: any, event: any) {
    const checkboxes = document.querySelectorAll(".checkbox_patner_table") as NodeListOf<HTMLInputElement>;
  
    if (event.target.checked) {
      Array.from(checkboxes).forEach(function (element: HTMLInputElement) {
        element.checked = true;
      });
    } else {
      Array.from(checkboxes).forEach(function (element: HTMLInputElement) {
        element.checked = false;
      });
    }
  }
  
  
  backNavigation() {
    this.routes.navigate(["partnerListing"]);
  }

  onChange(event) {
    this.loader.show();
    var url = "/uploadFile";
    event.target.parentElement.childNodes[1].classList.remove('blue-back', 'circle')
    event.target.parentElement.childNodes[1].childNodes[0].classList.add('fullWidth');
    if (event.target.files.length != 0) {
      var file = event.target.files[0];
      const uploadData = new FormData();
      uploadData.append('file', file);
      const headers = new HttpHeaders({
        'Accept': 'application/json',
      });
      //headers.append('Content-Type', 'multipart/form-data');

      // this.config.httpService("post", url, uploadData).subscribe(
      //   res => {
      //     this.fileData = res;
      //     this.fileName = this.fileData.fileDownloadUri;
      //     event.target.parentElement.childNodes[0].style.display = 'none';
      //     this.loader.hide();
      //   },
      //   err => {
      //     console.log(err);
      //     this.loader.hide();
      //     event.target.parentElement.childNodes[1].classList.add('blue-back', 'circle')
      //     event.target.parentElement.childNodes[1].childNodes[0].classList.remove('fullWidth')
      //     Swal({
      //       title: "Something went wrong.\nPlease try after some time.",
      //       type: 'warning',
      //       showCancelButton: false,
      //       confirmButtonColor: '#eb1826',
      //       cancelButtonColor: '#F4F1F1',
      //       confirmButtonText: 'OK'
      //     }).then((result) => {
      //       event.target.value = ""
      //     })
      //   }
      // )

      this.http.post(url, uploadData, { headers: headers }).subscribe(
        res => {
          this.fileData = res;
          this.fileName = this.fileData.fileDownloadUri;
          event.target.parentElement.childNodes[0].style.display = 'none';
          this.loader.hide();
        },
        err => {
          console.log(err);
          this.loader.hide();
          event.target.parentElement.childNodes[1].classList.add('blue-back', 'circle')
          event.target.parentElement.childNodes[1].childNodes[0].classList.remove('fullWidth')
          SwalLib({
            title: "Something went wrong.\nPlease try after some time.",
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#eb1826',
            cancelButtonColor: '#F4F1F1',
            confirmButtonText: 'OK'
          }).then((result) => {
            event.target.value = ""
          })
        }
      )
    }

    console.log(file);
  }

  onClickOutside(event) {
    console.log(event);
  }
  keypressConfig(event) {
    var keycode = (event.keyCode ? event.keyCode : event.which);
    if (keycode == '13') {
      this.configNewPartner(event);
    }
  }
  configNewPartner(event) {
    console.log(event);
    let inputElements = document.getElementsByClassName('checkbox_patner_table') as any as HTMLInputElement[];
    var selectedFscIdList = Array.from(inputElements)
      .map(e => {
        if (e.checked) {
          return Number(e.getAttribute("id"));
        }
      })
      .filter(Boolean);
    this.product_id;
    this.partner_id;
    var count = 0;
    
    var url = "/service/fsc/mapFsc";
    var data = {
      "user_id": selectedFscIdList,
      "partner_id": this.partner_id,
      "product_id": this.product_id
    };

    this.config.httpService("post", url, data).subscribe(
      res => {
        var data;
        data = res;
        this.loader.hide();
        Swal({
          title: "Fsc Assigned Successfully.",
          type: 'success',
          showCancelButton: false,
          confirmButtonColor: '#eb1826',
          cancelButtonColor: '#F4F1F1',
          confirmButtonText: 'OK'
        }).then((result) => {
          this.loader.hide();
          this.routes.navigate(["partnerListing"]);
        })
      },
      err => {
        console.log(err);
      }
    );

    let inputs = this.el.nativeElement.querySelectorAll("input:not(.notNecessary)");
    inputs.forEach(function (item) {
      var text = "fieldset";
      if (item.value.trim() == "") {
        item.parentElement.firstElementChild.style.display = 'block';
        if (item.parentElement.nodeName == text.toUpperCase()) {
          item.closest("fieldset").classList.add("error");
        }

      }
      else {
        item.parentElement.firstElementChild.style.display = 'none';
        if (item.parentElement.nodeName == text.toUpperCase()) {
          item.closest("fieldset").classList.remove("error");
        }
        count = count + 1;

      }
    });
    var savedPartners;
    this.uniquePartner = true;
    var __self = this;
    savedPartners = this.config.getValues(environment.partnerListing, "partner_name");
    savedPartners.forEach(function (partner) {
      if (__self.partnerName.toLowerCase().trim() == partner.toLowerCase().trim() && __self.partnerName.toLowerCase().trim() != __self.currName.toLowerCase().trim()) {
        __self.uniquePartner = false;
        SwalLib({
          title: "Partner name already exists. Try again using a different name.",
          type: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#eb1826',
          cancelButtonColor: '#F4F1F1',
          confirmButtonText: 'OK'
        }).then((result) => {
          event.target.parentElement.childNodes[1].childNodes[6].childNodes[0].childNodes[5].value = ""
        });
      }
    })
    if (count == inputs.length && this.uniquePartner) {
      let el = event.target.parentElement.childNodes[1].childNodes[2];
      el.type = "file";
      this.b2cModal.open();
    }

  }

  toggleInput(event) {
    console.log(event.target);
    if (event.target.classList.contains('icon-eye')) {
      var sibling = event.target.previousElementSibling;
      if (sibling.type == "text") {
        sibling.type = "password";
        event.target.classList.remove("changeColor");
      }

      else if (sibling.type == "password") {
        sibling.type = "text";
        event.target.classList.add("changeColor");
      }
    }
  }

  keypreeLogin(event) {
    this.click = true
    this.loader.show();
    var keycode = (event.keyCode ? event.keyCode : event.which);
    if (keycode == '13') {
      this.validateLogin(event);
    }
  }

  validateLogin(event) {
    this.partnerLoginId = event.target.parentElement.parentElement.parentElement.childNodes[1].childNodes[2].childNodes[0].childNodes[5].value;
    this.partnerLoginPwd = event.target.parentElement.parentElement.parentElement.childNodes[1].childNodes[3].childNodes[0].childNodes[5].value;
    this.partnerLoginCPwd = event.target.parentElement.parentElement.parentElement.childNodes[1].childNodes[4].childNodes[0].childNodes[5].value;
    console.log(event.target);
    let count = 0;
    let pass1 = this.partnerLoginPwd;
    let pass2 = this.partnerLoginCPwd;
    event.target.parentElement.parentElement.parentElement.querySelectorAll('input').forEach(function (item) {

      console.log(item.value);
      if (item.value.trim() == "") {
        item.parentElement.firstElementChild.style.display = 'block';
        item.closest("fieldset").classList.add("error");
        console.log("Blank");
      }
      else {
        item.parentElement.firstElementChild.style.display = 'none';
        item.closest("fieldset").classList.remove("error");
        count = count + 1;

      }
    });
    if (pass1 != pass2) {
      this.click = false
      this.loader.hide();
      var el = event.target.parentElement.parentElement.parentElement.childNodes[1].childNodes[1];
      this.errMsg = "Passwords did not match, please try again";
      el.classList.remove("hide");
      var pwd1 = event.target.parentElement.parentElement.parentElement.childNodes[1].childNodes[3].childNodes[0].childNodes[5];
      var pwd2 = event.target.parentElement.parentElement.parentElement.childNodes[1].childNodes[4].childNodes[0].childNodes[5];
      pwd1.value = "";
      pwd2.value = "";
      pwd1.parentElement.firstElementChild.style.display = 'block';
      pwd2.parentElement.firstElementChild.style.display = 'block';
      pwd1.closest("fieldset").classList.add("error");
      pwd2.closest("fieldset").classList.add("error");
    }
    else if (this.partnerLoginId.length < 5) {
      this.click = false
      this.loader.hide();
      var el = event.target.parentElement.parentElement.parentElement.childNodes[1].childNodes[1];
      this.errMsg = "Please enter minimum 5 characters for Login ID";
      el.classList.remove("hide");
      var userId = event.target.parentElement.parentElement.parentElement.childNodes[1].childNodes[2].childNodes[0].childNodes[5];
      userId.value = "";
      userId.parentElement.firstElementChild.style.display = 'block';
      userId.closest("fieldset").classList.add("error");
    }
    else if (pass1.length < 4 || pass1.length > 8) {
      this.click = false
      this.loader.hide();
      var el = event.target.parentElement.parentElement.parentElement.childNodes[1].childNodes[1];
      this.errMsg = "Please enter password between 4 to 8 characters";
      el.classList.remove("hide");
      var pwd1 = event.target.parentElement.parentElement.parentElement.childNodes[1].childNodes[3].childNodes[0].childNodes[5];
      var pwd2 = event.target.parentElement.parentElement.parentElement.childNodes[1].childNodes[4].childNodes[0].childNodes[5];
      pwd1.value = "";
      pwd2.value = "";
      pwd1.parentElement.firstElementChild.style.display = 'block';
      pwd2.parentElement.firstElementChild.style.display = 'block';
      pwd1.closest("fieldset").classList.add("error");
      pwd2.closest("fieldset").classList.add("error");
    }
    else if (count == 3) {
      this.click = true
      this.loader.show();
      var url = "/service/admin/" + environment.userid + "/partners"
      var data = {
        "partner_id": this.partnerId, "partner_logo": this.partnerLogo, "partner_name": this.partnerName,
        "partner_address": this.partnerAddress, "status": 1, "b2cEmail": this.partnerLoginId,
        "b2cPassword": this.partnerLoginPwd
      };
      console.log("3 data");
      console.log(data);
      this.config.httpService('post', url, data).subscribe(
        res => {
          var details;
          details = res;
          environment.partnerDetails = [];
          environment.partnerDetails.push(details);
          this.b2cModal.close(event);
          if (environment.editDetails == true) {
            let url = "/service/admin/" + environment.userid + "/lpartners/" + this.partnerId;
            environment.currentPartnerDetails = [];

            environment.partnerDetails = [];

            this.config.httpService("post", url, "").subscribe(
              res => {
                var data = res;
                environment.editDetails = true;
                environment.currentPartnerDetails.push(data);
                environment.partnerDetails.push(data);
                if (environment.editDetails == true) {
                  this.loader.hide();
                  SwalLib({
                    title: "Partner configuration updated successfully.",
                    type: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#eb1826',
                    cancelButtonColor: '#F4F1F1',
                    confirmButtonText: 'OK'
                  }).then((result) => {
                    this.loader.hide();
                    this.routes.navigate(["partnerListing"]);
                  })

                }
                else {
                  this.loader.hide();
                  this.routes.navigate(["product-mapping"]);
                  console.log(res);
                }
              },
              err => {
                this.loader.hide();
                SwalLib({
                  title: "Something went wrong.\nPlease try after some time.",
                  type: 'warning',
                  showCancelButton: false,
                  confirmButtonColor: '#eb1826',
                  cancelButtonColor: '#F4F1F1',
                  confirmButtonText: 'OK'
                }).then((result) => {
                  // this.routes.navigate(["login"]);
                })
              }
            )
          }
          else {
            this.loader.hide();
            SwalLib({
              title: "Partner configuration created successfully.",
              type: 'success',
              showCancelButton: false,
              confirmButtonColor: '#eb1826',
              cancelButtonColor: '#F4F1F1',
              confirmButtonText: 'OK'
            }).then((result) => {
              this.loader.hide();
              this.routes.navigate(["product-mapping"]);
              console.log(res);
            })
          }
        },
        err => {
          this.loader.hide();
          SwalLib({
            title: "Something went wrong.\nPlease try after some time.",
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#eb1826',
            cancelButtonColor: '#F4F1F1',
            confirmButtonText: 'OK'
          }).then((result) => {
            this.routes.navigate(["partnerListing"]);
          })
        }
      )
    }
  }

  closeModel(event) {
    // Swals({
    //        title: "Are you sure, all changes are discard",
    //        type: 'warning',
    //        showCancelButton: true,
    //        confirmButtonColor: '#eb1826',
    //        cancelButtonColor: '#F4F1F1',
    //        confirmButtonText: 'OK'
    //      }).then((result) => {
    //       if(result.value){
    //         this.b2cModal.close(event);
    //       }
    //       else{

    //       }
    //      });
    this.b2cModal.close(event);

  }

  storeValue(event) {
    var savedb2cemail
    var __self = this;
    var userid = event.target.value;
    savedb2cemail = this.config.getValues(environment.partnerListing, "b2cEmail");
    savedb2cemail.forEach(function (email) {
      if (email.toLowerCase().trim() == userid.toLowerCase().trim() && email.toLowerCase().trim() != __self.currId.toLowerCase().trim()) {
        event.target.value = "";
        __self.errMsg = "Login ID already exists. Try again using a different ID";
        var ell = event.target.parentElement.parentElement.parentElement.parentElement.childNodes[1].childNodes[1];
        ell.classList.remove("hide");
      }
    })
  }
}
function Swals(arg0: { title: string; type: string; showCancelButton: boolean; confirmButtonColor: string; cancelButtonColor: string; confirmButtonText: string; }) {
  throw new Error('Function not implemented.');
}


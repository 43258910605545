import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataShareService {
  public selectedProduct = "";
  public newselectedProductSubject = new Subject<any>();
  public selectedLoan = "";
  public selectedOtherLoanDetails = "";
  public newSelectedOtherLoanDetails = new Subject<any>();
  public newselectedLoanSubject = new Subject<any>();
  public selectedreductionSchedule = "";
  public newselectedReductionSchedule = new Subject<any>();
  public selectedEntityType = "";
  public newselectedEntityType = new Subject<any>();
  public selectedMortality = "";
  public newselectedMortalitySubject = new Subject<any>();
  public selectedMortalityRate = [];
  public newselectedMortalityRateSubject = new Subject<any>();
  public selectedCriticalIllness = [];
  public newselectedCriticalIllnessSubject = new Subject<any>();
  public selectedPaymentTerm = "";
  public newselectedPaymentTermSubject = new Subject<any>();
  public selectedCoverageOption = "";
  public newselectedCoverageOptionSubject = new Subject<any>();
  public selectedFrequency = "";
  public newselectedFrequencySubject = new Subject<any>();
  public selectedNonMedicalLimit = "";
  public newselectedNonMedicalSubject = new Subject<any>();
  public selectedFlexibility = "";
  public newselectedFlexibilitySubject = new Subject<any>();
  public selectedChannel = "";
  public newselectedChannelSubject = new Subject<any>();
  public selectedAcici = "";
  public newselectedAciciSubject = new Subject<any>();
  public selectedNonMedicalRate = "";
  public newselectedNonMedicalRateSubject = new Subject<any>();
  public selectedNonMedicalRateCI = "";
  public newselectedNonMedicalRateSubjectCI = new Subject<any>();
  public selectedAciciRate = "";
  public newselectedAciciRateSubject = new Subject<any>();
  public partnerConfig = false;
  public newPartnerConfigSubject = new Subject<any>();
  public header = false;
  public newHeaderSubject = new Subject<any>();
  public asseleratedCI = false;
  public newAsseleratedCISubject = new Subject<any>();
  public additionalCI = false;
  public newAdditionalCISubject = new Subject<any>();
  public coverageOptions = false;
  public newCoverageOptionsSubject = new Subject<any>();
  public singleJointLife = false;
  public newSingleJointLifeSubject = new Subject<any>();
  public waiverHospital = false;
  public newWaiverHospitalSubject = new Subject<any>();
  public moratoriumPeriod = false;
  public newMoratoriumPeriodSubject = new Subject<any>();
  public moratoriumTerm = false;
  public newMoratoriumTermSubject = new Subject<any>();
  public interestRates = false;
  public newInterestRateSubject = new Subject<any>();
  public coverageTerm = "";
  public newCoverageTermSubject = new Subject<any>();
  public ADBValue = "";
  public newADBValueSubject = new Subject<any>();
  public DbMortality = "";
  public newDbMortalitySubject = new Subject<any>();
  public emi7 = "";
  public newEmi7Subject = new Subject<any>();
  public emi15 = "";
  public newEmi15Subject = new Subject<any>();


  constructor() { }
  setSelectedProduct(data) {
    this.selectedProduct = data;
    this.newselectedProductSubject.next(data);
  }

  getSelectedProduct() {
    return this.selectedProduct;
  }

  setSelectedLoan(data) {
    this.selectedLoan = data;
    this.newselectedLoanSubject.next(data);
  }
  // setSelectedOtherLoanDetails(data) {
  //   this.selectedOtherLoanDetails = data;
  //   this.newselectedLoanSubject.next(data);
  // }

  getSelectedLoan() {
    return this.selectedLoan;
  }
  // getSelectedOtherLoanDetails() {
  //   return this.selectedOtherLoanDetails;
  // }

  setReductionSchedule(data) {
    this.selectedreductionSchedule = data;
    this.newselectedReductionSchedule.next(data);
  }

  getReductionSchedule() {
    return this.selectedreductionSchedule;
  }

  setEntityType(data) {
    this.selectedEntityType = data;
    this.newselectedEntityType.next(data);
  }

  getEntityType() {
    return this.selectedEntityType;
  }

  setSelectedMortality(data) {
    this.selectedMortality = data;
    this.newselectedMortalitySubject.next(data);
  }

  getSelectedMortality() {
    return this.selectedMortality;
  }

  setSelectedMortalityRate(data) {
    this.selectedMortalityRate = data;
    this.newselectedMortalityRateSubject.next(data);
  }

  getSelectedMortalityRate() {
    return this.selectedMortalityRate;
  }

  setSelectedCriticalIllness(data) {
    this.selectedCriticalIllness = data;
    this.newselectedCriticalIllnessSubject.next(data);
  }

  getSelectedCriticalIllness() {
    return this.selectedCriticalIllness;
  }

  setSelectedPaymentTerm(data) {
    this.selectedPaymentTerm = data;
    this.newselectedPaymentTermSubject.next(data);
  }

  getSelectedPaymentTerm() {
    return this.selectedPaymentTerm;
  }
  setSelectedCoverageOption(data) {
    this.selectedCoverageOption = data;
    this.newselectedCoverageOptionSubject.next(data);
  }

  getSelectedCoverageOption() {
    return this.selectedCoverageOption;
  }
  setSelectedFrequency(data) {
    this.selectedFrequency = data;
    this.newselectedFrequencySubject.next(data);
  }

  getSelectedFrequency() {
    return this.selectedFrequency;
  }

  setSelectedNonMedical(data) {
    this.selectedNonMedicalLimit = data;
    this.newselectedNonMedicalSubject.next(data);
  }

  getSelectedNonMedical() {
    return this.selectedNonMedicalLimit;
  }

  setSelectedFlexibility(data) {
    this.selectedFlexibility = data;
    this.newselectedFlexibilitySubject.next(data);
  }

  getSelectedFlexibility() {
    return this.selectedFlexibility;
  }

  setSelectedChannel(data) {
    this.selectedChannel = data;
    this.newselectedChannelSubject.next(data);
  }

  getSelectedChannel() {
    return this.selectedChannel;
  }

  setSelectedAcici(data) {
    this.selectedAcici = data;
    this.newselectedAciciSubject.next(data);
  }

  getSelectedAcici() {
    return this.selectedAcici;
  }

  setSelectedNonMedicalRate(data) {
    this.selectedNonMedicalRate = data;
    this.newselectedNonMedicalRateSubject.next(data);
  }

  getSelectedNonMedicalRate() {
    return this.selectedNonMedicalRate;
  }

  setSelectedNonMedicalRateCI(data) {
    this.selectedNonMedicalRateCI = data;
    this.newselectedNonMedicalRateSubjectCI.next(data);
  }

  getSelectedNonMedicalRateCI() {
    return this.selectedNonMedicalRateCI;
  }

  setSelectedAciciRate(data) {
    this.selectedAciciRate = data;
    this.newselectedAciciRateSubject.next(data);
  }

  getSelectedAciciRate() {
    return this.selectedAciciRate;
  }
  setPartnerConfig(data) {
    this.partnerConfig = data;
    this.newPartnerConfigSubject.next(data);
  }

  getPartnerConfig() {
    return this.partnerConfig;
  }

  setHeader(data) {
    this.header = data;
    this.newHeaderSubject.next(data);
  }

  getHeader() {
    return this.header;
  }
  setAsseleratedCI(data) {
    this.asseleratedCI = data;
    this.newAsseleratedCISubject.next(data);
  }

  getAsseleratedCI() {
    return this.asseleratedCI;
  }
  setAdditionalCI(data) {
    this.additionalCI = data;
    this.newAdditionalCISubject.next(data);
  }

  getAdditionalCI() {
    return this.additionalCI;
  }
  setcoverageOptions(data) {
    this.coverageOptions = data;
    this.newCoverageOptionsSubject.next(data);
  }

  getcoverageOptions() {
    return this.coverageOptions;
  }
  setsingleJointLife(data) {
    this.singleJointLife = data;
    this.newSingleJointLifeSubject.next(data);
  }

  getsingleJointLife() {
    return this.singleJointLife;
  }
  setWaiverHospital(data) {
    this.waiverHospital = data;
    this.newWaiverHospitalSubject.next(data);
  }

  setemi7(data) {
    this.emi7 = data;
    this.newEmi7Subject.next(data);
  }

  getemi7() {
    return this.emi7
  }

  setemi15(data) {
    this.emi15 = data;
    this.newEmi15Subject.next(data);
  }

  getemi15() {
    return this.emi15
  }

  getWaiverHospital() {
    return this.waiverHospital;
  }
  setmoratoriumPeriod(data) {
    this.moratoriumPeriod = data;
    this.newMoratoriumPeriodSubject.next(data);
  }

  getmoratoriumPeriod() {
    return this.moratoriumPeriod;
  }
  setmoratoriumTerm(data) {
    this.moratoriumTerm = data;
    this.newMoratoriumTermSubject.next(data);
  }

  getmoratoriumTerm() {
    return this.moratoriumTerm;
  }

  setInterestRate(data) {
    this.interestRates = data;
    this.newInterestRateSubject.next(data);
  }

  getInterestRate() {
    return this.interestRates;
  }

  setCoverageTerm(data) {
    this.coverageTerm = data;
    this.newCoverageTermSubject.next(data);
  }

  getCoverageTerm() {
    return this.coverageTerm;
  }

  setADBValue(data) {
    this.ADBValue = data;
    this.newADBValueSubject.next(data);
  }

  getADBValue() {
    return this.ADBValue;
  }

  setDbMortality(data) {
    this.DbMortality = data;
    this.newDbMortalitySubject.next(data);
  }

  getDbMortality() {
    return this.DbMortality;
  }

}

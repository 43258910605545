// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  maxCoverageTerm: 30,
  loginToken: '',
  production: false,
  partner_config: false,
  savedProductMapping: [],
  reduction_sched: "",
  savedProductBenefit: [],
  savedProductOtherBenefit: [],
  savedProductBoundryCondition: {},
  savedTillProductBoundryCondition: [],
  savedProductScheme: [],
  username: "",
  password: "",
  userid: 0,
  partnerListing: [],
  partnerDetails: [],
  selectedProduct_Id: "",
  selectedLoan_Id: "",
  selectedMortality_Id: "",
  selectedNonMedicalLimits_Id: "",
  selectedReductionSchedule_Id: "",
  selectedReductionScheduleCode: "",
  selectedEntityName: "",
  selectedEntityId: "",
  minAge: 14,
  maxAge: 0,
  minCoverCeasing: 0,
  maxCoverCeasing: 0,
  minSumAssured: 2000,
  maxSumAssured: 0,
  minPolicyTerm: 2,
  maxPolicyTerm: 30,
  acimaxPolicyTerm: 15,
  canceremimaxPolicyTerm: 5,
  glSData: [],
  sroData: [],
  srlData: [],
  srcData: [],
  spcData: [],
  splData: [],
  spoData: [],
  productData: [],
  glsMinAge: 14,
  glsMaxAge: 70,
  glsMaxCeasingAge: 60,
  fixedMinPolicyTerm: 2,
  glsMaxPolicyTerm: 30,
  selectedCriticalIllness_Id: "",
  logedInUserName: "",
  logedInUserEmail: "",
  logingInUserId: "",
  currentPartnerDetails: [],
  editDetails: false,
  loginSuccessfully: false,
  editPolicy: false,
  editPolicyId: 0,
  selectedTableReference: "",
  currentBenefit: "",
  dbMinAge: 14,
  dbMaxAge: 0,
  dbMinCover: 0,
  dbMaxCover: 0,
  dbMinSum: 5000,
  dbMaxSum: 0,
  dbMinTerm: 2,
  dbMaxTerm: 0,
  fixedData: [{
    "min_age_at_entry": 14,
    "max_age_at_entry": 70,
    "min_cover_ceasing_age": 18,
    "max_cover_ceasing_age": 75,
    "min_sum_assured": 2000,
    "min_policy_term": 2,
    "max_policy_term": 30,
    "min_mortality_rate": 35,
    "max_mortality_rate": 300
  }],
  glsFixedData: [{
    "min_age_at_entry": 14,
    "max_age_at_entry": 70,
    "min_cover_ceasing_age": 18,
    "max_cover_ceasing_age": 75,
    "min_sum_assured": 2000,
    "min_policy_term": 2,
    "max_policy_term": 30,
    "min_mortality_rate": 35,
    "max_mortality_rate": 300
  }],
  spcFixedData: [{
    "min_age_at_entry": 14,
    "max_age_at_entry": 75,
    "min_cover_ceasing_age": 16,
    "max_cover_ceasing_age": 80,
    "min_sum_assured": 2000,
    "max_sum_assured_emi": 100000,
    "max_sum_assured_cancer": 10000000,
    "min_policy_term": 2,
    "max_policy_term": 30,
    "min_mortality_rate": 15,
    "max_mortality_rate": 500,
    "min_coverage_term": 2,
    "max_coverage_term": 30
  }],
  groupLoanSecure: true,
  superProductCredit: false,
  shubhRakshaCredit: false
}

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DragScrollComponent } from 'ngx-drag-scroll';
import { RouterModule, Router, ActivatedRoute } from '@angular/router';
import { environment } from "../../../environments/environment";
import { ConfigService } from "../../services/config.service";
import { DataShareService } from "../../services/data-share.service";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import Swal from 'sweetalert2';
import { toInteger } from '@ng-bootstrap/ng-bootstrap/util/util';
import * as jsPdf from 'jspdf';
import { HttpHeaders } from '@angular/common/http';

import html2canvas from 'html2canvas';

import * as XLSX from 'xlsx';


b2cModal1: ElementRef;
b2cModal2: ElementRef;

@Component({
  selector: 'app-fsc-details',
  templateUrl: './fsc-details.component.html',
  styleUrls: ['./fsc-details.component.scss']
})
export class FscDetailsComponent implements OnInit {

  @ViewChild('nav', { read: DragScrollComponent }) ds: DragScrollComponent;
  @ViewChild('downloadSoftCopy') pdfcontent : ElementRef;

  @ViewChild('TABLE') TABLE: ElementRef;

  //object keys
  objectEntries = Object.entries;
  b2cModal1;
  b2cModal2;
  sub;
  partner_id;
  partner_name;
  partner_logo;
  policyList;
  showPolicy = false;
  masterPolicy: any;
  renderHtml = false;
  policyId;
  policyDetails;
  policyHeader: any = [];
  policy: any = [];
  productMappingDetails: any = [];
  productBenifitsDetails: any = [];
  productBoundryConditionsDetails: any = [];
  productSchemeDetails: any = [];
  child: any;
  productName: any = [];
  name: any;
  benefitData: any;
  benefitDataFlat: any;
  benefitDataAge: any;
  benefitDataTerm: any;
  showData: any;
  status: any;
  schemesection;
  groupLoanSecure;
  superProductCredit;
  additionalCIopt;
  acceleratedCIopt;
  coverageTerm: any = [];
  coverageOption: any = [];
  selectedLife: any = [];
  moratoriumTerm: any = [];
  moratoriumPeriod: any = [];
  interestRate: any = [];
  indexCovTerm;
  id: any = []; 
  indexMorTerm;
  benefits: any = [];
  selectedBenefit = "";
  products = "";
  @ViewChild("b2cModal1") set content(content: ElementRef) {
    this.b2cModal1 = content;
  }
  @ViewChild("b2cModal2") set contents(contents: ElementRef) {
    this.b2cModal2 = contents;
  }
  // productData = this.data;
  constructor(public routes: Router, public route: ActivatedRoute, public config: ConfigService,
    public change: DataShareService, public loader: Ng4LoadingSpinnerService) {
    // console.log(this.data);
    this.policyHeader.push({ "header_name": "Product Mapping", "header_logo": "assets/images/product_mapping.svg" },
      { "header_name": "Benefits", "header_logo": "assets/images/Benefits.svg" },
      { "header_name": "Boundry Condition", "header_logo": "assets/images/boundary_condition.svg" },
      { "header_name": "Schema Section", "header_logo": "assets/images/scheme_section.svg" });
    this.change.setPartnerConfig(false);
  
  }
  ngOnChanges() {
    console.log("working now");
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.id = JSON.parse(params['fsc']);
      
    });
    var url = "/service/fsc/GetDetails/" + this.id;
    this.config.httpService("post", url, "").subscribe(
      (res: any) => {
        console.log('API Response:', res); // Log entire response for debugging
  
        this.partner_logo = res.partner_logo;
        this.partner_name = res.partner_name;
        this.products = res; // Ensure 'products' is correctly assigned
  
        console.log('Fetched Products:', this.products);
      },
      (err: any) => {
        console.error(err);
      }
    );
  }


//CREATING EXCEL
  downloadPdf() {
   
    this.loader.show();


   const wb: XLSX.WorkBook = XLSX.utils.book_new();
    wb.Props = {
      Title: "Policy Premium",
      Subject: "Policy",
      Author: "Himanshu Singh",
      //CreatedDate: new Date(2017,12,19)
    };
    

    var productMap=this.productMappingDetails;

    var productInfo=[  ['PRODUCT DETAILS'],
                   ['Policy name', productMap["policy_name"]], ['Loan Type', productMap["loan_type"] ] ,[ 'Reduction Schedule', productMap["reduction_schedule"] ] , ['Channel',productMap["channel"]],
                   ['Selected Life', productMap["selected_life"][0]["name"] ], [ 'Interest Rate' , productMap["interest_rate"][0]["name"] ] ,['Coverage Option', productMap["coverage_option"][0]["name"] ],
                   ['Coverage Term', productMap["coverage_term"][0]["name"]],
                   ['  '],
                   ['  '],
                   ['BOUNDARY CONDITIONS']
                ];



    var boundaryJson=this.productBoundryConditionsDetails;
    var flatBound=boundaryJson["FlatWise"];
    var ageBound=boundaryJson["AgeBandWise"];
    var termBound=boundaryJson["TermWise"];
    var flatkey=Object.keys(flatBound);
    var agekey=Object.keys(ageBound);
    var termkey=Object.keys(termBound);

    var boundContainer=[];
    var boundaryHeader=["Benefit Name","Minimum age", "Maximum age", "Minimum cover ceasing age", "Maximum cover ceasing age", "Minimum sum assured", "Maximum sum assured", "Minimum policy term", "Maximum policy term"];
    boundContainer.push(boundaryHeader);

    flatkey.forEach(e=>{
      var arr=Object.values(flatBound[e]["flat"]);
      arr.unshift(e);
      boundContainer.push(arr);

    });

    agekey.forEach(e=>{

      var ageband=Object.keys(ageBound[e]);
      ageband.forEach(item=>{
      var arr=Object.values(ageBound[e][item]);
      arr.unshift(e+" ("+item+")");
      console.log(arr);
      boundContainer.push(arr);
     });
    });

    termkey.forEach(e=>{
      var termband=Object.keys(termBound[e]);
      termband.forEach(item=>{
      var arr=Object.values(termBound[e][item]);
      arr.unshift(e+" ("+item+")");
      console.log(arr);
      boundContainer.push(arr);

     });

    });
    
    var scheme=this.productSchemeDetails;

    var schemeKeys=Object.keys(scheme);

    var schemeInfo=[[''],[''],['SCHEME SECTION']];

    schemeKeys.forEach(e=>{

      if(!Array.isArray(scheme[e])){
        var str=e.replace("_"," ");
        schemeInfo.push([str,scheme[e]]);
      }

    })




      wb.SheetNames.push("Super Protect");
      //USING AOA
      
      
      var ws =XLSX.utils.aoa_to_sheet(productInfo);

      XLSX.utils.sheet_add_aoa(ws,boundContainer, {origin:-1});
      XLSX.utils.sheet_add_aoa(ws,schemeInfo, {origin:-1});

      wb.Sheets["Super Protect"] = ws;

      XLSX.writeFile(wb, this.selectedBenefit+ '.xlsx');
      this.loader.hide();


  }

  //CREATING PDF
  ExportTOExceldownloadPdaf(){
    this.loader.show();
    console.log("downloading pdf");
    // let doc= new jsPdf('p', 'mm', 'a4');

    // let specialElementHandlers={

    //   '#editor':function (element,renderer) {
    //     return true;
    //   }
    // };

    // let content =this.pdfcontent.nativeElement;

    // doc.fromHTML(content.innerHTML, 15,15,{

    //     'width':190,
    //     'elementHandlers':specialElementHandlers

    // });

    // doc.save('test.pdf');

    /************new method */
    var data = document.getElementById('downloadSoftCopy');
    html2canvas(data).then(canvas => {
      // Few necessary setting options
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPdf('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save(this.selectedBenefit+ '_softcopy.pdf'); // Generated PDF
      this.loader.show();
    });





  }
counter=0;
  ngAfterViewInit() {
    var row = document.querySelectorAll("div.product-row");
    if (this.groupLoanSecure) {
      for (var i = 0; i < row.length; i++) {
        this.productName = [];
        var band;
        var benName;
        var covType;
        var column = row[i].querySelectorAll('.product-column');
        for (var j = 0; j < column.length; j++) {
          if (i > 0) {
            this.child = row[i].querySelectorAll('.product-column')[j];
            if (this.child.classList.value == "product-column product-name") {
              this.productName.push({ "product_name": this.child.innerText });
              this.name = (this.productName[0].product_name).trim();
              for (var k = 0; k < this.benefits.length; k++) {
                if (this.name.includes(this.benefits[k].title) && !(this.name.includes("Accidental Death Benefit"))) {
                  if (this.name.includes(this.benefits[k].bands)) {
                    band = this.benefits[k].bands
                    benName = this.benefits[k].title
                    covType = this.benefits[k].type
                  } else if (this.benefits[k].bands == '999') {
                    band = this.benefits[k].bands
                    benName = this.benefits[k].title
                    covType = this.benefits[k].type
                  }
                } else if (this.name.includes(this.benefits[k].title) && (this.benefits[k].title == ("Accidental Death Benefit"))) {
                  if (this.name.includes(this.benefits[k].bands)) {
                    band = this.benefits[k].bands
                    benName = this.benefits[k].title
                    covType = this.benefits[k].type
                  } else if (this.benefits[k].bands == '999') {
                    band = this.benefits[k].bands
                    benName = this.benefits[k].title
                    covType = this.benefits[k].type
                  }
                }
              }
              if (this.showData[covType][benName] != undefined) {
                if (this.showData[covType][benName][band] != undefined) {
                  this.benefitData = this.showData[covType][benName][band];
                } else {
                  this.benefitData = []
                }
              }
              else {
                this.benefitData = []
              }
              console.log(this.benefitData);
            }
            else if (this.child.classList.value == "product-column product-min-age" && Object.keys(this.benefitData).length > 0) {
              var value = this.config.getValues(this.benefitData, "product-min-age");
              this.child.querySelector('input').value = value;
            }
            else if (this.child.classList.value == "product-column product-max-age" && Object.keys(this.benefitData).length > 0) {
              var value = this.config.getValues(this.benefitData, "product-max-age");
              this.child.querySelector('input').value = value;
            }
            else if (this.child.classList.value == "product-column product-min-cover-ceasing-age" && Object.keys(this.benefitData).length > 0) {
              var value = this.config.getValues(this.benefitData, "product-min-cover-ceasing-age");
              this.child.querySelector('input').value = value;
            }
            else if (this.child.classList.value == "product-column product-cover-ceasing-age" && Object.keys(this.benefitData).length > 0) {
              var value = this.config.getValues(this.benefitData, "product-cover-ceasing-age");
              this.child.querySelector('input').value = value;
            }
            else if (this.child.classList.value == "product-column product-min-sum-assured" && Object.keys(this.benefitData).length > 0) {
              var value = this.config.getValues(this.benefitData, "product-min-sum-assured");
              this.child.querySelector('input').value = value;
            }
            else if (this.child.classList.value == "product-column product-max-sum-assured" && Object.keys(this.benefitData).length > 0) {
              var value = this.config.getValues(this.benefitData, "product-max-sum-assured");
              this.child.querySelector('input').value = value;
            }
            else if (this.child.classList.value == "product-column product-min-policy-term" && Object.keys(this.benefitData).length > 0) {
              var value = this.config.getValues(this.benefitData, "product-min-policy-term");
              this.child.querySelector('input').value = value;
            }
            else if (this.child.classList.value == "product-column product-max-policy-term" && Object.keys(this.benefitData).length > 0) {
              var value = this.config.getValues(this.benefitData, "product-max-policy-term");
              this.child.querySelector('input').value = value;
            }
          }
        }
      }
    } else if (this.superProductCredit) {
      for (var i = 0; i < row.length; i++) {
        this.productName = [];
        var band;
        var benName;
        var covType;
        var column = row[i].querySelectorAll('.product-column');
        for (var j = 0; j < column.length; j++) {
          if (i > 0) {
            this.child = row[i].querySelectorAll('.product-column')[j];
            if (this.child.classList.value == "product-column product-name") {
              this.productName.push({ "product_name": this.child.innerText });
              this.name = (this.productName[0].product_name).trim();
              for (var k = 0; k < this.benefits.length; k++) {
                if (this.name.includes(this.benefits[k].title) && !(this.name.includes("Accidental Death Benefit"))) {
                  if (this.benefits[k].type != "Flat" && this.name.includes(this.benefits[k].bands)) {
                    band = this.benefits[k].bands
                    benName = this.benefits[k].title
                    covType = this.benefits[k].type
                  } else if (this.benefits[k].type == "Flat" && this.benefits[k].title == this.name || this.benefits[k].type == "FlatWise" && this.benefits[k].title == this.name) {
                    benName = this.benefits[k].title
                    covType = this.benefits[k].type
                  }
                } else if (this.name.includes(this.benefits[k].title) && (this.benefits[k].title == ("Accidental Death Benefit"))) {
                  if (this.benefits[k].type != "Flat" && this.name.includes(this.benefits[k].bands)) {
                    band = this.benefits[k].bands
                    benName = this.benefits[k].title
                    covType = this.benefits[k].type
                  } else if (this.benefits[k].type == "Flat" && this.benefits[k].title == this.name || this.benefits[k].type == "FlatWise" && this.benefits[k].title == this.name) {
                    benName = this.benefits[k].title
                    covType = this.benefits[k].type
                  }
                }
              }

              if (covType != "FlatWise") {
                if (this.showData[covType][benName] != undefined) {
                  if (this.showData[covType][benName][band] != undefined) {
                    this.benefitData = this.showData[covType][benName][band];
                  } else {
                    this.benefitData = []
                  }
                }
                else {
                  this.benefitData = []
                }
              } else {
                if (this.showData[covType][benName] != undefined) {
                  this.benefitData = this.showData[covType][benName];
                } else {
                  this.benefitData = []
                }
              }
              console.log(this.counter++ +"  "+ this.benefitData);
            }
            else if (this.child.classList.value == "product-column product-min-age" && Object.keys(this.benefitData).length > 0) {
              var value = this.config.getValues(this.benefitData, "product-min-age");
              this.child.querySelector('input').value = value;
            }
            else if (this.child.classList.value == "product-column product-max-age" && Object.keys(this.benefitData).length > 0) {
              var value = this.config.getValues(this.benefitData, "product-max-age");
              this.child.querySelector('input').value = value;
            }
            else if (this.child.classList.value == "product-column product-min-cover-ceasing-age" && Object.keys(this.benefitData).length > 0) {
              var value = this.config.getValues(this.benefitData, "product-min-cover-ceasing-age");
              this.child.querySelector('input').value = value;
            }
            else if (this.child.classList.value == "product-column product-cover-ceasing-age" && Object.keys(this.benefitData).length > 0) {
              var value = this.config.getValues(this.benefitData, "product-cover-ceasing-age");
              this.child.querySelector('input').value = value;
            }
            else if (this.child.classList.value == "product-column product-min-sum-assured" && Object.keys(this.benefitData).length > 0) {
              var value = this.config.getValues(this.benefitData, "product-min-sum-assured");
              this.child.querySelector('input').value = value;
            }
            else if (this.child.classList.value == "product-column product-max-sum-assured" && Object.keys(this.benefitData).length > 0) {
              var value = this.config.getValues(this.benefitData, "product-max-sum-assured");
              this.child.querySelector('input').value = value;
            }
            else if (this.child.classList.value == "product-column product-min-policy-term" && Object.keys(this.benefitData).length > 0) {
              var value = this.config.getValues(this.benefitData, "product-min-policy-term");
              this.child.querySelector('input').value = value;
            }
            else if (this.child.classList.value == "product-column product-max-policy-term" && Object.keys(this.benefitData).length > 0) {
              var value = this.config.getValues(this.benefitData, "product-max-policy-term");
              this.child.querySelector('input').value = value;
            }
          }
        }
      }
    }
  }
  goToAddProduct(id) {
    var url = "/service/admin/" + environment.userid + "/lpartners/" + id;
    this.config.httpService("post", url, "").subscribe(
      res => {
        environment.partnerDetails = [];
        environment.currentPartnerDetails = [];
        var data = res;
        environment.editDetails = true;
        environment.currentPartnerDetails.push(data);
        environment.partnerDetails.push(data);
        this.routes.navigate(['product-mapping']);

      },
      err => {
        console.log(err);
      }
    )
  }

  isArray(ads) {
    return Object.prototype.toString.call(ads) == "[object Array]";
  }
  isObject(ads) {
    return Object.prototype.toString.call(ads) == "[object Object]";
  }

  backNavigation() {
    this.routes.navigate(["fscListing"]);
  }

  deactivate(policyId, method) {
    var activateValue;
    if (method == 'active') {
      activateValue = 1;
    }
    else {
      activateValue = 0;
    }
    console.log(policyId);
    console.log(environment.partnerDetails);
    var url = "/service/admin/" + environment.userid + "/partners/" + environment.partnerDetails[0].partner_id + "/policies/" + policyId + "/active/" + activateValue;
    this.config.httpService("post", url, "").subscribe(
      res => {
        //  this.b2cModal1.close(e)
        Swal({
          title: "Product is now" + " " + method + "!",
          type: 'success',
          showCancelButton: false,
          confirmButtonColor: '#eb1826',
          cancelButtonColor: '#F4F1F1',
          confirmButtonText: 'OK'
        }).then((result) => {

        });
        this.routes.navigate(["/partnerListing"]);
      },
      err => {

      }
    )
  }
  openModal1() {
    this.b2cModal1.open();
  }
  closeModel1(event) {
    this.b2cModal1.close(event);
  }
  openModal2() {
    this.b2cModal2.open();
  }

  

  closeModel2(event) {
    this.b2cModal2.close(event);
  }

  
  findIndexValue(arraytosearch, key, valuetosearch) {

    for (var i = 0; i < arraytosearch.length; i++) {

      if (arraytosearch[i][key] == valuetosearch) {
        return i;
      }
    }
    return null;
  }

  findProduct(arraytosearch, key, valuetosearch) {

    for (var i = 0; i < arraytosearch.length; i++) {
      var name = arraytosearch[i][key]
      name = name.toLowerCase();
      valuetosearch = valuetosearch.toLowerCase();
      if (name == valuetosearch) {
        return i;
      }
    }
    return null;
  }

  editPolicy(policyId) {
    environment.editPolicy = true;
    environment.editPolicyId = policyId;
    if (this.groupLoanSecure) {
      environment.savedProductMapping.push(this.policyDetails["Product Mapping"]);
      environment.savedProductBenefit.push({ "benefits": this.policyDetails["Benefits"] });
      environment.savedProductBoundryCondition = this.policyDetails["Boundary Conditions"];
      environment.savedProductScheme.push(this.policyDetails["SchemeSection"]);
      this.routes.navigate(["/product-mapping"]);
    }
    else if (this.superProductCredit) {
      environment.savedProductMapping.push(this.policyDetails["Product Mapping"]);
      environment.savedProductBenefit.push({ "benefits": this.policyDetails["Benefits"] });
      environment.savedProductOtherBenefit.push({ "other_benefits": this.policyDetails["Other-Benefits"] });
      environment.savedProductBoundryCondition = this.policyDetails["Boundary Conditions"];
      environment.savedProductScheme.push(this.policyDetails["SchemeSection"]);
      this.routes.navigate(["/product-mapping"]);
    }

  }
}

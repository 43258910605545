import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { PartnerPageComponent } from './components/partner-page/partner-page.component';
import { AddPartnerComponent } from './components/add-partner/add-partner.component';
import { ProductMappingComponent } from './components/product-mapping/product-mapping.component';
import { PartnerDetailsComponent } from './components/partner-details/partner-details.component';
import { ProductBenifitsComponent } from './components/product-benifits/product-benifits.component';
import { ProductBoundaryConditionComponent } from './components/product-boundary-condition/product-boundary-condition.component';
import { ProductSchemeComponent } from './components/product-scheme/product-scheme.component';
import { AddPartnerFscComponent } from './components/add-fsc/add-partner-fsc.component';
import { FscListingPageComponent } from './components/fsc-listing-page/fsc-listing-page.component';
import {AssignFscComponent} from './components/assign-fsc/assign-fsc.component';
import {FscDetailsComponent} from './components/fsc-details/fsc-details.component';

const routes: Routes = [
  {
    path: '', component: LoginPageComponent
  },
  {
    path: 'header', component: HeaderComponent
  },
  {
    path: 'login', component: LoginPageComponent
  },
  // {
  //   path: 'ICICI_Admin', component: LoginPageComponent
  // },
  {
    path: 'partnerListing', component: PartnerPageComponent
  },
  {
    path: 'fscListing', component: FscListingPageComponent
  },
  {
    path: 'fscMapping', component: AssignFscComponent
  },
  {
    path: 'fscDetails', component: FscDetailsComponent
  },
  {
    path: 'addPartner', component: AddPartnerComponent
  },
  {
    path: 'addPartnerFsc', component: AddPartnerFscComponent
  },
  {
    path: 'product-mapping', component: ProductMappingComponent
  },
  {
    path: 'partner-details', component: PartnerDetailsComponent
  },
  {
    path: 'product-benefits', component: ProductBenifitsComponent
  },
  {
    path: 'product-conditions', component: ProductBoundaryConditionComponent
  },
  {
    path: 'product-scheme', component: ProductSchemeComponent
  },
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' , useHash: true })
  ],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule { }

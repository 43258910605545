import { Injectable } from '@angular/core';
import { map, catchError } from "rxjs/operators";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { resolve, reject } from 'q';
import { Options } from 'selenium-webdriver/chrome';
import { environment } from '../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { RouterModule, Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})

export class ConfigService {
  // URL = 'http://192.168.3.76:8000';
  // URL = 'http://dm-staging.cloudapp.net:8089/b2btest/';
  //URL = "http://192.168.2.27:9090/b2btest";
  //URL = "http://192.168.2.27:9090/b2btest";// --dev
  // URL = "http://192.168.2.251:9090/spcgls_v7"; // --editByShefali
  // URL = "http://192.168.2.27:8001";
  // URL = "http://192.168.2.116:7000";
  // URL = "http://192.168.2.116:5959";
  //URL = "https://10.54.16.24:9443/b2btest";
  //URL = "https://10.54.12.19:9443/b2btest";

  //URL = "https://groupapprds.uat.cloud.iciciprulife.com";//-UAT

  //URL = "http://103.26.54.78:9090/b2btest";
  // URL = "http://192.168.2.27:1521";
  //URL = "http://dm-ishiva.cloudapp.net:9080/b2btest"
 
  //URL = "http://localhost:8080"
  
  //URL = "https://icici-pru.purplestone-06f04859.eastasia.azurecontainerapps.io/";
  //URL = "https://icici-pru-oracle.purplestone-06f04859.eastasia.azurecontainerapps.io/";
  //URL = "https://icici-pru-oracle.purplestone-06f04859.eastasia.azurecontainerapps.io/";
 
  //  URL = "https://primary:sxDO1rUhQTfKVH98X48YAygHoApbRcV9kxrxEB0fOxfI2GJELFuLyfL27iZp3aJ3@ipru-spring-app.test.azuremicroservices.io/icici-api/default/";
  // URL = "https://thumbs.dreamstime.com/z/happy-smile-button-badge-10990933.jpg"

  //Latest Prod URL
  URL = "https://ipru-spring-app-icici-api.azuremicroservices.io";
  
  httpHeaders;
  constructor(public http: HttpClient, public routes: Router) {
    this.httpHeaders = new HttpHeaders()
      .set('Authorization', 'Bearer' + " " + environment.loginToken)
  }
  httpService(method, service, data) {
    //var headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    this.httpHeaders = new HttpHeaders()
      .set("Content-Type", 'application/json')
      .set('Authorization', 'Bearer' + " " + environment.loginToken)

    var reqOpts = {
      headers: this.httpHeaders
    }
    //debugger;
    console.log(reqOpts);
    if (method == 'get') {
      return this.http.get(this.URL + service, reqOpts)

        .pipe(map(this.extractData), catchError(this.handleError))
    } else if (method == 'post') {
      return this.http.post(this.URL + service, data, reqOpts)
        .pipe(map(this.extractData), catchError(this.handleError))
    }
    else {
      console.log(method);
    }

  }

  private extractData(res: Response) {
    if (res != null) {
      if (String(res) == "Success")
        return res;
      else {
        try {
          // res = JSON.parse(String(res));
          return res;
        }
        catch (e) {
          return res;
        }

        // return (res["Output"] == "Success" || res["Output"] == "No records found.") ? res || {} : {};
      }
    }
    else
      return null;
  }

  private handleError(error: Response | any) {
    console.log(error);
    let errMsg: string;
    console.log("Api error: " + error);
    // return Observable.throw(error.error);
    return Promise.reject(error.error);
    //return error;
  }

  icici(obj, key, val) {
    var objects = [];
    for (var i in obj) {
      if (!obj.hasOwnProperty(i)) continue;
      if (typeof obj[i] == 'object') {
        objects = objects.concat(this.icici(obj[i], key, val));
      } else
        //if key matches and value matches or if key matches and value is not passed (eliminating the case where key matches but passed value does not)
        if (i == key && obj[i] == val || i == key && val == '') { //
          objects.push(obj);
        } else if (obj[i] == val && key == '') {
          //only add if the object is not already in the array
          if (objects.lastIndexOf(obj) == -1) {
            objects.push(obj);
          }
        }
    }
    return objects;
  }
  getValues(obj, key) {
    var objects = [];
    for (var i in obj) {
      if (!obj.hasOwnProperty(i)) continue;
      if (typeof obj[i] == 'object') {
        objects = objects.concat(this.getValues(obj[i], key));
      } else if (i == key) {
        objects.push(obj[i]);
      }
    }
    return objects;
  }

  checkUser() {
    if (environment.logedInUserName == "" || environment.logedInUserEmail == "" || environment.logingInUserId == "") {
      this.routes.navigate(["login"]);
    }
  }
}

import { Component, EventEmitter, Output, OnInit, Input, ChangeDetectorRef, HostListener, ElementRef } from '@angular/core';
import { RouterModule, Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from '../../app.component';
import { DataShareService } from '../../services/data-share.service';
import { environment } from '../../../environments/environment';
import swal from 'sweetalert2';

@Component({
  selector: 'app-custom-group',
  templateUrl: './custom-group.component.html',
  styleUrls: ['./custom-group.component.scss']
})
export class CustomGroupComponent implements OnInit {


  @Input() type: string;
  @Input() legendText: string;
  @Input() inputType: string; //number , text , product
  @Input() placeholder: string;
  @Input() icon: string;
  @Input() position: string;
  @Input() svg: string;
  @Input() items: any = {};
  @Input() max_limit: number = 0;
  @Input() columns: any = [];
  @Input() mappingId: string;
  @Input() selected: string;
  @Input() closeOnOutsideClick = true;

  dropDownEnable: boolean = false;
  //selected = '';
  selectedProduct = '';
  selectedLoan = '';
  selectedMortality = '';
  selectedValue = [];
  ss = [];
  custom_group_values: any = [];
  custom_group_values_aci: any = [];
  custom_single_object = {};
  selectedRadio: string;
  schemeProduct = [];
  selectedreductionSchedule = "";


  constructor(public app: AppComponent, public change: DataShareService, public routes: Router, public el: ElementRef) {
    this.selectedRadio = this.change.getSelectedCoverageOption();
    this.change.newselectedCoverageOptionSubject.subscribe((data) => {
      this.selectedRadio = data;
      var coverageOpt = document.querySelectorAll(".radio #coverageRadio");
      for (var i = 0; i < coverageOpt.length; i++) {
        if (coverageOpt[i].previousElementSibling.innerHTML == this.selectedRadio) {
          coverageOpt[i].parentElement.classList.add("checked");
        }
      }

    });
    this.selectedRadio = this.change.getSelectedPaymentTerm();
    this.change.newselectedPaymentTermSubject.subscribe((data) => {
      this.selectedRadio = data;
      var paymetOpt = document.querySelectorAll(".radio #coverageRadio");
      for (var i = 0; i < paymetOpt.length; i++) {
        if (paymetOpt[i].previousElementSibling.innerHTML == this.selectedRadio) {
          paymetOpt[i].parentElement.classList.add("checked");
        }
      }
    });
    this.selectedRadio = this.change.getSelectedFrequency();
    this.change.newselectedFrequencySubject.subscribe((data) => {
      this.selectedRadio = data;
      var frequecyOpt = document.querySelectorAll(".radio #coverageRadio");
      for (var i = 0; i < frequecyOpt.length; i++) {
        if (frequecyOpt[i].previousElementSibling.innerHTML == this.selectedRadio) {
          frequecyOpt[i].parentElement.classList.add("checked");
        }
      }
    })

    // this.selected = this.change.getSelectedProduct();
    // this.change.newselectedProductSubject.subscribe((data) => {
    //   this.selected = data;
    // })
  }
  ngOnInit() {
    console.log(this.items);
    switch (this.type) {
      case 'simple-dropdown':
      case 'plain-dropdown':
      case 'checked-dropdown':
        this.svg = "assets/images/drop_down.svg";
        this.position = "right";
        break;
      case 'radio-group':

        break;
      case 'radio-group2':

        break;
      case 'custom-range':

        break;
    }
    if (environment.savedProductScheme.length > 0 && this.routes.url == "/product-scheme") {
      if (environment.savedProductScheme[0].non_medical_limit_values.length > 0) {
        this.custom_group_values = this.change.getSelectedNonMedicalRate();
        this.change.newselectedNonMedicalRateSubject.subscribe((data) => {
          this.custom_group_values = data;
        })
      }
      // else {
      //   var custom_single_object = this.custom_single_object;
      //   Object.entries(this.columns).forEach(function ([key, value]) {
      //     custom_single_object[<number>value] = " "
      //   });
      //   console.log(custom_single_object);
      //   console.log(this.custom_single_object);
      //   this.custom_group_values.push(this.custom_single_object);
      // }
      if (environment.savedProductScheme[0].acici_non_medical_limit_values != undefined) {
        if (environment.savedProductScheme[0].acici_non_medical_limit_values.length > 0) {
          this.custom_group_values_aci = this.change.getSelectedNonMedicalRateCI();
          this.change.newselectedNonMedicalRateSubjectCI.subscribe((data) => {
            this.custom_group_values_aci = data;
          })
        }
      }
      // else {
      //   var custom_single_object = this.custom_single_object;
      //   Object.entries(this.columns).forEach(function ([key, value]) {
      //     custom_single_object[<number>value] = " "
      //   });
      //   console.log(custom_single_object);
      //   console.log(this.custom_single_object);
      //   this.custom_group_values.push(this.custom_single_object);
      // }
      if (this.custom_group_values.length == 0) {
        var custom_single_object = this.custom_single_object;
        Object.entries(this.columns).forEach(function ([key, value]) {
          custom_single_object[<number>value] = " "
        });
        console.log(custom_single_object);
        console.log(this.custom_single_object);
        this.custom_group_values.push(this.custom_single_object);
      }
      if (this.custom_group_values_aci.length == 0) {
        var custom_single_object = this.custom_single_object;
        Object.entries(this.columns).forEach(function ([key, value]) {
          custom_single_object[<number>value] = " "
        });
        console.log(custom_single_object);
        console.log(this.custom_single_object);
        this.custom_group_values_aci.push(this.custom_single_object);
      }
    }
    else {
      var custom_single_object = this.custom_single_object;
      Object.entries(this.columns).forEach(function ([key, value]) {
        custom_single_object[<number>value] = " "
      });
      console.log(custom_single_object);
      console.log(this.custom_single_object);
      this.custom_group_values.push(this.custom_single_object);
      this.custom_group_values_aci.push(this.custom_single_object);
    }
  }
  @Output() valueChange = new EventEmitter();

  performSelection(event, item) {
    if (event.target.closest('fieldset').attributes.id.value == "productMapping") {
      if (item == 'none') {
        this.selected = "";
        environment.selectedProduct_Id = "";
        this.change.setSelectedProduct(this.selected);
      }
      else {
        this.selected = item.item_name;
        var previousProduct = this.change.getSelectedProduct()
        if (previousProduct != "" && previousProduct != this.selected) {
          environment.savedProductMapping = []
          environment.savedProductBenefit = []
          environment.savedProductOtherBenefit = []
          environment.savedTillProductBoundryCondition = []
          environment.savedProductBoundryCondition = {}
          environment.savedProductScheme = []
        }
        environment.selectedProduct_Id = item.item_id;
        this.change.setSelectedProduct(this.selected);
      }

    }
    else if (event.target.closest('fieldset').attributes.id.value == "loanMapping") {
      if (item == 'none') {
        this.selected = "";
        environment.selectedLoan_Id = "";
        this.change.setSelectedLoan(this.selected);
      }
      else {
        this.selected = item.item_name;
        environment.selectedLoan_Id = item.item_id;
        this.change.setSelectedLoan(this.selected);
      }

    }
    else if (event.target.closest('fieldset').attributes.id.value == "mortalityMapping") {
      if (item == 'none') {
        this.change.setSelectedMortalityRate("");
        this.selected = "";
        environment.selectedMortality_Id = "";
        this.change.setSelectedMortality(this.selected);
      }
      else {
        this.change.setSelectedMortalityRate("");
        this.selected = item.item_name;
        environment.selectedMortality_Id = item.item_id;
        this.change.setSelectedMortality(this.selected);
      }
    }
    else if (event.target.closest('fieldset').attributes.id.value == "criticalIllnessMapping") {
      if (item == 'none') {
        this.selected = "";
        environment.selectedCriticalIllness_Id = "";
        this.change.setSelectedCriticalIllness(this.selected);
      } else {
        this.selected = item.item_name;
        environment.selectedCriticalIllness_Id = item.item_id;
        this.change.setSelectedCriticalIllness(this.selected);
      }

    }
    else if (event.target.closest('fieldset').attributes.id.value == "nonMedicalLimits") {
      if (item == 'none') {
        this.selected = "";
        environment.selectedNonMedicalLimits_Id = "";
        this.change.setSelectedNonMedical(this.selected);
      }
      else {
        this.selected = item.item_name;
        environment.selectedNonMedicalLimits_Id = item.item_id;
        this.change.setSelectedNonMedical(this.selected);
      }

    }
    else if (event.target.closest('fieldset').attributes.id.value == "reductionScheduleMapping") {
      if (item == 'none') {
        this.selected = "";
        environment.selectedReductionSchedule_Id = "";
        this.change.setReductionSchedule(this.selected);
      } else {
        this.selected = item.item_name;
        environment.selectedReductionSchedule_Id = item.item_name;
        environment.selectedReductionScheduleCode = item.item_code;
        // environment.selectedReductionSchedule_Id = item.item_id;
        this.change.setReductionSchedule(this.selected);
      }

    }
    else if (event.target.closest('fieldset').attributes.id.value == "entityTypeMapping") {
      if (item == 'none') {
        this.selected = "";
        environment.selectedEntityId = "";
        this.change.setEntityType(this.selected);
      } else {
        this.selected = item.item_name;
        environment.selectedEntityId = item.item_name;
        environment.selectedEntityName = item.item_id;
        // environment.selectedReductionSchedule_Id = item.item_id;
        this.change.setEntityType(this.selected);
      }

    }
    else if (event.target.closest('fieldset').attributes.id.value == "asseleratedCIBenefit") {
      if (item == 'none') {
        this.selected = "";
        // environment.selectedProduct_Id = "";
        this.change.setAsseleratedCI(this.selected);
      }
      else {
        this.selected = item.item_name;
        this.change.setAsseleratedCI(this.selected);
      }

    }
    else if (event.target.closest('fieldset').attributes.id.value == "additionalCIBenefit") {
      if (item == 'none') {
        this.selected = "";
        //environment.selectedProduct_Id = "";
        this.change.setAdditionalCI(this.selected);
      }
      else {
        this.selected = item.item_name;
        this.change.setAdditionalCI(this.selected);
      }

    }
    else if (event.target.closest('fieldset').attributes.id.value == "interestRates") {
      if (item == 'none') {
        this.selected = "";
        //environment.selectedProduct_Id = "";
        this.change.setInterestRate(this.selected);
      }
      else {
        this.selected = item.item_name;
        this.change.setInterestRate(this.selected);
      }

    }
    else if (event.target.closest('fieldset').attributes.id.value == "hospitalWaiverBenefit") {
      if (item == 'none') {
        this.selected = "";
        //environment.selectedProduct_Id = "";
        this.change.setWaiverHospital(this.selected);
      }
      else {
        this.selected = item.item_name;
        this.change.setWaiverHospital(this.selected);
      }

    }
    else if (event.target.closest('fieldset').attributes.id.value == "emiWaiver7") {
      if (item == 'none') {
        this.selected = "";
        //environment.selectedProduct_Id = "";
        this.change.setemi7(this.selected);
      }
      else {
        this.selected = item.item_name;
        this.change.setemi7(this.selected);
      }

    }
    else if (event.target.closest('fieldset').attributes.id.value == "emiWaiver15") {
      if (item == 'none') {
        this.selected = "";
        //environment.selectedProduct_Id = "";
        this.change.setemi15(this.selected);
      }
      else {
        this.selected = item.item_name;
        this.change.setemi15(this.selected);
      }

    }
    else if (event.target.closest('fieldset').attributes.id.value == "singleJointLife") {
      if (item == 'none') {
        this.selected = "";
        // environment.selectedProduct_Id = "";
        this.change.setsingleJointLife(this.selected);
      }
      else {
        this.selected = item.item_name;
        this.change.setsingleJointLife(this.selected);
      }

    }
    else if (event.target.closest('fieldset').attributes.id.value == "coverageOptionBenefit") {
      if (item == 'none') {
        this.selected = "";
        // environment.selectedProduct_Id = "";
        this.change.setcoverageOptions(this.selected);
      }
      else {
        this.selected = item.item_name;
        this.change.setcoverageOptions(this.selected);
      }
    }
    else if (event.target.closest('fieldset').attributes.id.value == "moratoriumPeriod") {
      if (item == 'none') {
        this.selected = "";
        // environment.selectedProduct_Id = "";
        this.change.setmoratoriumPeriod(this.selected);
      }
      else {
        this.selected = item.item_name;
        this.change.setmoratoriumPeriod(this.selected);
      }
    }
    else if (event.target.closest('fieldset').attributes.id.value == "moratoriumTerm") {
      if (item == 'none') {
        this.selected = "";
        // environment.selectedProduct_Id = "";
        this.change.setmoratoriumTerm(this.selected);
      } else {
        this.selected = item.item_name;
        this.change.setmoratoriumTerm(this.selected);
      }
    }
    else if (event.target.closest('fieldset').attributes.id.value == "flexible") {
      if (item == 'none') {
        this.selected = "";
        // environment.selectedProduct_Id = "";
        this.change.setSelectedFlexibility(this.selected);
      } else {
        this.selected = item.item_name;
        this.change.setSelectedFlexibility(this.selected);
      }
    }
    else if (event.target.closest('fieldset').attributes.id.value == "channel") {
      if (item == 'none') {
        this.selected = "";
        // environment.selectedProduct_Id = "";
        this.change.setSelectedChannel(this.selected);
      } else {
        this.selected = item.item_name;
        this.change.setSelectedChannel(this.selected);
      }
    }
    else if (event.target.closest('fieldset').attributes.id.value == "aci_ci") {
      if (item == 'none') {
        this.selected = "";
        // environment.selectedProduct_Id = "";
        this.change.setSelectedAcici(this.selected);
      } else {
        this.selected = item.item_name;
        this.change.setSelectedAcici(this.selected);
      }
    }
    else if (event.target.closest('fieldset').attributes.id.value == "tableRefMapping") {
      environment.selectedTableReference = ""
      if (item == 'none') {
        this.selected = "";
        this.valueChange.emit(this.selected)
      } else {
        this.selected = item.item_name;
        this.valueChange.emit(this.selected)
      }
    }
    //else if(event.target.closest('fieldset').attriutes.id.value == "")


    // console.log(item);
    // this.selected = item.title;
    // this.change.setData(this.selected);
    // console.log(this.selected);
  }

  getSiblings(el) {
    //Returns all siblings of current element
    var siblings = [];
    el = el.parentNode.firstElementChild;
    do {
      siblings.push(el);
    } while (el = el.nextElementSibling);
    return siblings;
  }

  checkedDropDown(event) {
    var target;
    target = event.target.classList.contains('checkboxLegend') ? event.target : event.target.parentElement;
    console.log(target.nextElementSibling);
    if (target.classList.contains('checked')) {
      target.classList.remove('checked');
      target.nextElementSibling.classList.add('disabled');
    }
    else {
      target.classList.add('checked');
      target.nextElementSibling.classList.remove('disabled');
    }
  }

  toggleDropDown(event) {
    var target;
    target = event.target.classList.contains('dropdown') ? event.target : this.app.getAncestorByClassName(event.target, 'dropdown');
    if (this.dropDownEnable)
      target.querySelector('img').classList.remove('flip-horizontal');

    else
      target.querySelector('img').classList.add('flip-horizontal');
    this.dropDownEnable = !this.dropDownEnable;
  }

  @Output() valueSelected = new EventEmitter();

  toggleRadio(event, item, code) {
    var target;
    var data = [];
    target = event.target.classList.contains('radio') ? event.target : this.app.getAncestorByClassName(event.target, 'radio');
    var siblingsList = this.getSiblings(target);
    siblingsList.forEach(function (sibling) {
      if (sibling.classList.contains('checked'))
        sibling.classList.remove('checked');
    });

    if (target.classList.contains('checked')) {
      if (event.target.closest('fieldset').attributes.id.value == "paymentTermRadio") {
        target.classList.remove('checked');
        this.selectedRadio = "";
        data.push({ "name": this.selectedRadio, "code": "" });
        this.change.setSelectedPaymentTerm(data);
      }
      else if (event.target.closest('fieldset').attributes.id.value == "frequency-radio") {
        target.classList.remove('checked');
        this.selectedRadio = "";
        data.push({ "name": this.selectedRadio, "code": "" });
        this.change.setSelectedFrequency(data);
      }
      else if (event.target.closest('fieldset').attributes.id.value == "coverageRadio") {
        target.classList.remove('checked');
        this.selectedRadio = "";
        data.push({ "name": this.selectedRadio, "code": "" });
        this.change.setSelectedCoverageOption(data);
      } else if (event.target.closest('fieldset').attributes.id.value == "benefitRadio") {
        target.classList.remove('checked');
        this.valueSelected.emit(item.item_name)
      }
    }

    else {
      if (event.target.closest('fieldset').attributes.id.value == "paymentTermRadio") {
        target.classList.add('checked');
        this.selectedRadio = item.item_name;
        data.push({ "name": item.item_name, "code": code });
        this.change.setSelectedPaymentTerm(data);
      }
      else if (event.target.closest('fieldset').attributes.id.value == "frequency-radio") {
        target.classList.add('checked');
        this.selectedRadio = item.item_name;
        data.push({ "name": item.item_name, "code": code });
        this.change.setSelectedFrequency(data);
      }
      else if (event.target.closest('fieldset').attributes.id.value == "coverageRadio") {
        target.classList.add('checked');
        this.selectedRadio = item.item_name;
        data.push({ "name": item.item_name, "code": code });
        this.change.setSelectedCoverageOption(data);
      } else if (event.target.closest('fieldset').attributes.id.value == "benefitRadio") {
        target.classList.add('checked');
        this.valueSelected.emit(item.item_name)
      }
    }

  }

  addMore(event) {
    var dataCheck = this.custom_group_values.length;
    //var dd = this.custom_group_values[0]
    var count = 0;
    for (var i = 0; i < dataCheck; i++) {
      if (this.custom_group_values[i]["Age Band"] == " " || this.custom_group_values[i]["Age Band"] == undefined || this.custom_group_values[i]["Amount"] == " ") {
        event.target.parentElement.parentElement.classList.add("error");
        swal({
          title: "Please fill the above fields before adding more",
          type: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#eb1826',
          cancelButtonColor: '#F4F1F1',
          confirmButtonText: 'OK'
        }).then((result) => {
          // this.routes.navigate(["login"]);
        })
      }
      else {
        count++;
      }
    }
    if (count == dataCheck) {
      event.target.parentElement.parentElement.classList.remove("error");
      if (this.custom_group_values.length < this.max_limit) this.custom_group_values.push(this.custom_single_object);
    }
    //if (this.custom_group_values.length < this.max_limit) this.custom_group_values.push(this.custom_single_object);
  }

  addMoreCI(event) {
    var dataCheck = this.custom_group_values_aci.length;
    var count = 0;
    for (var i = 0; i < dataCheck; i++) {
      if (this.custom_group_values_aci[i]["ACI/CI Age Band"] == " " || this.custom_group_values_aci[i]["ACI/CI Age Band"] == undefined || this.custom_group_values_aci[i]["ACI/CI Amount"] == " ") {
        event.target.parentElement.parentElement.classList.add("error");
        swal({
          title: "Please fill the above fields before adding more",
          type: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#eb1826',
          cancelButtonColor: '#F4F1F1',
          confirmButtonText: 'OK'
        }).then((result) => {
        })
      }
      else {
        count++;
      }
    }
    if (count == dataCheck) {
      event.target.parentElement.parentElement.classList.remove("error");
      if (this.custom_group_values_aci.length < this.max_limit) this.custom_group_values_aci.push(this.custom_single_object);
    }
    //if (this.custom_group_values.length < this.max_limit) this.custom_group_values.push(this.custom_single_object);
  }
  addValue(index, value1, value2) {

  }

  deleteOne(index) {
    console.log(index);
    this.custom_group_values.splice(index, 1);
  }

  deleteOne_aci(index) {
    console.log(index);
    this.custom_group_values_aci.splice(index, 1);
  }

  focus(event) {
    event.target.focus();
  }

  checkAgeBand(event) {
    if (event.target.id.includes("Age Band")) {
      var value = event.target.value;
      var splitdata = value.split("-");
      if (splitdata[0] != " ") {
        if (splitdata.length == 1) {
          if (splitdata[0] < environment.fixedData[0].min_age_at_entry || splitdata[0] > environment.fixedData[0].max_age_at_entry) {
            // this.correctVal = false
            event.target.value = "";
            swal({
              title: 'Please enter value between min age' + " " + environment.fixedData[0].min_age_at_entry + " " + 'and' + " " + 'max age' + " " + environment.fixedData[0].max_age_at_entry + "" + ' of the product.',
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#eb1826',
              cancelButtonColor: '#F4F1F1',
              confirmButtonText: 'OK'
            }).then((result) => {
              event.target.value = "";
            })
          }
          else {
          }
        }
        else if (splitdata.length > 1) {
          if (parseInt(splitdata[0]) > parseInt(splitdata[1])) {
            // this.correctVal = false
            event.target.value = "";
            swal({
              title: "Value is incorrect.",
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#eb1826',
              cancelButtonColor: '#F4F1F1',
              confirmButtonText: 'OK'
            }).then((result) => {
              event.target.value = "";
            })
          }
          else {
          }
          if ((splitdata[0] < environment.fixedData[0].min_age_at_entry || splitdata[0] > environment.fixedData[0].max_age_at_entry ||
            splitdata[1] < environment.fixedData[0].min_age_at_entry || splitdata[1] > environment.fixedData[0].max_age_at_entry)) {
            // this.correctVal = false
            event.target.value = "";
            swal({
              title: 'Please enter value between min age' + " " + environment.fixedData[0].min_age_at_entry + " " + 'and' + " " + 'max age' + " " + environment.fixedData[0].max_age_at_entry + "" + ' of the product.',
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#eb1826',
              cancelButtonColor: '#F4F1F1',
              confirmButtonText: 'OK'
            }).then((result) => {
              event.target.value = "";
            })
          } else {
          }
        }
      }
    }
  }

  storeValue(event, i, column) {
    this.checkAgeBand(event);
    if (event.target.value != "") {
      var name = event.target.parentElement.parentElement.querySelector('#Age\\ Band').id
      var legend = event.target.parentElement.parentElement.parentElement.querySelector('legend').innerText
      if (name == "Age Band" && legend == "AGE WISE") {
        this.ss[i] = ({ "Age Band":  event.target.parentElement.parentElement.childNodes[1].querySelector("input").value.trim(), 'Amount': event.target.parentElement.parentElement.childNodes[2].querySelector("input").value.trim() });
        this.custom_group_values[i] = this.ss[i];
        this.change.setSelectedNonMedicalRate(this.custom_group_values);
      }
    }
    else
      console.log(event.target.id);
  }

  storeValueCI(event, i, column) {
    this.checkAgeBand(event);
    if (event.target.value != "") {
      var name = event.target.parentElement.parentElement.querySelector('#ACI\\/CI\\ Age\\ Band').id
      var legend = event.target.parentElement.parentElement.parentElement.querySelector('legend').innerText
      if (name == "ACI/CI Age Band" && legend == "ACI/CI AGE WISE") {
        this.ss[i] = ({ "ACI/CI Age Band": event.target.parentElement.parentElement.childNodes[1].querySelector("input").value.trim(), 'ACI/CI Amount':event.target.parentElement.parentElement.childNodes[2].querySelector("input").value.trim() });
        this.custom_group_values_aci[i] = this.ss[i];
        this.change.setSelectedNonMedicalRateCI(this.custom_group_values_aci);
      }
    }
    else
      console.log(event.target.id);
  }

  @HostListener('document:click', ['$event'])
  @HostListener('document:touchstart', ['$event'])
  handleOutsideClick(event) {
    // Some kind of logic to exclude clicks in Component.
    // This example is borrowed Kamil's answer
    if (!this.el.nativeElement.contains(event.target)) {
      var target = this.el.nativeElement.querySelectorAll('.dropdown');
      for (var i = 0; i < target.length; i++) {
        if (this.dropDownEnable) {
          target[i].querySelector('img').classList.remove('flip-horizontal');
          this.dropDownEnable = !this.dropDownEnable;
        }

      }
    }
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  host: {
    '(window:keydown)': 'InspectDisable($event)',
    '(window:contextmenu)': 'InspectDisableRight($event)'
  }
})
export class AppComponent {
  title = 'icici-admin-portal';




  // InspectDisableRight(event) {

  //   event.preventDefault();



  // }

  getAncestorByClassName(target, className) {
    var currentParent = target.parentElement;
    while (true) {
      // If the root of the DOM is reached, an ancestor cannot be found anymore.
      if (currentParent === null) {
        return null;
      }

      if (currentParent.classList.contains(className)) {
        return currentParent;
      }



      var currentParent = currentParent.parentElement;


    }
  }






  InspectDisable(event) {

    if (event.keyCode == 123) {
      return false;//Prevent from F12
    }
    else if (event.ctrlKey && event.shiftKey && event.keyCode == 73) {
      return false; //Prevent from ctrl+shift+i
    }



  }




}
